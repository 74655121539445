/* eslint-disable arrow-body-style */
import React, { FC } from "react";

import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { LocalLoader } from "components/Loader/LocalLoader";
import { HomeCheckboxBuildingsStore, HomeCheckboxWorksStore } from "screens/Home/store/HomeCheckbox.store";

import CalendarTable from "./CalendarTable/CalendarTable";
import CalendarTableStore from "./CalendarTable/store/CalendarTable.Store";
import getOffsetFromCurrentMonth from "./CalendarTable/utils/getOffsetFromCurrentMonth";
import GantDataTable from "./DataTable/DataTable";
import useAreaQuery from "./hooks/useAreaQuery";

interface IGant {}

const getInitialWidthTable = () => {
  const width = window.innerWidth;
  const percents40 = (width / 100) * 40;

  if (percents40 < 900) {
    return {
      "40": "900px",
      "60": `${width - 900 - 40}px`,
    };
  }
  return {
    "40": `${percents40}px`,
    "60": `${(width / 100) * 60 - 40}px`,
  };
};

const Gant: FC<IGant> = observer(() => {
  const {
    fn: { getFilteredBuildings, getFilteredJobsData },
    isLoading,
    areaStatistic,
  } = useAreaQuery();
  const calendarTableRef = React.useRef<HTMLDivElement>();
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      const offsetFromStart = getOffsetFromCurrentMonth(CalendarTableStore.calendarMonths);

      if (offsetFromStart) {
        calendarTableRef.current.scrollTo({
          left: offsetFromStart,
          behavior: "smooth",
        });
      }
    }, 600);
  }, [CalendarTableStore.currentOffsetCurrentDayLine]);

  React.useEffect(() => {
    if (!isEmpty(areaStatistic.data?.buildings)) {
      HomeCheckboxBuildingsStore.setCheckboxItems(areaStatistic.data.buildings);
    }
  }, [areaStatistic.data]);

  const isEmptyFilteredData = isEmpty(getFilteredBuildings()) || isEmpty(getFilteredJobsData().filter((row) => !("emptyRow" in row)));
  const isEmptyBuildingsOrWorks = isEmpty(HomeCheckboxBuildingsStore.checkboxItems) || isEmpty(HomeCheckboxWorksStore.checkboxItems);

  const onButtonFillClick = () => {
    navigate("/objects_edit");
  };

  const initialWidthPercentage = getInitialWidthTable();
  const [percents60OfScreen, setPercents60OfScreen] = React.useState(initialWidthPercentage["60"]);
  const [percents40OfScreen, setPercents40OfScreen] = React.useState(initialWidthPercentage["40"]);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      const percents40 = (window.innerWidth / 100) * 40;
      if (percents40 < 900) {
        setPercents40OfScreen("900px");
        setPercents60OfScreen(`${window.innerWidth - 900 - 40}px`);
      } else {
        setPercents60OfScreen(`${(window.innerWidth / 100) * 60 - 40}px`);
        setPercents40OfScreen(`${(window.innerWidth / 100) * 40}px`);
      }
    });
  }, []);

  return (
    <>
      <Container>
        {isEmptyBuildingsOrWorks && (
          <EmptyBlockContainer>
            <EmptyPlaceholder
              header={"У вас еще нет данных по объектам"}
              description={"Вам нужно заполнить всю необходимою информацию по объектам"}
              buttonText={"Заполнить"}
              onButtonClick={onButtonFillClick}
            />
          </EmptyBlockContainer>
        )}

        {!isEmptyBuildingsOrWorks && isEmptyFilteredData && (
          <EmptyBlockContainer>
            <EmptyPlaceholder header={""} description={"Выберите литер и работу."} buttonText={""} />
          </EmptyBlockContainer>
        )}

        {!isEmptyFilteredData && (
          <>
            <DataTableContainer width={percents40OfScreen}>
              <GantDataTable />
            </DataTableContainer>
            <CalendarTableContainer width={percents60OfScreen} ref={calendarTableRef}>
              <CalendarTable />
            </CalendarTableContainer>
          </>
        )}
        {/* </RenderWithCondition> */}
        <LocalLoader condition={isLoading} posY="40px" />
      </Container>
      {/* <CalendarTableZoom /> */}
    </>
  );
});

export default Gant;

const Container = styled.div`
  display: flex;
  position: relative;
  width: ${window.innerWidth - 30}px;
`;

const DataTableContainer = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  flex-shrink: 0;
`;

const CalendarTableContainer = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 0;
`;

const EmptyBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 18px;
`;
