import React, { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { useMutation } from "react-query";
import styled from "styled-components";

import { createMeasureUnitApi, updateMeasureApi } from "api/References/api.measureUnits";
import { queryClient } from "App";
import ButtonLight from "components/ButtonLight";
import ModalForm from "components/Modal/ModalForm";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import { ErrorsCode } from "models/ErrorsCode";
import { QueriesKeys } from "models/QueriesKeys";
import { IMeasureUnit } from "models/References/IMeasureUnit";
import { Screen } from "styles/common";
import notify from "utils/notify";

import MeasureTable from "./components/MeasureTable";

const MeasureUnits = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpenUpdate, setOpenUpdate] = useState(false);
  const text = useText();

  const restrictedData = useRestrictedAccessData();
  const [currentMeasure, setCurrentMeasure] = React.useState<IMeasureUnit>();

  const [measureValue, setMeasureValue] = React.useState("");

  useEffect(() => {
    if (currentMeasure) {
      setMeasureValue(currentMeasure?.value ?? "");
    }
  }, [currentMeasure]);

  const measureCreateMutation = useMutation(createMeasureUnitApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.measures);
      setMeasureValue("");
      setIsVisible(false);
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const updateMeasure = useMutation(updateMeasureApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.measures]);
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const mapFormMeasure = React.useMemo(
    () => [
      {
        type: "input",
        placeholder: text.pholderMeasure,
        value: measureValue,
        onChange: (e) => {
          setMeasureValue(e.target.value);
        },
      },
    ],
    [text, measureValue]
  );

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleOpenModalUpdate = (data: IMeasureUnit) => {
    setOpenUpdate(true);
    setCurrentMeasure(data);
  };

  const onSave = () => {
    if (measureValue) {
      measureCreateMutation.mutateAsync(measureValue);
    }
  };

  const onUpdate = () => {
    if (measureValue) {
      updateMeasure.mutateAsync({
        body: {
          value: measureValue,
        },
        path: { id: currentMeasure?.id },
      });
    }
  };

  return (
    <Screen className="w-full">
      <Container className="flex w-full">
        <TableContainer className="flex flex-col">
          <Text className="text-xl font-bold">{text.referenceMeasureUnitsFull}</Text>
          <MeasureTable onClickHandle={handleOpenModalUpdate} />
          {restrictedData.vars.canChangeDataOfReference && (
            <ButtonRow className="flex">
              <ButtonLight type="secondary" title={text.add} onClick={handleOpenModal} />
            </ButtonRow>
          )}
        </TableContainer>
        <RenderWithCondition condition={isVisible}>
          <ModalForm
            label={text.addMeasure}
            textButton={text.save}
            setIsVisible={setIsVisible}
            data={mapFormMeasure}
            onSave={onSave}
            isError={!!measureCreateMutation.error}
          />
        </RenderWithCondition>
        <RenderWithCondition condition={isOpenUpdate}>
          <ModalForm
            label={"Обновить данные"}
            textButton={"Обновить"}
            setIsVisible={setOpenUpdate}
            data={mapFormMeasure}
            onSave={onUpdate}
            isLoading={updateMeasure.isLoading}
            isError={!!updateMeasure.error}
          />
        </RenderWithCondition>
      </Container>
    </Screen>
  );
};

export default MeasureUnits;

const TableContainer = styled.div`
  flex: 1;
`;
const Text = styled.p`
  color: ${(props) => props.theme.colors.absolute700};
  padding: 20px;
`;
const Container = styled.section`
  position: relative;
`;
const ButtonRow = styled.p`
  padding: 20px;
`;
