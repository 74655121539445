import React, { FC } from "react";

import { createPortal } from "react-dom";
import styled from "styled-components";

interface ISimpleModal {
  children: JSX.Element;
  isVisibleModal: boolean;
}

const SimpleModal: FC<ISimpleModal> = (props) => (
  <>{props.isVisibleModal && createPortal(<ModalBackdrop>{props.children}</ModalBackdrop>, document.getElementById("modal-root"))}</>
);

export default SimpleModal;

const ModalBackdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
