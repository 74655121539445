import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";

import TextProvider from "providers/TextProvider";
import MainRouter from "routes/MainRouter";
import { LIGHT_THEME } from "theme/colors";

import "react-toastify/dist/ReactToastify.css";
import "./dayjs.config";

export const queryClient = new QueryClient();

queryClient.setDefaultOptions({
  queries: {
    staleTime: 1 * (60 * 1000), // 1 min
    refetchOnWindowFocus: process.env.NODE_ENV === "production",
    refetchInterval: false,
    retry: 1,
  },
});

function App() {
  const { host } = window.location;
  const link = document.querySelector("link[rel='icon']");

  if (link && (host === "dev-gpr.tap2v.com" || host === "localhost:3000")) {
    link.setAttribute("href", "gpr-icon-grey.svg");
  }
  if (link && host === "test-gpr.tap2v.com") {
    link.setAttribute("href", "gpr-icon-red.svg");
  }
  if (link && host === "gpr.tap2v.com") {
    link.setAttribute("href", "gpr-icon-blue.svg");
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={{ colors: LIGHT_THEME }}>
        <TextProvider>
          <MainRouter />
          <ToastContainer />
        </TextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
