import { FC } from "react";

import styled from "styled-components";

import { CELL_HEIGHT } from "../constants/common";

interface IGantTableHeaderCell extends React.HTMLProps<HTMLParagraphElement> {
  children?: any;
}

const GantTableHeaderCell: FC<IGantTableHeaderCell> = (props) => {
  const { ...otherParagraphProps } = props;
  return <HeaderCellText {...otherParagraphProps}>{props.children}</HeaderCellText>;
};

export default GantTableHeaderCell;

const HeaderCellText = styled.p`
  font-size: 12px;
  background-color: ${(props) => props.theme.colors.blue100};
  border-right: 1px solid ${(props) => props.theme.colors.absolute200};
  border-bottom: 1px solid ${(props) => props.theme.colors.absolute200};
  white-space: pre;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${CELL_HEIGHT};
  color: ${(props) => props.theme.colors.absolute700};
`;
