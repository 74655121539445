import React, { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import { createWorkApi, updateWorkApi } from "api/References/api.works";
import { queryClient } from "App";
import ButtonLight from "components/ButtonLight";
import ModalForm from "components/Modal/ModalForm";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useMeasureListBySearch from "hooks/useMeasureBySearch/useMeasureListBySearch";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import { ErrorsCode } from "models/ErrorsCode";
import { QueriesKeys } from "models/QueriesKeys";
import { IWork } from "models/References/IWork";
import { Screen, Spacer } from "styles/common";
import notify from "utils/notify";

import InfoTemplate from "./components/InfoTemplate";
import WorksTable from "./components/WorksTable";

const Reference = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpenUpdate, setOpenUpdate] = useState(false);
  const text = useText();
  const restrictedData = useRestrictedAccessData();

  const [currentWork, setCurrentWork] = React.useState<IWork>();
  const [workName, setWorkName] = React.useState("");
  const [measureID, setMeasureID] = React.useState("");

  const { measures, searchInputMeasures, setInputMeasures } = useMeasureListBySearch();

  useEffect(() => {
    if (currentWork) {
      setWorkName(currentWork?.name ?? "");
    }
  }, [currentWork]);

  const createWork = useMutation(createWorkApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.works);
      setWorkName("");
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const updateWork = useMutation(updateWorkApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.works]);
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const onSearhInput = (e) => {
    setInputMeasures(e.target.value);
  };

  const mapFormWorkTypes = React.useMemo(
    () => [
      { type: "input", placeholder: text.jobTitle, value: workName, onChange: (e) => setWorkName(e.target.value) },
      {
        type: "select",
        selectData: measures,
        selectPlaceholder: "Ед. изм",
        topPlaceholder: "Ед. изм",
        isFullList: true,
        addNewElement: {
          text: text.addMeasure,
          type: "Measure",
          onChangeInputSearch: onSearhInput,
          valueInputSearch: searchInputMeasures,
        },
        onSelect: (item) => {
          setMeasureID(item.id);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workName, measures]
  );

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleOpenModalUpdate = (data: IWork) => {
    setOpenUpdate(true);
    setCurrentWork(data);
  };

  const onSave = () => {
    if (workName) {
      createWork.mutateAsync({ name: workName, measureUnitId: measureID });
    }
  };

  const onUpdate = () => {
    if (workName) {
      updateWork.mutateAsync({
        body: {
          name: workName,
          measureUnitId: measureID,
        },
        workId: currentWork?.id,
      });
    }
  };

  return (
    <>
      <Outlet />
      <Screen className="w-full">
        <Container className="flex w-full">
          <TableContainer className="flex flex-col">
            <Text className="text-xl font-bold">{text.referenceWorks}</Text>
            <WorksTable onClickHandle={handleOpenModalUpdate} />
            {restrictedData.vars.canChangeDataOfReference && (
              <ButtonRow className="flex">
                <ButtonLight type="secondary" title={text.add} onClick={handleOpenModal} />
              </ButtonRow>
            )}
          </TableContainer>
          <Spacer px={30} horizontal />
          <InfoTemplate />
          <RenderWithCondition condition={isVisible}>
            <ModalForm
              onSave={onSave}
              textButton={text.save}
              data={mapFormWorkTypes}
              label={text.addWorkTypes}
              setIsVisible={setIsVisible}
              isLoading={createWork.isLoading}
              isError={!!createWork.error}
            />
          </RenderWithCondition>
          <RenderWithCondition condition={isOpenUpdate}>
            <ModalForm
              onSave={onUpdate}
              textButton={"Сохранить"}
              data={mapFormWorkTypes}
              label={"Добавить наименование работы"}
              setIsVisible={setOpenUpdate}
              isLoading={updateWork.isLoading}
              isError={!!updateWork.error}
            />
          </RenderWithCondition>
        </Container>
      </Screen>
    </>
  );
};

export default Reference;

const TableContainer = styled.div`
  flex: 1;
  position: relative;
`;
const Container = styled.section`
  padding-top: 20px;
  max-width: 1310px;
  @media (max-width: 1023px) {
    /* width: 100%; */
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
`;
const Text = styled.p`
  color: ${(props) => props.theme.colors.absolute700};
  padding-left: 20px;
  padding-bottom: 20px;
`;
const ButtonRow = styled.p`
  padding: 20px;
`;
