import { FC, useState } from "react";

import classNames from "classnames";
import styled from "styled-components";

import { LocalLoader } from "components/Loader/LocalLoader";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import { cumulativeLines } from "screens/Home/constants";

import { IHistogramsData } from "../Screens/components/WorkItem";

import { TextInfo } from "./BarChart.Item";
import HeaderChartInner from "./HeaderChart.Inner";
import LineChartItem from "./LineChart.Item";
import RectanglesLine from "./RectanglesLine";
import TooltipChart from "./TooltipLine";

const titlesLines = ["План накопительное", "Факт накопительное"];

const commonTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return <TooltipChart label={label} payload={payload} />;
  }
  return null;
};

interface ILineChartCumulative {
  title?: string;
  data: IHistogramsData[];
  isValuesOfData?: boolean;
  isWeekly?: boolean;
  isLargeScreen?: boolean;
}

const LineChartCumulative: FC<ILineChartCumulative> = (props) => {
  const [isActive, setActive] = useState(false);
  const sizeOfChart = props.isValuesOfData ? 72 : 270;

  return (
    <Container className={classNames("flex flex-col justify-center ")}>
      <HeaderChartInner rectanglesLine={titlesLines} isLargeScreen={!props.isValuesOfData && !props.isLargeScreen} />
      <div className={`flex w-full ${props.isValuesOfData ? "h-[71px]" : null}`}>
        <ChartContainer className={`flex ${props.isLargeScreen ? "w-[80%]" : "w-[100%]"}`} height={sizeOfChart}>
          <RenderWithCondition condition={props.isValuesOfData}>
            <TextInfo className="flex">Нет данных для графика</TextInfo>
          </RenderWithCondition>
          <LineChartItem
            dot={{ r: 0 }}
            pointXaxis="date"
            data={props.data}
            renderLine={cumulativeLines}
            externalTooltip={commonTooltip}
            isValuesOfData={props.isValuesOfData}
            isWeekly={props.isWeekly}
          />
          <LocalLoader condition={false} />
        </ChartContainer>
        <RenderWithCondition condition={!props.isValuesOfData && props.isLargeScreen}>
          <RectanglesLine mapTitleLines={titlesLines} vertical />
        </RenderWithCondition>
      </div>
    </Container>
  );
};

export default LineChartCumulative;

export const Container = styled.div`
  padding: 10px 10px;
  justify-content: center;
`;

const ChartContainer = styled.div<{ height: number }>`
  flex-direction: column;
  position: relative;
  height: ${(props) => `${props.height}px`};
  padding: 6px 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.absolute100};
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
`;

export const TitleLine = styled.p<{ weight?: number; fontSize?: number }>`
  font-family: Inter;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "16px")};
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  color: ${(props) => props.theme.colors.absolute700};
  line-height: 24px;
`;
