import React, { Dispatch, SetStateAction } from "react";

import { AxiosError } from "axios";
import { useMutation } from "react-query";

import { createContractorApi } from "api/References/api.contractor";
import { createMeasureUnitApi } from "api/References/api.measureUnits";
import { createWorkApi } from "api/References/api.works";
import { queryClient } from "App";
import useMeasureListBySearch from "hooks/useMeasureBySearch/useMeasureListBySearch";
import useText from "hooks/useText";
import { ErrorsCode } from "models/ErrorsCode";
import { Events } from "models/Events";
import { QueriesKeys } from "models/QueriesKeys";
import emitter from "services/emitter";
import notify from "utils/notify";

type THookArgs = {
  openInnerForm?: Dispatch<SetStateAction<boolean>>;
};

const useCreateReferenceElements = (args?: THookArgs) => {
  const text = useText();

  const [name, setName] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");

  const [workName, setWorkName] = React.useState("");

  const [measureValue, setMeasureValue] = React.useState("");
  const { measures, searchInputMeasures, setInputMeasures } = useMeasureListBySearch();
  const [measureID, setMeasureID] = React.useState("");

  const createContract = useMutation(createContractorApi, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueriesKeys.contractors);
      setContactName("");
      setContactPhone("");
      setName("");
      args.openInnerForm(false);
      emitter.emit(Events["get_data_for_select"], {
        value: data.name,
        id: data.id,
        typeList: "Подрядчик",
      });
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const createWork = useMutation(createWorkApi, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueriesKeys.works);
      setWorkName("");
      args.openInnerForm(false);
      emitter.emit(Events["get_data_for_select"], {
        value: data.name,
        id: data.id,
        typeList: "Наименование работы",
      });
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const measureCreateMutation = useMutation(createMeasureUnitApi, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueriesKeys.measures]);
      setMeasureValue("");
      args.openInnerForm(false);
      emitter.emit(Events["get_data_for_select"], {
        ...data,
        typeList: "Ед. изм",
      });
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const mapFormContractor = React.useMemo(
    () => [
      {
        textAction: text.addContractor,
        type: "input",
        placeholder: text.pholderNameCompany,
        value: name,
        onChange: (e) => {
          setName(e.target.value);
        },
      },
      {
        textAction: text.addContractor,
        type: "input",
        placeholder: text.pholderContactPerson,
        value: contactName,
        onChange: (e) => setContactName(e.target.value),
      },
      {
        textAction: text.addContractor,
        type: "input",
        placeholder: text.pholderContacts,
        mask: "+7 (999) 999 99 99",
        value: contactPhone,
        onChange: (e) => setContactPhone(e.target.value),
      },
    ],
    [text, name, contactName, contactPhone]
  );

  const mapFormWorkTypes = React.useMemo(
    () => [
      {
        textAction: text.addWorkTypes,
        type: "input",
        placeholder: text.jobTitle,
        mask: null,
        value: workName,
        onChange: (e) => setWorkName(e.target.value),
      },
      {
        type: "select",
        selectData: measures,
        selectPlaceholder: "Ед. изм",
        topPlaceholder: "Ед. изм",
        isFullList: true,
        addNewElement: {
          text: text.addMeasure,
          type: "Measure",
          onChangeInputSearch: setInputMeasures,
          valueInputSearch: searchInputMeasures,
        },
        onSelect: (item) => {
          setMeasureID(item.id);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workName, measures]
  );

  const mapFormMeasure = React.useMemo(
    () => [
      {
        textAction: text.addMeasure,
        type: "input",
        placeholder: text.pholderMeasure,
        mask: null,
        value: measureValue,
        onChange: (e) => {
          setMeasureValue(e.target.value);
        },
      },
    ],
    [text, measureValue]
  );

  const onSaveContractor = () => {
    if (name && contactName && contactPhone) {
      createContract.mutateAsync({
        contactName,
        contactPhone,
        name,
      });
    } else {
      notify.error("Заполните все поля формы");
    }
  };

  const onSaveWork = () => {
    if (workName) {
      createWork.mutateAsync({ name: workName, measureUnitId: measureID });
    } else {
      notify.error("Заполните форму");
    }
  };

  const onSaveMeasure = () => {
    if (measureValue) {
      measureCreateMutation.mutateAsync(measureValue);
    } else {
      notify.error("Заполните форму");
    }
  };

  return {
    contractors: {
      modalCreateContractorsForm: mapFormContractor,
      onSaveNewContractors: onSaveContractor,
    },
    works: {
      modalCreateWorksForm: mapFormWorkTypes,
      onSaveNewWork: onSaveWork,
    },
    measures: {
      modalCreateMeasuresForm: mapFormMeasure,
      onSaveNewMeasure: onSaveMeasure,
    },
  };
};

export default useCreateReferenceElements;
