import React from "react";
import { FC } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import Dropdown from "components/Dropdown/Dropdown";
import { INode } from "components/Tree/Tree.Node";
import TreeStore from "components/Tree/Tree.store";
import { ISection } from "models/Objects/ISection";
import { HomeCheckboxSectionsStore } from "screens/Home/store/HomeCheckbox.store";
import ObjectStore from "screens/ObjectsEdit/store/Object.store";

interface IMainHomeHeader {
  treeData: any;
  selectedBuildingName: string;
  setBuilding: (item: INode, closeDropdownCallback?: () => void) => void;
  isHiddenGroup: boolean;
  sections: ISection[];
}

const MainHomeHeader: FC<IMainHomeHeader> = observer((props) => {
  React.useEffect(() => {
    HomeCheckboxSectionsStore.setCheckboxItems(props.sections);
  }, [props.sections]);

  if (TreeStore.isEmptyTreeData) {
    return <></>;
  }

  return (
    <>
      <Group>
        <div>
          <Dropdown
            type="tree-select"
            data={props.treeData as any}
            onChange={props.setBuilding as any}
            selectedValue={props.selectedBuildingName ?? "Выберите участок"}
            highlightSelectedValue={!!props.selectedBuildingName}
            isLoading={TreeStore.isLoading}
          />
        </div>
        {props.isHiddenGroup && TreeStore.selectedArea && (
          <Group>
            <div>
              <Dropdown
                type="multi-select"
                data={HomeCheckboxSectionsStore.checkboxItems}
                onChange={HomeCheckboxSectionsStore.toggleCheckedItem}
                checkboxCheckedIds={HomeCheckboxSectionsStore.checkboxSelectedItems?.map((section) => section.id)}
                selectedValue={HomeCheckboxSectionsStore.checkboxSelectedString || "Выберите секцию"}
              />
            </div>

            {/* <Spacer horizontal px={10} />
        <div>
          <Dropdown
            type="simple"
            data={PERIOD_OF_CONTROL}
            onChange={setPeriodOfControl}
            selectedValue={HomeStore.selectedPerionOfControl?.label ?? text.controlPeriod}
          />
        </div> */}
          </Group>
        )}
      </Group>
    </>
  );
});

export default MainHomeHeader;

const Group = styled.div`
  display: flex;
  gap: 20px;
`;
