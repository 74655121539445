import { FC } from "react";
import React from "react";

import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";
import InfoTooltip from "components/InfoTooltip";
import { lessThan1023 } from "constants/mediaQuery";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import { HeaderTitle, SectionHeader, Table, TableCell, TableCellText, TableHeader, TableRow } from "screens/Home/styles/common";
import getOffsetFromHeaderToTable from "screens/Home/utils/getOffsetFromHeaderToTable";
import emitter from "services/emitter";
import { Spacer } from "styles/common";

import "styles/animation.css";
import { Events } from "models/Events";

interface IMainTable {
  buildingSubHeader?: string;
  data: {
    control: string;
    contractor: string;
    name_of_the_work: string;
    sectionName?: string;
  }[];
  columns: any;
  getIsHidden?: (value: boolean) => void;
}

const calculateAnimationDelay = (index) => index * 300; // Изменяйте это значение по своему усмотрению, чтобы настроить задержку
const MainTable: FC<IMainTable> = (props) => {
  const [columnVisibility, setColumnVisibility] = React.useState(lessThan1023.matches ? { control: false, contractor: false } : {});

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const isHiddenLeftColumns = "control" in columnVisibility && "contractor" in columnVisibility;

  React.useEffect(() => {
    if (lessThan1023.matches) {
      emitter.addListener("trigger_collapse_calendar_table", (isCollapsed) => {
        if (!isCollapsed) {
          if (!isHiddenLeftColumns) {
            toggleHideLeftColumns();
          }
        }
      });
    }
  }, [isHiddenLeftColumns]);

  const toggleHideLeftColumns = () => {
    props.getIsHidden?.(!isHiddenLeftColumns); // TODO getIsHidden is undefined
    if (isHiddenLeftColumns) {
      setColumnVisibility({});
      emitter.emit(Events["trigger_collapse_main_table"], false);
      return;
    }

    emitter.emit(Events["trigger_collapse_main_table"], true);
    setColumnVisibility({ control: false, contractor: false });
  };

  return (
    <Table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              // @ts-ignore
              const style = header.column.columnDef.meta?.styleHeader || {};
              // @ts-ignore
              const isLeftButtonHideExist = header.column.columnDef.meta?.leftButtonHide;

              return (
                <TableHeader key={header.id} style={{ ...style, height: "61.5px" }}>
                  {isLeftButtonHideExist && (
                    <LeftButtonTable>
                      <InfoTooltip text={isHiddenLeftColumns ? "Показать подрядчика" : "Скрыть подрядчика"} type="sm">
                        <LeftButtonTable onClick={toggleHideLeftColumns} className="flex justify-center items-center">
                          {isHiddenLeftColumns ? <ArrowRight height={10} fill="#016CF5" /> : <ArrowLeft fill="#016CF5" height={10} />}
                        </LeftButtonTable>
                      </InfoTooltip>
                    </LeftButtonTable>
                  )}
                  <HeaderTitle>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</HeaderTitle>
                </TableHeader>
              );
            })}
          </TableRow>
        ))}
      </thead>
      <TransitionGroup component="tbody">
        {table.getRowModel().rows.map((row, rowIdx) => (
          <CSSTransition
            key={rowIdx}
            classNames="stagger"
            timeout={300}
            unmountOnExit
            mountOnEnter
            style={{ transitionDelay: `${calculateAnimationDelay(rowIdx)}ms` }}
          >
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell, cellIdx) => {
                // @ts-ignore
                const style = cell.column.columnDef.meta?.styleCol || {};

                const offset = getOffsetFromHeaderToTable(!!props.buildingSubHeader, !!row.original.sectionName, rowIdx);

                return (
                  <TableCell additionalHeight={offset} style={{ ...style }} key={cell.id} borderBottom="3px">
                    <RenderWithCondition condition={row.original.sectionName}>
                      <>
                        {props.buildingSubHeader && (
                          <>
                            <SectionHeader color="blue">
                              <BuildingHeader>{cellIdx === 0 && props.buildingSubHeader}</BuildingHeader>
                            </SectionHeader>
                          </>
                        )}
                        <SectionHeader offsetTop={props.buildingSubHeader && rowIdx === 0 ? 18 : 0}>
                          <SectionHeaderText>{cellIdx === 0 && row.original.sectionName}</SectionHeaderText>
                        </SectionHeader>
                        <Spacer px={offset} />
                      </>
                    </RenderWithCondition>
                    <TableCellText>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCellText>
                  </TableCell>
                );
              })}
            </TableRow>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Table>
  );
};

export default MainTable;

export const LeftButtonTable = styled.button`
  height: 100%;
  width: 20px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.blue200};
`;

const SectionHeaderText = styled.div`
  position: absolute;
  width: 300px;
  z-index: 2;
  text-align: left;
  padding-left: 5px;
  font-size: 12px;
`;

const BuildingHeader = styled.div`
  background-color: ${(props) => props.theme.colors.blue300};
  width: 100%;
  text-align: left;
  padding-left: 5px;
  font-size: 12px;
`;
