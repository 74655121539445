import React, { FC } from "react";

import styled from "styled-components";

import { monthWidth } from "../CalendarTable/constants/calendarTable";
import CalendarTableStore from "../CalendarTable/store/CalendarTable.Store";
import { CELL_HEIGHT } from "../constants/common";

interface IGantEmptyTableDataCell {
  isBlueCell?: boolean;
}

const GantEmptyTableDataCell: FC<IGantEmptyTableDataCell> = (props) => {
  const cellRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const changeMonthWidthOnResize = () => {
      CalendarTableStore.setMonthWidth(Number(cellRef.current.offsetWidth));
    };

    changeMonthWidthOnResize();

    window.addEventListener("resize", changeMonthWidthOnResize);

    return () => {
      window.removeEventListener("resize", changeMonthWidthOnResize);
    };
  }, []);

  return <Cell ref={cellRef} isBlueCell={props.isBlueCell}></Cell>;
};

export default GantEmptyTableDataCell;

const Cell = styled.div<{ isBlueCell: boolean }>`
  width: ${monthWidth}px;
  min-width: ${monthWidth}px;
  height: ${CELL_HEIGHT};
  border-right: 1px solid ${(props) => props.theme.colors.absolute200};
  border-bottom: 1px solid ${(props) => props.theme.colors.absolute200};
  ${(props) => props.isBlueCell && `background-color: ${props.theme.colors.blue100}`};

  @media (min-width: 1920px) {
    width: 100%;
  }
`;
