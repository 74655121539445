import React from "react";

import { isEmpty } from "lodash";
import { useQuery } from "react-query";

import { getGantChartApi } from "api/Objects/api.statistic";
import TreeStore from "components/Tree/Tree.store";
import { IGantChartBuilding, IGantWork } from "models/Objects/IGantChart";
import { QueriesKeys } from "models/QueriesKeys";
import HomeStore from "screens/Home/store/Home.store";
import { HomeCheckboxBuildingsStore, HomeCheckboxWorksStore } from "screens/Home/store/HomeCheckbox.store";

import CalendarTableStore from "../CalendarTable/store/CalendarTable.Store";

const useAreaQuery = () => {
  const areaStatistic = useQuery({
    queryFn: () => getGantChartApi({ areaId: TreeStore.selectedObject?.id }),
    queryKey: [QueriesKeys.areaGantStatistic, TreeStore.selectedObject?.id],
    enabled: TreeStore.selectedObject.meta.area === "area" && (HomeStore.tableMode === "gant" || HomeStore.tableMode === "histogram"),
    retry: false,
  });

  const getAllAvailableJobs = (buildings: IGantChartBuilding[]) => {
    const currentWorksData = buildings
      .map((building, idxBuilding) => {
        if (idxBuilding > 0) {
          if (isEmpty(building.works)) {
            return undefined;
          }
          return [{ emptyRow: true }, ...building.works];
        }

        return building.works;
      })
      .flat(1)
      .filter((work) => work);

    return currentWorksData;
  };

  const getFilteredBuildings = () => {
    if (!areaStatistic.data) {
      return [];
    }

    const selectedBuildings = HomeCheckboxBuildingsStore.checkboxSelectedItems?.map((building) => building.id);

    const filteredBuildings = areaStatistic.data?.buildings.filter((building) => {
      if (selectedBuildings.includes(building.id) && !isEmpty(building.works)) {
        return true;
      }
    });

    return filteredBuildings;
  };

  const getFilteredJobsData = (works?: IGantWork[]) => {
    if (!areaStatistic.data) {
      return [];
    }

    const filteredBuildings = getFilteredBuildings();

    const allJobs = works ?? getAllAvailableJobs(filteredBuildings);
    const filteredJobs = allJobs.filter((job) => {
      if (!("emptyRow" in job)) {
        if (CalendarTableStore.isHidden100PercentJobs && job.actualInPercentage >= 100) {
          return false;
        }

        const selectedJobsIds = HomeCheckboxWorksStore.checkboxSelectedItems.map((job) => job.id);

        if (!selectedJobsIds.includes(job.id)) {
          return false;
        }
      }
      return true;
    });

    return filteredJobs;
  };

  React.useEffect(() => {
    if (TreeStore.selectedBuilding?.meta?.area === "area" && HomeStore.tableMode === "gant") {
      if (!areaStatistic.isLoading) {
        areaStatistic.refetch();
      }
    }
  }, [TreeStore.selectedBuilding, HomeStore.tableMode]);

  return {
    areaStatistic: areaStatistic,
    isLoading: areaStatistic.isLoading,
    fn: {
      getFilteredBuildings,
      getFilteredJobsData,
    },
  };
};

export default useAreaQuery;
