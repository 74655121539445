import { FC } from "react";

import styled from "styled-components";

import { ReactComponent as CalendarIcon } from "assets/calendar.svg";
import Input from "components/Input";

import ObjectEditCalendar from "../ObjectEdit.Calendar";

interface ICalendarSelectDate {
  isVisibleCalendar: boolean;
  closeCalendar: () => void;
  onChangeDate: (v: any) => void;
  startDate: string;
  endDate: string;
  openCalendar: (date: "startDate" | "endDate") => void;
}

const CalendarSelectDate: FC<ICalendarSelectDate> = (props) => (
  <>
    {props.isVisibleCalendar && <ObjectEditCalendar closeCalendar={props.closeCalendar} onChange={props.onChangeDate} />}
    <CalendarContainer>
      <Input
        value={props.startDate}
        style={{ width: "120px" }}
        placeholder="Начало"
        icon={CalendarIcon}
        onClickAllField={() => props.openCalendar("startDate")}
        readOnly
        label="Начало"
      />
      <Input
        value={props.endDate}
        style={{ width: "120px" }}
        placeholder="Конец"
        icon={CalendarIcon}
        onClickAllField={() => props.openCalendar("endDate")}
        readOnly
        label="Конец"
      />
    </CalendarContainer>
  </>
);

export default CalendarSelectDate;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;
