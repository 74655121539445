import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import InfoTooltip from "components/InfoTooltip";
import { LocalLoader } from "components/Loader/LocalLoader";
import TreeStore from "components/Tree/Tree.store";
import { lessThan1023 } from "constants/mediaQuery";
import useTableData from "hooks/useTableData";
import useText from "hooks/useText";
import emitter from "services/emitter";
import { Container, Screen } from "styles/common";

import CalendarTable from "./components/Calendar/Calendar.Table";
import ChooseAreaOrBuilding from "./components/ChooseAreaOrBuilding/ChooseAreaOrBuilding";
import Gant from "./components/Gant/Gant";
import MainTable from "./components/MainTable/MainTable";
import HistogramsScreen from "./components/Screens/HistogramsScreen";
import ScrollableTable from "./components/ScrollableTable/Scrollable.Table";
import HomeStore from "./store/Home.store";
import { HomeCheckboxSectionsStore } from "./store/HomeCheckbox.store";

const DEFAULT_MAIN_TABLE_SIZE = lessThan1023.matches ? 44 : 23;
const DEFAULT_COLLAPSED_MAIN_TABLE_SIZE = 12;
const DEFAULT_SCROLLABLE_TABLE_SIZE = lessThan1023.matches ? 32 : 44.5;
const DEFAULT_CALENDAR_TABLE_SIZE = 32.5;
const DEFAULT_COLLAPSED_CALENDAR_TABLE_SIZE = 5;

const DEFAULT_SCROLLABLE_TABLE_SIZE_INTERNAL = "100%";

const columnHelperMainTable = createColumnHelper<{
  control: { rowId: string; value: string };
  contractor: { rowId: string; value: string };
  name_of_the_work: { rowId: string; value: string };
}>();

const columnHelper = createColumnHelper<{
  units_of_measurement: { rowId: string; value: string };
  by_gpr: { rowId: string; value: string };
  "by_gpr/beginning": { rowId: string; value: string };
  "by_gpr/ending": { rowId: string; value: string };
  "by_gpr/number_of_days": { rowId: string; value: string };
  total_for_the_project: { rowId: string; value: string };
  fact: { rowId: string; value: string };
  remains: { rowId: string; value: string };
  "%_completed": { rowId: string; value: string };
  staff_an_average_per_week: { rowId: string; value: string };
  actual_volime: { rowId: string; value: string };
  planneed_volue: { rowId: string; value: string };
}>();

const Home = observer(() => {
  const text = useText();
  const navigate = useNavigate();

  const isDaily = HomeStore.selectedPerionOfControl?.value === "Ежедневный" && "DAILY";
  const isWeekly = HomeStore.selectedPerionOfControl?.value === "Еженедельный" && "WEEKLY";
  const isAll = HomeStore.selectedPerionOfControl?.value === "Показать все" && "DAILY,WEEKLY";
  const defaultPeriod = "DAILY,WEEKLY";

  const sectionsVisibleIds = React.useMemo(() => {
    const isAllSectionsIndex = HomeCheckboxSectionsStore.checkboxSelectedItems.findIndex((section) => section.id === "all");

    if (isAllSectionsIndex === -1) {
      return HomeCheckboxSectionsStore.checkboxSelectedItems.map((section) => section.id).join(",");
    }
    const allSlugIndex = HomeCheckboxSectionsStore.checkboxSelectedItems.findIndex((section) => section.id === "all");

    return [
      ...HomeCheckboxSectionsStore.checkboxSelectedItems.slice(0, allSlugIndex),
      ...HomeCheckboxSectionsStore.checkboxSelectedItems.slice(allSlugIndex + 1),
    ]
      .map((section) => section.id)
      .join(",");
  }, [HomeCheckboxSectionsStore.checkboxSelectedItems?.toString()]);

  const tableData = useTableData({
    sectionId: sectionsVisibleIds,
    controlType: isDaily || isWeekly || isAll || defaultPeriod,
    sectionItemsStatisticEnabled: true,
  });

  const [mainTableWidth, setMainTableWidth] = React.useState(
    lessThan1023.matches ? DEFAULT_COLLAPSED_MAIN_TABLE_SIZE : DEFAULT_MAIN_TABLE_SIZE
  );
  const [scrollableTableWidth, setScrollableTableWidth] = React.useState(
    lessThan1023.matches ? 100 - (DEFAULT_COLLAPSED_CALENDAR_TABLE_SIZE + DEFAULT_COLLAPSED_MAIN_TABLE_SIZE) : DEFAULT_SCROLLABLE_TABLE_SIZE
  );
  const [calendarTableWidth, setCalendarTableWidth] = React.useState(
    lessThan1023.matches ? DEFAULT_COLLAPSED_CALENDAR_TABLE_SIZE : DEFAULT_CALENDAR_TABLE_SIZE
  );

  React.useEffect(() => {
    const mainTableCollapseListener = (isCollapsed: boolean) => {
      if (isCollapsed) {
        setMainTableWidth(DEFAULT_COLLAPSED_MAIN_TABLE_SIZE);
        const freeSpace = DEFAULT_MAIN_TABLE_SIZE - DEFAULT_COLLAPSED_MAIN_TABLE_SIZE;
        setScrollableTableWidth((size) => size + freeSpace);
      } else {
        setMainTableWidth(DEFAULT_MAIN_TABLE_SIZE);
        setScrollableTableWidth((size) => size - (DEFAULT_MAIN_TABLE_SIZE - DEFAULT_COLLAPSED_MAIN_TABLE_SIZE));
      }
    };

    const calendarTableCollapseListener = (isCollapsed: boolean) => {
      if (isCollapsed) {
        setCalendarTableWidth(DEFAULT_COLLAPSED_CALENDAR_TABLE_SIZE);
        const freeSpace = DEFAULT_CALENDAR_TABLE_SIZE - DEFAULT_COLLAPSED_CALENDAR_TABLE_SIZE;
        setScrollableTableWidth((size) => size + freeSpace);
      } else {
        setCalendarTableWidth(DEFAULT_CALENDAR_TABLE_SIZE);
        setScrollableTableWidth((size) => size - (DEFAULT_CALENDAR_TABLE_SIZE - DEFAULT_COLLAPSED_CALENDAR_TABLE_SIZE));
      }
    };

    emitter.addListener("trigger_collapse_main_table", mainTableCollapseListener);
    emitter.addListener("trigger_collapse_calendar_table", calendarTableCollapseListener);

    return () => {
      emitter.removeListener("trigger_collapse_main_table", mainTableCollapseListener);
      emitter.removeListener("trigger_collapse_calendar_table", calendarTableCollapseListener);
    };
  }, []);

  const columnsMainTable = [
    // columnHelperMainTable.accessor("control", {
    //   header: "Контроль",
    //   cell: (info) => info.getValue().value,
    //   meta: { styleCol: { width: "50px" }, styleHeader: { fontsize: "10px", padding: "0px", maxWidth: "73px" } },
    // }),
    columnHelperMainTable.accessor("contractor", {
      header: text.contractor,
      cell: (info) => (
        <div className="flex h-12">
          <InfoTooltip text={info.getValue()?.value ?? "Подрядчик не определен"} lenText={20} type="md" />
        </div>
      ),
      enableResizing: true,
      meta: { styleCol: { textAlign: "left", paddingLeft: "8px" } },
    }),
    columnHelperMainTable.accessor("name_of_the_work", {
      header: text.jobTitle,
      cell: (info) => (
        <div className="flex h-12">
          <InfoTooltip text={info.renderValue().value} lenText={20} type="md" alignInitialText="start" />
        </div>
      ),
      meta: { styleCol: { width: "180px", textAlign: "left", paddingLeft: "8px" }, leftButtonHide: true },
    }),
  ];

  const columns = [
    columnHelper.accessor("units_of_measurement", {
      header: text.unit,
      cell: (info) => info.getValue().value,
      meta: { styleCol: { width: "50px", padding: "3px" } },
    }),
    columnHelper.group({
      header: text.byGPR,
      columns: [
        columnHelper.accessor("by_gpr/beginning", {
          header: text.begin,
          cell: (info) => info.getValue().value,
          meta: { styleCol: { width: "95px", padding: "3px" } },
        }),
        columnHelper.accessor("by_gpr/ending", {
          header: text.ending,
          cell: (info) => info.getValue().value,
          meta: { styleCol: { width: "95px", padding: "3px" } },
        }),
        columnHelper.accessor("by_gpr/number_of_days", {
          header: text.amountOfDays,
          cell: (info) => info.getValue().value,
          meta: { styleCol: { width: "45px", padding: "3px" } },
        }),
      ],
    }),
    columnHelper.accessor("total_for_the_project", {
      header: "Всего по\nпроекту",
      cell: (info) => info.getValue().value,
      meta: { styleCol: { minWidth: "75px", padding: "3px" } },
    }),
    columnHelper.accessor("fact", {
      header: () => (
        <ToolTipText data-tooltip-id="header_fact" data-tooltip-content="Суммарный факт до текущего момента">
          {text.fact}
        </ToolTipText>
      ),
      cell: (info) => info.getValue().value,
    }),
    columnHelper.accessor("remains", {
      header: text.remainder,
      cell: (info) => info.getValue().value,
    }),
    columnHelper.accessor("%_completed", {
      header: text.percentageOfCompletion,
      cell: (info) => info.getValue().value,
      meta: { styleCol: { width: "100px", padding: "3px" } },
    }),
    columnHelper.accessor("staff_an_average_per_week", {
      header: text.staffOnAveragePerWeek,
      cell: (info) => info.getValue().value,
      meta: { styleCol: { minWidth: "75px", padding: "3px" } },
    }),

    columnHelper.accessor("planneed_volue", {
      header: () => (
        <ToolTipText data-tooltip-id="header" data-tooltip-content="Суммарный план до текущего момента">
          План
        </ToolTipText>
      ),
      cell: (info) => info.getValue().value,
      meta: { styleCol: { minWidth: "50px", padding: "3px" } },
    }),
  ];

  const onButtonFillClick = () => {
    navigate("/objects_edit");
  };

  if (TreeStore.isLoading) {
    return <LocalLoader condition={true} />;
  }

  if (TreeStore.isEmptyTreeData) {
    return (
      <EmptyPlaceholder
        header={"У вас еще нет данных по объектам"}
        description={"Вам нужно заполнить всю необходимою информацию по объектам"}
        buttonText={"Заполнить"}
        onButtonClick={onButtonFillClick}
      />
    );
  }

  if (TreeStore.selectedObject?.meta?.area === "complex") {
    if (isEmpty(TreeStore.selectedObject.children)) {
      return (
        <EmptyPlaceholder
          header={"У вас еще нет данных по участкам"}
          description={"Вам нужно заполнить всю необходимою информацию по участкам"}
          buttonText={"Заполнить"}
          onButtonClick={onButtonFillClick}
        />
      );
    }
    return (
      <Screen className="w-full">
        <ChooseAreaOrBuildingContainer className="flex flex-col w-full container mx-auto">
          <ChooseAreaOrBuilding />
        </ChooseAreaOrBuildingContainer>
      </Screen>
    );
  }

  if (TreeStore.selectedObject?.meta?.area === "area") {
    if (HomeStore.tableMode === "gant") {
      return <Gant />;
    }
    return <HistogramsScreen />;
  }

  if (TreeStore.selectedObject?.meta?.area === "building" && isEmpty(TreeStore.selectedObject?.children)) {
    return (
      <EmptyPlaceholder
        className="container mx-auto"
        header={"У вас еще нет секций в выбранном литере"}
        description={"Вам нужно заполнить информацию по секциям и видам работ литера"}
        buttonText={"Заполнить"}
        onButtonClick={onButtonFillClick}
      />
    );
  }

  return (
    <Screen className="w-full">
      <Tooltip id="header" positionStrategy="fixed" variant="light" style={{ zIndex: 2 }} />
      <Tooltip id="header_fact" positionStrategy="absolute" variant="light" style={{ zIndex: 2 }} />
      <Container className="flex flex-col w-full container mx-auto">
        <div className="flex w-full relative">
          <div style={{ width: `${mainTableWidth}%` }}>
            <MainTable columns={columnsMainTable} data={(tableData.data.left as any) ?? []} />
          </div>
          <div style={{ width: `${scrollableTableWidth}%`, overflowX: "scroll" }}>
            <div style={{ width: DEFAULT_SCROLLABLE_TABLE_SIZE_INTERNAL }}>
              <ScrollableTable data={(tableData.data.right as any) ?? []} columns={columns} />
            </div>
          </div>
          <div style={{ width: `${calendarTableWidth}%` }}>
            <CalendarTable
              data={tableData.data.calendarData as any}
              isLoadingLeftTable={!tableData.data.isLoadingLeft || !HomeStore.isLoadedTree}
            />
          </div>
        </div>
        <LocalLoader condition={tableData.data.isLoadingLeft || !HomeStore.isLoadedTree} posY="70px" />
      </Container>
    </Screen>
  );
});

export default Home;

const ChooseAreaOrBuildingContainer = styled(Container)`
  padding: 0 20px;
`;

const ToolTipText = styled.p`
  cursor: help;
`;
