import apiClient from "api/api.client";
import { IItemPeriodCharts } from "models/Objects/IItemPeriod";

export type TGetAreaStatisticApi = (params: IItemPeriodCharts) => Promise<any>;

export const getAreaStatisticApi: TGetAreaStatisticApi = async (params) => {
  try {
    const response = await apiClient.get("objects/v1/statistic/area/histogram", { params });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
