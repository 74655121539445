import React, { FC } from "react";

import { uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import GantTableHeaderCell from "../components/Gant.TableHeaderCell";
import useAreaQuery from "../hooks/useAreaQuery";

import { monthWidth } from "./constants/calendarTable";
import CalendarTableStore from "./store/CalendarTable.Store";
import getAllPlanedMonths from "./utils/getAllPlanedMonths";
import getMinDateFromWorks from "./utils/getMinMaxDateFromWorks";

interface ICalendarTableHeader {}

const CalendarTableHeader: FC<ICalendarTableHeader> = observer((props) => {
  const { areaStatistic } = useAreaQuery();

  React.useEffect(() => {
    if (areaStatistic.data) {
      const minDateStartedPlanedWork = getMinDateFromWorks(areaStatistic?.data);
      const allPlannedMonths = getAllPlanedMonths(minDateStartedPlanedWork);

      CalendarTableStore.setCalendarTableMonths(allPlannedMonths);
    }
  }, [areaStatistic.data]);

  const ref = React.useRef<HTMLInputElement>();

  return (
    <Container ref={ref}>
      {CalendarTableStore.calendarMonths.map((month) => (
        <Header key={uniqueId(month)}>{month}</Header>
      ))}
    </Container>
  );
});

export default CalendarTableHeader;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Header = styled(GantTableHeaderCell)`
  min-width: ${monthWidth}px;
  width: ${monthWidth}px;
  @media (min-width: 1920px) {
    width: 100%;
  }
`;
