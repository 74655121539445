import { FC, useState } from "react";

import classNames from "classnames";
import styled from "styled-components";

import { LocalLoader } from "components/Loader/LocalLoader";
import { RenderWithCondition } from "hoc/RenderWithCondition";

import { IHistogramsData } from "../Screens/components/WorkItem";

import BarChartItem from "./BarChart.Item";
import HeaderChartInner from "./HeaderChart.Inner";
import RectanglesLine from "./RectanglesLine";
import TooltipChart from "./TooltipLine";

const titlesLinesWeek = ["План на неделю", "Факт на неделю", "Персонал"];
const titlesLinesMonth = ["План на месяц", "Факт на месяц", "Персонал"];

const commonTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return <TooltipChart label={label} payload={payload} />;
  }
  return null;
};
export interface IMaxScales {
  maxPlan: number;
  maxFact: number;
  maxPersonal: number;
}

interface IBarChartTotal {
  interval?: number;
  maxScale?: IMaxScales;
  factorScale: number;
  data: IHistogramsData[];
  placeLabel?: "center" | "top";
  isValuesOfData?: boolean;
  isLargeScreen?: boolean;
}

const BarChartTotal: FC<IBarChartTotal> = (props) => {
  const isWeekly = props.placeLabel === "center";

  return (
    <Container className={classNames("flex flex-col justify-center ")}>
      <HeaderChartInner
        rectanglesLine={isWeekly ? titlesLinesWeek : titlesLinesMonth}
        isLargeScreen={!props.isValuesOfData && !props.isLargeScreen}
      />
      <div className={`flex w-full ${props.isValuesOfData ? "h-[65px]" : null}`}>
        <ChartContainer className={`flex ${props.isLargeScreen ? "w-[80%]" : "w-[100%]"}`}>
          <span style={{ paddingLeft: 60, height: 10 }}></span>
          <BarChartItem
            placeLabel={props.placeLabel}
            interval={props.interval}
            pointXaxis="date"
            data={props.data}
            externalTooltip={commonTooltip}
            maxScale={props.maxScale}
            factorScale={props.factorScale}
            isValuesOfData={props.isValuesOfData}
          />
          <LocalLoader condition={false} />
        </ChartContainer>
        <RenderWithCondition condition={!props.isValuesOfData && props.isLargeScreen}>
          <RectanglesLine mapTitleLines={isWeekly ? titlesLinesWeek : titlesLinesMonth} vertical />
        </RenderWithCondition>
      </div>
    </Container>
  );
};

export default BarChartTotal;

export const Container = styled.div`
  padding: 10px 10px;
`;

const ChartContainer = styled.div`
  flex-direction: column;
  padding: 0 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.absolute100};
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
`;

export const TitleLine = styled.p<{ weight?: number; fontSize?: number }>`
  font-family: Inter;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "16px")};
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  color: ${(props) => props.theme.colors.absolute700};
  line-height: 24px;
`;
