import { useEffect } from "react";

import dayjs from "dayjs";
import { filter, find, isEmpty } from "lodash";
import { useQuery } from "react-query";

import { getAreaStatisticApi } from "api/Objects/api.statistics";
import { getContractorsApi } from "api/References/api.contractor";
import { getMeasureUnitsApi } from "api/References/api.measureUnits";
import { getWorksApi } from "api/References/api.works";
import TreeStore from "components/Tree/Tree.store";
import { getPeriodDataForChart, serializePeriodDataItemForDay } from "hooks/useTableData";
import useText from "hooks/useText";
import { QueriesKeys } from "models/QueriesKeys";
import { IContractor } from "models/References/IContractor";
import { IMeasureUnit } from "models/References/IMeasureUnit";
import { IWork } from "models/References/IWork";
import CalendarStore from "store/Calendar.Store";

import useAreaQuery from "../components/Gant/hooks/useAreaQuery";
import HomeStore from "../store/Home.store";
import { HomeCheckboxWorksStore } from "../store/HomeCheckbox.store";
import { HomeCheckboxBuildingsStore } from "../store/HomeCheckbox.store";

export interface IDataDay {
  liter: string;
  total_for_the_project: number;
  total_plan_of_period: number;
  fact: number;
  total_fact_of_period: number;
  total_staff_of_period: number;
  deviationTitle: string;
  productionTitle: string;
  production: number;
  deviation: number;
  remains: number;
  completed: number;
  contractors: string;
  background: string;
  total: string;
}

type THookArgs = {
  isFirstEnter?: boolean;
};

const useTableHistogram = (args?: THookArgs) => {
  const text = useText();
  const isMonthly = HomeStore.typePeriodForCharts === "MONTHLY";
  const calendarStartDate = CalendarStore.calendarStartDate.format("YYYY-MM-DD");
  const calendarEndDate = CalendarStore.calendarEndDate.format("YYYY-MM-DD");
  const currentMonth = CalendarStore.calendarCurrentMonth;
  const currentTypeOfData = HomeStore.typeDataForCharts.label;

  const { isLoading } = useAreaQuery();

  const isEmptyData =
    (isEmpty(HomeCheckboxBuildingsStore.checkboxSelectedItems) && !isEmpty(HomeCheckboxBuildingsStore.checkboxItems)) ||
    (isEmpty(HomeCheckboxWorksStore.checkboxSelectedItems) && !isEmpty(HomeCheckboxWorksStore.checkboxItems));
  const isEmptyWorks = isEmpty(HomeCheckboxWorksStore.checkboxItems) && isEmpty(HomeCheckboxWorksStore.checkboxSelectedItems);

  const paramsBuildings = HomeCheckboxBuildingsStore.checkboxSelectedItems
    .map((item) => item.id)
    .filter((item) => item !== "all")
    .join(",");

  const paramsWorks = HomeCheckboxWorksStore.checkboxSelectedItems
    .map((item) => item.id)
    .filter((item) => item !== "all")
    .join(",");

  const areaStatistic = useQuery({
    queryKey: [
      QueriesKeys.sectionItems,
      QueriesKeys.tree,
      TreeStore.selectedArea?.id,
      TreeStore.selectedBuilding?.id,
      isMonthly ? null : CalendarStore.calendarStartDate,
      isMonthly ? null : CalendarStore.calendarEndDate,
      HomeCheckboxWorksStore.checkboxSelectedItems,
      HomeCheckboxBuildingsStore.checkboxSelectedItems,
      isMonthly ? currentMonth : null,
      HomeStore.typePeriodForCharts,
    ],
    queryFn: () =>
      getAreaStatisticApi({
        areaId: TreeStore.selectedObject?.id,
        startDate: isMonthly ? null : calendarStartDate,
        endDate: isMonthly ? null : calendarEndDate,
        workIds: paramsWorks,
        buildingIds: paramsBuildings,
        yearMonth: isMonthly ? currentMonth.format("YYYY-MM") : null,
      }),
    enabled: TreeStore.selectedObject?.meta?.area === "area" && !!paramsBuildings && !!paramsWorks,
  });

  useEffect(() => {
    if (!isEmpty(areaStatistic.data?.buildings)) {
      HomeCheckboxBuildingsStore.setCheckboxItems(areaStatistic.data.buildings);
    }
  }, [isLoading]);

  const contractors = useQuery({ queryKey: QueriesKeys.contractors, queryFn: getContractorsApi });
  const works = useQuery({ queryKey: QueriesKeys.works, queryFn: getWorksApi });
  const measures = useQuery({ queryKey: QueriesKeys.measures, queryFn: getMeasureUnitsApi });

  const getListContractors = (contractorsBase: IContractor[], contractorsCurr: string[]) => {
    const lineContractors = contractorsCurr
      .map((contractor) => contractorsBase?.filter((base) => base.id === contractor))
      .flat()
      .map((contractor) => contractor?.name)
      .join(",");

    return lineContractors ? lineContractors : text.contractorNotDetermined;
  };

  const getWorkData = (data: IWork[], id: string) => data?.filter((work) => work.id === id)[0];
  const getMeasureData = (data: IMeasureUnit[], id: string) => data?.filter((measureUnit) => measureUnit.id === id)[0].value;
  const numberDaysOfMonth = currentMonth.daysInMonth();

  const getDataCharts = (data: any) => {
    const sortedPeriod = data.map((statPeriod) => statPeriod).sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)));
    const sortedPeriodMonth = sortedPeriod.filter((day) => day.startDate.slice(0, 7) === currentMonth.format("YYYY-MM"));

    return Array.from(Array(isMonthly ? numberDaysOfMonth : 7).keys())
      .map((day) => getPeriodDataForChart(isMonthly ? sortedPeriodMonth : sortedPeriod, day, isMonthly ? "MONTH" : undefined))
      .flat();
  };

  const getCalendarCellsData = (data) => {
    const sortedPeriodDailyDate = data.dailyData.itemPeriods.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)));
    const sortedPeriodCumulativeDate = data.cumulativeData.itemPeriods.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)));
    const periodDateForTable = currentTypeOfData === "Daily" ? sortedPeriodDailyDate : sortedPeriodCumulativeDate;

    return data
      ? [
          ...data.buildings
            .map((liter) => {
              const sortedPeriodByDate = liter.periodStatistic.itemPeriods.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)));
              return {
                days: [
                  serializePeriodDataItemForDay(sortedPeriodByDate, 0),
                  serializePeriodDataItemForDay(sortedPeriodByDate, 1),
                  serializePeriodDataItemForDay(sortedPeriodByDate, 2),
                  serializePeriodDataItemForDay(sortedPeriodByDate, 3),
                  serializePeriodDataItemForDay(sortedPeriodByDate, 4),
                  serializePeriodDataItemForDay(sortedPeriodByDate, 5),
                  serializePeriodDataItemForDay(sortedPeriodByDate, 6),
                ],
              };
            })
            .flat(),
          {
            controlType: "DAILY",
            days: [
              serializePeriodDataItemForDay(periodDateForTable, 0, true),
              serializePeriodDataItemForDay(periodDateForTable, 1, true),
              serializePeriodDataItemForDay(periodDateForTable, 2, true),
              serializePeriodDataItemForDay(periodDateForTable, 3, true),
              serializePeriodDataItemForDay(periodDateForTable, 4, true),
              serializePeriodDataItemForDay(periodDateForTable, 5, true),
              serializePeriodDataItemForDay(periodDateForTable, 6, true),
            ],
            sectionNameExist: true,
          },
        ]
      : [];
  };

  let lineChartData = [];
  let barChartData = [];
  let cellHistogramData = [];
  let isProduction: number | null;
  let isDailyPeriods: boolean;
  let isCumulativePeriods: boolean;

  const tableHistogramData = areaStatistic?.data?.works
    ?.filter((work) => !isEmpty(work.buildings))
    ?.map((work) => {
      lineChartData = getDataCharts(work.cumulativeData.itemPeriods);
      barChartData = getDataCharts(work.dailyData.itemPeriods);
      isProduction = work.cumulativeData.production;
      isDailyPeriods = isEmpty(filter(work.dailyData.itemPeriods, ({ actualVolume, volume }) => actualVolume > 0 || volume > 0));
      isCumulativePeriods = isEmpty(filter(work.cumulativeData.itemPeriods, ({ actualVolume, volume }) => actualVolume > 0 || volume > 0));
      cellHistogramData = getCalendarCellsData(work);
      HomeStore.setFirstOpenWork([work.id]);

      return {
        tableLeftRightData: [
          ...work.buildings.map((liter) => ({
            workId: getWorkData(works.data, work.id),
            measureUnit: work.measureUnitId ? getMeasureData(measures.data, work.measureUnitId) : "-",
            liter: liter.name,
            total_for_the_project: liter.volume,
            total_plan_of_period: liter.periodStatistic.volume,
            fact: liter.actualVolume,
            total_fact_of_period: liter.periodStatistic.actualVolume,
            total_staff_of_period: liter.periodStatistic.staffVolume,
            deviationTitle: text.deviationPlanFact,
            production: liter.periodStatistic.production,
            deviation: liter.periodStatistic.deviation,
            remains: liter.leftVolume,
            completed: liter.actualInPercentage,
            contractors: getListContractors(contractors.data, liter.contractorIds),
          })),
          {
            liter: "",
            total_for_the_project: null,
            total_plan_of_period: null,
            fact: null,
            total_fact_of_period: null,
            total_staff_of_period: null,
            deviationTitle: "",
            deviation: null,
            remains: null,
            completed: "",
            contractors: "",
            offset: "20px",
          },
          {
            liter: "Итого по проекту",
            total_for_the_project: work.volume,
            total_plan_of_period: work.dailyData.volume,
            fact: work.actualVolume,
            total_fact_of_period: work.dailyData.actualVolume,
            total_staff_of_period: work.dailyData.staffVolume,
            deviationTitle: text.deviationPlanFact,
            measureUnit: work.measureUnitId ? getMeasureData(measures.data, work.measureUnitId) : "-",
            production: work.cumulativeData.production,
            deviation: work.dailyData.deviation,
            remains: work.leftVolume,
            completed: work.actualInPercentage,
            contractors: "",
            background: "blue",
            total: "total",
            workId: getWorkData(works.data, work.id),
          },
        ],
        cellHistogramData,
        barChartData,
        lineChartData,
        isProduction,
        isDailyPeriods,
        isCumulativePeriods,
      };
    });
  return {
    isLoading: areaStatistic.isLoading,
    data: {
      tableHistogramData,
      isLoading: areaStatistic.isLoading,
      isAnyChecked: !isEmptyData,
      isEmptyWorks: isEmptyWorks,
    },
  };
};

export default useTableHistogram;
