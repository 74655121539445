import styled from "styled-components";

export const Screen = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
`;

export const Container = styled.section`
  position: relative;
`;

export const Spacer = styled.div<{ px: number; horizontal?: boolean }>`
  margin-top: ${(props) => (!props.horizontal ? `${props.px}px` : undefined)};
  margin-right: ${(props) => (props.horizontal ? `${props.px}px` : undefined)};
`;

export const SpanColored = styled.span<{ color: string; bold?: number; hoverColor?: string }>`
  color: ${(props) => props.color};
  font-weight: ${(props) => (props.bold ? props.bold : 400)};
  &:hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
  }
`;
