import dayjs from "dayjs";
import { capitalize } from "lodash";

import { monthWidth } from "../constants/calendarTable";
import { TCalendarTableMode } from "../store/CalendarTable.Store";

const getOffsetFromStartTable = (allMonths: string[], currMonthWidth?: number) => {
  const currentDay = dayjs();
  const currentMonth = capitalize(currentDay.format("MMMM YYYY"));
  const currentMonthIdxInAllMonths = allMonths.findIndex((month) => month === currentMonth);

  const dayWidth = (currMonthWidth ?? monthWidth) / currentDay.daysInMonth();

  const offset = (currMonthWidth ?? monthWidth) * currentMonthIdxInAllMonths + dayWidth * currentDay.date();

  return `${offset}px`;
};

export default getOffsetFromStartTable;
