import apiClient from "api/api.client";
import { IUser, IUserPassword, IUserRole } from "models/Users/IUser";

export type TGetUsersApi = (params: { query?: string; ids?: string; roles?: string; sectionsIds?: string }) => Promise<IUser[]>;

export const getUsersApi: TGetUsersApi = async (params) => {
  try {
    const res = await apiClient.get("users/v1/users", { params });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetUserMeApi = () => Promise<IUser>;

export const getUserMeApi: TGetUserMeApi = async () => {
  try {
    const res = await apiClient.get("users/v1/users/info");
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetRolesApi = () => Promise<IUserRole[]>;

export const getRolesApi: TGetRolesApi = async () => {
  try {
    const res = await apiClient.get("uaa/v1/roles");
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TCreateUserApi = (body: Partial<IUser>) => Promise<IUser>;

export const createUserApi: TCreateUserApi = async (body) => {
  try {
    const res = await apiClient.post("users/v1/users", body);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TAddUserSectionApi = (body: { userId: string; sectionIds: any[] }) => Promise<void>;

export const addUserSectionApi: TAddUserSectionApi = async (body) => {
  try {
    const res = await apiClient.patch("users/v1/users/sections", body);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateUserApi = (data: { body: Partial<IUser>; userId: string }) => Promise<IUser>;

export const updateUserApi: TUpdateUserApi = async (data) => {
  try {
    const res = await apiClient.patch(`/users/v1/users/${data.userId}`, data.body);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdatePasswordApi = (data: { body: IUserPassword; uaaId: string }) => Promise<IUser>;

export const updatePasswordApi: TUpdatePasswordApi = async (data) => {
  try {
    const res = await apiClient.post(`/uaa/v1/users/${data.uaaId}/update-password`, data.body);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteUserApi = (id: string) => Promise<void>;

export const deleteUserApi: TDeleteUserApi = async (id) => {
  try {
    const res = await apiClient.delete(`/users/v1/users/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
