import { makeAutoObservable } from "mobx";

export type TCalendarTableMode = "by_month" | "by_week" | "by_week_detail" | "by_day" | "by_day_detail";

const listCalendarTableMode: TCalendarTableMode[] = ["by_day_detail", "by_day", "by_week_detail", "by_week", "by_month"];

class CalendarTableStore {
  mode: TCalendarTableMode = "by_month";
  currentOffsetCurrentDayLine = "0px";

  calendarMonths: string[] | [] = [];

  monthWidth: number | undefined = undefined;

  isHidden100PercentJobs = false;

  constructor() {
    makeAutoObservable(this);
  }

  setMonthWidth = (width: number) => {
    this.monthWidth = width;
  };

  toggleIsHidden100PercentsWorks = () => {
    this.isHidden100PercentJobs = !this.isHidden100PercentJobs;
  };

  setCalendarTableMode = (zoom: "zoom_in" | "zoom_out") => {
    const currentZoomIdx = listCalendarTableMode.findIndex((currZoom) => currZoom === this.mode);

    if (zoom === "zoom_out" && currentZoomIdx === listCalendarTableMode.length - 1) {
      return;
    }

    if (zoom === "zoom_in" && currentZoomIdx === 0) {
      return;
    }

    const followZoom = zoom === "zoom_in" ? currentZoomIdx - 1 : currentZoomIdx + 1;

    this.mode = listCalendarTableMode[followZoom];
  };

  setCurrentOffsetCurrentDayLine = (offset: string) => {
    this.currentOffsetCurrentDayLine = offset;
  };

  setCalendarTableMonths = (months: string[]) => {
    this.calendarMonths = months;
  };
}

export default new CalendarTableStore();
