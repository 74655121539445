import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";

class CalendarStore {
  calendarStartDate: dayjs.Dayjs = dayjs(new Date()).startOf("week").add(1, "day");
  calendarEndDate: dayjs.Dayjs = dayjs(new Date()).startOf("week").add(7, "days");
  calendarCurrentMonth: dayjs.Dayjs = dayjs(new Date()).startOf("month");
  isHiddenColumns = false;
  isHiddenLiters = false;
  isPossibleChangePeriod = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleIsHiddenColumns = () => {
    this.isHiddenColumns = !this.isHiddenColumns;
  };
  toggleIsHiddenLiters = () => {
    this.isHiddenLiters = !this.isHiddenLiters;
  };

  setCalendarStartDate(value: dayjs.Dayjs) {
    this.calendarStartDate = value;
    // this.setCalendarStartDateMonth(value);
  }
  setCalendarCurrentMonth(value: dayjs.Dayjs) {
    this.calendarCurrentMonth = value.startOf("month");
  }

  setCalendarEndDate(value: dayjs.Dayjs) {
    this.calendarEndDate = value;
  }

  setPossibleChangePeriod(value: boolean) {
    this.isPossibleChangePeriod = value;
  }
}

export default new CalendarStore();
