import { FC } from "react";
import React from "react";

import { first } from "lodash";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import styled from "styled-components";

import { createAreaApi } from "api/Objects/api.areas";
import { createComplexApi } from "api/Objects/api.complexes";
import { queryClient } from "App";
import { ReactComponent as ArrowLongRight } from "assets/arrowLongRight.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { LocalLoader } from "components/Loader/LocalLoader";
import Tree, { getTreeNodeById } from "components/Tree/Tree";
import { ArrowLongRightContainer, INode, TreeInputContainer } from "components/Tree/Tree.Node";
import TreeStore from "components/Tree/Tree.store";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useTreeData from "hooks/useTreeData/useTreeData";
import { QueriesKeys } from "models/QueriesKeys";
import { Spacer } from "styles/common";
import notify from "utils/notify";

import useAddObjectsModalData from "./hooks/useAddObjectsModalData";
import ObjectStore from "./store/Object.store";

const getParentNameOfNode = (node: INode, nodes: INode[], names = []) => {
  const currNames = [...names, node.name];
  if (node?.meta?.parentId) {
    const foundedParent = getTreeNodeById(nodes, node.meta.parentId);
    if (foundedParent) {
      return getParentNameOfNode(foundedParent, nodes, currNames);
    }
  }

  return currNames;
};

const ObjectsEditSideBar: FC = observer(() => {
  const [newComplexInput, setNewComplexInput] = React.useState("");
  const [IsVisibleAddNewObjInput, setIsVisibleAddNewObjInput] = React.useState(false);
  const addObjectModalData = useAddObjectsModalData();
  const restrictedData = useRestrictedAccessData();

  const { data: treeData, isLoading } = useTreeData([], ["section"]);

  const createComplex = useMutation(createComplexApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.tree]);
    },
  });

  const createArea = useMutation(createAreaApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.tree]);
    },
  });

  const addNewObject = () => {
    onAddNewObject({
      name: newComplexInput,
      meta: {
        area: "initial",
      },
      id: "new",
      canBeDeleted: true,
    });
    setNewComplexInput("");
    setIsVisibleAddNewObjInput(false);
  };

  const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" && newComplexInput) {
      addNewObject();
    }
  };

  const onAddNewObject = (node: INode) => {
    const isDuplicate = TreeStore.selectedObject?.children?.some(
      (currNodeName) => currNodeName.name.toLowerCase() === node.name.toLowerCase()
    );

    if (isDuplicate) {
      notify.error("Обьект с таким названием уже существует");
      return;
    }

    switch (node.meta.area) {
      case "initial":
        createComplex.mutateAsync({ name: node.name });
        break;
      case "complex":
        createArea.mutateAsync({ name: node.name, complexId: node.meta.parentId });
        break;
      default:
        throw new Error("unknown area");
    }
  };

  const selectNode = (node: INode) => {
    if (node?.meta.area !== "section") {
      if (node?.meta.area !== "building" && node?.id !== ObjectStore.selectedBuilding?.id) {
        ObjectStore.clearSelectedBuilding();
      }
      ObjectStore.clearSelectedSection();
    }

    if (node?.meta.area === "building") {
      ObjectStore.setSelectedObject(node);
      ObjectStore.toggleSelectedBuilding(node);
    } else if (node?.meta.area === "section") {
      const parentNode = getTreeNodeById(treeData, node.meta.parentId);
      if (node?.id !== ObjectStore.selectedSection?.id) {
        ObjectStore.toggleSelectedSection(node);
      }
      ObjectStore.toggleSelectedBuilding(parentNode, "force-set");
      ObjectStore.setSelectedObject(node);
    } else {
      ObjectStore.setSelectedObject(node);
    }
  };

  React.useEffect(() => {
    selectNode(TreeStore.selectedComplex);
  }, []);

  const highlightTreeNodes = [
    ObjectStore.selectedBuilding?.id,
    ObjectStore.selectedSection?.id,
    TreeStore.selectedComplex?.id,
    TreeStore.selectedArea?.id,
  ];

  const initialNodeWasSet = React.useRef(false);

  React.useEffect(() => {
    if (!TreeStore.selectedObject && !isLoading && !initialNodeWasSet.current) {
      initialNodeWasSet.current = true;
      const firstNode = first(treeData);
      if (firstNode) {
        TreeStore.setSelectedObject(firstNode);
      }
    }
  }, [treeData]);

  React.useEffect(() => {
    if (ObjectStore.selectedObject) {
      ObjectStore.setSelectedObjectParentNames(getParentNameOfNode(ObjectStore.selectedObject, treeData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ObjectStore.selectedObject]);

  const containerRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    ObjectStore.setSideBarWidth(`${containerRef.current.offsetWidth}px`);
  }, []);

  return (
    <Container ref={containerRef}>
      {restrictedData.vars.canAddAndChangeObjects && (
        <AddObjectContainer onClick={() => setIsVisibleAddNewObjInput((state) => !state)}>
          <PlusIcon />
          <Spacer px={10} horizontal />
          <AddObjectText>Добавить обьект</AddObjectText>
        </AddObjectContainer>
      )}
      {IsVisibleAddNewObjInput && (
        <TreeInputContainer minusOffsetLeft={0}>
          <Input
            autoFocus
            onBlur={() => setIsVisibleAddNewObjInput(false)}
            value={newComplexInput}
            onChange={(e) => setNewComplexInput(e.target.value)}
            onKeyDown={onKeyEnter}
            maxLength={30}
          />
          <ArrowLongRightContainer onMouseDown={addNewObject}>
            <ArrowLongRight />
          </ArrowLongRightContainer>
          <Spacer px={8} horizontal />
        </TreeInputContainer>
      )}
      <Spacer px={10} />

      <TreeContainer>
        <LocalLoader condition={TreeStore.isLoading} posY="150px" withoutBlur={true} />
        <Tree
          nodes={treeData}
          onNodeClick={selectNode}
          mode={restrictedData.vars.canAddAndChangeObjects ? "added" : undefined}
          addInputProps={{ value: newComplexInput, onFocus: () => undefined }}
          onAddNewObject={onAddNewObject}
          hightlightedNodeIds={highlightTreeNodes}
          modalData={addObjectModalData.data.modalForm}
          onSaveModalData={addObjectModalData.fn.onModalSave}
          isErrorInModal={addObjectModalData.data.isError}
          isLoadingInModal={addObjectModalData.data.isLoading}
        />
      </TreeContainer>
    </Container>
  );
});

export default ObjectsEditSideBar;

const Container = styled.div`
  position: relative;
  padding-top: 5px;
  min-width: 300px;
`;

const AddObjectContainer = styled.button`
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

const AddObjectText = styled.button`
  white-space: nowrap;
  color: ${(props) => props.theme.colors.blue400};
`;

const Input = styled.input`
  width: 100%;
  padding: 2px 12px;
  outline: none;
`;

const TreeContainer = styled.div`
  padding-right: 10px;
`;
