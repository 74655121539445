import { FC, useState } from "react";

import styled from "styled-components";

import { ReactComponent as RectangleIconBlue } from "assets/rectangle-blue.svg";
import { ReactComponent as RectangleIconGreen } from "assets/rectangle-green.svg";
import { ReactComponent as RectangleIconYellow } from "assets/rectangle-yellow.svg";

const rectangles = [<RectangleIconGreen />, <RectangleIconBlue />, <RectangleIconYellow />];

interface IRectanglesLine {
  mapTitleLines: string[];
  vertical?: boolean;
}

const RectanglesLine: FC<IRectanglesLine> = (props) => {
  const [isActive, setActive] = useState(false);

  return (
    <div className={`flex gap-8 ${props.vertical ? "flex-col px-12" : null}`}>
      {props.mapTitleLines.map((title, i) => (
        <div key={i} className="flex gap-2">
          {rectangles[i]}
          <TitleLine>{title}</TitleLine>
        </div>
      ))}
    </div>
  );
};

export default RectanglesLine;

const TitleLine = styled.p<{ weight?: number; fontSize?: number }>`
  font-family: Inter;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "16px")};
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  color: ${(props) => props.theme.colors.absolute700};
  line-height: 24px;
`;
