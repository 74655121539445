import { makeAutoObservable } from "mobx";

// mocks
import { ICheckboxValue } from "components/Dropdown/Dropdown";
import { INode } from "components/Tree/Tree.Node";
import { IUser, IUserRole } from "models/Users/IUser";

const DEFAULT_SELECTED_ROLES = [{ label: "Выбрано все", value: "all", id: "all" }];
const DEFAULT_SELECTED_ROLES_STRING = "Роль";
const DEFAULT_SELECTED_USERS_STRING = "Сортировка";

class UsersStore {
  selectedBuilding: INode | undefined = undefined;

  searchValue: string | undefined = undefined;
  dataTree: any = [];

  checkboxRoles = DEFAULT_SELECTED_ROLES;
  checkboxSelectedRoles: ICheckboxValue[] = [];
  checkboxSelectedRolesString = DEFAULT_SELECTED_ROLES_STRING;

  checkboxUsers = DEFAULT_SELECTED_ROLES;
  checkboxSelectedUsers: ICheckboxValue[] = [];
  checkboxSelectedUsersString = DEFAULT_SELECTED_USERS_STRING;
  isLoadingUsers = false;
  settingPeriod = "";

  constructor() {
    makeAutoObservable(this);
  }

  private setSelectedRolesString = () => {
    const isAllExist = this.checkboxSelectedRoles.findIndex((role) => role.id === "all");
    if (isAllExist !== -1) {
      this.checkboxSelectedRolesString = DEFAULT_SELECTED_ROLES[0].label;
    } else {
      this.checkboxSelectedRolesString = this.checkboxSelectedRoles.map((role) => role.label).join(", ");
    }
  };

  private setSelectedUsersString = () => {
    const isAllExist = this.checkboxSelectedUsers.findIndex((role) => role.id === "all");
    if (isAllExist !== -1) {
      this.checkboxSelectedUsersString = DEFAULT_SELECTED_ROLES[0].label;
    } else {
      this.checkboxSelectedUsersString = this.checkboxSelectedUsers.map((role) => role.label).join(", ");
    }
  };

  setCheckboxRoles = (roles: IUserRole[]) => {
    this.checkboxRoles = [
      ...DEFAULT_SELECTED_ROLES,
      ...roles.map((role) => ({
        id: role.name,
        label: role.description,
        value: role.name,
      })),
    ];
    this.checkboxSelectedRoles = this.checkboxRoles;
  };

  setCheckboxUsers = (users: IUser[]) => {
    this.checkboxUsers = [
      ...DEFAULT_SELECTED_ROLES,
      ...users.map((user) => ({
        id: user.id,
        label: `${user.lastName} ${user.firstName ?? ""} ${user.middleName ?? ""}`,
        value: user.id,
      })),
    ];
    this.checkboxSelectedUsers = this.checkboxUsers;
  };

  toggleCheckedRole = (role: ICheckboxValue) => {
    const isSelectedRoleIndexExist = this.checkboxSelectedRoles.findIndex((currRole) => currRole.id === role.id);

    if (role.id === "all") {
      if (isSelectedRoleIndexExist > -1) {
        this.checkboxSelectedRoles = [];
      } else {
        this.checkboxSelectedRoles = [...DEFAULT_SELECTED_ROLES, ...this.checkboxRoles];
      }

      this.setSelectedRolesString();
      return;
    }

    if (isSelectedRoleIndexExist === -1) {
      const newRoles = this.checkboxSelectedRoles.filter((currRole) => currRole.id !== "all");
      newRoles.push(role as any);

      this.checkboxSelectedRoles = newRoles;
    } else {
      const newRoles = this.checkboxSelectedRoles.filter((currRole) => currRole.id !== "all" && currRole.id !== role.id);

      this.checkboxSelectedRoles = newRoles;
    }
    this.setSelectedRolesString();
  };

  toggleCheckedUser = (role: ICheckboxValue) => {
    const isSelectedRoleIndexExist = this.checkboxSelectedUsers.findIndex((currRole) => currRole.id === role.id);

    if (role.id === "all") {
      if (isSelectedRoleIndexExist > -1) {
        this.checkboxSelectedUsers = [];
      } else {
        this.checkboxSelectedUsers = [...DEFAULT_SELECTED_ROLES, ...this.checkboxUsers];
      }

      this.setSelectedUsersString();

      return;
    }

    if (isSelectedRoleIndexExist === -1) {
      const newRoles = this.checkboxSelectedUsers.filter((currRole) => currRole.id !== "all");
      newRoles.push(role as any);

      this.checkboxSelectedUsers = newRoles;
    } else {
      const newRoles = this.checkboxSelectedUsers.filter((currRole) => currRole.id !== "all" && currRole.id !== role.id);

      this.checkboxSelectedUsers = newRoles;
    }
    this.setSelectedUsersString();
  };

  selectBuilding = (building: INode) => {
    this.selectedBuilding = building;
  };

  setSearchValue = (value: string) => {
    this.searchValue = value ? value : null;
  };

  setIsLoadingUsers = (value: boolean) => {
    this.isLoadingUsers = value;
  };
  setDataTree = (data: any) => {
    this.dataTree = data;
  };
  setSettingPeriod = (data: string) => {
    this.settingPeriod = data;
  };
}

export default new UsersStore();
