import { makeAutoObservable } from "mobx";

import { TAgreementStatus } from "models/Objects/IAgreement";

class PlanStatusStore {
  isISentPlanForAgreement = false;
  lastAgreementUserId: string | undefined = undefined;
  statusPlan?: TAgreementStatus = undefined;
  currentEditableItemId: undefined | string = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  changeIsISentPlanForAgreement = (status: boolean) => {
    this.isISentPlanForAgreement = status;
  };

  changeStatusPlan = (status?: TAgreementStatus) => {
    this.statusPlan = status;
  };

  setCurrentEditableItemId = (itemId?: string) => {
    this.currentEditableItemId = itemId;
  };
  setLastAgreementUserId = (userId?: string) => {
    this.lastAgreementUserId = userId;
  };
}

export default new PlanStatusStore();
