/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { FC } from "react";

import { debounce, isEmpty } from "lodash";
import { useMutation, UseMutationResult } from "react-query";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { approvePeriodApi, createPeriodApi, updatePeriodApi } from "api/Objects/api.itemPeriod";
import { queryClient } from "App";
import { LocalLoader } from "components/Loader/LocalLoader";
import SimpleModal from "components/SimpleModal";
import { IItemPeriod } from "models/Objects/IItemPeriod";
import { QueriesKeys } from "models/QueriesKeys";

import CalendarTableCell, { ICalendarTableCell } from "./Calendar.Table.Cell";
import CalendarTableHeader from "./Calendar.Table.Header";

import "styles/animation.css";
import React from "react";

import emitter from "services/emitter";

interface ICalendarTable {
  data: ICalendarTableCell[];
  isLoadingLeftTable: boolean;
}
const calculateAnimationDelay = (index) => {
  return index * 300; // Изменяйте это значение по своему усмотрению, чтобы настроить задержку
};

export type TUpdatePeriodQuery = UseMutationResult<
  IItemPeriod,
  unknown,
  {
    id: string;
    body: Partial<IItemPeriod>;
  },
  unknown
>;

export type TCreatePeriodQuery = UseMutationResult<
  IItemPeriod,
  unknown,
  {
    id: string;
    body: {
      actualVolume: number;
      staffVolume: number;
      date: string;
    };
    additionalData: {
      controlType: "DAILY" | "WEEKLY";
    };
  },
  unknown
>;

const onSuccessUpdatePeriodAfterEditCell = debounce(() => {
  queryClient.invalidateQueries(QueriesKeys.sectionItems);
}, 2000);

const CalendarTable: FC<ICalendarTable> = (props) => {
  const onSuccessUpdatePeriod = () => queryClient.invalidateQueries(QueriesKeys.sectionItemsPeriod);

  const createPeriod = useMutation(createPeriodApi, {
    onSuccess: onSuccessUpdatePeriodAfterEditCell,
  });

  const updatePeriod = useMutation(updatePeriodApi, {
    onSuccess: onSuccessUpdatePeriodAfterEditCell,
  });

  const approvePeriod = useMutation(approvePeriodApi);

  const onApprove = async (periodIdx: number) => {
    const agreedPeriod = props.data[periodIdx];
    const { days, weeklyData } = agreedPeriod;

    if (weeklyData) {
      const plan = weeklyData.find((cell) => cell.cell === "volume");
      await approvePeriod.mutateAsync({
        id: plan.periodId,
        body: {
          volume: plan.value,
        },
        params: {
          startDate: plan.startDate,
          endDate: plan.endDate,
        },
      });
    }

    if (days) {
      const plans = days.flat().filter((cell) => cell.cell.indexOf("_volume") !== -1 && cell.periodId);

      await Promise.all(
        plans.map((plan) =>
          approvePeriod.mutateAsync({
            id: plan.periodId,
            body: {
              volume: plan.value,
            },
            params: {
              startDate: plan.startDate,
              endDate: plan.endDate,
            },
          })
        )
      );
    }

    onSuccessUpdatePeriod();
  };

  const [isVisibleModal, setIsVisibleModal] = React.useState(false);

  React.useEffect(() => {
    emitter.addListener("show_modal_you_need_to_save_or_cancel_plan_changes", () => {
      setIsVisibleModal(true);
    });
  }, []);

  return (
    <>
      <div style={{ position: "relative" }}>
        <CalendarTableHeader />
        <LocalLoader condition={isEmpty(props.data) && !props.isLoadingLeftTable} posY={"70px"} />
        <TransitionGroup>
          {props.data.map((period, idx) => {
            return (
              <CSSTransition
                key={idx}
                classNames="stagger_right"
                timeout={300}
                unmountOnExit
                mountOnEnter
                style={{ transitionDelay: `${calculateAnimationDelay(idx)}ms` }}
              >
                <CalendarTableCell
                  {...period}
                  periodIdx={idx}
                  onApprove={onApprove}
                  updatePeriod={updatePeriod}
                  createPeriod={createPeriod}
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>
      <SimpleModal isVisibleModal={isVisibleModal}>
        <ModalContentContainer>
          <ModalText>Ошибка! Отправьте редактируемый план или</ModalText>
          <ModalText>отмените изменения</ModalText>
          <ModalButton onClick={() => setIsVisibleModal(false)}>Ок</ModalButton>
        </ModalContentContainer>
      </SimpleModal>
    </>
  );
};

export default CalendarTable;

const ModalText = styled.div`
  color: ${(props) => props.theme.colors.absolute700};
`;

const ModalContentContainer = styled.div`
  background-color: ${(props) => props.theme.colors.absolute100};
  box-shadow: 0px 4px 20px 0px #0013551c;
  padding: 10px;
  border-radius: 10px;
`;

const ModalButton = styled.button`
  color: ${(props) => props.theme.colors.blue400};
  margin-top: 10px;
`;
