import React, { FC } from "react";

import { isEmpty, last } from "lodash";
import styled, { css, keyframes } from "styled-components";

import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { ReactComponent as TreeArrowRight } from "assets/treeArrowRight.svg";
import Checkbox from "components/Checkbox";
import ModalForm from "components/Modal/ModalForm";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import { Spacer } from "styles/common";

import { TAddedMode, TOnNodeCheck, TOnNodeClick } from "./Tree";
import TreeStore from "./Tree.store";

type TArea = "initial" | "complex" | "area" | "building" | "section";

export interface INode {
  id: string;
  name: string;
  children?: INode[];
  readOnlyAddNewObjectInputPart?: string;
  meta?: {
    area: TArea;
    parentId?: string;
    addedNewObjectType?: "input" | "modal";
  };
  disabledCollapse?: boolean;
  disabledSelect?: boolean;
  canBeDeleted: boolean;
  maxLengthChildObject?: number;
}

interface ITreeNode {
  node: INode;
  onNodeClick: TOnNodeClick;
  onNodeChange?: TOnNodeCheck;
  checked?: boolean;
  parentChecked?: boolean;
  parents?: INode[];
  offsetLeft?: number;
  mode?: TAddedMode;
  level?: number;
  addInputProps?: any;
  onAddNewObject?: (node: INode) => void;
  hightlightedNodeIds?: (any | undefined)[];
  checkedElements?: any[];
  modalData?: {
    [key: string]: {
      label: string;
      data: any[];
    };
  };
  onSaveModalData?: (closeModalCb: () => void) => void;
  isLoadingInModal?: boolean;
  isErrorInModal?: boolean;
  coloredSearchLetter?: string;
}

const TreeNode: FC<ITreeNode> = (props) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [isChecked, setIsChecked] = React.useState(false);
  const [IsVisionAddedNewObjectInput, setIsVisionAddedNewObjectInput] = React.useState(false);
  const [IsVisionAddedNewObjectModal, setIsVisionAddedNewObjectModal] = React.useState(false);
  const { onBlur, ...otherInputProps } = props.addInputProps || {};
  const [newObjectInput, setNewObjectInput] = React.useState(
    props.node.readOnlyAddNewObjectInputPart ? `${props.node.readOnlyAddNewObjectInputPart} ` : ""
  );
  const currentObjectModalLevel = React.useRef("");

  const collapseTreeNode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsCollapsed(false);
  };

  // ? update current selected object after update tree node (added new children for example)
  React.useEffect(() => {
    if (TreeStore.selectedObject?.id === props.node?.id) {
      TreeStore.setSelectedObject(props.node);
    }
  }, [props.node]);

  const refChecked = React.useRef(false);

  const onChangeNewObjectInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const readOnlyAddNewObjectInputPart = props.node.readOnlyAddNewObjectInputPart ? `${props.node.readOnlyAddNewObjectInputPart} ` : "";

    if (readOnlyAddNewObjectInputPart) {
      if (inputValue.startsWith(readOnlyAddNewObjectInputPart)) {
        setNewObjectInput(inputValue);
      } else {
        if (inputValue.length < readOnlyAddNewObjectInputPart.length) {
          setNewObjectInput(readOnlyAddNewObjectInputPart);
        }
      }
    } else {
      setNewObjectInput(inputValue);
    }
  };

  const toggleCollapsed = (е) => {
    е.stopPropagation();
    if (props.node.disabledCollapse) {
      return;
    }

    setIsCollapsed((state) => !state);
  };

  // TODO We need to remove this logic from this component
  React.useEffect(() => {
    if (TreeStore.selectedComplex?.id === props.node.id) {
      setIsCollapsed(false);
    }

    if (TreeStore.selectedArea?.id === props.node?.id) {
      setIsCollapsed(false);
    }
  }, [props.node?.id]);

  const createNewSection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    selectObject(e);

    if (props.node.meta.addedNewObjectType === "input") {
      setIsVisionAddedNewObjectInput(!IsVisionAddedNewObjectInput);
      if (!IsVisionAddedNewObjectInput) {
        collapseTreeNode(e);
      }
    }

    if (props.node.meta.addedNewObjectType === "modal") {
      if (!IsVisionAddedNewObjectModal) {
        currentObjectModalLevel.current = props.level?.toString();
      }

      setIsVisionAddedNewObjectModal(!IsVisionAddedNewObjectModal);
      if (!IsVisionAddedNewObjectModal) {
        collapseTreeNode(e);
      }
    }
  };

  const onBlurAddedNewSectionInput = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    onBlur?.(e);
    setIsVisionAddedNewObjectInput(false);
  };

  const selectObject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (props.node.disabledSelect) {
      return;
    }

    TreeStore.setSelectedObject(props.node);
    switch (props.node?.meta?.area) {
      case "complex":
        TreeStore.setComplex(props.node);
        // TODO Moved to store
        TreeStore.setArea(undefined);
        TreeStore.setBuilding(undefined);
        TreeStore.setSection(undefined);
        break;
      case "area":
        TreeStore.setArea(props.node);
        // TODO Moved to store
        TreeStore.setBuilding(undefined);
        TreeStore.setSection(undefined);
        break;
      case "building":
        TreeStore.setBuilding(props.node);
        // TODO Moved to store
        TreeStore.setSection(undefined);

        break;
      case "section":
        TreeStore.setSection(props.node);
        break;
    }

    props.onNodeClick(props.node);
  };

  let inputMarginLeft;

  switch ((props.level ?? 0) + 1) {
    case 1:
      inputMarginLeft = -25;
      break;
    case 2:
      inputMarginLeft = -45;
      break;
    case 3:
      inputMarginLeft = -85;
      break;
    default:
      inputMarginLeft = -25;
  }

  const addNewObject = () => {
    props.onAddNewObject?.({
      id: "new",
      name: newObjectInput,
      meta: {
        area: props.node.meta.area,
        parentId: last(props.parents).id,
      },
      canBeDeleted: false,
    });
    setNewObjectInput(props.node.readOnlyAddNewObjectInputPart ? `${props.node.readOnlyAddNewObjectInputPart} ` : "");
    setIsVisionAddedNewObjectInput(false);
  };

  const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" && newObjectInput) {
      addNewObject();
    }
  };

  const isCheckedElements = props.checkedElements?.includes(last(props.parents).id);

  const onCheckedObject = (checked: boolean, innerText: string) => {
    setIsChecked(!isChecked);
    refChecked.current = !refChecked.current;

    props.onNodeChange && props.onNodeChange(props.parents, checked, innerText);
  };

  const ColoredSearchText = ({ text, searchText }) => {
    const regex = new RegExp(searchText, "gi");
    const coloredLine = text.replace(regex, (match) => `<span style="background: #cccc2166">${match}</span>`);
    return <div dangerouslySetInnerHTML={{ __html: coloredLine }} />;
  };

  React.useEffect(() => {
    if (isCheckedElements) {
      setIsChecked(!isChecked);
    }
    if (props.parentChecked) {
      refChecked.current = true;
    }
  }, []);

  return (
    <>
      <Node onClick={selectObject} offsetLeft={props.offsetLeft ?? 0} fadeIn={props.mode === "added" && IsVisionAddedNewObjectInput}>
        <InfoNodeContainer offsetLeft={props.offsetLeft} isHighlighted={props.hightlightedNodeIds?.includes(props.node.id)}>
          <div className="flex">
            {props.mode === "checkbox" && props.level > 0 && (
              <Checkbox
                label={""}
                disabled={props.parentChecked}
                checked={props.parentChecked ? props.parentChecked : isChecked}
                setChecked={onCheckedObject}
              />
            )}

            <RenderWithCondition condition={!props.node.disabledCollapse && !isEmpty(props.node.children)}>
              <ButtonCollapse onClick={toggleCollapsed}>{<AnimatedArrowRight isCollapsed={isCollapsed} />}</ButtonCollapse>
            </RenderWithCondition>
            <button>
              <NodeNameText>
                <ColoredSearchText text={props.node.name} searchText={props.coloredSearchLetter} />
              </NodeNameText>
            </button>
          </div>

          {props.mode === "added" && props.node.meta.addedNewObjectType && (
            <button onClick={createNewSection}>
              <CustomPlusIcon />
            </button>
          )}
        </InfoNodeContainer>

        {IsVisionAddedNewObjectInput && (
          <>
            {/* <div> */}
            <TreeInputContainer minusOffsetLeft={inputMarginLeft}>
              <Input
                onBlur={onBlurAddedNewSectionInput}
                autoFocus
                offsetLeft={(props.offsetLeft ?? 0) + 10}
                {...otherInputProps}
                value={newObjectInput}
                onChange={onChangeNewObjectInput}
                onKeyDown={onKeyEnter}
                maxLength={props.node.maxLengthChildObject}
              />
              <ArrowLongRightContainer onMouseDown={addNewObject}>
                <TreeArrowRight fill="white" />
              </ArrowLongRightContainer>
              <Spacer px={10} horizontal />
            </TreeInputContainer>
          </>
        )}
        {IsVisionAddedNewObjectModal && (
          <ModalForm
            label={props.modalData[currentObjectModalLevel.current].label}
            textButton={"Добавить"}
            setIsVisible={setIsVisionAddedNewObjectModal}
            data={props.modalData[currentObjectModalLevel.current].data}
            onSave={() => props.onSaveModalData(() => setIsVisionAddedNewObjectModal(false))}
            isLoading={props.isLoadingInModal}
            isError={props.isErrorInModal}
          />
        )}
        <>
          {!isCollapsed && (
            <div className="flex flex-col">
              {props.node?.children?.map((node) => (
                <TreeNode
                  key={node.id}
                  onAddNewObject={props.onAddNewObject}
                  onNodeChange={props.onNodeChange}
                  offsetLeft={(props.offsetLeft ?? 0) + 10}
                  node={node}
                  checked={refChecked.current}
                  parentChecked={refChecked.current}
                  onNodeClick={props.onNodeClick}
                  parents={[...(props.parents ?? []), node]}
                  mode={props.mode}
                  level={(props.level ?? 0) + 1}
                  hightlightedNodeIds={props.hightlightedNodeIds}
                  checkedElements={props.checkedElements}
                  modalData={props.modalData}
                  onSaveModalData={props.onSaveModalData}
                  isErrorInModal={props.isErrorInModal}
                  isLoadingInModal={props.isLoadingInModal}
                />
              ))}
            </div>
          )}
        </>
      </Node>
    </>
  );
};

export default TreeNode;

const PADDING_LEFT = 15;

const Node = styled.button<{ offsetLeft: number; fadeIn: boolean }>`
  padding-left: ${(props) => props.offsetLeft}px;
  padding-top: 5px;
  position: relative;
  ${(props) => props.fadeIn && fadeInStyles};
`;

const NodeNameText = styled.p`
  z-index: 2;
`;

const ButtonCollapse = styled.button`
  z-index: 2;
  padding-right: 10px;
`;

const InfoNodeContainer = styled.div<{ offsetLeft: number; isHighlighted: boolean }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 5px 10px 5px ${PADDING_LEFT}px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${(props) => (props.isHighlighted ? props.theme.colors.blue200 : "")};
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: ${(props) => (props.offsetLeft - PADDING_LEFT < 0 ? props.offsetLeft - PADDING_LEFT : -(props.offsetLeft - PADDING_LEFT))}px;
    width: ${(props) => props.offsetLeft}px;
    height: 100%;
    z-index: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: ${(props) => (props.isHighlighted ? props.theme.colors.blue200 : "")};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.blue200};

    &:before {
      background-color: ${(props) => props.theme.colors.blue200};
    }
  }
`;

const CustomPlusIcon = styled(PlusIcon)`
  > path {
    fill: ${(props) => props.theme.colors.absolute500};
  }
`;

export const TreeInputContainer = styled.div<{ minusOffsetLeft: number }>`
  border: 1px solid ${(props) => props.theme.colors.blue400};
  display: flex;
  align-items: center;
  width: 100%;
`;

const Input = styled.input<{ offsetLeft: number }>`
  padding-left: ${(props) => props.offsetLeft + 15}px;
  background-color: ${(props) => props.theme.colors.blue100};
  width: 100%;
`;

export const ArrowLongRightContainer = styled.button`
  background-color: ${(props) => props.theme.colors.green300};

  padding: 4px 8px;
  margin: 4px 0;
  border-radius: 4px;
`;

const rotateArrowAnimationDown = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
`;

const rotateArrowAnimationUp = keyframes`
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const AnimatedArrowRight = styled(TreeArrowRight)<{ isCollapsed: boolean }>`
  animation: ${(props) => (props.isCollapsed ? rotateArrowAnimationUp : rotateArrowAnimationDown)} 300ms linear forwards;
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInStyles = css`
  animation: ${fadeInAnimation} 0.3s ease-in-out forwards;
  opacity: 0;
`;
