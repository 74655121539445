import { FC, useState } from "react";

import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export interface IRenderLine {
  fill: string;
  fillDot?: string;
  dataKey: string;
}

interface ILineChartItem {
  data: any;
  externalTooltip: any;
  renderLine?: IRenderLine[];
  fill?: string;
  pointXaxis?: string;
  dot?: any;
  isValuesOfData?: boolean;
  isWeekly?: boolean;
}

const LineChartItem: FC<ILineChartItem> = (props) => {
  const [isActive, setActive] = useState(false);
  const angleTick = props.isWeekly ? 0 : -90;
  const bottomMargin = props.isWeekly ? 0 : 30;
  const tickMargin = props.isWeekly ? 7 : 27;
  const tickXInterval = !props.isWeekly && props.isValuesOfData ? 3 : 0;

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={props.data}
          margin={{ right: 37, left: -10, bottom: props.isValuesOfData ? 0 : bottomMargin, top: 7 }}
          width={1000}
          height={350}
        >
          <CartesianGrid vertical={false} height={350} />
          <XAxis
            angle={props.isValuesOfData ? 0 : angleTick}
            tick={{ fill: "#000" }}
            tickMargin={props.isValuesOfData ? 7 : tickMargin}
            textAnchor="middle"
            textLength={51}
            fontSize={11.5}
            fontFamily={"Inter"}
            dataKey={props.pointXaxis}
            interval={tickXInterval}
            tickLine={false}
            axisLine={false}
            padding={{ left: 3 }}
          />

          <YAxis
            tick={{ fill: "#000" }}
            tickMargin={12}
            fontFamily={"Inter"}
            fontSize={12}
            fontWeight={400}
            tickCount={8}
            tickFormatter={(value) => (Number.isInteger(value) ? value : "")}
            tickLine={false}
            orientation="left"
            axisLine={false}
            padding={{ top: 0, bottom: 0 }}
          ></YAxis>
          <Tooltip content={props.externalTooltip} />
          {props.renderLine &&
            props.renderLine.map((line) => (
              <Line
                type="monotone"
                key={line.dataKey}
                dataKey={line.dataKey}
                strokeWidth={2}
                stroke={line.fill}
                fill={line.fill}
                dot={props.dot}
                activeDot={{ r: 6, stroke: line.fill, fill: line.fillDot }}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineChartItem;
