import React, { FC } from "react";

import { AxiosError } from "axios";
import { isEmpty, last, uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import styled from "styled-components";

import { updateAreaApi, deleteAreaApi } from "api/Objects/api.areas";
import { updateBuildingApi, deleteBuildingApi } from "api/Objects/api.buildings";
import { deleteComplexApi, updateComplexApi } from "api/Objects/api.complexes";
import { deleteSectionApi, updateSectionApi } from "api/Objects/api.sections";
import { queryClient } from "App";
import { ReactComponent as Bin } from "assets/bin.svg";
import { ReactComponent as CircleCheckIcon } from "assets/circle-check.svg";
// import { ReactComponent as FileTray } from "assets/file-tray.svg";
import addObjectPlusImg from "assets/images/addObjectPlus.png";
import { ReactComponent as EditPencil } from "assets/pencil.svg";
import Button from "components/Button";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import TreeStore from "components/Tree/Tree.store";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useObjectNames from "hooks/useObjectNames";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import { ErrorsCode } from "models/ErrorsCode";
import { QueriesKeys } from "models/QueriesKeys";
import { Spacer } from "styles/common";
import notify from "utils/notify";

import ObjectStore from "../store/Object.store";

const isMaxLengthNameOfObject = (startWithKeywordValue: string, newValue: string) => {
  switch (startWithKeywordValue) {
    case "Комплекс ":
      return newValue.length > 30;
    case "Литер ":
      return newValue.length > 16;
    case "Участок ":
      return newValue.length > 18;
    case "Секция ":
      return newValue.length > 17;
    default:
      return false;
  }
};

const ObjectNames: FC = observer(() => {
  const restrictedData = useRestrictedAccessData();

  const [name, setName] = React.useState("");
  const nameInputRef = React.useRef<HTMLInputElement>();
  const [isFocusedNameInput, setIsFocusedNameInput] = React.useState(false);
  const nameMetaDataRef = React.useRef({
    isExistReadOnlyKeywordInTheStartingInput: false,
    startWithKeywordValue: "",
  });

  const objectNames = useObjectNames();

  const onErrorDelete = (error_code: number) => {
    if (ErrorsCode.Bad_Request === error_code) {
      notify.error("Перед удалением обьекта, нужно удалить все вложенности");
    }
  };

  const updateComplex = useMutation(updateComplexApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.tree]);
    },
  });
  const deleteComplex = useMutation(deleteComplexApi, {
    onSuccess: () => {
      TreeStore.setSelectedObject(undefined);
      TreeStore.setComplex(undefined);
      queryClient.invalidateQueries([QueriesKeys.tree]);
    },
    onError: (err: AxiosError<any>) => {
      onErrorDelete(err.response.status);
    },
  });

  const updateArea = useMutation(updateAreaApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.tree]);
    },
  });
  const deleteArea = useMutation(deleteAreaApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.tree]);

      TreeStore.setSelectedObject(TreeStore.selectedComplex);
      TreeStore.setArea(undefined);
    },
    onError: (err: AxiosError<any>) => {
      onErrorDelete(err.response.status);
    },
  });

  const updateBuilding = useMutation(updateBuildingApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.tree);
    },
  });
  const deleteBuilding = useMutation(deleteBuildingApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.tree]);

      TreeStore.setSelectedObject(TreeStore.selectedArea);
      TreeStore.setBuilding(undefined);
    },
    onError: (err: AxiosError<any>) => {
      onErrorDelete(err.response.status);
    },
  });

  const updateSection = useMutation(updateSectionApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.tree]);
    },
  });
  const deleteSection = useMutation(deleteSectionApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.tree]);

      TreeStore.setSelectedObject(TreeStore.selectedBuilding);
      TreeStore.setSection(undefined);
    },
    onError: (err: AxiosError<any>) => {
      onErrorDelete(err.response.status);
    },
  });

  const getFocusInput = () => {
    setIsFocusedNameInput(true);
    nameInputRef.current.disabled = false;
    nameInputRef.current.focus();
  };

  const onBlur = () => {
    if (name) {
      setIsFocusedNameInput(false);
      nameInputRef.current.disabled = true;
    }
  };

  const onUpdateObject = () => {
    if (name) {
      onBlur();
      const currentSelectedObjectStore = ObjectStore.selectedObject;

      switch (currentSelectedObjectStore.meta.area) {
        case "complex":
          updateComplex.mutateAsync({ body: { name }, path: { id: currentSelectedObjectStore.id } });
          break;
        case "area":
          updateArea.mutateAsync({ body: { name }, path: { id: currentSelectedObjectStore.id } });
          break;
        case "building":
          updateBuilding.mutateAsync({ body: { name }, path: { id: currentSelectedObjectStore.id } });
          break;
        case "section":
          updateSection.mutateAsync({ body: { name }, path: { id: currentSelectedObjectStore.id } });
          break;
        default:
          throw new Error(`unrecognized area: ${ObjectStore.selectedObject.meta.area}`);
      }
    } else {
      notify.error("Заполните название");
      getFocusInput();
    }
  };

  const onDeleteObject = () => {
    const currentSelectedObjectStore = TreeStore.selectedObject;

    switch (currentSelectedObjectStore.meta.area) {
      case "complex":
        deleteComplex.mutateAsync({ path: { id: currentSelectedObjectStore.id } });
        break;
      case "area":
        deleteArea.mutateAsync({ path: { id: currentSelectedObjectStore.id } });
        break;
      case "building":
        deleteBuilding.mutateAsync({ path: { id: currentSelectedObjectStore.id } });
        break;
      case "section":
        deleteSection.mutateAsync({ path: { id: currentSelectedObjectStore.id } });
        break;
      default:
        throw new Error(`unrecognized area: ${ObjectStore.selectedObject.meta.area}`);
    }
  };

  React.useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.style.width = `${nameInputRef.current.scrollWidth}px`;
    }
  }, [name]);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const isMaxLengthNewValue = isMaxLengthNameOfObject(nameMetaDataRef.current.startWithKeywordValue, newValue);

    if (isMaxLengthNewValue) {
      return;
    }

    if (
      nameMetaDataRef.current.isExistReadOnlyKeywordInTheStartingInput &&
      !newValue.startsWith(nameMetaDataRef.current.startWithKeywordValue)
    ) {
      setName(nameMetaDataRef.current.startWithKeywordValue);
    } else {
      setName(e.target.value);
    }
  };

  React.useEffect(() => {
    if (!isEmpty(objectNames)) {
      const objectName = last(objectNames);

      if (objectName.startsWith("Участок ") || objectName.startsWith("Литер ") || objectName.startsWith("Секция ")) {
        nameMetaDataRef.current.isExistReadOnlyKeywordInTheStartingInput = true;
        nameMetaDataRef.current.startWithKeywordValue = `${objectName.split(" ")[0]} `;
      } else {
        nameMetaDataRef.current.isExistReadOnlyKeywordInTheStartingInput = false;
        nameMetaDataRef.current.startWithKeywordValue = "Комплекс ";
      }

      setName(objectName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectNames]);

  return (
    <div className="relative">
      <div className="flex  items-center gap-5">
        <ObjectNamesContainer>
          {objectNames?.slice().map((currName, idx, arr) => {
            if (idx === arr.length - 1) {
              return <NodeNameInput ref={nameInputRef} disabled value={name} onChange={onChangeName} onBlur={onBlur} />;
            }

            return (
              <React.Fragment key={uniqueId(`object_name_${currName}`)}>
                <ParentName>{currName}</ParentName>
                <Spacer px={15} horizontal />
                <ParentName>|</ParentName>
              </React.Fragment>
            );
          })}
        </ObjectNamesContainer>

        {restrictedData.vars.canAddAndChangeObjects && (
          <>
            <RenderWithCondition condition={isFocusedNameInput}>
              <CircleIconButton onMouseDown={onUpdateObject}>
                <CircleCheckIcon fill="white" />
              </CircleIconButton>
            </RenderWithCondition>
            <RenderWithCondition condition={!isFocusedNameInput && !isEmpty(objectNames)}>
              <ButtonRow>
                <Button type="usual" icon={EditPencil} onClick={getFocusInput} />
                {/* //TODO: временно скрыть */}
                {/* <Button type="usual" icon={FileTray} onClick={() => undefined} /> */}
                <Button type="usual" icon={() => <Bin fill="#DB333D" />} onClick={onDeleteObject} />
              </ButtonRow>
            </RenderWithCondition>
          </>
        )}
      </div>
      <EmptyPlaceholderCmp />
    </div>
  );
});

export default ObjectNames;

const ObjectNamesContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const ParentName = styled.p`
  color: ${(props) => props.theme.colors.absolute500};
  font-size: 18px;
`;

const NodeNameInput = styled.input`
  color: ${(props) => props.theme.colors.absolute700};
  font-weight: bold;

  &[disabled] {
    background-color: ${(props) => props.theme.colors.absolute100};
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 20px;
`;

const CircleIconButton = styled.button`
  background-color: ${(props) => props.theme.colors.blue400};
  padding: 14px;
  border-radius: 10px;
`;

const EmptyPlaceholderCmp = observer(() => {
  if (ObjectStore.selectedSection || TreeStore.isEmptyTreeData) {
    return <></>;
  }

  if (isEmpty(TreeStore.selectedObject?.children)) {
    let dontHaveYetText = "";

    switch (TreeStore.selectedObject?.meta?.area) {
      case "complex":
        dontHaveYetText = "У вас еще нет ни одного участка";
        break;
      case "area":
        dontHaveYetText = "У вас еще нет ни одного литера";
        break;
      case "building":
        dontHaveYetText = "У вас еще нет ни одной секции";
        break;
      default:
        console.warn(`unrecognized area: ${ObjectStore?.selectedObject?.meta?.area}`);
    }

    return (
      <EmptyPlaceholder
        style={{ left: 0, top: "70px" }}
        description={"Для того чтобы добавить объект нажмите"}
        header={dontHaveYetText}
        ImgComponent={() => <img src={addObjectPlusImg} />}
        description2={"В левой части экрана"}
      />
    );
  }

  return <></>;
});
