import apiClient from "api/api.client";
import { IArea } from "models/Objects/IArea";

export type TCreateAreaApi = (body: { name: string; complexId: string }) => Promise<IArea>;

export const createAreaApi: TCreateAreaApi = async (body) => {
  try {
    const res = await apiClient.post("objects/v1/areas", body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetAreasApi = (params: { complexId: string }) => Promise<IArea[]>;

export const getAreasApi: TGetAreasApi = async (params) => {
  try {
    const res = await apiClient.get("objects/v1/areas", { params });
    const modifiedResult = res.data.map((item) => ({ ...item, parentId: params.complexId }));

    return modifiedResult;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateAreaApi = (payload: { body: { name: string }; path: { id: string } }) => Promise<Omit<IArea, "parentId">>;

export const updateAreaApi: TUpdateAreaApi = async (payload) => {
  try {
    const res = await apiClient.patch(`objects/v1/areas/${payload.path.id}`, payload.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteAreagApi = (payload: { path: { id: string } }) => Promise<void>;

export const deleteAreaApi: TDeleteAreagApi = async (payload) => {
  try {
    const res = await apiClient.delete(`objects/v1/areas/${payload.path.id}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
