import { FC } from "react";
import React from "react";

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";

import { deleteWorkApi, getWorksApi } from "api/References/api.works";
import { queryClient } from "App";
import { ReactComponent as EditPencilBlue } from "assets/pencil-blue.svg";
import { ReactComponent as SetMenu } from "assets/set-menu.svg";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import { ErrorsCode } from "models/ErrorsCode";
import { QueriesKeys } from "models/QueriesKeys";
import { IWork } from "models/References/IWork";
import { Table, TableCell, TableHeader, TableRow } from "screens/Home/styles/common";
import notify from "utils/notify";

interface IReferenceTable {
  width: number | string;
  handleOpenUpdate: (e: IWork) => void;
}

const columnHelper = createColumnHelper<any>();

const ReferenceTable: FC<IReferenceTable> = (props) => {
  const restrictedData = useRestrictedAccessData();

  const text = useText();

  const works = useQuery({
    queryKey: QueriesKeys.works,
    queryFn: getWorksApi,
  });
  const deleteWork = useMutation(deleteWorkApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.works);
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const data = works.data ?? [];

  const getColumns = () => {
    const worksColumn = columnHelper.accessor("name_of_the_work", {
      header: text.jobTitle,
      cell: (info) => info.row.original.name,
      enableResizing: true,
      meta: { styleCol: { textAlign: "left", padding: "0 16px" } },
    });
    const measures = columnHelper.accessor("update", {
      header: "ед. изм",
      cell: (info) => info.row.original.measureUnit?.value,
      enableResizing: true,
      meta: { styleCol: { minWidth: "100px", padding: "0 8px" } },
    });
    const updateColumn = columnHelper.accessor("update", {
      header: "Редактировать",
      cell: (info) => (
        <div className="flex justify-center cursor-pointer h-full items-center">
          <button className="flex justify-center w-full h-full items-center" onClick={() => props.handleOpenUpdate(info.cell.row.original)}>
            <EditPencilBlue />
          </button>
        </div>
      ),
      enableResizing: true,
      meta: { styleCol: { padding: "4px", minWidth: "150px" } },
    });

    if (!restrictedData.vars.canChangeDataOfReference) {
      return [worksColumn, measures];
    }
    return [
      worksColumn,
      measures,
      updateColumn,
      //TODO: ==== TEMPORARY HIDING ==========
      // columnHelper.accessor("control", {
      //   header: text.delete,
      //   cell: (info) => (
      //     <div className="flex justify-center">
      //       <button
      //         className="flex justify-center"
      //         onClick={() => {
      //           deleteWork.mutateAsync(info.row.original.id);
      //         }}
      //       >
      //         <Bin />
      //       </button>
      //     </div>
      //   ),
      //   enableResizing: true,
      //   meta: { styleCol: { alignItems: "center", height: "48px", width: "150px" } },
      // }),
    ];
  };

  const [columnVisibility, setColumnVisibility] = React.useState({});

  const table = useReactTable({
    data,
    columns: getColumns(),
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  return (
    <Table width={props.width}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeader className="text-sm" key={header.id} style={{ height: 48 }}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => {
              // @ts-ignore
              const style = cell.column.columnDef.meta?.styleCol || {};

              return (
                <TableCell className="h-12" style={{ ...style }} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default ReferenceTable;

export const LeftButtonTable = styled.button`
  height: 100%;
  width: 20px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.blue200};
`;
