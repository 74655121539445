import React, { useState } from "react";

import { useQuery } from "react-query";
import styled from "styled-components";

import { getReportSettingsApi } from "api/Settings/api.settings";
import Input from "components/Input";
import RadioGroup from "components/RadioGroup";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import { QueriesKeys } from "models/QueriesKeys";
import UsersStore from "screens/Users/store/Users.store";
import { Screen, Spacer } from "styles/common";

import SetPeriod from "./components/SetPeriod";

const tempData = {
  amountOfDays: 7,
  amountOfMonths: 4,
  id: "ad5f8381-d7d6-4864-9bd5-7e91e7cf9f9f",
  period: "ARBITRARY",
  remindAboutUnfilledPlansInDays: 3,
  startDay: "MONDAY",
};

const Settings = () => {
  const reportSettings = useQuery({ queryKey: QueriesKeys.settings, queryFn: getReportSettingsApi });

  // const data = reportSettings.data ?? ({} as IReportSettings);
  const data = reportSettings.data ?? tempData;
  const { remindAboutUnfilledPlansInDays, period, amountOfMonths, amountOfDays } = data;

  const [optionPeriod, setOptionPeriod] = useState(period);
  const [startDay, setStartDay] = useState(data.startDay);
  const [amountMonths, setAmountMonths] = useState(String(amountOfMonths));
  const [amountDays, setAmountDays] = useState(String(amountOfDays));
  const [remindDays, setRemindDays] = useState(String(remindAboutUnfilledPlansInDays));
  const text = useText();

  const restrictedData = useRestrictedAccessData();

  const OPTIONS_BIND_PERIOD = React.useMemo(
    () => [
      { value: "WEEK", label: text.bindWeek },
      //TODO:  временно скрыть
      // { value: "MONTH", label: text.bindMonth },
      // { value: "ARBITRARY", label: text.arbitraryPeriod },
    ],
    [text]
  );
  let titlePeriod = "";
  switch (optionPeriod) {
    case "WEEK":
      titlePeriod = "Привязать к неделе";
      break;
    case "MONTH":
      titlePeriod = "Привязать к месяцу";
      break;
    case "ARBITRARY":
      titlePeriod = "Произвольный период";
      break;
    default:
      titlePeriod = "";
  }

  const handleChangePeriod = (value: string) => {
    setOptionPeriod(value);
    UsersStore.setSettingPeriod(value);
  };
  const changeRemindDays = (value: any) => {
    setRemindDays(value.target.value);
  };
  const changeAmountMonths = (value: any) => {
    setAmountMonths(value.target.value);
  };
  const changeAmountDays = (value: any) => {
    setAmountDays(value.target.value);
  };
  const changeStartDays = (value: any) => {
    setStartDay(value);
  };

  return (
    <Screen className="w-full">
      <Container className="md:container  flex">
        <TableContainer className="flex flex-col">
          <TextTitle className="text-xl font-bold">{`${text.settings}. ${titlePeriod}`}</TextTitle>
          <div className="flex items-center  gap-3 ">
            <Text>{text.textSettings}</Text>
            <ShadowContainer className="flex items-center">
              <Text>
                за
                {/* //TODO:  временно disabled */}
                <Input onChange={changeRemindDays} widthInputLine="35px" disabled value={remindDays} />
                дня
              </Text>
              {/* <div className="flex"></div> */}
            </ShadowContainer>
          </div>
          <Spacer px={37} />
          <TextTitle className="text-xl font-bold">{text.reportPeriod}</TextTitle>
          <div className="flex gap-8 w-full pr-3">
            <RadioGroup onChange={handleChangePeriod} options={OPTIONS_BIND_PERIOD} defaultChecked={period} />
          </div>
          <Spacer px={30} />
          <SetPeriod
            amountMonths={amountMonths}
            amountDays={amountDays}
            onChange={changeStartDays}
            optionPeriod={optionPeriod}
            defaultChecked={startDay}
            changeMonths={changeAmountMonths}
            changeDays={changeAmountDays}
            disabled={!restrictedData.vars.canChangeDataOfReference}
          />
          <div className="flex py-5">
            {/* //TODO:  временно скрыть */}
            {/* <ButtonLight title={text.save} /> */}
          </div>
        </TableContainer>
      </Container>
    </Screen>
  );
};

export default Settings;

const TableContainer = styled.div`
  flex: 1;
  padding-left: 20px;
`;
const Container = styled.section`
  padding-top: 20px;
`;
const TextTitle = styled.p`
  color: ${(props) => props.theme.colors.absolute700};
  /* padding-left: 20px; */
  padding-bottom: 20px;
`;
const Text = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 448px;
  color: ${(props) => props.theme.colors.absolute700};
  font-size: 18px;
  @media (max-width: 1279px) {
    font-size: 14px;
  }
`;

const ShadowContainer = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
  background-color: ${(props) => props.theme.colors.absolute100};
`;
