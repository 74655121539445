import React, { FC, ReactElement, useRef } from "react";

import { useClickAway } from "@uidotdev/usehooks";
import { uniqueId } from "lodash";
import { isEmpty } from "lodash";
import styled from "styled-components";

import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { LocalLoader } from "components/Loader/LocalLoader";
import Tree, { TOnNodeCheck } from "components/Tree/Tree";
import { INode } from "components/Tree/Tree.Node";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import { Events } from "models/Events";
import emitter from "services/emitter";

import Button from "./Dropdown.Button";
import DropdownCheckboxList from "./Dropdown.CheckboxList";
import DropdownList from "./Dropdown.List";
import DropDownOverlay from "./Dropdown.Overlay";
import { EmptyList } from "./EmptyList";

export interface ICheckboxValue {
  label: string;
  value: string | ReactElement;
  id?: string;
}
export interface INewElementsForm {
  text: string;
  type: string;
  onChangeInputSearch?: (event: any) => void;
  valueInputSearch?: string;
}

export interface IDropdownItem {
  value: string | ReactElement;
  label: string;
  icon?: FC;
  activeIcon?: FC;
  bottomDivider?: boolean;
  children?: IDropdownItem[];
  linkTo?: string;
  parentPath?: string;
  id?: string;
  rightText?: string;
}

interface IDropDown {
  type?: "tree-select" | "multi-select" | "simple" | "tree-select-checkbox";
  permissiveRole?: boolean;
  data: IDropdownItem[];
  onChange: (item: { value: string | ReactElement; label?: string; id?: string }, closeDropdownCallback?: () => void) => void;
  selectedValue?: string;
  buttonChangeClassName?: any;
  checkboxCheckedIds?: string[];
  iconButton?: React.FC;
  styleButton?: any;
  meta?: {
    element: string;
  };
  CustomButton?: React.FC<{ onClick: () => void }>;
  dropdownStyles?: React.CSSProperties;
  dropdownItemStyles?: React.CSSProperties;
  highlightSelectedValue?: boolean;
  isLoading?: boolean;
  isFullWidthList?: boolean;
  topPlaceholder?: string;
  placeholderInSelectedText?: string;
  onChangeSelectCheckbox?: TOnNodeCheck;
  checkedElements?: any[];
  addNewElementsForm?: INewElementsForm;
  openNewElementsForm?: (value: string) => void;
  isLoadingTree?: boolean;
  onClearSelectedValueCallback?: () => void;
}

const traverseUpAndCheckAttribute = (element, attributeName, onClose) => {
  if (!element || element === document.documentElement) {
    return onClose?.();
  }

  if (element.hasAttribute(attributeName)) {
    // Attribute found, no need to continue traversing
    return;
  }

  traverseUpAndCheckAttribute(element.parentNode, attributeName, onClose);
};

const DropDown: FC<IDropDown> = (props) => {
  const [isOpenDropDown, setIsOpenDropdown] = React.useState(false);
  const [selectValue, setSelectValue] = React.useState("");
  const dropdownId = React.useRef(uniqueId());
  const [isChangedSelectValue, setIsChangedSelectValue] = React.useState(false);

  const refSelected = useRef<boolean>(false);
  const refInputSearch = useRef(null);

  const toggleOpenDropDown = () => {
    if (!isOpenDropDown) {
      closeOthersDropdown();
    }
    setIsOpenDropdown((status) => !status);
  };

  const onChange = (item: { value: string | ReactElement; label: string; id: string; typeList: string }) => {
    setIsChangedSelectValue(true);
    if (typeof item.value === "string") {
      props.onChange(item);
      setIsOpenDropdown(false);
      emitter.emit(Events["selected_item"], {
        item,
      });
    }
    if (item.value && typeof item.value === "string") {
      setSelectValue(item.value);
      refSelected.current = true;
    }
  };

  const onClearSelectedValue = (e) => {
    e.stopPropagation();
    refSelected.current = false;
    setSelectValue(props.selectedValue);
    emitter.emit(Events["selected_clear"], {
      typeList: props.topPlaceholder,
    });
    emitter.emit(Events["close_away_dropdown"], {
      isOpenDropDown,
    });
    if (isOpenDropDown) {
      refInputSearch.current.focus();
    }
    props.onClearSelectedValueCallback?.();
  };

  const onChangeTree = (item: INode) => {
    props.onChange(item as any, () => setIsOpenDropdown(false));
  };

  const dropdownOverlayRef = useClickAway((e) => {
    traverseUpAndCheckAttribute(e.target, "data-dropdown-button", () => {
      setIsOpenDropdown(false);
      emitter.emit(Events["close_away_dropdown"], {
        isOpenDropDown,
      });
      emitter.emit(Events["selected_clear"]);
    });
  });

  React.useEffect(() => {
    setSelectValue(props.selectedValue);
  }, [props.selectedValue]);

  React.useEffect(() => {
    emitter.emit(Events["click_dropdown"], {
      isOpenDropDown,
      meta: props.meta,
    });

    emitter.addListener("close_dropdown", (e) => {
      if (e.emmitterDropdownId !== dropdownId.current) {
        setIsOpenDropdown(false);
      }
    });
  }, [isOpenDropDown]);

  const closeOthersDropdown = () => {
    emitter.emit(Events["close_dropdown"], {
      emmitterDropdownId: dropdownId.current,
      isOpenDropDown,
    });
  };

  const onChangeInputSearch = (e) => {
    props.addNewElementsForm?.onChangeInputSearch(e);
    refSelected.current = false;
    setSelectValue(props.selectedValue);
    emitter.emit(Events["selected_clear"]);
  };

  const { CustomButton } = props;

  return (
    <>
      <RenderWithCondition condition={CustomButton}>
        <div data-dropdownButton="true" className="flex items-center justify-center">
          <CustomButton onClick={toggleOpenDropDown} />
        </div>
      </RenderWithCondition>
      <RenderWithCondition condition={!CustomButton && !props.permissiveRole}>
        <div data-dropdown-button="true">
          <Button
            onClick={toggleOpenDropDown}
            type={props.iconButton ? "icon" : "dropdown"}
            className={props.buttonChangeClassName}
            icon={props.iconButton}
            borderFocus={isOpenDropDown}
          >
            {(props.addNewElementsForm && isOpenDropDown) || (refSelected.current && props.topPlaceholder) ? (
              <LabelPlaceholder>{props.topPlaceholder}</LabelPlaceholder>
            ) : null}
            <Flex>
              {isChangedSelectValue && props.placeholderInSelectedText && <GreyText>{props.placeholderInSelectedText}</GreyText>}
              {selectValue && !(props.addNewElementsForm && isOpenDropDown) ? (
                <Label primaryText={props.highlightSelectedValue ? true : false}>{selectValue}</Label>
              ) : null}
              {props.addNewElementsForm && isOpenDropDown && (
                <InputSearch
                  ref={refInputSearch}
                  autoFocus={true}
                  onChange={onChangeInputSearch}
                  value={props.addNewElementsForm?.valueInputSearch}
                />
              )}
            </Flex>
            {refSelected.current && selectValue && props.addNewElementsForm && (
              <TextClearRow onClick={onClearSelectedValue}>✕ очистить</TextClearRow>
            )}
          </Button>
        </div>
      </RenderWithCondition>
      <RenderWithCondition condition={isOpenDropDown} animated>
        <DropDownOverlay style={props.dropdownStyles} ref={dropdownOverlayRef as any} isFullWidthList={props.isFullWidthList}>
          {props.type === "tree-select" && (
            <>
              <Tree nodes={props.data as any} onNodeClick={onChangeTree} onClose={setIsOpenDropdown} />
            </>
          )}
          {props.type === "tree-select-checkbox" && (
            <>
              <Tree
                mode="checkbox"
                nodes={props.data as any}
                onNodeClick={() => undefined}
                onClose={setIsOpenDropdown}
                onNodeChange={props.onChangeSelectCheckbox}
                checkedElements={props.checkedElements}
              />
            </>
          )}

          {props.type === "multi-select" && (
            <>
              <DropdownCheckboxList
                data={props.data}
                checkboxCheckedIds={props.checkboxCheckedIds ?? []}
                onChange={props.onChange}
                onClose={setIsOpenDropdown}
              />
            </>
          )}

          {props.type === "simple" && (
            <>
              <RenderWithCondition condition={props.addNewElementsForm}>
                <ContainerAddElement className="flex py-2 gap-4 " onClick={() => props.openNewElementsForm(props.addNewElementsForm?.type)}>
                  <CustomPlusIcon />
                  {props.addNewElementsForm?.text}
                </ContainerAddElement>
              </RenderWithCondition>
              <DropdownList
                style={props.dropdownItemStyles}
                data={props.data}
                onChange={onChange}
                typeOfList={props.topPlaceholder}
                onClose={setIsOpenDropdown}
              />
            </>
          )}
          <div className="ml-[-12px]">
            <LocalLoader condition={props.isLoading} />
            <EmptyList condition={isEmpty(props.data) && !props.addNewElementsForm} />
          </div>
        </DropDownOverlay>
      </RenderWithCondition>
    </>
  );
};

export default DropDown;

const Label = styled.p<{ primaryText: boolean }>`
  height: 18px;
  /* overflow: hidden; */
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  margin: 0;
  color: ${(props) => (props.primaryText ? props.theme.colors.blue400 : undefined)};
`;
const LabelPlaceholder = styled.p`
  height: 16px;
  max-width: 250px;
  overflow: hidden;
  text-align: left;
  font-size: 11px;
  margin: 0;
  color: ${(props) => props.theme.colors.absolute500};
`;

const InputSearch = styled.input`
  border: 0;
  outline: none;
  width: 200px;
`;

const Flex = styled.div`
  display: flex;
  gap: 4px;
`;

const ContainerAddElement = styled.button`
  padding: 8px 0;
  gap: 7px;
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  color: ${(props) => props.theme.colors.blue400};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.absolute300};
  &:hover {
    background-color: ${(props) => props.theme.colors.blue200};
  }
`;
const CustomPlusIcon = styled(PlusIcon)`
  > path {
    fill: ${(props) => props.theme.colors.blue400};
  }
`;

const GreyText = styled.p`
  color: ${(props) => props.theme.colors.absolute500};
`;

const TextClearRow = styled.button`
  position: absolute;
  right: 50px;
  top: 22px;
  font-size: 11px;
  color: ${(props) => props.theme.colors.blue400};
`;
