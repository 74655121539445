import React, { FC } from "react";

import { useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";

import NavBar from "components/NavBar/NavBar";
import NavBarHeaders from "components/NavBar/NavBar.Headers";
import SideBar from "components/NavBar/NavBar.SideBar";
import HomeHeadersBottomRow from "screens/Home/components/HomeHeadersBottomRow";
import UsersHeaderBottomRow from "screens/Users/components/UsersHeaderBottomRow";

import ExtendedRoutes from "./ExtendedRoutes";

import "styles/animation.css";

interface IWithNavbar {
  children: JSX.Element;
  routesPath: string[];
  publicPaths?: string[];
}

const WithNavbar: FC<IWithNavbar> = (props) => {
  const [sideBarWidth, setSideBarWidth] = React.useState("auto");
  const [headerHeight, setHeaderHeight] = React.useState("0");
  const [headerExist, setHeaderExist] = React.useState(false);

  const location = useLocation();

  const headerRef = React.useRef<HTMLDivElement>();

  const setSideBarWidthAndHeaderWidth = (width: number) => {
    if (width === 0) {
      setSideBarWidth("auto");
    } else {
      if (width > 280) {
        setSideBarWidth(`${width}px`);
      } else {
        setSideBarWidth(`${280}px`);
      }
    }
  };

  React.useEffect(() => {
    setHeaderHeight(`${headerRef?.current?.clientHeight}px`);
  }, []);

  React.useEffect(() => {
    switch (location.pathname) {
      case "/":
        setHeaderExist(true);
        break;
      case "/users":
        setHeaderExist(true);
        break;
      default:
        setHeaderExist(false);
    }
  }, [location]);

  if (!props.routesPath.includes(location.pathname)) {
    return undefined;
  }

  return (
    <div>
      {sideBarWidth !== "auto" && <LeftPlaceholder />}
      <Container headerExist={headerExist} className={" "}>
        <GridArea area="header" width={sideBarWidth}>
          <NavBar isSideBarExist={sideBarWidth !== "auto"} ref={headerRef} AdditionalsHeaders={NavBarHeaders} />
          <HomeHeadersBottomRow />
          <UsersHeaderBottomRow />
        </GridArea>
        <GridArea area="sidebar">
          <SideBar setSideBarWidth={setSideBarWidthAndHeaderWidth} headerHeight={headerHeight} />
        </GridArea>
        <GridArea area="main-content">
          {/* <Spacer px={20} horizontal={false} /> */}
          <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={600}>
              <ExtendedRoutes publicPaths={props.publicPaths}>{props.children}</ExtendedRoutes>
            </CSSTransition>
          </TransitionGroup>
        </GridArea>
      </Container>
    </div>
  );
};

export default WithNavbar;

const Container = styled.div<{ headerExist: boolean }>`
  display: grid;
  padding: 0px 15px;
  grid-template-areas:
    "header ${(props) => (props.headerExist ? "header" : "main-content")}"
    "sidebar main-content";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
`;

const GridArea = styled.div<{ area: string; width?: string }>`
  grid-area: ${(props) => props.area};
`;

const LeftPlaceholder = styled.div`
  position: fixed;
  height: 100%;
  width: 15%;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.blue100};
  z-index: -1;
`;
