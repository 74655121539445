import dayjs from "dayjs";

import { IGantChartArea } from "models/Objects/IGantChart";

export default (areaGant: IGantChartArea) => {
  if (!areaGant) {
    return {
      minDate: dayjs().startOf("year"),
      maxDate: dayjs().endOf("year"),
    };
  }

  const allWorks = (areaGant?.buildings ?? []).map((building) => building.works).flat();

  const allDates = allWorks.map((work) => {
    const possibleEndDate = dayjs(work?.endDateByPlan);
    let endDate = possibleEndDate.isValid() ? possibleEndDate : dayjs();

    if (work.forecastEndDate) {
      const date = dayjs(work.forecastEndDate);
      if (date.isValid()) {
        endDate = date;
      }
    }

    return {
      startDate: dayjs(work.startDateByPlan).isValid() ? dayjs(work.startDateByPlan) : dayjs(),
      endDate,
    };
  });

  // @ts-ignore
  const minDate = dayjs.min(allDates.map((date) => date.startDate));
  // @ts-ignore
  const maxDate = dayjs.max(allDates.map((date) => date.endDate))?.add(1, "month");

  return { minDate, maxDate };
};
