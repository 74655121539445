/* eslint-disable max-len */
import { FC } from "react";

import { useQuery } from "react-query";
import styled from "styled-components";

import { getWorksApi } from "api/References/api.works";
import { IGantWork } from "models/Objects/IGantChart";
import { QueriesKeys } from "models/QueriesKeys";

import { CELL_HEIGHT } from "../constants/common";

import { getDateFormattedToView, getFactDeviationIcon, isDeadlineOverdueAndNoDateFact, isShouldWorkBegun } from "./utils";

interface IDataTableRow {
  row: IGantWork;
}

const DataTableRow: FC<IDataTableRow> = (props) => {
  const works = useQuery({ queryKey: QueriesKeys.works, queryFn: getWorksApi });

  const workName = works.data.find((work) => work.id === props.row.id)?.name;
  const FactIcon = getFactDeviationIcon(props.row.actualInPercentage, props.row.planInPercentage);

  const isRedCellStartPlan = isShouldWorkBegun(props.row.startDateByPlan, props.row.startDateActual);
  const isRedCellEndPlan = isDeadlineOverdueAndNoDateFact(props.row.endDateByPlan, props.row.endDateActual);

  return (
    <>
      <RowCellContainer borderRightSize="1px" justify="flex-start">
        <RowCellContent style={{ wordWrap: "break-word", width: "240px", textAlign: "start" }}>{workName}</RowCellContent>
      </RowCellContainer>

      <RowCellContainer borderRightSize="1px" justify="center">
        <RowCellContent>{props.row.sectionSize}</RowCellContent>
      </RowCellContainer>

      <RowCellContainer borderRightSize="1px" justify="center">
        <FactIcon />
        <RowCellContent>{props.row.actualInPercentage ?? 0}%</RowCellContent>
      </RowCellContainer>

      <RowCellContainer borderRightSize="2px" justify="center">
        <RowCellContent>{props.row.planInPercentage} %</RowCellContent>
      </RowCellContainer>

      <RowCellContainer borderRightSize="1px" justify="center" dangerCell={isRedCellStartPlan}>
        <RowCellContent dangerText={isRedCellStartPlan}>{getDateFormattedToView(props.row.startDateByPlan)}</RowCellContent>
      </RowCellContainer>

      <RowCellContainer borderRightSize="2px" justify="center" dangerCell={isRedCellEndPlan}>
        <RowCellContent dangerText={isRedCellEndPlan}>{getDateFormattedToView(props.row.endDateByPlan)}</RowCellContent>
      </RowCellContainer>

      <RowCellContainer borderRightSize="1px" justify="center">
        <RowCellContent>{getDateFormattedToView(props.row.startDateActual)}</RowCellContent>
      </RowCellContainer>

      <RowCellContainer borderRightSize="2px" justify="center">
        <RowCellContent>{getDateFormattedToView(props.row.endDateActual)}</RowCellContent>
      </RowCellContainer>
    </>
  );
};

export default DataTableRow;

const RowCellContent = styled.p<{ dangerText?: boolean }>`
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  height: calc(${CELL_HEIGHT} - 1px);
  color: ${(props) => (props.dangerText ? props.theme.colors.absolute100 : props.theme.colors.absolute700)};
`;

const RowCellContainer = styled.div<{ borderRightSize: string; justify: string; dangerCell?: boolean }>`
  border-right: ${(props) =>
    `${props.borderRightSize} solid ${props.borderRightSize === "1px" ? props.theme.colors.absolute200 : props.theme.colors.absolute400}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.absolute200};
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify};
  padding: 0 4px;
  gap: 4px;

  ${(props) => props.dangerCell && `background-color: ${props.theme.colors.red400}`};
`;
