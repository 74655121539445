import apiClient from "api/api.client";
import { IMeasureUnit } from "models/References/IMeasureUnit";

export type TCreateMeasureUnitApi = (unit: string) => Promise<IMeasureUnit>;

export const createMeasureUnitApi: TCreateMeasureUnitApi = async (unit) => {
  try {
    const res = await apiClient.post("references/v1/measure-units", { value: unit });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetMeasureUnitsApi = () => Promise<IMeasureUnit[]>;

export const getMeasureUnitsApi: TGetMeasureUnitsApi = async () => {
  try {
    const res = await apiClient.get("references/v1/measure-units");

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteMeasureUnitApi = (measureId: string) => Promise<void>;

export const deleteMeasureUnitApi: TDeleteMeasureUnitApi = async (measureId) => {
  try {
    const res = await apiClient.delete(`references/v1/measure-units/${measureId}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateMeasureApi = (payload: { body: Partial<IMeasureUnit>; path: { id: string } }) => Promise<IMeasureUnit>;

export const updateMeasureApi: TUpdateMeasureApi = async (payload) => {
  try {
    const res = await apiClient.patch(`references/v1/measure-units/${payload.path.id}`, payload.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
