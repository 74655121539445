import { FC } from "react";

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { styled } from "styled-components";

import InfoTooltip from "components/InfoTooltip";
import useText from "hooks/useText";
import HomeStore from "screens/Home/store/Home.store";
import { EmptyRow, Table, TableCell, TableHeader, TableRow } from "screens/Home/styles/common";
import { SpanColored } from "styles/common";
import { LIGHT_THEME } from "theme/colors";

interface IRightTableChart {
  data: {
    liter: string;
    total_for_the_project: number;
    fact: number;
    remains: number;
    completed: string;
    contractors: string;
  }[];
}

const RightTableChart: FC<IRightTableChart> = (props) => {
  const columnHelper = createColumnHelper<any>();
  const text = useText();
  const colors = LIGHT_THEME;
  const isWeekly = HomeStore.typePeriodForCharts === "WEEKLY";
  const titleHeaderPeriod = isWeekly ? "неделю" : "месяц";

  const columns = [
    columnHelper.accessor("total_of_period", {
      header: `Итого\n за ${titleHeaderPeriod}`,
      enableResizing: true,
      cell: (info) => (
        <>
          {info.row.original.offset ? (
            <div>{info.row.original.total_for_the_project}</div>
          ) : (
            <div className="flex flex-col h-full w-full text-[14px] p-0">
              <ItemCell color={info.row.original.background ? colors.green200 : colors.green100} className="flex">
                {info.row.original.total_plan_of_period}
              </ItemCell>
              <ItemCell color={info.row.original.background ? colors.blue200 : colors.absolute100} className="flex">
                {info.row.original.total_fact_of_period}
              </ItemCell>
              <ItemCell color={info.row.original.background ? colors.yellow200 : colors.yellow100} className="flex">
                <InfoTooltip text={"Среднее"} type="sm" top={-10} colorText={colors.blue400}>
                  {info.row.original.total_staff_of_period}
                </InfoTooltip>
              </ItemCell>
            </div>
          )}
        </>
      ),
      meta: { styleCol: { width: "76px", padding: "0px", textAlign: "center" } },
    }),

    columnHelper.accessor("total_for_the_project", {
      header: "",
      cell: (info) => (
        <>
          {info.row.original.offset ? (
            <div>{info.row.original.total_for_the_project}</div>
          ) : (
            <div className="flex flex-col items-center text-[11px] h-[67px] justify-between grow w-full">
              <ItemDoubleCell className="flex">{info.row.original.deviationTitle}</ItemDoubleCell>
              <ItemDoubleCell className="flex">{`Выработка ${info.row.original.measureUnit}/чел. в день`}</ItemDoubleCell>
            </div>
          )}
        </>
      ),
      meta: { styleCol: { minWidth: "111px" } },
    }),
    columnHelper.accessor("completed", {
      header: "",
      cell: (info) => {
        const isNegativeNumber = info.row.original.deviation < 0;
        const deviationText = info.row.original.deviation > 0 ? `+${info.row.original.deviation}` : info.row.original.deviation;
        const deviationColor = isNegativeNumber ? colors.red400 : colors.green400;
        return (
          <>
            {info.row.original.offset ? (
              <div>{info.row.original.total_for_the_project}ff</div>
            ) : (
              <div className="flex flex-col h-[67px] text-[12px] items-center justify-between grow w-full">
                <ItemDoubleCell className="flex">
                  <SpanColored color={deviationColor}>{deviationText}</SpanColored>
                </ItemDoubleCell>

                <ItemDoubleCell className="flex ">
                  <SpanColored color={colors.absolute700} bold={700}>
                    {info.row.original.production}
                  </SpanColored>
                </ItemDoubleCell>
              </div>
            )}
          </>
        );
      },
      meta: { styleCol: { minWidth: "47px" } },
    }),
  ];
  const table = useReactTable({
    data: props.data,
    // @ts-ignore
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true,
  });

  return (
    <>
      <Table width={"100%"}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow half key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                // @ts-ignore
                const style = cell.column.columnDef.meta?.styleCol || {};

                return (
                  <>
                    {cell.row.original.offset ? (
                      <EmptyRow>{flexRender(cell.column.columnDef.cell, cell.getContext())}</EmptyRow>
                    ) : (
                      <TableCell
                        className="h-[72px]"
                        style={{ ...style }}
                        key={cell.id}
                        background={cell.row.original.background}
                        forCharts={row.original.liter.startsWith("Литер")}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    )}
                  </>
                );
              })}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default RightTableChart;

const ItemCell = styled.div<{ color?: string }>`
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.absolute200};
  background-color: ${(props) => props.color};
  width: 100%;
  height: 33.33%;
  /* padding: 1px 0;
  margin: -1px 0; */
`;
const ItemDoubleCell = styled.div<{ color?: string }>`
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.absolute200};
  width: 100%;
  height: 66.6%;
`;
