import { FC, InputHTMLAttributes } from "react";

import { isEmpty } from "lodash";
import InputMask from "react-input-mask";
import styled from "styled-components";

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  icon?: FC;
  mask?: string;
  onClickIcon?: () => void;
  onClickAllField?: () => void;
  placeholder?: string;
  widthInputLine?: string;
  value?: string;
  onChange?: (event: any) => void;
  redStarSign?: string;
}

const Input: FC<IInput> = (props) => {
  const { label, icon, onChange, onClickIcon, ...inputProps } = props;
  const Icon = icon;
  return (
    <InputContainer className="flex flex-col" onClick={props.onClickAllField}>
      <div className="flex">
        <div className="flex flex-col  justify-between items-start w-full">
          {label && !isEmpty(props.value) && (
            <Label>
              {label}
              {props.redStarSign && <SpanStar>*</SpanStar>}
            </Label>
          )}
          {inputProps.mask ? (
            <InputMask {...inputProps} onChange={onChange} style={{ width: "250px" }} />
          ) : (
            <>
              <InputLine widthInputLine={props.widthInputLine} {...inputProps} value={props.value} onChange={onChange} />
              {props.redStarSign && !props.value && (
                <LabelPlaceholder>
                  {props.redStarSign}
                  <SpanStar>*</SpanStar>
                </LabelPlaceholder>
              )}
            </>
          )}
        </div>
        {icon && (
          <button onClick={onClickIcon}>
            <Icon />
          </button>
        )}
      </div>
    </InputContainer>
  );
};

export default Input;

const InputContainer = styled.div`
  padding: 7px 20px 7px 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 43px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.absolute300};
`;

const Label = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.absolute500};
`;
const SpanStar = styled.span`
  color: ${(props) => props.theme.colors.red400};
`;
const LabelPlaceholder = styled.span`
  position: absolute;
  left: 15px;
  top: 25%;
  pointer-events: none;
  font-size: 100%;
  color: ${(props) => props.theme.colors.absolute400};
`;
const InputLine = styled.input<{ widthInputLine: string }>`
  border: 0;
  outline: none;
  width: ${(props) => props.widthInputLine};
  &:disabled {
    background-color: transparent;
  }
`;
