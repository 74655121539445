import React from "react";
import { FC } from "react";

import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { getTemplateSectionsApi } from "api/Objects/api.sections";
import ButtonLight from "components/ButtonLight";
import useText from "hooks/useText";
import { QueriesKeys } from "models/QueriesKeys";

interface IInfoTemplate {
  icon?: string;
  children?: React.ReactNode;
  label?: string;
  style?: any;
  className?: string;
  onClick?: () => void;
}

const InfoTemplate: FC<IInfoTemplate> = (props) => {
  const text = useText();
  const navigate = useNavigate();

  const templateSectionItems = useQuery({
    queryFn: () => getTemplateSectionsApi(),
    queryKey: [QueriesKeys.sectionItems],
  });

  const isReference = !isEmpty(templateSectionItems?.data?.items);

  return (
    <InfoContainer style={props.style} className="flex flex-col w-[280px]  xl:w-[480px] ">
      <Text className="font-bold text-lg">{isReference ? "Эталонная секция" : text.referenceSectionOffer}</Text>
      <Text className="">
        {isReference
          ? "Эталонная секция - это шаблон для заполнения списков работ в секции. Для просмотра и редактирования шаблонного списка работ перейдите в эталонную секцию"
          : "Эталонная секция - это шаблон для заполнения списков работ в секции. Ей удобно пользоваться для быстрого заполнения списка работ в секции"}
      </Text>
      <ButtonRow className="flex">
        <ButtonLight
          type="secondary"
          title={isReference ? "Перейти в эталонную секцию" : "Оформить эталонную секцию"}
          onClick={() => navigate("/settings/section")}
        />
      </ButtonRow>
    </InfoContainer>
  );
};

export default InfoTemplate;

const InfoContainer = styled.div`
  margin-top: 40px;
  width: 480px;
  height: fit-content;
  padding: 25px 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
  @media (max-width: 1630px) {
    max-width: 380px;
  }
  @media (max-width: 1023px) {
    width: 100%;
    max-width: inherit;
    padding: 8px;
  }
`;
const Text = styled.div`
  display: flex;
  text-align: left;
  color: ${(props) => props.theme.colors.absolute700};
`;

const ButtonRow = styled.div`
  padding-top: 20px;
`;
