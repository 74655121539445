import React, { FC } from "react";

import styled from "styled-components";

import { IContractor } from "models/References/IContractor";
import { Spacer } from "styles/common";

import ContrTableItem from "./ContrTableItem";

interface IContractorTable {
  onClickHandle?: (e: IContractor) => void;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContractorTable: FC<IContractorTable> = (props) => (
  <div className="flex flex-col">
    <Container className="flex">
      <ContrTableItem handleOpenUpdate={props.onClickHandle} width="100%" />
      <Spacer px={30} horizontal />
    </Container>
  </div>
);

export default ContractorTable;

const Container = styled.section`
  position: relative;
  // padding: 10px;
`;
