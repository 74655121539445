import { CSSProperties, FC } from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { Spacer } from "styles/common";

interface IButton {
  type?: "primary" | "secondary" | "link" | "usual";
  title?: string;
  icon?: FC;
  to?: string;
  disabled?: boolean;
  onClick?: () => void;
  buttonStyle?: CSSProperties;
  buttonTextStyle?: CSSProperties;
  children?: React.ReactElement;
}

const Button: FC<IButton> = (props) => {
  let ButtonContainer: any;
  let ButtonText: any;

  switch (props.type) {
    case "primary":
      ButtonContainer = PrimaryButton;
      ButtonText = PrimaryButtonTitle;
      break;
    case "secondary":
      ButtonContainer = SecondaryButton;
      ButtonText = SecondaryButtonTitle;
      break;
    case "link":
      ButtonContainer = LinkButton as any;
      ButtonText = LinkButtonTitle;
      break;
    case "usual":
      ButtonContainer = UsualButton as any;
      ButtonText = UsualButtonTitle;
      break;
    default:
      ButtonContainer = PrimaryButton;
      ButtonText = PrimaryButtonTitle;
  }

  const isOnlyIconButton = !props.title && props.icon;
  const Icon = props.icon;

  return (
    <ButtonContainer
      onClick={props.onClick}
      disabled={props.disabled}
      to={props.to}
      isOnlyIconButton={isOnlyIconButton}
      className="flex justify-between items-center"
      style={props.buttonStyle}
    >
      {props.title && <ButtonText style={props.buttonTextStyle}>{props.title}</ButtonText>}
      {props.title && props.icon && <Spacer px={10} horizontal />}
      {Icon && <Icon />}
      {props.children}
    </ButtonContainer>
  );
};

export default Button;

const ButtonNode = styled.button`
  padding: 12px 0;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
`;
const ButtonTextNode = styled.p`
  white-space: nowrap;
`;

const PrimaryButton = styled(ButtonNode)`
  background-color: ${(props) => props.theme.colors.blue400};
`;
const PrimaryButtonTitle = styled(ButtonTextNode)`
  color: ${(props) => props.theme.colors.absolute100};
`;

const SecondaryButton = styled(ButtonNode)`
  background-color: ${(props) => props.theme.colors.blue200};
  &:hover {
    background-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.blue100};
  }
`;

const SecondaryButtonTitle = styled(ButtonTextNode)`
  color: ${(props) => props.theme.colors.blue400};
`;

const LinkButton = styled(Link)``;
const LinkButtonTitle = styled(ButtonTextNode)`
  color: ${(props) => props.theme.colors.blue400};
`;

const UsualButton = styled(ButtonNode)<{ isOnlyIconButton: boolean }>`
  background-color: ${(props) => props.theme.colors.background};
  padding: ${(props) => (props.isOnlyIconButton ? "12px" : "6px")} 12px;
  border-radius: 5px;
  height: 43px;
`;

const UsualButtonTitle = styled(ButtonTextNode)``;
