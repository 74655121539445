import React, { FC } from "react";

import styled from "styled-components";

import { IWork } from "models/References/IWork";

import ReferenceTable from "./ReferenceTable";

interface IWorksTable {
  onClickHandle?: (data: IWork) => void;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorksTable: FC<IWorksTable> = (props) => (
  <Container className="flex  items-baseline">
    <div className="flex xl:w-[774px] ">
      <ReferenceTable handleOpenUpdate={props.onClickHandle} width={"100%"} />
    </div>
  </Container>
);

export default WorksTable;
const Container = styled.section`
  padding-right: 20px;
`;
