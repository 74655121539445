import React, { CSSProperties, FC } from "react";

import styled from "styled-components";

import { ReactComponent as EmptyPlaceholderImg } from "assets/empty-placeholder.svg";
import Button from "components/Button";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import { Spacer } from "styles/common";

interface IEmptyPlaceholder {
  className?: string;
  onButtonClick?: () => void;
  description: string;
  description2?: string;
  header: string;
  style?: CSSProperties;
  styleContainer?: CSSProperties;
  buttonText?: string;
  ImgComponent?: FC;
  RightImage?: FC;
}

const EmptyPlaceholder: FC<IEmptyPlaceholder> = (props) => {
  const { ImgComponent, RightImage } = props;
  return (
    <EmptyObjectPlaceholder className={props.className} style={props.styleContainer}>
      <EmptyPlaceholderContainer style={props.style}>
        <EmptyPlaceholderTextContainer>
          <EmptyPlaceholderHeader>{props.header}</EmptyPlaceholderHeader>
          <Spacer px={8} />
          <DescriptionContainer>{props.description}</DescriptionContainer>
          <Spacer px={8} />
          <RenderWithCondition condition={!!ImgComponent}>
            <ImageContainer>
              <Spacer px={8} />
              <ImgComponent />
              <Spacer px={8} />
            </ImageContainer>
          </RenderWithCondition>
          <RenderWithCondition condition={!!props.description2}>
            <>
              <Spacer px={8} />
              <DescriptionContainer>{props.description2}</DescriptionContainer>
              <Spacer px={8} />
            </>
          </RenderWithCondition>
          <RenderWithCondition condition={!!props.buttonText}>
            <EmptyPlaceholderButtonContainer>
              <Button
                title={props.buttonText}
                buttonStyle={{ width: "90%" }}
                buttonTextStyle={{ textAlign: "center", width: "100%" }}
                onClick={props.onButtonClick}
              />
            </EmptyPlaceholderButtonContainer>
          </RenderWithCondition>
        </EmptyPlaceholderTextContainer>
        {RightImage ? <RightImage /> : <EmptyPlaceholderImg />}
      </EmptyPlaceholderContainer>
    </EmptyObjectPlaceholder>
  );
};

export default EmptyPlaceholder;

const EmptyObjectPlaceholder = styled.div`
  display: flex;
  justify-content: center;
`;

const EmptyPlaceholderContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
  max-width: 710px;
  padding: 32px;
  border-radius: 20px;
`;

const EmptyPlaceholderTextContainer = styled.div`
  text-align: center;
  max-width: 350px;
`;

const EmptyPlaceholderHeader = styled.p`
  font-weight: bold;
  font-size: 20px;
`;

const EmptyPlaceholderButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DescriptionContainer = styled.p`
  white-space: pre-line;
`;
