import { first } from "lodash";

import apiClient from "api/api.client";
import { IAgreement, IAgreementHistory } from "models/Objects/IAgreement";
import { IArea } from "models/Objects/IArea";
import { IBuilding } from "models/Objects/IBuilding";
import { IComplex } from "models/Objects/IComplex";
import { ISection } from "models/Objects/ISection";
import { ISectionItem, ISectionItems, ISectionItemStatistic } from "models/Objects/ISectionItem";
import PlanStatusStore from "screens/Home/store/PlanStatus.store";
import notify from "utils/notify";

import { getAreasApi } from "./api.areas";
import { getBuildingsApi } from "./api.buildings";
import { getComplexApi } from "./api.complexes";
import { getSectionsApi } from "./api.sections";

export type TGetAllStructureApi = () => Promise<{
  complexes: IComplex[];
  areas: IArea[];
  buildings: IBuilding[];
  sections: ISection[];
}>;

export const getAllStructuresApi: TGetAllStructureApi = async () => {
  try {
    const complexes = await getComplexApi();

    const areasData: IArea[][] = await Promise.all(
      complexes.map(async (complex) => {
        const res = await getAreasApi({ complexId: complex.id });
        return res;
      })
    );

    const buildingsDataPromises: Promise<IBuilding[]>[] = areasData.flatMap((areaArr) =>
      areaArr.map((area) => getBuildingsApi({ areaId: area.id }))
    );

    const buildingsData: IBuilding[][] = await Promise.all(buildingsDataPromises);

    const sectionsDataPromises: Promise<IBuilding[]>[] = buildingsData.flatMap((buildingArr) =>
      buildingArr.map((building) => getSectionsApi({ buildingId: building.id }))
    );

    const sectionsData: ISection[][] = await Promise.all(sectionsDataPromises);

    return {
      complexes,
      areas: areasData.flat(),
      buildings: buildingsData.flat(),
      sections: sectionsData.flat(),
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

type TCreateSectionItemApiBody = {
  sectionId: string;
} & Omit<ISectionItem, "id">;

export type TCreateSectionItemApi = (body: TCreateSectionItemApiBody) => Promise<ISectionItem>;

export const createSectionItemApi: TCreateSectionItemApi = async (body) => {
  try {
    const res = await apiClient.post("objects/v1/items", body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteSectionItemApi = (id: string) => Promise<void>;

export const deleteSectionItemApi: TDeleteSectionItemApi = async (id) => {
  try {
    const res = await apiClient.delete(`objects/v1/items/${id}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetObjectsBySectionIdApi = (
  params: { sectionId: string; controlType: string },
  options?: { enabledStatistic: boolean }
) => Promise<ISectionItems[]>;

export const getObjectsBySectionIdApi: TGetObjectsBySectionIdApi = async (params, options) => {
  try {
    const res = await apiClient.get("objects/v1/items", { params });

    const sectionsItems = res.data as ISectionItems[];

    if (options?.enabledStatistic) {
      try {
        for await (const section of sectionsItems) {
          const sectionItemsStatistic = await Promise.all(section.items.map((item) => getObjectItemStatisticApi(item.id)));
          section.items = section.items.map((item, idx) => ({
            ...item,
            statistic: sectionItemsStatistic[idx],
          }));
        }
      } catch (error) {
        notify.error("Данные статистики не были подгружены, уже занимаемся этой проблемой");
      }
    }

    return sectionsItems;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getItemsBySectionIdApi: TGetObjectsBySectionIdApi = async (params) => {
  try {
    const res = await apiClient.get("objects/v1/items", { params });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateObjectApi = (payload: { id: string; body: Partial<ISectionItem> }) => Promise<ISectionItem>;

export const updateObjectApi: TUpdateObjectApi = async (payload) => {
  try {
    const res = await apiClient.patch(`objects/v1/items/${payload.id}`, payload.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export type TUpdateObjectWithTemplateApi = (payload: { id: string }) => Promise<ISectionItem>;

export const updateObjectWithTemplateApi: TUpdateObjectWithTemplateApi = async (payload) => {
  try {
    const res = await apiClient.post(`objects/v1/sections/${payload.id}/template`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetObjectItemStatisticApi = (id: string) => Promise<ISectionItemStatistic>;

export const getObjectItemStatisticApi: TGetObjectItemStatisticApi = async (id) => {
  try {
    const res = await apiClient.get(`objects/v1/items/${id}/statistics`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

//#region Agreements

export type TGetPeriodAgreementApi = (args: {
  params: {
    startDate: string;
    endDate: string;
    itemIds: string;
  };
}) => Promise<IAgreement[]>;

export const getPeriodAgreementApi: TGetPeriodAgreementApi = async (args) => {
  try {
    const res = await apiClient.get("objects/v1/agreements", {
      params: args.params,
    });

    const data: IAgreement[] = res.data;

    PlanStatusStore.setLastAgreementUserId(first(data)?.userId);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TApprovePlaBodyApi = {
  startDate: string;
  endDate: string;
  itemId: string;
  periods: {
    id?: string;
    date: string;
    volume: number | null;
  }[];
};

export type TCreateAndApprovePlanApi = (args: { body: TApprovePlaBodyApi }) => Promise<IAgreement>;

export const createAndApprovePlanApi: TCreateAndApprovePlanApi = async (args) => {
  try {
    const res = await apiClient.post("objects/v1/agreements/create-and-approve", args.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TCreateApprovalPlanApi = (args: { body: TApprovePlaBodyApi }) => Promise<IAgreement>;

export const createApprovalPlanApi: TCreateApprovalPlanApi = async (args) => {
  try {
    const res = await apiClient.post("/objects/v1/agreements", args.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetHistoryAgreementsApi = (args: {
  path: {
    id: string;
  };
}) => Promise<IAgreementHistory[]>;

export const getHistoryAgreementsApi: TGetHistoryAgreementsApi = async (args) => {
  try {
    const res = await apiClient.get(`/objects/v1/agreements/${args.path.id}/history`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TRevokeAgreementsApi = (args: {
  path: {
    id: string;
  };
}) => Promise<IAgreement>;

export const revokeAgreementsApi: TRevokeAgreementsApi = async (args) => {
  try {
    const res = await apiClient.post(`/objects/v1/agreements/${args.path.id}/cancel`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

type TApproveCreatedPlanArgs = {
  path: { id: string };
  body: {
    id?: string;
    date: string;
    volume: number | null;
  }[];
};

export type TApprovePlanApi = (args: TApproveCreatedPlanArgs) => Promise<void>;

export const approvePlanApi: TApprovePlanApi = async (args) => {
  try {
    const res = await apiClient.post(`/objects/v1/agreements/${args.path.id}/approve`, args.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TEditAndApprovePlanApi = (args: TApproveCreatedPlanArgs) => Promise<void>;

export const editAndApprovePlanApi: TEditAndApprovePlanApi = async (args) => {
  try {
    const res = await apiClient.post(`/objects/v1/agreements/${args.path.id}/edit-and-approve`, args.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

//#endregion
