/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
import { IArea } from "models/Objects/IArea";
import { IBuilding } from "models/Objects/IBuilding";
import { IComplex } from "models/Objects/IComplex";
import { ISection } from "models/Objects/ISection";

export const getRestrictedTreeData = (
  data: {
    complexes: IComplex[];
    areasData: IArea[];
    buildingsData: IBuilding[];
    sectionsData: ISection[];
  },
  canVisibleSection: (sectionId: string) => boolean,
  canVisibleAllSections: boolean
) => {
  if (canVisibleAllSections) {
    return {
      restrictedComplexes: data.complexes,
      restrictedAreas: data.areasData,
      restrictedBuildings: data.buildingsData,
      restrictedSections: data.sectionsData,
    };
  }

  const restrictedComplexes = new Set<IComplex>();
  const restrictedAreas = new Set<IArea>();
  const restrictedBuildings = new Set<IBuilding>();
  const restrictedSections = new Set<ISection>();

  data.sectionsData.forEach((section) => {
    const isAvailableSection = canVisibleSection(section.id);

    if (isAvailableSection) {
      restrictedSections.add(section);
      const building = data.buildingsData.find((building) => building.id === section.parentId);
      const area = data.areasData.find((area) => area.id === building.parentId);
      const complex = data.complexes.find((complex) => complex.id === area.parentId);

      restrictedBuildings.add(building);
      restrictedAreas.add(area);
      restrictedComplexes.add(complex);
    }
  });

  return {
    restrictedComplexes: Array.from(restrictedComplexes),
    restrictedAreas: Array.from(restrictedAreas),
    restrictedBuildings: Array.from(restrictedBuildings),
    restrictedSections: Array.from(restrictedSections),
  };
};

export const convertFromMapToArray = (object: Map<string, any[]>) => Array.from(object.values()).flat(Infinity);
