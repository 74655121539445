import React, { FC } from "react";

import AuthStore from "store/Auth.Store";

const Logout: FC = () => {
  React.useEffect(() => {
    AuthStore.logout();
  }, []);

  return <>Logout in process...</>;
};

export default Logout;
