export default [
  {
    label: "ЖК Dogma-Park'",
    value: "1",
    children: [],
  },
  {
    label: "ЖК 'Рекорд'",
    value: "2",
    children: [
      {
        label: "Литер 1",
        value: "3",
        children: [
          {
            label: "Секция A",
            value: "4",
          },
          {
            label: "Секция B",
            value: "5",
          },
        ],
      },
      {
        label: "Литер 2",
        value: "6",
        children: [],
      },
      {
        label: "Литер 3",
        value: "7",
        children: [],
      },
    ],
  },
  {
    label: "ЖК 'Парк Победы'",
    value: "8",
    children: [],
  },
  {
    label: "МКР 'Самолет'",
    value: "9",
    children: [],
  },
  {
    label: "ЖК 'Арена'",
    value: "10",
    children: [],
  },
];
