export const ROW_HEIGHT = 45;

export const cumulativeLines = [
  { dataKey: "planCumulative", fill: "#82C09E", fillDot: "#1C9152" },
  { dataKey: "factCumulative", fill: "#BFE0FE", fillDot: "#5d9eda" },
];
export const OPTIONS_CHART_PERIOD = [
  { value: "WEEKLY", label: "Недельный" },
  { value: "MONTHLY", label: "Месячный" },
];
