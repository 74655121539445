import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ReactComponent as ThinArrowRight } from "assets/thinArrowRight.svg";
import { INode } from "components/Tree/Tree.Node";
import TreeStore from "components/Tree/Tree.store";
import useTreeData from "hooks/useTreeData/useTreeData";

interface IChooseAreaOrBuilding {}

const ChooseAreaOrBuilding: FC<IChooseAreaOrBuilding> = observer((props) => {
  const { data: treeData } = useTreeData();

  const [currentAreas, setCurrentAreas] = React.useState<INode[]>([]);

  // ? get areas and buildings
  React.useEffect(() => {
    if (TreeStore.selectedObject) {
      setCurrentAreas(treeData?.find((complex) => complex.id === TreeStore.selectedObject?.id)?.children ?? []);
    } else {
      const localComplex: INode = JSON.parse(localStorage.getItem("selectedComplex"));
      if (localComplex) {
        setCurrentAreas(treeData?.find((complex) => complex.id === localComplex.id)?.children ?? []);
      }
    }
  }, [treeData, TreeStore.selectedObject]);

  const onClickRow = (node: INode) => {
    TreeStore.setSelectedObject(node);

    const nodeArea = node?.meta?.area;

    if (nodeArea === "area") {
      TreeStore.setArea(node);
    }

    if (nodeArea === "building") {
      TreeStore.setArea(currentAreas.find((area) => area.id === node?.meta?.parentId));
      TreeStore.setBuilding(node);
    }
  };

  return (
    <>
      {currentAreas.map((area) => (
        <Container>
          <AreaRow onClick={() => onClickRow(area)}>
            <AreaName>{area.name}</AreaName>
            <HoverContainer>
              <WatchText>Смотреть график</WatchText>
              <ThinArrowRight />
            </HoverContainer>
          </AreaRow>
          {area.children.map((building) => (
            <BuildingRow onClick={() => onClickRow(building)}>
              <Row>
                <p>{building.name}</p>
                <HoverContainer>
                  <WatchText>Смотреть таблицу </WatchText>
                  <ThinArrowRight />
                </HoverContainer>
              </Row>
            </BuildingRow>
          ))}
        </Container>
      ))}
    </>
  );
});

export default ChooseAreaOrBuilding;

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  width: 400px;
`;

const Row = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.colors.blue200};
    > span {
      visibility: visible;
    }
  }
`;

const BuildingRow = styled(Row)`
  padding: 6px 20px 6px 50px;
`;

const AreaRow = styled(Row)`
  padding: 6px 20px;
`;

const AreaName = styled.div`
  font-weight: bold;
`;

const HoverContainer = styled.span`
  display: flex;
  gap: 6px;
  align-items: center;

  visibility: hidden;
`;

const WatchText = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.absolute500};
`;
