import { FC } from "react";

import classNames from "classnames";
import styled from "styled-components";

interface IButtonLight {
  type?: "primary" | "secondary";
  title?: string;
  icon?: string;
  to?: string;
  disabled?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
}

const ButtonLight: FC<IButtonLight> = (props) => {
  let ButtonContainer: any;

  switch (props.type) {
    case "primary":
      ButtonContainer = PrimaryButton;
      break;
    case "secondary":
      ButtonContainer = SecondaryButton;
      break;
    default:
      ButtonContainer = PrimaryButton;
  }

  return (
    <ButtonContainer
      to={props.to}
      onClick={props.onClick}
      disabled={props.disabled}
      className={classNames("flex justify-center items-center", props.fullWidth && "w-full")}
    >
      <p className="min-w-[150px]">{props.title ? props.title : "action"}</p>
    </ButtonContainer>
  );
};

export default ButtonLight;

const SecondaryButton = styled.button`
  padding: 12px 15px;
  border: 0;
  border-radius: 10px;
  font-weight: 700;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.blue200};
  color: ${(props) => props.theme.colors.blue400};
  &:hover {
    background-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.absolute100};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.blue300};
    color: ${(props) => props.theme.colors.absolute100};
  }
`;
const PrimaryButton = styled.button`
  padding: 12px 55px;
  border-radius: 10px;
  border: 0;
  font-weight: 700;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.blue400};
  color: ${(props) => props.theme.colors.absolute100};
  &:hover {
    background-color: ${(props) => props.theme.colors.blue200};
    color: ${(props) => props.theme.colors.blue400};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.blue300};
    color: ${(props) => props.theme.colors.absolute100};
  }
`;
