import React, { FC } from "react";

import { useClickAway } from "ahooks";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import styled from "styled-components";

interface IObjectEditCalendar {
  closeCalendar: () => void;
  onChange?: (value: Value, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ObjectEditCalendar: FC<IObjectEditCalendar> = (props) => {
  const calendarContainerRef = React.useRef(null);
  const isMounted = React.useRef(false);

  useClickAway(() => {
    if (isMounted.current) {
      props.closeCalendar();
    }
    isMounted.current = true;
  }, calendarContainerRef);

  return (
    <CalendarComponentContainer ref={calendarContainerRef}>
      <div>
        <Calendar
          showNavigation={true}
          onChange={props.onChange}
          showNeighboringMonth={false}
          onClickMonth={() => (isMounted.current = false)}
          onClickYear={() => (isMounted.current = false)}
        />
      </div>
    </CalendarComponentContainer>
  );
};

export default ObjectEditCalendar;

const CalendarComponentContainer = styled.div`
  display: flex;
  justify-content: center;
`;
