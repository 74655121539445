import { FC } from "react";

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import classNames from "classnames";

import InfoTooltip from "components/InfoTooltip";
import useText from "hooks/useText";
import { EmptyRow, Table, TableCell, TableHeader, TableRow } from "screens/Home/styles/common";
import { SpanColored } from "styles/common";
import { LIGHT_THEME } from "theme/colors";

interface ILeftTableChart {
  data: {
    liter: string;
    total_for_the_project: number;
    fact: number;
    remains: number;
    completed: string;
    contractors: string;
  }[];
}

const LeftTableChart: FC<ILeftTableChart> = (props) => {
  const columnHelper = createColumnHelper<any>();
  const text = useText();
  const colors = LIGHT_THEME;

  const columns = [
    columnHelper.accessor("liter", {
      header: text.liter,
      enableResizing: true,
      cell: (info) => (
        <div className={classNames("flex flex-col grow w-full gap-3", { "font-bold": info.row.original?.total })}>
          <p>{info.row.original.liter}</p>
          <SpanColored color={colors.absolute500}>
            <InfoTooltip
              text={info.row.original.contractors}
              lenText={16}
              type="md"
              vertical
              colorText={colors.blue400}
              alignInitialText="start"
            />
          </SpanColored>
        </div>
      ),
      meta: { styleCol: { minWidth: "150px", maxWidth: "280px", padding: "5px", textAlign: "left" } },
    }),

    columnHelper.accessor("total_for_the_project", {
      header: "Всего по проекту",
      cell: (info) => info.row.original.total_for_the_project,
      meta: { styleCol: { minWidth: "75px", padding: "3px" } },
    }),
    columnHelper.accessor("fact", {
      header: text.fact,
      cell: (info) => info.row.original.fact,
    }),
    columnHelper.accessor("remains", {
      header: text.remainder,
      cell: (info) => info.row.original.remains,
    }),
    columnHelper.accessor("completed", {
      header: text.percentageOfCompletion,
      cell: (info) => info.row.original.completed,
      meta: { styleCol: { width: "70px", padding: "3px" } },
    }),
  ];
  const table = useReactTable({
    data: props.data,
    // @ts-ignore
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true,
  });

  return (
    <>
      <Table width={"100%"}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow half key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                // @ts-ignore
                const style = cell.column.columnDef.meta?.styleCol || {};

                return (
                  <>
                    {cell.row.original.offset ? (
                      <EmptyRow>{flexRender(cell.column.columnDef.cell, cell.getContext())}</EmptyRow>
                    ) : (
                      <TableCell
                        style={{ ...style }}
                        key={cell.id}
                        background={cell.row.original.background}
                        forCharts={row.original.liter.startsWith("Литер")}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    )}
                  </>
                );
              })}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default LeftTableChart;
