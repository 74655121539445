import React, { FC } from "react";

import { uniqueId } from "lodash";
import styled from "styled-components";

interface ICalendarTableForecastLine {
  width: number;
  offset: number;
}

const CalendarTableForecastLine: FC<ICalendarTableForecastLine> = (props) => {
  const forecastLinesLength = Number((props.width / 8).toFixed(0)) - 2;
  const listLines = new Array(forecastLinesLength > 0 ? forecastLinesLength : 0).fill(0);

  return (
    <>
      <ForecastLineContainer
        width={`${props.width}px`}
        offset={`${props.offset}px`}
        data-tooltip-id="calendar-table-tooltip"
        data-tooltip-content="Прогноз на основе средней скорости предыдущих работ"
        data-tooltip-place="top"
        data-tooltip-variant="light"
      >
        <>
          <ForecastLine offset="5px" />
          <ForecastLine offset="12px" />

          {listLines.map((_, idx) => {
            const lineOffset = idx * 8 + 20;
            return <ForecastLine key={uniqueId(lineOffset.toString())} offset={`${lineOffset}px`} />;
          })}

          <ForecastLine offset={`${props.width + 2}px`} />
        </>
      </ForecastLineContainer>
    </>
  );
};

export default CalendarTableForecastLine;

const ForecastLineContainer = styled.div<{ width: string; offset: string }>`
  background-color: ${(props) => props.theme.colors.blue200};
  height: 20px;
  width: ${(props) => props.width};
  position: absolute;
  top: 6px;
  left: ${(props) => props.offset};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: help;
`;

const ForecastLine = styled.div<{ offset: string }>`
  height: 52px;
  width: 3px;
  background-color: #86c0f5;
  position: absolute;
  top: -8px;
  left: ${(props) => props.offset};
  transform: rotate(-35deg);
`;
