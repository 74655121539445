import dayjs, { Dayjs } from "dayjs";
import { useMutation, useQuery } from "react-query";

import {
  TApprovePlaBodyApi,
  createAndApprovePlanApi,
  createApprovalPlanApi,
  approvePlanApi,
  editAndApprovePlanApi,
} from "api/Objects/api.objects";
import { getRolesApi, getUserMeApi, getUsersApi } from "api/Users/api.users";
import { queryClient } from "App";
import { IAgreement } from "models/Objects/IAgreement";
import { QueriesKeys } from "models/QueriesKeys";
import { CurrentUserRoles } from "models/Users/IUser";
import PlanStatusStore from "screens/Home/store/PlanStatus.store";
import CalendarStore from "store/Calendar.Store";
import notify from "utils/notify";

const usePermissions = () => {
  const userMe = useQuery({
    queryFn: getUserMeApi,
    queryKey: [QueriesKeys.userMe],
  });

  const users = useQuery({
    queryFn: () => getUsersApi({ ids: null, roles: null }),
    queryKey: [QueriesKeys.users],
  });

  const roles = useQuery({
    queryKey: QueriesKeys.roles,
    queryFn: getRolesApi,
    select: (res) =>
      res.filter((role) => {
        if (role.name === CurrentUserRoles.CHIEF_ADMINISTRATOR) {
          return false;
        }

        if (role.name === CurrentUserRoles.ADMINISTRATOR) {
          switch (userMe.data?.role) {
            case CurrentUserRoles.CHIEF_ADMINISTRATOR:
            case CurrentUserRoles.ADMINISTRATOR:
              return true;
            default:
              return false;
          }
        }

        return true;
      }),
  });

  // #region Agreements
  const createAndApprovePlan = useMutation({
    mutationFn: (body: TApprovePlaBodyApi) =>
      createAndApprovePlanApi({
        body: body,
      }),
    onError: (error: any) => {
      notify.error(error.response.data.message);
    },
  });

  const createApprovalPlan = useMutation({
    mutationFn: (body: TApprovePlaBodyApi) =>
      createApprovalPlanApi({
        body: body,
      }),
    onError: (error: any) => {
      notify.error(error.response.data.message);
    },
  });

  const approvePlan = useMutation({
    mutationFn: (args: {
      body: {
        id?: string;
        date: string;
        volume: number | null;
      }[];
      statusPlanId: string;
    }) =>
      approvePlanApi({
        path: {
          id: args.statusPlanId,
        },
        body: args.body,
      }),
    onError: (error: any) => {
      notify.error(error.response.data.message);
    },
  });

  const editAndApprovePlan = useMutation({
    mutationFn: (args: {
      body: {
        id?: string;
        date: string;
        volume: number | null;
      }[];
      statusPlanId: string;
    }) =>
      editAndApprovePlanApi({
        path: {
          id: args.statusPlanId,
        },
        body: args.body,
      }),
    onError: (error: any) => {
      notify.error(error.response.data.message);
    },
  });
  // #endregion Agreements

  const canVisibleUsers = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.HEAD:
      case CurrentUserRoles.ENGINEER_CNP:
        return true;
      default:
        return false;
    }
  };

  const canVisibleAddAndChangeObjects = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.ENGINEER_CNP:
        return true;
      default:
        return false;
    }
  };

  const canVisibleReferences = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.HEAD:
      case CurrentUserRoles.ENGINEER_CNP:
        return true;
      default:
        return false;
    }
  };

  const canVisibleSettings = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.HEAD:
      case CurrentUserRoles.ENGINEER_CNP:
        return true;
      default:
        return false;
    }
  };

  const canAddAndChangeObjects = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.ENGINEER_CNP:
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
        return true;
      default:
        return false;
    }
  };

  const canChangeUserData = (user: { role: CurrentUserRoles; id: string }) => {
    switch (userMe.data.role) {
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
        return user.id !== userMe.data.id;
      case CurrentUserRoles.ADMINISTRATOR:
        return user.role !== CurrentUserRoles.CHIEF_ADMINISTRATOR;
      case CurrentUserRoles.ENGINEER_CNP:
      case CurrentUserRoles.HEAD:
        return user.role !== CurrentUserRoles.CHIEF_ADMINISTRATOR && user.role !== CurrentUserRoles.ADMINISTRATOR;
      default:
        return false;
    }
  };

  const canChangeDataOfReference = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      case CurrentUserRoles.ENGINEER_CNP:
        // case CurrentUserRoles.HEAD:
        return true;
      default:
        return false;
    }
  };

  const canCreateAndUpdateReferenceSection = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      case CurrentUserRoles.ENGINEER_CNP:
        return true;
      default:
        return false;
    }
  };

  const canAddUsers = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.HEAD:
      case CurrentUserRoles.ENGINEER_CNP:
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
        return true;
      default:
        return false;
    }
  };

  const canVisibleSection = (sectionId: string) => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.HEAD:
      case CurrentUserRoles.ENGINEER_CNP:
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
        return true;
      default:
        return userMe.data?.sections.includes(sectionId);
    }
  };

  const canVisibleAllSections = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.HEAD:
      case CurrentUserRoles.ENGINEER_CNP:
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
        return true;
      default:
        return false;
    }
  };

  const canInputFactWork = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.HEAD:
        return false;
      default:
        return true;
    }
  };

  const canInputStaffWork = () => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.ADMINISTRATOR:
      case CurrentUserRoles.HEAD:
        return false;
      default:
        return true;
    }
  };

  // #region Agreements

  const canEditAndApprovalPlan = (planStatus?: IAgreement) => {
    switch (userMe.data.role) {
      case CurrentUserRoles.CHIEF_ENGINEER:
        if (planStatus?.status !== "APPROVED") {
          return true;
        }
        // eslint-disable-next-line no-case-declarations
        const currentStartWeekDate: Dayjs = dayjs();
        if (currentStartWeekDate.isBefore(CalendarStore.calendarStartDate)) {
          return true;
        }
        return false;
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      case CurrentUserRoles.ENGINEER_CNP:
        return true;
      default:
        return false;
    }
  };

  const canSendPlanForApproval = (planStatus?: IAgreement) => {
    if (userMe.data?.role === CurrentUserRoles.AREA_HEAD) {
      if (!planStatus || planStatus.status === "CANCELED") {
        return true;
      }
      return false;
    }
  };

  const canRevokePlan = (planStatus?: IAgreement) => {
    if (userMe.data?.role === CurrentUserRoles.AREA_HEAD) {
      const userUaaId = users.data.find((user) => user.id === userMe.data.id).uaaId;

      if (planStatus && planStatus.status === "IN_PROCESS" && userUaaId === planStatus.userId) {
        return true;
      }
      return false;
    }
    return false;
  };

  const canApprovalPlan = (currentPlan: IAgreement) => {
    switch (userMe.data?.role) {
      case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      case CurrentUserRoles.CHIEF_ENGINEER:
        return currentPlan?.status !== "APPROVED";
      default:
        return false;
    }
  };

  const onApprovePlan = async (body: TApprovePlaBodyApi, currentPlan?: IAgreement) => {
    if (currentPlan?.id && currentPlan?.status !== "CANCELED") {
      if (PlanStatusStore.currentEditableItemId) {
        await createApprovalPlan.mutateAsync(body);
      } else {
        await approvePlan.mutateAsync({
          body: body.periods,
          statusPlanId: currentPlan.id,
        });
      }
    } else {
      switch (userMe.data.role) {
        case CurrentUserRoles.CHIEF_ADMINISTRATOR:
        case CurrentUserRoles.CHIEF_ENGINEER:
        case CurrentUserRoles.ENGINEER_CNP:
        case CurrentUserRoles.AREA_HEAD:
          await createApprovalPlan.mutateAsync(body);
          break;
        default:
          console.error("there may be an error in the code, the approval confirmation function for an unknown role has worked");
      }
    }

    queryClient.invalidateQueries([QueriesKeys.statusPlan, CalendarStore.calendarStartDate, body.itemId]);

    const calendarStartDate = CalendarStore.calendarStartDate.format("YYYY-MM-DD");
    const calendarEndDate = CalendarStore.calendarEndDate.format("YYYY-MM-DD");

    queryClient.invalidateQueries([QueriesKeys.sectionItemsPeriod, calendarStartDate, calendarEndDate]);

    if (currentPlan?.id) {
      queryClient.invalidateQueries([QueriesKeys.statusPlanHistory, currentPlan.id]);
    }
  };

  // #endregion

  const isLoading = roles.isLoading || userMe.isLoading;

  return {
    vars: {
      canAddUsers: canAddUsers(),
      canInputFactWork: canInputFactWork(),
      canInputStaffWork: canInputStaffWork(),
      canAddAndChangeObjects: canAddAndChangeObjects(),
      canChangeDataOfReference: canChangeDataOfReference(),
      canCreateAndUpdateReferenceSection: canCreateAndUpdateReferenceSection(),
      canVisibleUsers: canVisibleUsers(),
      canVisibleAddAndChangeObjects: canVisibleAddAndChangeObjects(),
      canVisibleReferences: canVisibleReferences(),
      canVisibleSettings: canVisibleSettings(),
      canVisibleAllSections: canVisibleAllSections(),
      canSendPlanForApproval,
    },
    fn: {
      canApprovalPlan,
      canChangeUserData,
      canVisibleSection,
      onApprovePlan,
      canEditAndApprovalPlan,
      canSendPlanForApproval,
      canRevokePlan,
    },
    data: {
      roles: roles.data ?? [],
    },
    isLoading,
    userMe: userMe.data,
    userMeId: userMe.data?.id,
  };
};

export default usePermissions;
