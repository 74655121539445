import { makeAutoObservable } from "mobx";

import { INode } from "./Tree.Node";

type TInitialLoadTreeNodes = {
  complex?: INode;
  area?: INode;
  building?: INode;
};

class TreeStore {
  selectedObject: undefined | INode = undefined;
  initialLoadTreeNodes: TInitialLoadTreeNodes = {};

  selectedComplex: INode | undefined = undefined;
  selectedArea: INode | undefined = undefined;
  selectedBuilding: INode | undefined = undefined;
  selectedSection: INode | undefined = undefined;

  isEmptyTreeData = false;

  isLoading = false;

  constructor() {
    const complex: INode = JSON.parse(localStorage.getItem("selectedComplex"));
    const area: INode = JSON.parse(localStorage.getItem("selectedArea"));
    const building: INode = JSON.parse(localStorage.getItem("selectedBuilding"));

    if (complex) {
      this.selectedComplex = complex;
    }

    if (area) {
      this.selectedArea = area;
    }

    if (building) {
      this.selectedBuilding = building;
    }

    if (building) {
      this.selectedObject = building;
    } else if (area) {
      this.selectedObject = area;
    } else if (complex) {
      this.selectedObject = complex;
    }

    makeAutoObservable(this);
  }

  setIsEmptyTreeData = (isEmpty: boolean) => {
    this.isEmptyTreeData = isEmpty;
  };

  setIsLoading = (status: boolean) => {
    this.isLoading = status;
  };

  setSelectedObject = (node: INode) => {
    this.selectedObject = node;
  };

  setComplex = (complex: INode) => {
    this.selectedComplex = complex;
    if (complex) {
      localStorage.setItem("selectedComplex", JSON.stringify(complex));
      localStorage.removeItem("selectedArea");
      localStorage.removeItem("selectedBuilding");
    }
  };

  setArea = (area: INode) => {
    this.selectedArea = area;
    if (area) {
      localStorage.setItem("selectedArea", JSON.stringify(area));
      localStorage.removeItem("selectedBuilding");
    }
  };

  setBuilding = (building: INode) => {
    this.selectedBuilding = building;
    if (building) {
      localStorage.setItem("selectedBuilding", JSON.stringify(building));
    }
  };

  setSection = (section: INode) => {
    this.selectedSection = section;
  };

  clearInitialTreeNode = (nodeArea: keyof TInitialLoadTreeNodes) => {
    this.initialLoadTreeNodes[nodeArea] = undefined;
  };
}

export default new TreeStore();
