import React from "react";

import { useQuery } from "react-query";

import { getMeasureUnitsApi } from "api/References/api.measureUnits";
import { QueriesKeys } from "models/QueriesKeys";

const useMeasureListBySearch = () => {
  const measures = useQuery({ queryKey: QueriesKeys.measures, queryFn: getMeasureUnitsApi });
  const [searchedMeasures, setSearchedMeasures] = React.useState(measures.data ?? []);

  const [searchInputMeasures, setInputMeasures] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (searchInputMeasures) {
      setSearchedMeasures(measures.data.filter((item) => item.value.toLowerCase().includes(searchInputMeasures)));
    } else {
      setSearchedMeasures(measures.data ?? []);
    }
  }, [searchInputMeasures, measures.isFetching]);

  return {
    setInputMeasures,
    searchInputMeasures,
    measures: searchedMeasures,
  };
};

export default useMeasureListBySearch;
