export default (isBuildingHeaderExist: boolean, isSectionHeaderExist: boolean, rowIdx: number) => {
  let offset = 0;

  if (rowIdx === 0 && isBuildingHeaderExist) {
    offset += 20;
  }

  if (isSectionHeaderExist) {
    offset += 20;
  }

  return offset;
};
