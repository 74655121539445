import React, { FC } from "react";

import styled from "styled-components";

import GantTableHeaderCell from "../components/Gant.TableHeaderCell";

import { TCellType } from "./models";
import { getBorderRightSize } from "./utils";

interface IDataTableHeader {
  buildingName: string;
  buildingIndex: number;
}

type THeader = {
  title: string;
  cell: TCellType;
};

const headers: THeader[] = [
  { title: "Секции", cell: "section" },
  { title: "%\nфакт", cell: "fact" },
  { title: "%\nплан", cell: "plan" },
  { title: "начало\nплан", cell: "startPlan" },
  { title: "окончание\nплан", cell: "endPlan" },
  { title: "начало\nфакт", cell: "startFact" },
  { title: "окончание\nфакт", cell: "endFact" },
];

const emptyHeaders: THeader[] = [
  { title: "", cell: "section" },
  { title: "", cell: "fact" },
  { title: "", cell: "plan" },
  { title: "", cell: "startPlan" },
  { title: "", cell: "endPlan" },
  { title: "", cell: "startFact" },
  { title: "", cell: "endFact" },
];

const DataTableHeader: FC<IDataTableHeader> = (props) => {
  const headerList: THeader[] =
    props.buildingIndex === 0
      ? [{ title: props.buildingName, cell: "buildingName" }, ...headers]
      : [{ title: props.buildingName, cell: "buildingName" }, ...emptyHeaders];

  return (
    <>
      {headerList.map((header) => (
        <HeaderCell cell={header} />
      ))}
    </>
  );
};

export default DataTableHeader;

const HeaderCell: FC<{ cell: THeader }> = (props) => {
  const borderRightWidth = getBorderRightSize(props.cell.cell);
  const justify = props.cell.cell === "buildingName" ? "flex-start" : "center";

  return (
    <HeaderCellComponent justify={justify} borderColor={borderRightWidth === "1px" ? "regular" : "bold"} style={{ borderRightWidth }}>
      {props.cell.title}
    </HeaderCellComponent>
  );
};

// borderRightSize={getBorderRightSize(props.cell.cell)}

const HeaderCellComponent = styled(GantTableHeaderCell)<{ borderColor: "regular" | "bold"; justify: string }>`
  border-right-color: ${(props) => (props.borderColor === "regular" ? props.theme.colors.absolute200 : props.theme.colors.absolute400)};
  justify-content: ${(props) => props.justify};
  padding: 0 8px;
`;
