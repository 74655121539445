const ROLES = [
  { value: "Начальник участка", label: "Начальник участка" },
  { value: "Оператор данных", label: "Оператор данных" },
  { value: "Главный инженер", label: "Главный инженер" },
  { value: "Руководитель", label: "Руководитель" },
];

export const defaultModalForm = [
  { type: "input", placeholder: "ФИО" },
  { type: "select", selectedValue: "", selectData: ROLES },
  { type: "input", placeholder: "Логин" },
  { type: "select", selectedValue: "selectedRole", selectData: ROLES },
];

export const defaultFormChapter = [{ type: "search", placeholder: "ФИО" }];

export const defaultLabel = "Добавить значение";
