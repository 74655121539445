import React, { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";

import { createContractorApi, getContractorsApi, updateContractorApi } from "api/References/api.contractor";
import { queryClient } from "App";
import ButtonLight from "components/ButtonLight";
import ModalForm from "components/Modal/ModalForm";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import { ErrorsCode } from "models/ErrorsCode";
import { QueriesKeys } from "models/QueriesKeys";
import { IContractor } from "models/References/IContractor";
import { Screen } from "styles/common";
import notify from "utils/notify";

import ContractorTable from "./components/ContractorTable";

const Contractors = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpenUpdate, setOpenUpdate] = useState(false);
  const text = useText();

  const restrictedData = useRestrictedAccessData();

  const [currentContractor, setCurrentContractor] = React.useState<IContractor>();
  const [name, setName] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");

  useEffect(() => {
    if (currentContractor) {
      setName(currentContractor?.name ?? "");
      setContactName(currentContractor?.contactName ?? "");
      setContactPhone(currentContractor?.contactPhone ?? "");
    }
  }, [currentContractor]);

  const createContract = useMutation(createContractorApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.contractors);
      setContactName("");
      setContactPhone("");
      setName("");
      setIsVisible(false);
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const updateContractor = useMutation(updateContractorApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.contractors]);
      setContactName("");
      setContactPhone("");
      setName("");
      setIsVisible(false);
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
      } else {
        notify.error("Что то пошло не так ... попробуйте позже");
      }
    },
  });

  const mapFormContractor = React.useMemo(
    () => [
      {
        type: "input",
        placeholder: text.pholderNameCompany,
        value: name,
        onChange: (e) => {
          setName(e.target.value);
        },
      },
      { type: "input", placeholder: text.pholderContactPerson, value: contactName, onChange: (e) => setContactName(e.target.value) },
      {
        type: "input",
        placeholder: text.pholderContacts,
        mask: "+7 (999) 999 99 99",
        value: contactPhone,
        onChange: (e) => setContactPhone(e.target.value),
      },
    ],
    [text, name, contactName, contactPhone]
  );
  const handleOpenModal = () => {
    setIsVisible(true);
  };
  const handleOpenModalUpdate = (data: IContractor) => {
    setOpenUpdate(true);
    setCurrentContractor(data);
  };
  const handleCloseModalUpdate = (value: boolean) => {
    setOpenUpdate(value);
    setContactName("");
    setContactPhone("");
    setName("");
    setIsVisible(false);
    setCurrentContractor(null);
  };

  const onSave = () => {
    if (name) {
      createContract.mutateAsync({
        contactName,
        contactPhone,
        name,
      });
    }
  };
  const onUpdate = () => {
    if (name || contactName || contactPhone) {
      updateContractor.mutateAsync({
        body: {
          contactName,
          contactPhone,
          name,
        },
        path: { id: currentContractor?.id },
      });
    }
  };

  return (
    <Screen className="w-full">
      <Container className="flex w-full">
        <TableContainer className="flex flex-col">
          <Text className="text-xl font-bold">{text.referenceContractor}</Text>
          <ContractorTable onClickHandle={handleOpenModalUpdate} />
          {restrictedData.vars.canChangeDataOfReference && (
            <ButtonRow className="flex">
              <ButtonLight type="secondary" title={text.add} onClick={handleOpenModal} />
            </ButtonRow>
          )}
        </TableContainer>
        <RenderWithCondition condition={isVisible}>
          <ModalForm
            textButton={text.save}
            data={mapFormContractor}
            label={text.addContractor}
            setIsVisible={setIsVisible}
            onSave={onSave}
            isLoading={createContract.isLoading}
            isError={!!createContract.error}
          />
        </RenderWithCondition>
        <RenderWithCondition condition={isOpenUpdate}>
          <ModalForm
            textButton={"Обновить"}
            data={mapFormContractor}
            label={"Обновить данные"}
            setIsVisible={handleCloseModalUpdate}
            onSave={onUpdate}
            isLoading={updateContractor.isLoading}
            isError={!!updateContractor.error}
          />
        </RenderWithCondition>
      </Container>
    </Screen>
  );
};

export default Contractors;

const TableContainer = styled.div`
  position: relative;
  flex: 1;
`;
const ButtonRow = styled.p`
  padding: 20px;
`;
const Text = styled.p`
  color: ${(props) => props.theme.colors.absolute700};
  padding: 20px;
`;

const Container = styled.section``;
