import React, { FC } from "react";

import dayjs from "dayjs";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ReactComponent as ArrowLeftIcon } from "assets/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "assets/arrowRight.svg";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import { Events } from "models/Events";
import emitter from "services/emitter";
import CalendarStore from "store/Calendar.Store";

interface ICalendarTableHeader {}

const CalendarTableHeader: FC<ICalendarTableHeader> = observer(() => {
  const getDay = (dayNum: number) => capitalize(CalendarStore.calendarStartDate.add(dayNum, "day").locale("ru").format("dd"));
  const getDate = (dayNum: number) => CalendarStore.calendarStartDate.add(dayNum, "day").locale("ru").format("DD");

  const collapseCalendar = () => {
    CalendarStore.toggleIsHiddenColumns();
    emitter.emit(Events["trigger_collapse_calendar_table"], CalendarStore.isHiddenColumns);
  };

  const isColoredColumn = (args: { day?: string; date?: string }) => {
    const startDate = CalendarStore.calendarStartDate;
    const endDate = CalendarStore.calendarEndDate;
    const currentDate = dayjs();

    const isGreaterThanStartDate = startDate.diff(currentDate, "days") <= 0;
    const isLessThanEndDate = endDate.diff(currentDate, "days") >= 0;

    if (!isGreaterThanStartDate || !isLessThanEndDate) {
      return false;
    }

    const currentDateFormatted = currentDate.locale("ru").format("DD");
    const currentDayFormatted = capitalize(currentDate.locale("ru").format("dd"));

    if (args.day && currentDayFormatted === args.day) {
      return true;
    }

    if (args.date && currentDateFormatted === args.date) {
      return true;
    }

    return false;
  };

  const dayColumns = new Array(7).fill(<></>).map((_, idx) => {
    const day = getDay(idx);
    const areaKey = `day${idx + 1}`;
    return () => (
      <HeaderCell key={areaKey} colored={isColoredColumn({ day })} area={areaKey}>
        {day}
      </HeaderCell>
    );
  });

  const dateColumns = new Array(7).fill(<></>).map((_, idx) => {
    const date = getDate(idx);
    const areaKey = `date${idx + 1}`;
    return () => (
      <HeaderCell key={areaKey} colored={isColoredColumn({ date })} area={areaKey}>
        {date}
      </HeaderCell>
    );
  });

  return (
    <ContainerCalendar>
      <HeaderCell colored area="collapse_days">
        <CollaseDaysContainer>
          <CollapseButton onClick={collapseCalendar}>
            <CollapseText>{CalendarStore.isHiddenColumns ? "Показать" : "Свернуть"}</CollapseText>
            <CollapseArrowAndTextContainer>
              <p>дни</p>
              {CalendarStore.isHiddenColumns ? (
                <ArrowLeftIcon height={10} fill={"#0B4795"} />
              ) : (
                <ArrowRightIcon height={10} fill={"#0B4795"} />
              )}
            </CollapseArrowAndTextContainer>
          </CollapseButton>
        </CollaseDaysContainer>
      </HeaderCell>

      <RenderWithCondition condition={!CalendarStore.isHiddenColumns}>
        <>
          {dayColumns.map((DayColumn) => (
            <>
              <DayColumn />
            </>
          ))}
          {dateColumns.map((DateColumn) => (
            <>
              <DateColumn />
            </>
          ))}

          <HistorySlug />
        </>
      </RenderWithCondition>
    </ContainerCalendar>
  );
});

export default CalendarTableHeader;

export const ContainerCalendar = styled.div`
  display: grid;
  grid-template-areas:
    "collapse_days day1 day2 day3 day4 day5 day6 day7 history_slug"
    "collapse_days date1 date2 date3 date4 date5 date6 date7 history_slug";
  grid-template-columns: 80px repeat(7, 1fr) 20px;
  grid-template-rows: auto;
  justify-content: space-between;
  align-items: center;
  height: 61.5px;
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 12px;
`;

const HeaderCell = styled.div<{ area: string; colored?: boolean }>`
  grid-area: ${(props) => props.area};
  background-color: ${(props) => (props.colored ? props.theme.colors.blue200 : undefined)};
  color: ${(props) => (props.colored ? props.theme.colors.blue400 : undefined)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  width: 100%;
`;

const HistorySlug = styled.div`
  grid-area: history_slug;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  height: 100%;
`;

const CollaseDaysContainer = styled.div`
  padding: 8px 4px;
`;

const CollapseArrowAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollapseText = styled.p`
  padding-right: 8px;
  text-align: left;
`;

const CollapseButton = styled.button``;
