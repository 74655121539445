import React from "react";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { getUserMeApi } from "api/Users/api.users";
import { ReactComponent as AddBlue } from "assets/add-blue.svg";
import { ReactComponent as Add } from "assets/add.svg";
import { ReactComponent as Exit } from "assets/exit.svg";
import { ReactComponent as GPRIcon } from "assets/gpr.svg";
import { ReactComponent as HomeBlue } from "assets/home-blue.svg";
import { ReactComponent as Home } from "assets/home.svg";
import { ReactComponent as PeopleBlue } from "assets/people-blue.svg";
import { ReactComponent as People } from "assets/people.svg";
import { ReactComponent as Person } from "assets/person.svg";
import { ReactComponent as SettingsBlue } from "assets/settings-blue.svg";
import { ReactComponent as Settings } from "assets/settings.svg";
import { ReactComponent as TreeBlue } from "assets/tree-blue.svg";
import { ReactComponent as Tree } from "assets/tree.svg";
import Dropdown, { IDropdownItem } from "components/Dropdown/Dropdown";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import { QueriesKeys } from "models/QueriesKeys";
import { IUser } from "models/Users/IUser";
import { Spacer } from "styles/common";
import { getRoleText } from "utils/user";

import BurgerMenu from "./BurgerMenu/BurgerMenu";

interface INavBar {
  isSideBarExist: boolean;
  AdditionalsHeaders: React.FC;
}

interface IAboutMe {
  user: IUser;
}

const NavBar = React.forwardRef<HTMLDivElement, INavBar>((props, ref) => {
  const restrictedData = useRestrictedAccessData();

  const text = useText();
  const navigate = useNavigate();

  const { data: userMe } = useQuery({
    queryFn: getUserMeApi,
    queryKey: [QueriesKeys.userMe],
  });

  const AboutMe: React.FC<IAboutMe> = ({ user }) => (
    <AboutMeContainer>
      <p style={{ marginTop: "-20px;" }}>{`${user?.lastName} ${user?.firstName} ${user?.middleName}`}</p>
      <p style={{ color: "#7d7d7d", fontSize: "12px" }}>{`${getRoleText(user?.role)} ${user?.login ?? ""}`}</p>
    </AboutMeContainer>
  );

  const MAIN_MENU: IDropdownItem[] = React.useMemo(() => {
    const home = { label: text.objects, value: text.objects, icon: Home, activeIcon: HomeBlue, linkTo: "/" };
    const objectsEdit = {
      label: text.addOrChangeObjects,
      value: text.addOrChangeObjects,
      icon: Add,
      activeIcon: AddBlue,
      linkTo: "/objects_edit",
      parentPath: "/objects",
    };
    const users = { label: text.users, value: text.users, icon: People, activeIcon: PeopleBlue, linkTo: "/users" };
    const references = {
      label: text.directory,
      value: text.directory,
      icon: Tree,
      activeIcon: TreeBlue,
      linkTo: "/references",
      parentPath: "/references",
    };
    const settings = {
      label: text.settings,
      value: text.settings,
      icon: Settings,
      activeIcon: SettingsBlue,
      bottomDivider: true,
      linkTo: "/settings/section",
      parentPath: "/settings",
    };
    const aboutMe = {
      label: text.settings,
      value: <AboutMe user={userMe} />,
      icon: Person,
      activeIcon: SettingsBlue,
      bottomDivider: true,
    };
    const exit = {
      label: text.exit,
      value: text.exit,
      icon: Exit,
      linkTo: "/logout-screen",
      rightText: `v. ${process.env.REACT_APP_VERSION}`,
    };

    const menu: IDropdownItem[] = [home];

    const { canVisibleUsers, canVisibleSettings, canVisibleReferences, canVisibleAddAndChangeObjects } = restrictedData.vars;

    if (canVisibleAddAndChangeObjects) {
      menu.push(objectsEdit);
    }

    if (canVisibleUsers) {
      menu.push(users);
    }

    if (canVisibleReferences) {
      menu.push(references);
    }

    if (canVisibleSettings) {
      menu.push(settings);
    }

    menu.push(aboutMe);
    menu.push(exit);

    return menu;
  }, [text, userMe]);

  const onChange = (changedItem: { value: string }) => {
    const linkTo = MAIN_MENU.find((item) => item.value === changedItem.value)?.linkTo;
    navigate(linkTo);
    // TODO navigate to other
  };

  const clickByLogo = () => {
    navigate("/");
  };

  const { AdditionalsHeaders } = props;

  return (
    <Container colored={props.isSideBarExist} className="flex items-center">
      <ContainerInner className="flex" ref={ref}>
        <div>
          <Dropdown type="simple" data={MAIN_MENU} onChange={onChange} iconButton={BurgerMenu} meta={{ element: "burger-menu" }} />
        </div>
        <Spacer horizontal px={10} />
        <Logo onClick={clickByLogo}>
          <GPRIcon />
        </Logo>
        <Spacer horizontal px={10} />
      </ContainerInner>
      <AdditionalsHeaders />
    </Container>
  );
});

export default NavBar;

const ContainerInner = styled.div`
  padding: 20px;
  min-width: 222px;
  @media (max-width: 1279px) {
    padding: 10px;
    min-width: 175px;
  }
`;

const Container = styled.div<{ colored: boolean }>`
  border-right: ${(props) => (props.colored ? "1px" : "0px")} solid ${(props) => props.theme.colors.absolute200};
  border-bottom: ${(props) => (props.colored ? "1px" : "0px")} solid ${(props) => props.theme.colors.absolute200};
  background-color: ${(props) => props.colored && props.theme.colors.blue100};
`;

const Logo = styled.button`
  background-color: ${(props) => props.theme.colors.absolute100};
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 20px;

  border-radius: 10px;

  @media (max-width: 1279px) {
    padding: 0px 14px;
  }
`;

const AboutMeContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
