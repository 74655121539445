import apiClient from "api/api.client";
import { ISection } from "models/Objects/ISection";
import { ISectionItems } from "models/Objects/ISectionItem";
import { IGetTemplateSection, ITemplateSection, ITemplateSectionItem } from "models/Objects/ITemplateSection";

export type TGetSectionsApi = (params: { buildingId: string; sectionId?: string; controlType?: string[] }) => Promise<ISection[]>;

export const getSectionsApi: TGetSectionsApi = async (params) => {
  try {
    const res = await apiClient.get("objects/v1/sections", { params });
    const modifiedResult = res.data.map((item) => ({ ...item, parentId: params.buildingId }));

    return modifiedResult;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetTemplateSectionsApi = () => Promise<ITemplateSection>;

export const getTemplateSectionsApi: TGetTemplateSectionsApi = async () => {
  try {
    const res = await apiClient.get("objects/v1/sections/template");

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TCreateSectionsApi = (args: { body: { name: string; buildingId: string }[] }) => Promise<ISection[]>;

export const createSectionsApi: TCreateSectionsApi = async (args) => {
  try {
    const res = await apiClient.post("objects/v1/sections/list", args.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TCreateTemplateApi = (body: IGetTemplateSection) => Promise<ITemplateSectionItem>;

export const createTemplateApi: TCreateTemplateApi = async (body) => {
  try {
    const res = await apiClient.post("objects/v1/items", body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

type TGetSectionsItemsApiParams = {
  buildingId: string;
  sectionId: string[];
  controlType?: ("DAILY" | "WEEKLY")[];
};

export type TGetSectionsItemsApi = (params: TGetSectionsItemsApiParams) => Promise<ISectionItems>;

export const getSectionsItemsApi: TGetSectionsItemsApi = async (params) => {
  try {
    const res = await apiClient.get("objects/v1/sections/filtered", { params });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateSectionApi = (payload: { body: { name: string }; path: { id: string } }) => Promise<Omit<ISection, "parentId">>;

export const updateSectionApi: TUpdateSectionApi = async (payload) => {
  try {
    const res = await apiClient.patch(`objects/v1/sections/${payload.path.id}`, payload.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteSectionApi = (payload: { path: { id: string } }) => Promise<void>;

export const deleteSectionApi: TDeleteSectionApi = async (payload) => {
  try {
    const res = await apiClient.delete(`objects/v1/sections/${payload.path.id}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
