import { CSSProperties, FC } from "react";
import React from "react";

import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { getItemsBySectionIdApi, updateObjectWithTemplateApi } from "api/Objects/api.objects";
import { queryClient } from "App";
import AddObjectImg from "assets/images/addObject.png";
import ButtonLight from "components/ButtonLight";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import TreeStore from "components/Tree/Tree.store";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useText from "hooks/useText";
import { QueriesKeys } from "models/QueriesKeys";
import { Spacer } from "styles/common";

import ObjectsMonitoring from "./ObjectsEdit.Monitoring";
import ObjectStore from "./store/Object.store";
import { Title } from "./styles";

const ObjectsEdit: FC = observer(() => {
  const navigate = useNavigate();
  const text = useText();
  const selectedSectionName = ObjectStore.selectedSection?.name;

  const sectionItems = useQuery({
    queryFn: () =>
      getItemsBySectionIdApi({
        controlType: "DAILY,WEEKLY",
        sectionId: ObjectStore.selectedSection?.id,
      }),
    queryKey: [QueriesKeys.sectionItems, ObjectStore.selectedSection?.id],
    enabled: true,
  });

  const updateSectionWithTemplate = useMutation(updateObjectWithTemplateApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.sectionItems]);
    },
  });

  const onClickTemplate = () => {
    updateSectionWithTemplate.mutateAsync({
      id: ObjectStore.selectedSection?.id,
    });
    navigate("/objects_edit/manually");
  };
  const onClickManually = () => {
    ObjectStore.setEmptySection(true);
    navigate("/objects_edit/manually");
  };
  React.useEffect(() => {
    if (ObjectStore.selectedSection) {
      const foundSection = sectionItems.data?.find((section) => section.section.id === ObjectStore.selectedSection.id);
      if (foundSection?.items?.length) {
        navigate("/objects_edit/manually");
      }
    }
  }, [sectionItems.data, ObjectStore.selectedSection]);

  return (
    <>
      <Outlet />
      <Container>
        <Title>{text.addOrChangeObjects}</Title>
        <Spacer px={20} />
        <RenderWithCondition condition={ObjectStore.selectedObject}>
          <ObjectsMonitoring />
        </RenderWithCondition>
        <RenderWithCondition condition={TreeStore.isEmptyTreeData}>
          <EmptyPlaceholder
            styleContainer={{ paddingRight: ObjectStore.sideBarWidth ?? 0 }}
            description={"Для того чтобы добавить объект нажмите "}
            header={"У вас еще нет ни одного объекта"}
            ImgComponent={() => <img src={AddObjectImg} />}
          />
        </RenderWithCondition>
        <RenderWithCondition condition={selectedSectionName && !TreeStore.isEmptyTreeData}>
          <>
            <Spacer px={30} />
            <SubTitle>Заполнить список работ</SubTitle>
            <Spacer px={10} />
            <ButtonRow>
              <RenderWithCondition condition={sectionItems.data && isEmpty(sectionItems.data[0]?.items)}>
                <ButtonLight title="По эталонной секции" type="secondary" onClick={onClickTemplate} />
              </RenderWithCondition>
              <ButtonLight title="Вручную" type="secondary" onClick={onClickManually} />
              {/* //TODO: временно скрыть */}
              {/* <ButtonLight title="Экспорт из Excel " type="secondary" /> */}
            </ButtonRow>
          </>
        </RenderWithCondition>
      </Container>
    </>
  );
});

export default ObjectsEdit;

const Container = styled.div`
  padding: 20px;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
`;

const SubTitle = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.colors.absolute700};
`;

const ButtonRow = styled.p`
  display: flex;
  gap: 20px;
`;
