/* eslint-disable max-len */
export default {
  Block: "Блок",
  accordingModel: "По образцу",
  add: "Добавить",
  addChapter: "Добавить раздел",
  addComment: "Добавить комментарий",
  addContractor: "Добавить подрядчика",
  addMeasure: "Добавить единицу измерения",
  addObject: "Добавить объект",
  addOrChangeObjects: "Добавление и изменение объектов",
  addUser: "Добавить пользователя",
  addValue: "Добавить значение",
  addWorkTypes: "Добавить наименование работы",
  amountOfDays: "Дней",
  approve: "Согласовать",
  arbitraryPeriod: "Произвольный период",
  begin: "Начало",
  bindMonth: "Привязать к месяцу",
  bindWeek: "Привязать к неделе",
  byGPR: "По ГПР",
  cancel: "Отмена",
  chapter: "Раздел",
  changeRole: "Изменить роль",
  contactData: "Контактные данные",
  contactPerson: "Контактное лицо",
  contractor: "Подрядчик",
  contractorNotDetermined: "Подрядчик не определен",
  control: "Контроль",
  controlPeriod: "Частота контроля",
  createPassword: "Придумайте пароль",
  daily: "Ежедневный",
  dataCheck: "Проверка данных",
  delete: "Удалить",
  deviationPlanFact: "Отклонение факта от плана",
  directory: "Справочники",
  edit: "Редактировать",
  emptyList: "Список пуст",
  ending: "Конец",
  enter: "Войти",
  entry: "Вход",
  exit: "Выход",
  fact: "Факт",
  factOn: "Факт на",
  firstName: "Имя",
  fillListOfWorks: "Заполнить список работ",
  forgotPassword: "Забыли пароль?",
  formalize: "Оформить",
  fullName: "ФИО",
  goTo: "Перейти",
  hideControlAndContractor: "Cкрыть контроль и подрядчика",
  hideDays: "Свернуть\nдни",
  jobTitle: "Наименование работы",
  lastName: "Фамилия",
  liter: "Литер",
  login: "Логин",
  loginDisclaimer1: "Авторизовываясь вы соглашаетесь на обработку",
  loginDisclaimer2: "персональных данных",
  manually: "Вручную",
  measureUnits: "ед. изм.",
  measureUnitsFull: "Единицы измерения",
  middleName: "Отчество",
  numberOfDay: "Количество дней",
  numberOfMonth: "Количество месяцев",
  objectAddingDescription: "При создании 2-й и последующих секций литера набор работ такой же ",
  objectEditingDescription: "При редактировании текущего набора списка работ, изменения будут применяться во всех секциях литера",
  objects: "Объекты",
  password: "Пароль",
  percentageOfCompletion: "%\nвыполнения",
  plan: "План",
  planOn: "План на",
  pholderContactPerson: "Контактное лицо (ФИО)",
  pholderContacts: "Контактные данные (Телефон)",
  pholderMeasure: "Единица измерения",
  pholderNameCompany: "Наименование организации",
  phoneConfirmed: "Телефон подтвержден пользователем",
  referenceContractor: "Справочник подрядчика",
  referenceMeasureUnits: "Справочник ед. измерения",
  referenceMeasureUnitsFull: "Справочник единицы измерения",
  referenceSectionGet: "Оформить эталонную секцию ",
  referenceSection: "Эталонная секция",
  referenceSectionDescr:
    "Эталонная секция - это шаблон для заполнения списков работ в секции. Ей удобно пользоваться для быстрого заполнения списка работ в секции",
  referenceSectionDescrExistTemplate: `Эталонная секция
Эталонная секция - это шаблон для заполнения списков работ в секции. Для просмотра и редактирования шаблонного списка работ перейдите в эталонную секцию`,
  referenceSectionDone: "Эталонная секция уже создана",
  referenceSectionOffer: "Вы также можете оформить эталонную секцию",
  referenceWorks: "Справочник работ",
  remainder: "Остаток",
  repeatPassword: "Повторить пароль",
  reportPeriod: "Отчетный период",
  requestCodeAgain: "Запросить код повторно",
  resetAndSetNew: "Сбросить и задать новый",
  restorePassword: "Восстановить пароль",
  role: "Роль",
  save: "Сохранить",
  search: "Поиск",
  sendCode: "Отправить код",
  setNewPassword: "Задать новый пароль",
  settings: "Настройки",
  showAll: "Показать все",
  smsCode: "Код из sms",
  smsCodeDescription: "Проверьте свой номер телефона\nна него будет отправлена sms",
  sorting: "Сортировка",
  staff: "Персонал",
  staffOnAveragePerWeek: "Персонал\nсреднее",
  startDay: "День начала",
  startDayOfPeriod: "День начала первого периода",
  telephone: "Телефон",
  textSettings: "За сколько дней до начала отчетного периода начинать напоминать о незаполненных планах?",
  through: "Через",
  totalForProject: "Всего по проекту",
  unit: "ед.\nизм",
  updateControl: "Изменить период контроля",
  updateMeasureUnit: "Изменить единицу измерения",
  users: "Пользователи",
  weekly: "Еженедельный",
  textCreateSections: "При создании 2-й и последующих секций литера набор работ такой же ",
  textUpdateSections: "При редактировании текущего набора списка работ, изменения будут применяться во всех секциях литера",
};
