import React, { FC, useState } from "react";

import classNames from "classnames";
import { styled } from "styled-components";

interface ITooltipChartProps {
  formattedTooltip?: (str: string) => void;
  payload: any[];
  label: any[];
}

const TooltipChart: FC<ITooltipChartProps> = (props) => {
  const [isActive, setActive] = useState(false);

  return (
    <Container className={classNames("flex flex-col justify-center ")}>
      {/* <div>{props.label}</div> */}
      <div>{props.payload[0].payload.tooltipLabelCumulative}</div>

      {props.payload.map((line: any) => (
        <div key={line.name} className="label" style={{ color: line.color }}>
          {`${line.name === "planCumulative" ? "План" : "Факт"}: ${line.value} `}
        </div>
      ))}
    </Container>
  );
};

export default TooltipChart;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  /* width: 150px; */
  padding: 10px;
  border-right: 10px;

  background-color: ${(props) => props.theme.colors.absolute100};
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
`;
