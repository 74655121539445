import React, { FC } from "react";

import { useLocation } from "react-router-dom";
import styled from "styled-components";

import ObjectsEditSideBar from "screens/ObjectsEdit/ObjectsEdit.SideBar";
import NavBarReference from "screens/Reference/components/NavBar.Reference";
import NavBarRefSection from "screens/RefSection/components/NavBar.RefSection";

interface INavBarSideBar {
  setSideBarWidth: (width: number) => void;
  headerHeight: string;
}

const NavBarSideBar: FC<INavBarSideBar> = (props) => {
  const location = useLocation();
  const sideBarRefContainer = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const { clientWidth } = sideBarRefContainer.current;

    if (location.pathname === "/" || location.pathname === "/users") {
      props.setSideBarWidth(0);
    } else {
      props.setSideBarWidth(clientWidth);
    }
  }, [location]);

  const showReferenceBar =
    location.pathname === "/references" || location.pathname === "/references/measures" || location.pathname === "/references/contractors";
  const showSectionBar = location.pathname === "/settings/section" || location.pathname === "/settings";
  const showObjectEditSideBar = location.pathname === "/objects_edit" || location.pathname === "/objects_edit/manually";

  return (
    <Container ref={sideBarRefContainer} headerHeight={props.headerHeight}>
      {showReferenceBar && <NavBarReference />}
      {showSectionBar && <NavBarRefSection />}
      {showObjectEditSideBar && <ObjectsEditSideBar />}
    </Container>
  );
};

export default NavBarSideBar;

const Container = styled.div<{ headerHeight: string }>`
  background-color: ${(props) => props.theme.colors.blue100};
  height: 100%;
`;
