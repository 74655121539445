export interface IUser {
  id: string;
  login: string;
  firstName: string;
  lastName: string;
  middleName: string;
  role: CurrentUserRoles;
  sections: string[];
  uaaId: string;
}

export interface IUserRole {
  name: CurrentUserRoles;
  description: string;
}

export interface IUserPassword {
  password: string;
}

// eslint-disable-next-line no-shadow
export enum Roles {
  CHIEF_ADMINISTRATOR,
  ADMINISTRATOR,
  ENGINEER_CNP,
  HEAD,
  CHIEF_ENGINEER,
  AREA_HEAD,
  DATA_OPERATOR,
}

export interface ICurrentUser {
  id: string;
  login: string;
  firstName: string;
  lastName: string;
  middleName: string;
  role: CurrentUserRoles;
}

// eslint-disable-next-line no-shadow
export enum CurrentUserRoles {
  CHIEF_ADMINISTRATOR = "CHIEF_ADMINISTRATOR",
  ADMINISTRATOR = "ADMINISTRATOR",
  ENGINEER_CNP = "ENGINEER_CNP",
  HEAD = "HEAD",
  CHIEF_ENGINEER = "CHIEF_ENGINEER",
  AREA_HEAD = "AREA_HEAD",
  DATA_OPERATOR = "DATA_OPERATOR",
}
