import { max } from "lodash";

import { IHistogramsData } from "../components/Screens/components/WorkItem";

export const getMaxValueDivisionScale = (period: IHistogramsData[]) => {
  const maxPlan = max(
    period.map((day) => {
      if (!day.plan) {
        return 0;
      }
      return day.plan;
    })
  );
  const maxFact = max(
    period.map((day) => {
      if (!day.fact) {
        return 0;
      }
      return day.fact;
    })
  );

  const maxPersonal = max(
    period.map((day) => {
      if (!day.personal) {
        return 0;
      }
      return day.personal;
    })
  );

  return { maxPlan, maxFact, maxPersonal };
};
