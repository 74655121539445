import { isUndefined } from "lodash";
import { makeAutoObservable } from "mobx";

import { getTokensApi } from "api/api.auth";
import { ITokens } from "models/Tokens";

class AuthStore {
  isAuthenticated = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  checkAuth = () => {
    if (isUndefined(this.isAuthenticated)) {
      this.isAuthenticated = !!localStorage.getItem("refresh_token");
      return this.isAuthenticated;
    }

    return this.isAuthenticated;
  };

  login = () => {
    const redirectUri = encodeURIComponent(`https://${window.location.host}/callback`);
    // eslint-disable-next-line max-len
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/oauth2/authorize?response_type=code&client_id=web-client&scope=openid&redirect_uri=${redirectUri}`;
  };

  saveTokens = (tokens: ITokens) => {
    const { access_token, refresh_token, token_type } = tokens;
    if (access_token && refresh_token && token_type) {
      localStorage.setItem("access_token", tokens.access_token);
      localStorage.setItem("refresh_token", tokens.refresh_token);
      localStorage.setItem("token_type", tokens.token_type);
      this.isAuthenticated = true;
    }
  };

  logout = () => {
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/logout`;
  };

  getTokens = () => ({
    access_token: localStorage.getItem("access_token"),
    refresh_token: localStorage.getItem("refresh_token"),
    token_type: localStorage.getItem("token_type"),
  });

  fetchTokens = (authCode: string) => () => getTokensApi(authCode);
}

export default new AuthStore();
