import React from "react";

import { isEmpty, isNumber } from "lodash";
import { useMutation } from "react-query";
import styled from "styled-components";

import { createBuildingsApi } from "api/Objects/api.buildings";
import { createSectionsApi } from "api/Objects/api.sections";
import { queryClient } from "App";
import TreeStore from "components/Tree/Tree.store";
import russianLetters from "constants/russianLetters";
import { QueriesKeys } from "models/QueriesKeys";
import emitter from "services/emitter";

import ObjectStore from "../store/Object.store";

type THook = () => {
  data: {
    modalForm: any;
    isLoading: boolean;
    isError: boolean;
  };
  fn: {
    onModalSave: (closeModalCb: () => void) => void;
  };
};

type TSelecItem =
  | {
      value: string;
    }[]
  | [];

const generateUniqueRussianLetters = (amountOfLetters: number, alreadyExistLetters: string[], fromLetterIndex = 0) => {
  const result = [];
  let nextLetterIndex = 0;
  let lettersIndexFromStart: undefined | number = undefined;
  let indexToLetter = 0;
  const setAlreadyExistLetters = new Set(alreadyExistLetters);

  for (let i = fromLetterIndex; i < amountOfLetters + fromLetterIndex; i++) {
    if (
      (lettersIndexFromStart && russianLetters[(lettersIndexFromStart ?? 0) + nextLetterIndex - 1] === "Я") ||
      (!lettersIndexFromStart && russianLetters[i + nextLetterIndex - 1] === "Я")
    ) {
      lettersIndexFromStart = 0;
      nextLetterIndex = 0;
      indexToLetter += 1;
    }

    let currentLetter = isNumber(lettersIndexFromStart)
      ? russianLetters[lettersIndexFromStart + nextLetterIndex] + indexToLetter
      : russianLetters[i + nextLetterIndex];

    while (setAlreadyExistLetters.has(currentLetter)) {
      nextLetterIndex += 1;
      if (isNumber(lettersIndexFromStart)) {
        lettersIndexFromStart += 1;
        currentLetter = russianLetters[lettersIndexFromStart + nextLetterIndex] + indexToLetter;
      } else {
        currentLetter = russianLetters[i + nextLetterIndex];
      }
    }

    if (isNumber(lettersIndexFromStart)) {
      lettersIndexFromStart += 1;
    }

    result.push({ letter: currentLetter, idx: i + nextLetterIndex });
  }

  return result;
};

const uniqueNumbersWithIncrement = (amoutBuildingsNumbers: number[], buildingsExistNumbers: number[]) => {
  const result = [];
  const set = new Set(buildingsExistNumbers);

  for (const num of amoutBuildingsNumbers) {
    let uniqueNum = num;
    while (set.has(uniqueNum)) {
      uniqueNum++;
    }
    result.push(uniqueNum);
    set.add(uniqueNum);
  }

  return result;
};

const useAddObjectsModalData: THook = () => {
  const createBuildings = useMutation(createBuildingsApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.tree);
      clearHandler();
    },
  });

  const createSections = useMutation(createSectionsApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.tree);
      clearHandler();
    },
  });

  const alreadyExistObjectsLetters = ObjectStore.selectedObject?.children?.map((child) => child.name).map((name) => name.split(" ")[1]);

  const [amountOfSections, setAmountOfSections] = React.useState(1);
  const [isChangedStartingFromSection, setIsChangedStartingFromSection] = React.useState(false);
  const [selectSectionsDataList, setSelectSectionsDataList] = React.useState<TSelecItem>([]);
  const [fromLetter, setFromLetter] = React.useState(0);
  const creationSectionLetters: { letter: string; idx: number }[] =
    ObjectStore.selectedObject?.meta.area === "building" &&
    isChangedStartingFromSection &&
    generateUniqueRussianLetters(amountOfSections, alreadyExistObjectsLetters, fromLetter);

  const [amountOfBuildings, setAmountOfBuildings] = React.useState(1);
  const [selectBuildingDataList, setSelectBuildingDataList] = React.useState<TSelecItem>([]);

  const [createBuildingsFrom, setCreateBuildingsFrom] = React.useState<number | undefined>();

  React.useEffect(() => {
    if (ObjectStore.selectedObject?.meta.area === "building") {
      const res = generateUniqueRussianLetters(amountOfSections, alreadyExistObjectsLetters ?? []);
      setSelectSectionsDataList(res);
    }
  }, [amountOfSections, ObjectStore.selectedObject]);

  const clearHandler = () => {
    setCreateBuildingsFrom(undefined);
    setSelectBuildingDataList([]);
    setAmountOfBuildings(1);
    setAmountOfSections(1);
    setSelectSectionsDataList([]);
    setFromLetter(0);
    setIsChangedStartingFromSection(false);
  };
  React.useEffect(() => {
    emitter.addListener("close_modal", clearHandler);

    return () => {
      emitter.removeListener("close_modal", clearHandler);
    };
  }, []);

  React.useEffect(() => {
    const numbersOfStart = (ObjectStore.selectedObject?.children ?? [])
      .map((child) => Number(child.name.slice(6)))
      .filter((el) => el > 0)
      .sort((a, b) => a - b);
    if (isEmpty(numbersOfStart) || numbersOfStart[0] !== 1) {
      numbersOfStart?.unshift(0);
    }
    const listNumberOfStart = numbersOfStart
      ?.filter((num, i, obj) => num + 1 !== obj[i + 1])
      .map((numStart) => ({
        value: String(numStart + 1),
      }));
    setSelectBuildingDataList(listNumberOfStart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOfBuildings, ObjectStore.selectedObject, createBuildings.isLoading]);

  const buildingsExistNumbers = ObjectStore.selectedObject?.children?.map((child) => child.name)?.map((name) => Number(name.split(" ")[1]));
  const amoutBuildingsNumbers = new Array(amountOfBuildings).fill(undefined).map((_, idx) => idx + createBuildingsFrom);

  const creationBuildingsListNumber: number[] =
    createBuildingsFrom && uniqueNumbersWithIncrement(amoutBuildingsNumbers, buildingsExistNumbers);

  return {
    data: {
      isLoading: createBuildings.isLoading,
      isError: createBuildings.isError,
      modalForm: {
        "2": {
          label: "Добавить секцию",
          data: [
            {
              type: "input",
              placeholder: "Количество секций",
              value: amountOfSections.toString(),
              label: "Количество секций",
              onChange: (e) => {
                const value = e.target.value;

                if (!isNaN(Number(value))) {
                  if (Number(value) > 10) {
                    setAmountOfSections(10);
                  } else {
                    setAmountOfSections(Number(value));
                  }
                }
              },
            },
            {
              type: "select",
              selectData: selectSectionsDataList.map((letterData) => ({ value: letterData.letter, label: letterData.idx })),
              selectPlaceholder: "Начать с",
              topPlaceholder: "Начать с",
              onSelect: (item) => {
                setIsChangedStartingFromSection(true);
                setFromLetter(item.label);
              },
              placeholderInSelectedText: "Секция",
            },
            {
              type: "custom",
              Component: () => {
                if (creationSectionLetters) {
                  return <Text>{`Создадутся секции: ${creationSectionLetters.map((letterData) => letterData.letter).join(",")}`}</Text>;
                }
              },
            },
          ],
        },
        "1": {
          label: "Добавить литер",
          data: [
            {
              type: "input",
              placeholder: "Количество литеров",
              value: amountOfBuildings.toString(),
              label: "Количество литеров",
              onChange: (e) => {
                const value = e.target.value;

                if (!isNaN(Number(value))) {
                  if (Number(value) > 10) {
                    setAmountOfBuildings(10);
                  } else {
                    setAmountOfBuildings(Number(value));
                  }
                }
              },
            },
            {
              type: "select",
              selectData: selectBuildingDataList,
              selectPlaceholder: "Начать с",
              topPlaceholder: "Начать с",
              onSelect: (item) => {
                setCreateBuildingsFrom(Number(item.value));
              },
              placeholderInSelectedText: "Литер",
            },
            {
              type: "custom",
              Component: () => {
                if (createBuildingsFrom) {
                  return <Text>{`Создадутся литеры: ${creationBuildingsListNumber.join(",")}`}</Text>;
                }
              },
            },
          ],
        },
      },
    },
    fn: {
      onModalSave: async (closeModalCb: () => void) => {
        if (creationBuildingsListNumber) {
          await createBuildings.mutateAsync({
            body: creationBuildingsListNumber.map((buildingNum) => ({ name: `Литер ${buildingNum}`, areaId: TreeStore.selectedArea.id })),
          });

          queryClient.invalidateQueries([QueriesKeys.tree]);

          closeModalCb();

          return;
        }

        if (creationSectionLetters) {
          await createSections.mutateAsync({
            body: creationSectionLetters.map((sectionNum) => ({
              name: `Секция ${sectionNum.letter}`,
              buildingId: TreeStore.selectedBuilding.id,
            })),
          });

          queryClient.invalidateQueries([QueriesKeys.tree]);

          closeModalCb();
        }
      },
    },
  };
};

export default useAddObjectsModalData;

const Text = styled.p`
  text-align: left;
`;
