import { FC, useState } from "react";

import styled from "styled-components";

import HomeStore from "screens/Home/store/Home.store";
import { Spacer } from "styles/common";

import { RenderWithCondition } from "../../../../hoc/RenderWithCondition";
import { Vertical } from "../../../../stories/RadioGroup.stories";

import { TitleLine } from "./LineChart.Cumulative";
import RectanglesLine from "./RectanglesLine";

interface IHeaderChartInner {
  rectanglesLine: string[];
  isLargeScreen?: boolean;
}

const HeaderChartInner: FC<IHeaderChartInner> = (props) => {
  const isCumulative = HomeStore.typeDataForCharts.label === "Cumulative";
  return (
    <Container className="flex">
      <TitleLine weight={700} fontSize={18}>
        Недельно суточный график
      </TitleLine>
      <RenderWithCondition condition={props.isLargeScreen}>
        <RectanglesLine mapTitleLines={props.rectanglesLine} />
      </RenderWithCondition>
    </Container>
  );
};

export default HeaderChartInner;

export const Container = styled.div`
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;
