import { makeAutoObservable } from "mobx";

import { IGantChartBuilding, IGantWork } from "models/Objects/IGantChart";

export type TTableMode = "gant" | "histogram";

class HomeChartsStore {
  worksData: any[] | [];
  cellCalendarData: any[] | [];

  constructor() {
    makeAutoObservable(this);
  }

  setWorksData(data: any[]) {
    this.worksData = data;
  }
  setCellCalendarData(data: any[]) {
    this.cellCalendarData = data;
  }
}

export default new HomeChartsStore();
