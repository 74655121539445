import { FC } from "react";

import { uniqueId } from "lodash";

import Checkbox from "components/Checkbox";

import { ICheckboxValue } from "./Dropdown";

interface IDropdownCheckboxList {
  data: ICheckboxValue[];
  checkboxCheckedIds: string[];
  onChange: (item: { value: string | React.ReactElement; label?: string; id?: string }) => void;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropdownCheckboxList: FC<IDropdownCheckboxList> = (props) => {
  const isCheckedValue = (item: ICheckboxValue) => !!props.checkboxCheckedIds.find((checkboxCheckedIds) => checkboxCheckedIds === item.id);

  return (
    <div>
      {props.data.map((item) => (
        <Checkbox
          key={item.id + uniqueId("checkbox_key")}
          checked={isCheckedValue(item)}
          label={item.label}
          setChecked={() => props.onChange(item)}
        />
      ))}
    </div>
  );
};

export default DropdownCheckboxList;
