import { CSSTransition } from "react-transition-group";

interface IRenderWithCondition {
  condition: any;
  children: JSX.Element;
  animated?: boolean;
}

export const RenderWithCondition = ({ condition, children, animated }: IRenderWithCondition) => {
  if (animated) {
    return (
      <CSSTransition classNames="fade" in={condition} timeout={300} unmountOnExit mountOnEnter>
        {children}
      </CSSTransition>
    );
  }

  if (!condition) {
    return null;
  }

  return children;
};
