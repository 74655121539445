import React, { FC } from "react";

import { observer } from "mobx-react-lite";

import { IText } from "models/Text";
import AppStore from "store/App.Store";

interface ITextProvider {
  children: React.ReactNode;
}

export const TextProviderContext = React.createContext<{ text: IText }>(null);

const TextProvider: FC<ITextProvider> = observer((props) => {
  React.useEffect(() => {
    AppStore.setText();
  }, []);

  if (!AppStore.text) {
    return <></>;
  }

  return (
    <TextProviderContext.Provider value={{ text: AppStore.text }}>{<>{AppStore.text && props.children}</>}</TextProviderContext.Provider>
  );
});

export default TextProvider;
