import React, { FC } from "react";

import styled from "styled-components";

import { IRefSectionTableData } from "models/Objects/ITemplateSection";
import { Spacer } from "styles/common";

import RefSectionTableItem from "./RefSectionTable.Item";

interface IRefSectionTable {
  onClickHandle?: () => void;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  data: IRefSectionTableData[];
  columns: any;
}

const RefSectionTable: FC<IRefSectionTable> = (props) => (
  <div className="flex flex-col">
    <Container className="flex">
      <RefSectionTableItem data={props.data} width="100%" columns={props.columns} />
      <Spacer px={30} horizontal />
    </Container>
  </div>
);

export default RefSectionTable;

const Container = styled.section`
  // padding: 10px;
`;
