import { FC } from "react";

import { useLocation } from "react-router-dom";

import HomeHeaders from "screens/Home/components/HomeHeaders";
import UsersHeader from "screens/Users/components/UsersHeader";

const NavBarHeaders: FC = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/" && <HomeHeaders />}
      {location.pathname === "/users" && <div className="flex items-center justify-between w-full ">{<UsersHeader />}</div>}
    </>
  );
};

export default NavBarHeaders;
