import IThemeColors from "models/ThemeColors";

export const LIGHT_THEME: IThemeColors = {
  background: "#FFFFFF",
  backgroundOpacity60: "rgba(255,255,255, 0.6)",

  absolute100: "#FFFFFF",
  absolute150: "#F8F8F8",
  absolute200: "#F0F0F0",
  absolute300: "#E3E3E3",
  absolute400: "#B8B8B8",
  absolute500: "#7D7D7D",
  absolute600: "#595959",
  absolute700: "#3D3D3D",
  absolute800: "#1F1F1F",
  absolute900: "#050505",
  absolute1000: "#000000",
  absolute1000blure50: "rgba(0, 0, 0, 0.49)",
  blue100: "#F6FBFF",
  blue200: "#DDEFFE",
  blue300: "#BFE0FE",
  blue400: "#0B4795",
  blue500: "#00306F",
  yellow100: "#FFFCF3",
  yellow200: "#FFF6D7",
  yellow300: "#FEE694",
  yellow400: "#F3BD00",
  yellow500: "#DC9700",
  green100: "#F5FFFA",
  green200: "#CFE3D9",
  green300: "#82C09E",
  green400: "#1C9152",
  green500: "#027D3B",
  red100: "#FFF9FA",
  red200: "#FFE7EB",
  red300: "#FFD2D9",
  red400: "#DB333D",
  red500: "#BF1D30",
  shadow: "#0013551c",
  bold_header: "#333333",
};

export const DARK_THEME: IThemeColors = {
  background: "#F5F5F5",
  backgroundOpacity60: "rgba(255,255,255, 0.6)",

  absolute100: "#FFFFFF",
  absolute150: "#F9F8F8",
  absolute200: "#F0F0F0",
  absolute300: "#E3E3E3",
  absolute400: "#B8B8B8",
  absolute500: "#7D7D7D",
  absolute600: "#595959",
  absolute700: "#3D3D3D",
  absolute800: "#1F1F1F",
  absolute900: "#050505",
  absolute1000: "#000000",
  absolute1000blure50: "#828282",
  blue100: "#FAFDFF",
  blue200: "#DDEFFE",
  blue300: "#BFE0FE",
  blue400: "#0B4795",
  blue500: "#00306F",
  yellow100: "#FFFCF3",
  yellow200: "#FFF6D7",
  yellow300: "#FEE694",
  yellow400: "#F3BD00",
  yellow500: "#DC9700",
  green100: "#F5FFFA",
  green200: "#CFE3D9",
  green300: "#82C09E",
  green400: "#1C9152",
  green500: "#027D3B",
  red100: "#FFF9FA",
  red200: "#FFE7EB",
  red300: "#FFD2D9",
  red400: "#DB333D",
  red500: "#BF1D30",
  shadow: "#0013551c",
  bold_header: "#333333",
};
