import { FC } from "react";
import React from "react";

import styled from "styled-components";

import { ReactComponent as ArrowDown } from "assets/arrowDown.svg";

interface IButton {
  type?: "icon" | "dropdown";
  selectType?: "tree" | "checkbox";
  icon?: React.FC;
  children?: React.ReactNode;
  paddingHorizontal?: string;
  label?: string;
  style?: any;
  className?: string;
  onClick?: () => void;
  borderFocus?: boolean;
}

const Button: FC<IButton> = (props) => {
  const Icon = props.icon;

  return (
    <ButtonContainer onClick={props.onClick} style={props.style} className={props.className} borderFocus={props.borderFocus}>
      {props.type === "icon" && <Icon />}
      {props.type === "dropdown" && (
        <LongButton className="flex justify-between items-center ">
          <ButtonText>{props.children}</ButtonText>
          <ArrowDown />
        </LongButton>
      )}
      {typeof props.type === "undefined" && props.children}
    </ButtonContainer>
  );
};

export default Button;

const ButtonContainer = styled.button<{ borderFocus: boolean }>`
  height: 43px;
  display: flex;
  align-items: center;
  padding: 7px 14px;
  width: 100%;
  border: 0;
  border-radius: 10px;
  border: 1px solid ${(props) => (props.borderFocus ? props.theme.colors.blue400 : props.theme.colors.absolute300)} !important;
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
  background-color: ${(props) => props.theme.colors.absolute100};
`;

const ButtonText = styled.div`
  padding-bottom: 4px;
  margin-right: 20px;
  @media (max-width: 1279px) {
    margin-right: 5px;
  }
`;

const LongButton = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
`;
