import React, { ChangeEvent, useEffect, useRef } from "react";

import { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { isNumber } from "lodash";
import { useQuery, useMutation } from "react-query";
import styled from "styled-components";

import { createSectionItemApi, updateObjectApi } from "api/Objects/api.objects";
import { getContractorsApi } from "api/References/api.contractor";
import { getMeasureUnitsApi } from "api/References/api.measureUnits";
import { getWorksApi } from "api/References/api.works";
import { queryClient } from "App";
import { ReactComponent as CalendarIcon } from "assets/calendar.svg";
import Input from "components/Input";
import useMeasureListBySearch from "hooks/useMeasureBySearch/useMeasureListBySearch";
import useText from "hooks/useText";
import { QueriesKeys } from "models/QueriesKeys";
import emitter from "services/emitter";
import { formatFromISODate } from "utils/getISODate";
import notify from "utils/notify";

import CalendarSelectDate from "../components/CalendarSelectDate";
import ObjectEditCalendar from "../ObjectEdit.Calendar";
import ObjectStore from "../store/Object.store";

interface IState {
  selectedContractor: { id: string; value: string } | null;
  selectedWork: { id: string; value: string } | null;
  selectedMeasures: { id: string; value: string } | null;
  selectedControl: { id: string; value: string } | null;
  selectedVolume: number | null;
  selectedCompletedVolume: number | null;
  selectedDate: { startDate: Dayjs; endDate: Dayjs } | null;
  selectedUpdatePeriod: { startDate: Dayjs; endDate: Dayjs } | null;
  selectedDays: number | null;
  updateDays: number | null;
}

interface IAction {
  type: string;
  payload?: any;
}

// eslint-disable-next-line no-shadow
export enum actionTypes {
  SET_SELECTED_CONTRACTOR = "SET_SELECTED_CONTRACTOR",
  SET_SELECTED_WORK = "SET_SELECTED_WORK",
  SET_SELECTED_MEASURES = "SET_SELECTED_MEASURES",
  SET_SELECTED_CONTROL = "SET_SELECTED_CONTROL",
  SET_SELECTED_VOLUME = "SET_SELECTED_VOLUME",
  SET_SELECTED_COMPLETED_VOLUME = "SET_SELECTED_COMPLETED_VOLUME",
  SET_SELECTED_DATE = "SET_SELECTED_DATE",
  SET_SELECTED_UPDATE_PERIOD = "SET_SELECTED_UPDATE_PERIOD",
  SET_SELECTED_DAYS = "SET_SELECTED_DAYS",
  SET_UPDATE_DAYS = "SET_UPDATE_DAYS",
  CLEAR = "CLEAR",
}

export const initialState: IState = {
  selectedContractor: null,
  selectedWork: null,
  selectedMeasures: null,
  selectedControl: null,
  selectedVolume: null,
  selectedCompletedVolume: null,
  selectedDate: { startDate: dayjs(), endDate: dayjs() },
  selectedUpdatePeriod: null,
  selectedDays: null,
  updateDays: null,
};

export function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case actionTypes.SET_SELECTED_CONTRACTOR:
      return { ...state, selectedContractor: action.payload };
    case actionTypes.SET_SELECTED_WORK:
      return { ...state, selectedWork: action.payload };
    case actionTypes.SET_SELECTED_MEASURES:
      return { ...state, selectedMeasures: action.payload };
    case actionTypes.SET_SELECTED_CONTROL:
      return { ...state, selectedControl: action.payload };
    case actionTypes.SET_SELECTED_VOLUME:
      return { ...state, selectedVolume: action.payload };
    case actionTypes.SET_SELECTED_COMPLETED_VOLUME:
      return { ...state, selectedCompletedVolume: action.payload };
    case actionTypes.SET_SELECTED_DATE:
      return { ...state, selectedDate: action.payload };
    case actionTypes.SET_SELECTED_UPDATE_PERIOD:
      return { ...state, selectedUpdatePeriod: action.payload };
    case actionTypes.SET_SELECTED_DAYS:
      return { ...state, selectedDays: action.payload };
    case actionTypes.SET_UPDATE_DAYS:
      return { ...state, updateDays: action.payload };
    case "CLEAR":
    default:
      return initialState;
  }
}

const useFormAddObjectData = () => {
  const text = useText();

  const contractors = useQuery({ queryKey: QueriesKeys.contractors, queryFn: getContractorsApi });
  const works = useQuery({ queryKey: QueriesKeys.works, queryFn: getWorksApi });
  const measures = useQuery({ queryKey: QueriesKeys.measures, queryFn: getMeasureUnitsApi });

  const createSectionItem = useMutation(createSectionItemApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.sectionItems]);
      dispatchSelectData({ type: actionTypes.CLEAR });
      setInputContractors("");
      setInputWorks("");
    },
    onError: (e: AxiosError<any, any>) => {
      notify.error(e?.response?.data?.message);
    },
  });

  const updateSectionItem = useMutation(updateObjectApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.sectionItems]);
      dispatchSelectData({ type: actionTypes.CLEAR, payload: null });
    },
  });

  const [selectedState, dispatchSelectData] = React.useReducer(reducer, initialState);
  const [isVisibleCalendar, setIsVisibleCalendar] = React.useState(false);
  const whichIsOpenCalendar = React.useRef<"startDate" | "endDate">();

  const [isVisibleModal, setIsVisibleModal] = React.useState(false);
  const [openModalPeriod, setOpenModalPeriod] = React.useState(false);
  const [isOpenModalTotalForProject, setIsOpenModalTotalForProject] = React.useState(false);
  const [isOpenModalCompletedVolume, setIsOpenModalCompletedVolume] = React.useState(false);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const canShowToast = React.useRef(false);

  const updateStartDate = ObjectStore.selectedUpdateStartPeriod && ObjectStore.selectedUpdateStartPeriod;
  const updateEndDate = ObjectStore.selectedUpdateEndPeriod && ObjectStore.selectedUpdateEndPeriod;

  useEffect(() => {
    if (updateStartDate) {
      setStartDate(formatFromISODate(updateStartDate));
    } else {
      setStartDate(selectedState.selectedUpdatePeriod && dayjs(selectedState.selectedUpdatePeriod?.startDate).format("DD.MM.YY"));
    }
    if (updateEndDate) {
      setEndDate(formatFromISODate(updateEndDate));
    } else {
      setEndDate(selectedState.selectedUpdatePeriod && dayjs(selectedState.selectedUpdatePeriod?.endDate).format("DD.MM.YY"));
    }
    if (!openModalPeriod) {
      ObjectStore.setUpdateStartPeriod(undefined);
      ObjectStore.setUpdateEndPeriod(undefined);
      ObjectStore.setUpdatePeriodRowId(undefined);
      ObjectStore.setUpdatePeriodDays(undefined);
      dispatchSelectData({
        type: actionTypes.SET_SELECTED_UPDATE_PERIOD,
        payload: null,
      });
    }
  }, [openModalPeriod, updateStartDate, updateEndDate, selectedState.selectedUpdatePeriod]);

  const openCalendar = (date: "startDate" | "endDate") => {
    whichIsOpenCalendar.current = date;
    setIsVisibleCalendar(true);

    if (!canShowToast.current) {
      canShowToast.current = true;
    }
  };

  const onSave = () => {
    let totalForTheProject = selectedState.selectedVolume?.toString();
    if (totalForTheProject?.indexOf(",")) {
      totalForTheProject = totalForTheProject.split(",").join(".");
    }

    createSectionItem.mutateAsync({
      contractorId: selectedState.selectedContractor?.id,
      controlType: "DAILY",
      days: Number(selectedState.selectedDays),
      endDate: dayjs(selectedState.selectedDate.endDate).format("YYYY-MM-DD"),
      startDate: dayjs(selectedState.selectedDate.startDate).format("YYYY-MM-DD"),
      measureUnitId: selectedState.selectedMeasures?.id,
      volume: Number(totalForTheProject),
      workId: selectedState.selectedWork?.id,
      sectionId: ObjectStore.selectedSection?.id,
    });
  };

  const onSavePeriodDate = () => {
    updateSectionItem.mutateAsync({
      id: ObjectStore.selectedUpdatePeriodRowId,
      body: {
        days: Number(selectedState.updateDays),
        startDate: selectedState?.selectedUpdatePeriod?.startDate
          ? dayjs(selectedState?.selectedUpdatePeriod?.startDate).format("YYYY-MM-DD")
          : null,
        endDate: selectedState?.selectedUpdatePeriod?.endDate
          ? dayjs(selectedState?.selectedUpdatePeriod?.endDate).format("YYYY-MM-DD")
          : null,
      },
    });
  };

  const onSaveTotalForProject = () => {
    updateSectionItem.mutateAsync({
      id: ObjectStore.selectedUpdateTotalForProjectRowId,
      body: {
        volume: selectedState.selectedVolume,
      },
    });
  };
  const onSaveCompletedVolume = () => {
    updateSectionItem.mutateAsync({
      id: ObjectStore.selectedUpdateTotalForProjectRowId,
      body: {
        completedVolume: selectedState.selectedCompletedVolume,
      },
    });
  };

  const onChangeDate = React.useCallback(
    (v) => {
      setIsVisibleCalendar(false);
      dispatchSelectData({
        type: actionTypes.SET_SELECTED_DATE,
        payload: {
          ...selectedState.selectedDate,
          [whichIsOpenCalendar.current]: dayjs(v),
        },
      });
    },
    [selectedState.selectedDate]
  );
  const onChangePeriod = React.useCallback(
    (v) => {
      setIsVisibleCalendar(false);
      ObjectStore.setUpdatePeriodDays(undefined);
      if (whichIsOpenCalendar.current === "startDate") {
        ObjectStore.setUpdateStartPeriod(undefined);
      }
      if (whichIsOpenCalendar.current === "endDate") {
        ObjectStore.setUpdateEndPeriod(undefined);
      }
      dispatchSelectData({
        type: actionTypes.SET_SELECTED_UPDATE_PERIOD,
        payload: {
          ...selectedState.selectedUpdatePeriod,
          [whichIsOpenCalendar.current]: dayjs(v),
        },
      });
    },
    [selectedState.selectedUpdatePeriod]
  );

  useEffect(() => {
    const startPeriod = dayjs(selectedState?.selectedDate?.startDate);
    const endPeriod = dayjs(selectedState?.selectedDate?.endDate);
    const diff = dayjs(endPeriod.format("YYYY-MM-DD")).diff(dayjs(startPeriod.format("YYYY-MM-DD")), "day");
    if (diff < 0) {
      dispatchSelectData({ type: actionTypes.SET_SELECTED_DAYS, payload: "Неверно заполнен период" });
    } else {
      dispatchSelectData({ type: actionTypes.SET_SELECTED_DAYS, payload: diff + 1 });
    }
  }, [selectedState.selectedDate]);

  useEffect(() => {
    const startPeriod = selectedState?.selectedUpdatePeriod?.startDate
      ? dayjs(selectedState?.selectedUpdatePeriod?.startDate)
      : updateStartDate;
    const endPeriod = selectedState?.selectedUpdatePeriod?.endDate ? dayjs(selectedState?.selectedUpdatePeriod?.endDate) : updateEndDate;
    const diff = dayjs(endPeriod).diff(dayjs(startPeriod), "day");
    if (diff < 0) {
      dispatchSelectData({ type: actionTypes.SET_UPDATE_DAYS, payload: "Неверно заполнен период" });
    } else {
      dispatchSelectData({ type: actionTypes.SET_UPDATE_DAYS, payload: diff + 1 });
    }
  }, [selectedState.selectedUpdatePeriod]);

  const closeCalendar = () => setIsVisibleCalendar(false);

  const handleSelectedDays = (e) => {
    const startPeriod = dayjs(selectedState?.selectedDate?.startDate);
    const endPeriod = dayjs(selectedState?.selectedDate?.endDate);
    const diff = dayjs(endPeriod).diff(dayjs(startPeriod), "day");
    if (e.target.value > diff + 2) {
      dispatchSelectData({ type: actionTypes.SET_SELECTED_DAYS, payload: diff + 2 });
    } else {
      dispatchSelectData({ type: actionTypes.SET_SELECTED_DAYS, payload: e.target.value });
    }
  };
  const handleUpdateDays = (e) => {
    ObjectStore.setUpdatePeriodDays(undefined);
    const startPeriod = selectedState?.selectedUpdatePeriod?.startDate
      ? dayjs(selectedState?.selectedUpdatePeriod?.startDate)
      : updateStartDate;
    const endPeriod = selectedState?.selectedUpdatePeriod?.endDate ? dayjs(selectedState?.selectedUpdatePeriod?.endDate) : updateEndDate;
    const diff = dayjs(endPeriod).diff(dayjs(startPeriod), "day");
    if (e.target.value > diff + 1) {
      dispatchSelectData({ type: actionTypes.SET_UPDATE_DAYS, payload: diff + 1 });
    } else {
      const limitedValue = Number(e.target.value) < 0 ? "1" : e.target.value;
      dispatchSelectData({ type: actionTypes.SET_UPDATE_DAYS, payload: limitedValue });
    }
  };

  const handleUpdateTotalForProject = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (isNaN(Number(value))) {
      dispatchSelectData({ type: actionTypes.SET_SELECTED_VOLUME, payload: selectedState.selectedVolume });
    } else {
      dispatchSelectData({ type: actionTypes.SET_SELECTED_VOLUME, payload: Number(value) < 0 ? "1" : value });
    }

    const limitedValue = Number(e.target.value) < 0 ? "1" : e.target.value;
    dispatchSelectData({ type: actionTypes.SET_SELECTED_VOLUME, payload: limitedValue });
  };
  const handleUpdateCompletedValue = (e: ChangeEvent<HTMLInputElement>) => {
    const limitedValue = Number(e.target.value) < 0 ? "0" : e.target.value;
    dispatchSelectData({ type: actionTypes.SET_SELECTED_COMPLETED_VOLUME, payload: limitedValue });
  };

  const RenderCalendarInputs = React.useCallback(
    () => (
      <CalendarInputs
        isVisibleCalendar={isVisibleCalendar}
        closeCalendar={closeCalendar}
        onChangeDate={onChangeDate}
        startDate={dayjs(selectedState.selectedDate?.startDate).format("DD.MM.YY")}
        endDate={dayjs(selectedState.selectedDate?.endDate).format("DD.MM.YY")}
        openCalendar={openCalendar}
      />
    ),
    [isVisibleCalendar, selectedState.selectedDate]
  );

  //============== Search Elements Of References ======================================

  const [searchInputContractors, setInputContractors] = React.useState<string | undefined>();
  const [searchInputWorks, setInputWorks] = React.useState<string | undefined>();
  const [searchedContractors, setSearchedContractors] = React.useState(
    contractors.data?.map((item) => ({ value: item.name, id: item.id })) ?? []
  );
  const [searchedWorks, setSearchedWorks] = React.useState(works.data?.map((work) => ({ value: work.name, id: work.id })) ?? []);

  const refSelectedContractors = useRef(false);
  const refSelectedWorks = useRef(false);
  const refSelectedMeasures = useRef(false);

  React.useEffect(() => {
    if (searchInputContractors) {
      setSearchedContractors(
        contractors?.data
          ?.map((item) => ({ value: item.name, id: item.id }))
          .filter((item) => item.value.toLowerCase().includes(searchInputContractors?.toLowerCase()))
      );
    } else {
      setSearchedContractors(contractors.data?.map((item) => ({ value: item.name, id: item.id })) ?? []);
    }

    if (searchInputWorks) {
      setSearchedWorks(
        works?.data
          ?.map((item) => ({ value: item.name, id: item.id }))
          .filter((item) => item.value.toLowerCase().includes(searchInputWorks?.toLowerCase()))
      );
    } else {
      setSearchedWorks(works.data?.map((item) => ({ value: item.name, id: item.id })) ?? []);
    }

    emitter.addListener("selected_item", (data) => {
      if (data.item.typeList?.startsWith("Подрядчик")) {
        setInputContractors(data.item.value);
        refSelectedContractors.current = true;
      }
      if (data.item.typeList?.startsWith("Наименование работы")) {
        setInputWorks(data.item.value);
        refSelectedWorks.current = true;
      }
    });
    emitter.addListener("selected_clear", (data) => {
      if (data?.typeList?.startsWith("Подрядчик")) {
        refSelectedContractors.current = false;
      }
      if (data?.typeList?.startsWith("Наименование работы")) {
        refSelectedWorks.current = false;
      }
      if (data?.typeList?.startsWith("Ед. изм")) {
        refSelectedMeasures.current = false;
      }
    });
    emitter.addListener("close_modal", () => {
      setInputContractors("");
      setInputWorks("");
      dispatchSelectData({ type: actionTypes.CLEAR });
    });
    emitter.addListener("close_away_dropdown", () => {
      if (!refSelectedContractors.current) {
        setSearchedContractors(contractors.data?.map((item) => ({ value: item.name, id: item.id })) ?? []);
        setInputContractors("");
      }
      if (!refSelectedWorks.current) {
        setSearchedWorks(works.data?.map((work) => ({ value: work.name, id: work.id })) ?? []);
      }
    });
  }, [
    searchInputContractors,
    searchInputWorks,
    // searchInputMeasures,
    refSelectedContractors.current,
    refSelectedWorks.current,
    refSelectedMeasures.current,
    // measures.data,
    works.data,
    contractors.data,
  ]);

  const onSearchedContractors = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputContractors(event.target.value);
    refSelectedContractors.current = false;
  };
  const onSearchedWorks = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputWorks(event.target.value);
    refSelectedWorks.current = false;
  };

  //============== MapForms for Modal =============================================

  const addObjectForm = React.useMemo(
    () => [
      {
        type: "select",
        selectData: searchedContractors,
        // selectData: contractors.data?.map((item) => ({ value: item.name, id: item.id })) ?? [],
        selectPlaceholder: "Подрядчик",
        topPlaceholder: "Подрядчик",
        isFullList: true,
        addNewElement: {
          text: text.addContractor,
          type: "Contractor",
          onChangeInputSearch: onSearchedContractors,
          valueInputSearch: searchInputContractors,
        },
        onSelect: (item) => {
          dispatchSelectData({ type: actionTypes.SET_SELECTED_CONTRACTOR, payload: item });
        },
      },
      {
        type: "select",
        selectData: searchedWorks,
        // selectData: works.data?.map((work) => ({ value: work.name, id: work.id })) ?? [],
        selectPlaceholder: "Наименование работы",
        topPlaceholder: "Наименование работы",
        isFullList: true,
        addNewElement: { text: text.addWorkTypes, type: "Work", onChangeInputSearch: onSearchedWorks, valueInputSearch: searchInputWorks },
        onSelect: (item) => {
          dispatchSelectData({ type: actionTypes.SET_SELECTED_WORK, payload: item });
          const currentWork = works.data.find((work) => work.id === item.id);
          dispatchSelectData({ type: actionTypes.SET_SELECTED_MEASURES, payload: currentWork.measureUnit });
        },
        onClearSelectedValueCallback: () => {
          dispatchSelectData({ type: actionTypes.SET_SELECTED_MEASURES });
        },
      },
      {
        type: "input",
        disabled: true,
        placeholder: "Ед. изм",
        value: selectedState.selectedMeasures?.value ?? "",
      },
      //TODO: =======  Temporary hiding ============
      // {
      //   type: "select",
      //   selectData: [
      //     { value: "Eжедневный", id: "DAILY" },
      //     // { value: "Еженедельный", id: "WEEKLY" },
      //   ],
      //   selectPlaceholder: "Контроль",
      //   topPlaceholder: "Контроль",
      //   isFullList: true,
      //   onSelect: (item) => dispatchSelectData({ type: actionTypes.SET_SELECTED_CONTROL, payload: item }),
      // },
      {
        type: "input",
        placeholder: "Всего по проекту",
        value: selectedState.selectedVolume,
        label: "Всего по проекту",
        onChange: (e) => {
          const value = e.target.value;

          if (isNaN(Number(value)) || Number(value) < 0) {
            dispatchSelectData({ type: actionTypes.SET_SELECTED_VOLUME, payload: selectedState.selectedVolume });
          } else {
            dispatchSelectData({ type: actionTypes.SET_SELECTED_VOLUME, payload: value });
          }
        },
      },
      {
        type: "custom",
        Component: () => <ByGPRText>По ГПР</ByGPRText>,
      },
      {
        type: "custom",
        Component: RenderCalendarInputs,
      },
      {
        type: "input",
        placeholder: "Количество дней",
        value: selectedState.selectedDays,
        label: "Количество дней",
        onChange: (e) => handleSelectedDays(e),
      },
    ],
    [
      text,
      isVisibleCalendar,
      measures,
      works.data,
      contractors.data,
      selectedState.selectedDays,
      searchedContractors,
      searchInputContractors,
      searchedWorks,
      selectedState.selectedVolume,
    ]
  );

  const updatePeriodForm = React.useMemo(
    () => [
      {
        type: "custom",
        Component: () => <ByGPRText>По ГПР</ByGPRText>,
      },
      {
        type: "custom",
        Component: () => (
          <CalendarSelectDate
            isVisibleCalendar={isVisibleCalendar}
            closeCalendar={closeCalendar}
            onChangeDate={onChangePeriod}
            startDate={startDate}
            endDate={endDate}
            openCalendar={openCalendar}
          />
        ),
      },
      {
        type: "input",
        placeholder: "Количество дней",
        value: ObjectStore.selectedUpdatePeriodDays ?? selectedState.updateDays,
        label: "Количество дней",
        onChange: (e) => handleUpdateDays(e),
        typeInput: "number",
      },
    ],
    // eslint-disable-next-line no-shadow
    [
      ObjectStore.selectedUpdatePeriodDays,
      isVisibleCalendar,
      startDate,
      endDate,
      selectedState.selectedUpdatePeriod,
      selectedState.updateDays,
    ]
  );

  const updateTotalForProjectForm = React.useMemo(
    () => [
      {
        type: "input",
        placeholder: "Всего по проекту",
        value: selectedState.selectedVolume ?? ObjectStore.selectedTotalForProject,
        label: "Всего по проекту",
        onChange: handleUpdateTotalForProject,
        typeInput: "number",
      },
    ],
    // eslint-disable-next-line no-shadow
    [ObjectStore.selectedTotalForProject, selectedState.selectedVolume]
  );

  const updateCompletedVolumeForm = React.useMemo(
    () => [
      {
        type: "input",
        placeholder: "Объем",
        value: selectedState.selectedCompletedVolume ?? ObjectStore.selectedCompletedVolume,
        label: "Объем",
        onChange: handleUpdateCompletedValue,
        typeInput: "number",
      },
    ],
    // eslint-disable-next-line no-shadow
    [ObjectStore.selectedCompletedVolume, selectedState.selectedCompletedVolume]
  );

  return {
    data: {
      addObjectForm,
      updatePeriodForm,
      updateTotalForProjectForm,
      updateCompletedVolumeForm,
    },
    fn: {
      onSave,
      onSavePeriodDate,
      onSaveTotalForProject,
      onSaveCompletedVolume,
      setIsVisibleModal,
      setOpenModalPeriod,
      setIsOpenModalTotalForProject,
      setIsOpenModalCompletedVolume,
    },
    vars: {
      isVisibleModal,
      openModalPeriod,
      isOpenModalTotalForProject,
      isOpenModalCompletedVolume,
      isLoading: createSectionItem.isLoading,
      isLoadingUpdateSection: updateSectionItem.isLoading,
      errorUpdateSection: updateSectionItem.error,
      errorCreationSection: createSectionItem.error,
    },
  };
};

export default useFormAddObjectData;

const ByGPRText = styled.p`
  color: ${(props) => props.theme.colors.absolute700};
  font-weight: 700;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

const CalendarInputs = React.memo(
  (props: {
    isVisibleCalendar: boolean;
    closeCalendar: () => void;
    onChangeDate: (v: any) => void;
    startDate: string;
    endDate: string;
    openCalendar: (date: "startDate" | "endDate") => void;
  }) => (
    <>
      {props.isVisibleCalendar && <ObjectEditCalendar closeCalendar={props.closeCalendar} onChange={props.onChangeDate} />}
      <CalendarContainer>
        <Input
          value={props.startDate}
          style={{ width: "120px" }}
          placeholder="Начало"
          icon={CalendarIcon}
          onClickAllField={() => props.openCalendar("startDate")}
          readOnly
          label="Начало"
        />
        <Input
          value={props.endDate}
          style={{ width: "120px" }}
          placeholder="Конец"
          icon={CalendarIcon}
          onClickAllField={() => props.openCalendar("endDate")}
          readOnly
          label="Конец"
        />
      </CalendarContainer>
    </>
  ),
  (prevProps, nextProps) =>
    // Compare the relevant props to determine if re-render is needed
    prevProps.isVisibleCalendar === nextProps.isVisibleCalendar &&
    prevProps.startDate === nextProps.startDate &&
    prevProps.endDate === nextProps.endDate
);
