import React, { FC, useEffect } from "react";

import classNames from "classnames";
import styled from "styled-components";

import { RenderWithCondition } from "hoc/RenderWithCondition";
import emitter from "services/emitter";
import { SpanColored } from "styles/common";

interface IInfoTooltip {
  type: "sm" | "md";
  text?: string;
  colorText?: string;
  active?: boolean;
  vertical?: boolean;
  lenText?: number;
  alignInitialText?: "start" | "center" | "end";
  top?: number;
  children?: JSX.Element;
}

const InfoTooltip: FC<IInfoTooltip> = (props) => {
  const [isOpenToolTip, setOpenToolTip] = React.useState(false);
  const [shortText, setShortText] = React.useState("");

  const addSpaceText = props.text?.slice(0, 14).includes(" ") ? props.text : `${props.text?.slice(0, 9)} ${props.text?.slice(9)}`;

  const mouseHoverIn = () => {
    setOpenToolTip(true);
  };

  const mouseHoverOut = () => {
    setOpenToolTip(false);
  };

  const tooltipText = props.vertical ? props.text?.split(",") : props.text?.split(",");

  useEffect(() => {
    if (props.lenText) {
      if (props.text?.length > props.lenText) {
        setShortText(`${props.text.substring(0, props.lenText)}...`);
      }
    } else {
      setShortText("l");
    }
  }, []);

  useEffect(() => {
    emitter.addListener("trigger_collapse_main_table", mouseHoverOut);
    return () => {
      emitter.removeListener("trigger_collapse_main_table", mouseHoverOut);
    };
  }),
    [];

  return (
    <div
      className={classNames(
        "flex relative h-full items-center  w-full",
        props.alignInitialText ? `justify-${props.alignInitialText}` : "justify-center "
      )}
      onMouseOver={mouseHoverIn}
      onMouseOut={mouseHoverOut}
    >
      {props.lenText && <p>{props.text?.length > props.lenText ? `${addSpaceText.substring(0, props.lenText - 3)}...` : props.text}</p>}
      {props.children}
      <ToolTipContainer
        className="flex absolute"
        active={isOpenToolTip && !!shortText}
        type={props.type}
        top={props.top}
        vertical={props.vertical}
      >
        {tooltipText?.map((text) => (
          <>
            <RenderWithCondition condition={!props.colorText}>
              <p>{text}</p>
            </RenderWithCondition>
            <RenderWithCondition condition={props.colorText}>
              <SpanColored color={props.colorText}>
                <p>{text}</p>
              </SpanColored>
            </RenderWithCondition>
          </>
        ))}
      </ToolTipContainer>
    </div>
  );
};

export default InfoTooltip;

const ToolTipContainer = styled.div<{ active: boolean; type: string; top: number; vertical?: boolean }>`
  align-items: ${(props) => (props.vertical ? "left" : "center")};
  top: ${(props) => (props.top ? `${props.top}px` : "0px")};
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  left: 105%;
  padding: 7px 7px;
  white-space: nowrap;
  z-index: 11;
  border-radius: ${(props) => (props.type === "sm" ? "5px" : "10px")};
  font-size: ${(props) => (props.type === "sm" ? "12px" : "14px")};
  height: ${(props) => {
    if (props.vertical) {
      return "fit-content";
    }
    return props.type === "sm" ? "26px" : "40px";
  }};
  scale: ${(props) => (props.active ? 1 : 0)};
  transition: all 0.4s;
  background-color: ${(props) => props.theme.colors.absolute100};
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
`;
