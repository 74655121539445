import React, { FC, useEffect, useRef } from "react";

import { isEmpty, uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { LocalLoader } from "components/Loader/LocalLoader";
import TreeStore from "components/Tree/Tree.store";
import useTableHistogram, { IDataDay } from "screens/Home/hooks/useTableHistogram";
import HomeChartsStore from "screens/Home/store/Home.Charts.store";
import { Container, Screen } from "styles/common";

import WorkItem, { IHistogramsData } from "./components/WorkItem";

interface IHistogramsScreen {
  fullWidth?: boolean;
}

const HistogramsScreen: FC<IHistogramsScreen> = observer(() => {
  const isFirstEnter = useRef(false);
  const histogramData = useTableHistogram({ isFirstEnter: isFirstEnter.current });
  const { tableHistogramData, isLoading, isAnyChecked, isEmptyWorks } = histogramData.data;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && tableHistogramData) {
      HomeChartsStore.setWorksData(tableHistogramData);
    }
  }, [isLoading]);

  const onButtonFillClick = () => {
    navigate("/objects_edit");
  };

  const mapData = isEmpty(tableHistogramData) ? HomeChartsStore.worksData : tableHistogramData;

  const isVisibleEmptyPlaceholder = isEmptyWorks && TreeStore.selectedObject?.meta?.area === "area";
  const isVisibleWorks = !isVisibleEmptyPlaceholder && isAnyChecked;

  return (
    <Screen className="w-full">
      <Container className="flex flex-col w-full container mx-auto">
        {isVisibleEmptyPlaceholder && (
          <EmptyPlaceholder
            header={"У вас еще нет данных по объектам"}
            description={"Вам нужно заполнить всю необходимою информацию по объектам"}
            buttonText={"Заполнить"}
            onButtonClick={onButtonFillClick}
          />
        )}

        {isVisibleWorks &&
          mapData?.map(
            (
              work: {
                tableLeftRightData: IDataDay[];
                cellHistogramData: any;
                barChartData: IHistogramsData[];
                lineChartData: IHistogramsData[];
                isProduction: number;
                isDailyPeriods: boolean;
                isCumulativePeriods: boolean;
              },
              idx
            ) => (
              <WorkItem
                data={work.tableLeftRightData}
                dataCells={work.cellHistogramData}
                dataLineChart={work.lineChartData}
                dataBarChart={work.barChartData}
                factorScale={work.isProduction}
                isDailyPeriods={work.isDailyPeriods}
                isCumulativePeriods={work.isCumulativePeriods}
              />
            )
          )}
        {!isAnyChecked && (
          <EmptyBlockContainer>
            <EmptyPlaceholder description={"Выберите литеры и работы"} header={""} />
          </EmptyBlockContainer>
        )}

        <LocalLoader condition={isLoading} />
      </Container>
    </Screen>
  );
});

export default HistogramsScreen;

const EmptyBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 18px;
`;
