import React, { FC } from "react";

import { pullAt } from "lodash";
import { observer } from "mobx-react-lite";
import { styled } from "styled-components";

import { LocalLoader } from "components/Loader/LocalLoader";
import { dataLitersMock, dataLitersMockCollapse, mapCellData, mapCellDataCollapse } from "mock/charts.mock";
import useTableHistogram from "screens/Home/hooks/useTableHistogram";
import HomeChartsStore from "screens/Home/store/Home.Charts.store";
import CalendarStore from "store/Calendar.Store";

import CalendarHeaderChart from "./CalendarHeader.Chart";
import DaysCellChart from "./DaysCell.Chart";
import LeftTableChart from "./LeftTable.Chart";
import RightTableChart from "./RightTable.Chart";

interface IMainTableCharts {
  fullWidth?: boolean;
  dataTable: any;
  dataCells: any;
}

const MainTableCharts: FC<IMainTableCharts> = observer((props) => {
  const histogramData = useTableHistogram();
  const { isLoading } = histogramData.data;

  const daysOfWeek = props.dataCells;

  const calendarDaysData = CalendarStore.isHiddenLiters ? pullAt([...daysOfWeek], [daysOfWeek.length - 1]) : daysOfWeek;
  const literTableData = CalendarStore.isHiddenLiters
    ? pullAt([...props.dataTable], [props.dataTable.length - 2, props.dataTable.length - 1])
    : props.dataTable;

  return (
    <div className="flex w-full relative">
      <div className="flex w-[38%]">
        <LeftTableChart data={literTableData} />
      </div>
      <CalendarContainer className="flex flex-col min-w-[430px] w-[40%]">
        <CalendarHeaderChart />
        {calendarDaysData.map((period, i) => (
          <DaysCellChart key={period.sectionItemId + i} {...period} />
        ))}
      </CalendarContainer>
      <div className="flex w-[22%]">
        <RightTableChart data={literTableData} />
      </div>
      <LocalLoader condition={isLoading} withoutBlur />
    </div>
  );
});

export default MainTableCharts;

const CalendarContainer = styled.div`
  margin-right: -2px;
`;
