import React, { FC } from "react";

import { isEmpty } from "lodash";

import TreeNode, { INode } from "./Tree.Node";

export type TOnNodeClick = (node: INode) => void;
export type TOnNodeCheck = (node: INode[], checked?: boolean, innerText?: string) => void;
export type TAddedMode = "added" | "checkbox";

interface ITree {
  nodes: INode[];
  onNodeClick: TOnNodeClick;
  onNodeChange?: TOnNodeCheck;
  mode?: TAddedMode;
  checkedElements?: any[];
  addInputProps?: React.HTMLProps<HTMLInputElement>;
  onAddNewObject?: (node: INode) => void;
  hightlightedNodeIds?: (string | undefined)[];
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  modalData?: {
    [key: string]: {
      label: string;
      data: any[];
    };
  };
  onSaveModalData?: (closeModalCb: () => void) => void;
  isLoadingInModal?: boolean;
  isErrorInModal?: boolean;
  coloredSearchLetter?: string;
}

export const getTreeNodeById = (nodes: INode[], id: string): INode | undefined => {
  if (!nodes) {
    return;
  }
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }
    if (!isEmpty(node.children)) {
      const foundNode = getTreeNodeById(node.children, id);
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return undefined;
};

export const getFullPathById = (nodes: INode[], id: string): string | undefined => {
  let res = "";
  const getStr = (arrNodes: INode[], parentId: string) => {
    const node = getTreeNodeById(arrNodes, parentId);
    res = `${node.name}, ${res}`;
    if (node.meta.parentId) {
      getStr(arrNodes, node.meta.parentId);
    }
  };
  getStr(nodes, id);
  return res;
};

const Tree: FC<ITree> = (props) => (
  <div className="flex flex-col">
    {props.nodes?.map((node) => (
      <TreeNode
        key={node.id}
        onNodeClick={props.onNodeClick}
        onNodeChange={props.onNodeChange}
        node={node}
        checked={true}
        mode={props.mode}
        addInputProps={props.addInputProps}
        onAddNewObject={props.onAddNewObject}
        parents={[node]}
        checkedElements={props.checkedElements}
        hightlightedNodeIds={props.hightlightedNodeIds ?? []}
        modalData={props.modalData}
        onSaveModalData={props.onSaveModalData}
        isErrorInModal={props.isErrorInModal}
        isLoadingInModal={props.isLoadingInModal}
        coloredSearchLetter={props.coloredSearchLetter}
      />
    ))}
  </div>
);

export default Tree;
