import apiClient from "api/api.client";
import { IBuilding } from "models/Objects/IBuilding";

export type TCreateBuildingsApi = (args: {
  body: {
    areaId: string;
    name: string;
  }[];
}) => Promise<IBuilding[]>;

export const createBuildingsApi: TCreateBuildingsApi = async (args) => {
  try {
    const res = await apiClient.post("objects/v1/buildings/list", args.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetBuildingsApi = (params: { areaId: string }) => Promise<IBuilding[]>;

export const getBuildingsApi: TGetBuildingsApi = async (params) => {
  try {
    const res = await apiClient.get("objects/v1/buildings", { params });
    const modifiedResult = res.data.map((item) => ({ ...item, parentId: params.areaId }));

    return modifiedResult;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateBuildingApi = (payload: { body: { name: string }; path: { id: string } }) => Promise<Omit<IBuilding, "parentId">>;

export const updateBuildingApi: TUpdateBuildingApi = async (payload) => {
  try {
    const res = await apiClient.patch(`objects/v1/buildings/${payload.path.id}`, payload.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteBuildingApi = (payload: { path: { id: string } }) => Promise<void>;

export const deleteBuildingApi: TDeleteBuildingApi = async (payload) => {
  try {
    const res = await apiClient.delete(`objects/v1/buildings/${payload.path.id}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
