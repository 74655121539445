import { FC } from "react";
import React from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import Dropdown, { IDropdownItem } from "components/Dropdown/Dropdown";
import { INode } from "components/Tree/Tree.Node";
import TreeStore from "components/Tree/Tree.store";
import { lessThan1023 } from "constants/mediaQuery";
import useObjectNames from "hooks/useObjectNames";
import useTreeData from "hooks/useTreeData/useTreeData";
import emitter from "services/emitter";

import HomeStore from "../store/Home.store";

import CalendarHeader from "./Calendar/Calendar.Header";
import MainHomeHeaderCharts from "./MainHome.Header.Charts";
import MainHomeHeader from "./MainTable/MainHome.Header";

const findFirstComplex = (nodes: INode[]): INode | undefined => {
  if (!nodes) {
    return;
  }
  for (const node of nodes) {
    if (node.meta.area === "complex") {
      return node;
    }
  }

  return undefined;
};

const HomeHeaders: FC = observer(() => {
  const {
    data: treeData,
    areas: { sections },
    isLoading,
  } = useTreeData([], ["building"]);
  const [selectedBuildingName, setSelectedBuildingName] = React.useState<string | undefined>();
  const objectNames = useObjectNames({ withoutSection: true });

  const [isHiddenCalendarHeader, setIsHiddenCalendarHeader] = React.useState(false);

  React.useEffect(() => {
    emitter.addListener("trigger_collapse_calendar_table", (isHidden) => {
      setIsHiddenCalendarHeader(isHidden);
    });
  }, []);

  React.useEffect(() => {
    HomeStore.setIsLoadedTree(!isLoading);
    if (TreeStore.selectedComplex) {
      const complexName = TreeStore.selectedComplex?.name;
      const areaName = TreeStore.selectedArea?.meta.parentId === TreeStore.selectedComplex?.id && TreeStore.selectedArea?.name;
      const buildingName = TreeStore.selectedBuilding?.meta?.parentId === TreeStore.selectedArea?.id && TreeStore.selectedBuilding?.name;

      const names = Array.from(new Set([complexName, areaName, buildingName].filter((name) => name)));

      setSelectedBuildingName(names.join(", "));
    } else {
      if (!isLoading) {
        const complex = findFirstComplex(treeData);
        if (complex) {
          TreeStore.setComplex(findFirstComplex(treeData));
          TreeStore.setSelectedObject(complex);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TreeStore.selectedComplex, TreeStore.selectedArea, TreeStore.selectedBuilding, TreeStore.selectedObject, treeData]);

  const setBuilding = (item: INode, closeDropdownCallback: () => void) => {
    const nodeArea = item.meta.area;
    if (nodeArea === "complex") {
      TreeStore.setSelectedObject(item);
      TreeStore.setComplex(item);
    }
    if (nodeArea === "area") {
      TreeStore.setArea(item);
      TreeStore.setSelectedObject(item);
    }
    if (nodeArea === "building") {
      TreeStore.setBuilding(item);
      TreeStore.setSelectedObject(item);
    }
    closeDropdownCallback();
  };

  const openChartsScreen = TreeStore.selectedObject?.meta?.area === "area";

  if (lessThan1023.matches) {
    return (
      <Group className="w-full">
        <div className="w-full">
          <Dropdown
            type="tree-select"
            data={treeData as unknown as IDropdownItem[]}
            onChange={setBuilding as any}
            buttonChangeClassName="w-full"
            selectedValue={selectedBuildingName ?? "Выберите участок"}
          />
        </div>
      </Group>
    );
  }

  return (
    <div className="flex items-center justify-between w-full gap-4">
      <MainHomeHeader
        treeData={treeData}
        selectedBuildingName={objectNames.join(", ")}
        setBuilding={setBuilding}
        isHiddenGroup={!openChartsScreen}
        sections={sections.filter((section) => section.parentId === TreeStore.selectedBuilding?.id)}
      />
      {openChartsScreen && (
        <MainHomeHeaderCharts treeData={treeData} selectedBuildingName={selectedBuildingName} setBuilding={setBuilding} />
      )}
      {!isHiddenCalendarHeader && !openChartsScreen && TreeStore.selectedArea && <CalendarHeader />}
    </div>
  );
});

export default HomeHeaders;

const Group = styled.div`
  display: flex;
`;
