import React, { FC } from "react";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { QueriesKeys } from "models/QueriesKeys";
import AuthStore from "store/Auth.Store";

const Callback: FC = () => {
  const urlObj = new URL(window.location.href);
  const searchParams = urlObj.searchParams;
  const authCode = searchParams.get("code");

  const authenticated = useQuery({ queryKey: QueriesKeys.authorization, enabled: !!authCode, queryFn: AuthStore.fetchTokens(authCode) });

  const navigate = useNavigate();

  React.useEffect(() => {
    if (authenticated.data && authenticated.isSuccess) {
      AuthStore.saveTokens(authenticated.data);
      navigate("/");
    }

    if (!authCode) {
      navigate("/auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return <p>Loading...</p>;
};

export default Callback;
