import React, { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { last, union, uniq } from "lodash";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";

import { createUserApi, getRolesApi, updatePasswordApi, updateUserApi } from "api/Users/api.users";
import { queryClient } from "App";
import ButtonLight from "components/ButtonLight";
import { LocalLoader } from "components/Loader/LocalLoader";
import ModalForm from "components/Modal/ModalForm";
import { INode } from "components/Tree/Tree.Node";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import useTreeData from "hooks/useTreeData/useTreeData";
import { ErrorsCode } from "models/ErrorsCode";
import { QueriesKeys } from "models/QueriesKeys";
import { IUserRole } from "models/Users/IUser";
import { Screen } from "styles/common";
import notify from "utils/notify";

import UsersTable from "./components/UsersTable";
import UsersStore from "./store/Users.store";

const FULL_SCREEN_TABLE_SIZE = "inherit";

const Users = observer(() => {
  const [tableSize] = React.useState(FULL_SCREEN_TABLE_SIZE);

  const restrictedData = useRestrictedAccessData();

  const [openModalUser, setOpenModalUser] = useState(false);
  const [openModalChapter, setOpenModalChapter] = useState(false);
  const [openModalPassword, setOpenModalPassword] = useState(false);

  const [selectedRole, setSelectedRole] = React.useState<any>();
  const [currentUserId, setCurrentUserId] = React.useState("");
  const [userSection, setUserSection] = React.useState([]);

  const [lastName, setLastName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [middleName, setMiddleName] = React.useState("");
  const [login, setLogin] = React.useState("");

  const [newPassword, setNewPassword] = React.useState("");
  const [rePassword, setRePassword] = React.useState("");

  const [chapters, setChapters] = React.useState<string[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [filteredTreeData, setFilteredTreeData] = React.useState([]);

  const text = useText();

  const roles = useQuery({ queryKey: QueriesKeys.roles, queryFn: getRolesApi });
  const treeData = useTreeData([], ["section"]);

  const createUser = useMutation(createUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.users);
      setLogin("");
      setLastName("");
      setFirstName("");
      setMiddleName("");
      setSelectedRole(undefined);
    },
    onError: (data: AxiosError<any>) => {
      const error = data.response.data;
      if (error.status === ErrorsCode.Bad_Request && error.message) {
        notify.error(data.response.data.message);
        //TODO: ====== убрать includes когда исправят ошибку на бэке ==========
      } else {
        if (error.message.includes("уже существует")) {
          notify.error("Пользователь с таким номером телефона уже существует");
        } else {
          notify.error("Что то пошло не так ... попробуйте позже");
        }
      }
    },
  });

  const updateUser = useMutation(updateUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.users);
    },
  });

  const updatePassword = useMutation(updatePasswordApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.users);
    },
  });
  const handleSearchObject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  //TODO: временно скрыть CHIEF_ENGINEER
  const data = roles?.data ? roles?.data.filter((role) => !(role.name === "CHIEF_ENGINEER" || role.name === "CHIEF_ADMINISTRATOR")) : [];

  useEffect(() => {
    if (searchText) {
      setFilteredTreeData(treeData.data.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase())));
    } else {
      setFilteredTreeData(treeData.data);
    }
    if (!openModalChapter) {
      setSearchText("");
    }
  }, [searchText, treeData.data, openModalChapter]);

  const mapFormChapter = React.useMemo(
    () => [
      { type: "search", onSearch: handleSearchObject, searchText: searchText, placeholder: text.fullName, dataTree: filteredTreeData },
    ],
    [searchText, filteredTreeData, text]
  );

  const mapRoles = (arr: IUserRole[]) => arr.map((roleFromApi) => ({ value: roleFromApi.description, label: roleFromApi.name }));
  const isPermissiveRole = (role: string) => !(role === "AREA_HEAD" || role === "DATA_OPERATOR");

  const mapFormUser = React.useMemo(
    () => [
      {
        type: "input",
        // placeholder: text.lastName,
        label: text.lastName,
        redStarSign: text.lastName,
        value: lastName,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value),
      },
      {
        type: "input",
        // placeholder: text.firstName,
        label: text.firstName,
        value: firstName,
        redStarSign: text.firstName,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value),
      },
      { type: "input", placeholder: text.middleName, onChange: (e: React.ChangeEvent<HTMLInputElement>) => setMiddleName(e.target.value) },
      {
        type: "select",
        selectPlaceholder: text.role,
        selectData: mapRoles(restrictedData.data.roles),
        isFullList: true,
        onSelect: setSelectedRole,
      },
      {
        type: "input",
        placeholder: text.login,
        mask: "+7 (999) 999 99 99",
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value),
      },
      {
        type: "tree-select-checkbox",
        selectPlaceholder: text.chapter,
        placeholder: text.fullName,
        isFullList: true,
        selectedValue: "selectedRole",
        dataTree: treeData.data,
      },
    ],
    [text, roles, treeData.data, restrictedData.data.roles]
  );

  const mapFormPassword = React.useMemo(
    () => [
      { type: "input", placeholder: text.password, onChange: (e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value) },
      {
        type: "input",
        placeholder: text.repeatPassword,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setRePassword(e.target.value),
      },
    ],
    [text]
  );

  const handleOpenModal = () => {
    setOpenModalUser(true);
  };

  const handleOpenModalChapter = (value: any) => {
    setUserSection(value.sections);
    setCurrentUserId(value.id);
    setChapters(value.sections);
    setOpenModalChapter(true);
  };

  useEffect(() => {
    if (!openModalChapter && !openModalUser) {
      setChapters([]);
      setSelectedRole(undefined);
    }
  }, [openModalChapter, openModalUser]);

  const handleOpenModalPassword = (value: any) => {
    setCurrentUserId(value.uaaId);
    setOpenModalPassword(true);
  };

  const onSaveUser = () => {
    const regex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    if (!login || !regex.test(login)) {
      notify.error("Номер телефона не заполнен");
    }
    if (!selectedRole) {
      notify.error("Выберите роль");
    }
    if (!firstName || !lastName) {
      notify.error("Введите фамилию и имя");
    }
    if (firstName && lastName && selectedRole && login) {
      createUser.mutateAsync({ firstName, lastName, middleName, login, role: selectedRole?.label, sections: chapters });
    }
  };

  const onSavePassword = () => {
    if (newPassword.length < 8 || newPassword.length > 16) {
      notify.error("Пароль должен быть от 8 до 16 символов");
      return;
    }

    if (newPassword !== rePassword) {
      notify.error("Пароли не совпадают");
      return;
    }

    if (newPassword) {
      updatePassword.mutateAsync({ body: { password: newPassword }, uaaId: currentUserId });
      setCurrentUserId("");
      setNewPassword("");
      setRePassword("");
    }
  };

  const onSaveChapters = () => {
    if (currentUserId && chapters) {
      updateUser.mutateAsync({ body: { sections: chapters }, userId: currentUserId });
      setCurrentUserId("");
      setChapters([]);
      setOpenModalChapter(false);
    }
  };

  const selectedChapters = (node: INode[], checked: boolean, innerText: string) => {
    if (innerText.includes("Участок")) {
      if (checked) {
        setChapters(
          uniq([
            ...chapters,
            ...union(...last([...node]).children.map((nodeTop) => nodeTop.children.map((nodeChildren) => nodeChildren.id))),
          ])
        );
      } else {
        setChapters(
          chapters.filter(
            (item) =>
              !union(...last([...node]).children.map((nodeTop) => nodeTop.children.map((nodeChildren) => nodeChildren.id))).includes(item)
          )
        );
      }
    }
    if (innerText.includes("Литер")) {
      if (checked) {
        setChapters(uniq([...chapters, ...last([...node]).children.map((nodeChildren) => nodeChildren.id)]));
      } else {
        setChapters(chapters.filter((item) => ![...last([...node]).children.map((nodeChildren) => nodeChildren.id)].includes(item)));
      }
    }
    if (innerText.includes("Секция")) {
      if (checked) {
        setChapters(uniq([...chapters, last([...node]).id]));
      } else {
        setChapters(chapters.filter((item) => item !== last([...node]).id));
      }
    }
  };

  return (
    <Screen className="w-full">
      <Container className="flex flex-col w-full relative">
        <div style={{ width: `${tableSize}` }}>
          <div className="flex flex-col">
            <UsersTable onClickIconPlus={handleOpenModalChapter} onClickIconPassword={handleOpenModalPassword} width={"100%"} />
            <RenderWithCondition condition={restrictedData.vars.canAddUsers}>
              <ButtonRow className="flex">
                <ButtonLight onClick={handleOpenModal} title={text.add} type="secondary" />
              </ButtonRow>
            </RenderWithCondition>
          </div>
          <RenderWithCondition condition={openModalUser}>
            <ModalForm
              data={mapFormUser}
              onChangeSearch={selectedChapters}
              onSave={onSaveUser}
              label={text.addUser}
              textButton={text.save}
              setIsVisible={setOpenModalUser}
              isLoading={UsersStore.isLoadingUsers}
              permissiveRole={isPermissiveRole(selectedRole?.label)}
              checkedElements={chapters}
              scrollToEnd
              isLoadingTree={treeData.isLoading}
            />
          </RenderWithCondition>
          <RenderWithCondition condition={openModalChapter}>
            <ModalForm
              onSave={onSaveChapters}
              textButton={text.save}
              data={mapFormChapter}
              onChangeSearch={selectedChapters}
              label={text.addChapter}
              setIsVisible={setOpenModalChapter}
              checkedElements={userSection}
              isLoadingTree={treeData.isLoading}
            />
          </RenderWithCondition>
          <RenderWithCondition condition={openModalPassword}>
            <ModalForm
              onSave={onSavePassword}
              textButton={text.save}
              data={mapFormPassword}
              label={text.setNewPassword}
              setIsVisible={setOpenModalPassword}
              isLoading={UsersStore.isLoadingUsers}
              isLoadingTree={treeData.isLoading}
            />
          </RenderWithCondition>
        </div>
        <LocalLoader condition={UsersStore.isLoadingUsers} />
      </Container>
    </Screen>
  );
});

export default Users;

const Container = styled.section``;

const ButtonRow = styled.p`
  padding: 20px;
`;
