import axios from "axios";

import { ErrorsCode } from "models/ErrorsCode";
import { ITokens } from "models/Tokens";
import AuthStore from "store/Auth.Store";

import { updateTokensApi } from "./api.auth";

const instance = axios.create({
  baseURL: `https://${window.location.host}/api/`,
});

let updateTokenInProgress = false;

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.warn(error.response);

    if (error.response.status === ErrorsCode.Unauthorized && originalRequest && !originalRequest._retry) {
      if (updateTokenInProgress) {
        return Promise.reject();
      }
      updateTokenInProgress = true;
      originalRequest._retry = true;
      let newTokens: ITokens;

      try {
        newTokens = await updateTokensApi();
        AuthStore.saveTokens(newTokens);
      } catch (error) {
        if (error.response.status === ErrorsCode.Unauthorized) {
          AuthStore.logout();
          AuthStore.login();
        }
        updateTokenInProgress = false;
        return Promise.reject();
      }

      originalRequest.headers["Authorization"] = `Bearer ${newTokens.access_token}`;
      updateTokenInProgress = false;
      return instance(originalRequest);
    }

    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${AuthStore.getTokens().access_token}`;
    config.headers["Access-Control-Allow-Origin"] = process.env.REACT_APP_AUTH_URL;
    config.headers["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE, OPTIONS, PATCH";
    config.headers["Access-Control-Allow-Headers"] = "*";

    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
