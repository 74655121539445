import { FC, useEffect } from "react";

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";

import { deleteUserApi, getRolesApi, getUsersApi, updateUserApi } from "api/Users/api.users";
import { queryClient } from "App";
import { ReactComponent as AddPlus } from "assets/add-plus.svg";
import { ReactComponent as Bin } from "assets/bin.svg";
import { ReactComponent as SetMenu } from "assets/set-menu.svg";
import DropDown from "components/Dropdown/Dropdown";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import useTreeData from "hooks/useTreeData/useTreeData";
import { usersTable } from "mock/table-data";
import { QueriesKeys } from "models/QueriesKeys";
import { CurrentUserRoles, IUser, IUserRole } from "models/Users/IUser";
import { Table, TableCell, TableHeader, TableRow } from "screens/Home/styles/common";

import UsersStore from "../store/Users.store";
import { selectedItems } from "../utils/selectedTtems";

interface IUsersTable {
  width: number | string;
  onClickIconPlus?: (value: any) => void;
  onClickIconPassword?: (value: any) => void;
  onClickIconRole?: (value: any) => void;
  data?: IUser[];
  dataRoles?: IUserRole[];
  mapFormUpdateRole?: any;
}

const columnHelper = createColumnHelper<any>();

const UsersTable: FC<IUsersTable> = observer((props) => {
  const text = useText();

  const restrictedData = useRestrictedAccessData();
  const roles = useQuery({ queryKey: QueriesKeys.roles, queryFn: getRolesApi });

  const selectedRoles = selectedItems(UsersStore.checkboxSelectedRoles);
  const selectedUsers = selectedItems(UsersStore.checkboxSelectedUsers);
  const searchValue = UsersStore.searchValue;
  const selectedSection = UsersStore.selectedBuilding?.id;

  const users = useQuery({
    queryFn: () => getUsersApi({ query: searchValue, ids: selectedUsers, roles: selectedRoles, sectionsIds: selectedSection }),
    queryKey: [QueriesKeys.users, selectedRoles, selectedUsers, searchValue, selectedSection],
  });

  const treeData = useTreeData();

  const getLinePath = (obj, id) => {
    let str = "";
    const getPathSection = (obj, id) => {
      let res = [];
      res = obj && obj.filter((node) => JSON.stringify(node).includes(id));
      if (res[0]?.name) {
        str = `${str} ${res[0]?.name}, `;
      }
      if (res[0]?.children) {
        getPathSection(res[0].children, id);
      } else {
        return str;
      }
      return str;
    };
    return getPathSection(obj, id);
  };

  useEffect(() => {
    UsersStore.setIsLoadingUsers(users.isFetching);
  }, [users.isFetching]);

  const data = users.data ?? usersTable;

  const updateRole = useMutation(updateUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.users);
    },
  });

  const deleteUser = useMutation(deleteUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.users);
    },
  });

  const columns = [
    columnHelper.accessor("firstName", {
      header: text.fullName,
      cell: (info) => (
        <div>{`${info.row.original.lastName} ${info.row.original.firstName ?? ""} ${info.row.original.middleName ?? ""}`} </div>
      ),
      enableResizing: true,
      meta: { styleCol: { textAlign: "left", paddingLeft: "8px", height: "48px" } },
    }),

    columnHelper.accessor("role", {
      header: text.role,
      cell: (info) => {
        const userRole = roles.data.filter((role) => role.name === info.getValue())[0];

        return (
          <>
            <div className="flex justify-between gap-2">
              {userRole?.description}
              {restrictedData.fn.canChangeUserData({ role: userRole?.name, id: info.row.original.id }) && (
                <div className="flex items-center justify-center w-7">
                  <DropDown
                    data={
                      restrictedData.data.roles.map((role) => ({
                        value: role.description,
                        id: role.name,
                        label: "",
                      })) ?? []
                    }
                    onChange={function (item: { value: string; label?: string; id: CurrentUserRoles }): void {
                      updateRole.mutateAsync({
                        userId: info.cell.row.original?.id,
                        body: {
                          role: item.id!,
                        },
                      });
                    }}
                    dropdownStyles={{ width: "70%", marginTop: "100px", marginRight: "-255px" }}
                    dropdownItemStyles={{ padding: "4px 0" }}
                    type="simple"
                    CustomButton={(props) => (
                      <button onClick={props.onClick} className="w-6 flex justify-center items-center">
                        <SetMenu />
                      </button>
                    )}
                  />
                </div>
              )}
            </div>
          </>
        );
      },
      enableResizing: true,
      meta: { styleCol: { padding: "10px 10px 10px 20px", height: "48px", maxWidth: "200px" } },
    }),
    columnHelper.accessor("login", {
      header: text.login,
      cell: (info) => <div className="flex justify-center gap-7">{info.getValue()}</div>,
      enableResizing: true,
      meta: { styleCol: { height: "48px", maxWidth: "180px", padding: "10px 20px" } },
    }),
    columnHelper.accessor("password", {
      header: text.password,
      cell: (info) => (
        <CellPassword onClick={() => props.onClickIconPassword(info.row.original)} className="flex">
          {info.getValue()}
          {!!info.row.original.login && text.phoneConfirmed}
        </CellPassword>
      ),
      enableResizing: true,
      meta: { styleCol: { height: "48px", maxWidth: "259px", cursor: "pointer", padding: "10px 20px" } },
    }),
    columnHelper.accessor("chapter", {
      header: text.chapter,
      cell: (info) => (
        <div
          className={classNames(
            "flex gap-7 items-center ",
            { "justify-center": isEmpty(info.row.original.sections) },
            { "justify-between": !isEmpty(info.row.original.sections) }
          )}
        >
          <div className="flex flex-col gap-8">
            {info.row.original.sections?.map((sectionId) => (
              <div className="inline-block text-left justify-start ">
                {/* remove comma at the end */}
                {treeData.data && getLinePath(treeData.data, sectionId).slice(0, -2)}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center">
            <Icon onClick={() => props.onClickIconPlus(info.row.original)}>
              <AddPlus />
            </Icon>
          </div>
        </div>
      ),
      enableResizing: true,
      meta: { styleCol: { padding: "10px 20px ", height: "48px" } },
    }),
    columnHelper.accessor("delete", {
      header: text.delete,
      cell: (info) => {
        const canDeleteUser = restrictedData.fn.canChangeUserData(info.row.original.role);
        return (
          <button
            disabled={!canDeleteUser}
            onClick={() => {
              deleteUser.mutateAsync(info.row.original.id);
            }}
          >
            <Bin fill={canDeleteUser ? "#DB333D" : "grey"} />
          </button>
        );
      },
      enableResizing: true,
      meta: { styleCol: { textAlign: "center", height: "48px" } },
    }),
  ];

  const table = useReactTable({
    // @ts-ignore
    data,
    // @ts-ignore
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table width={props.width}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeader className="text-sm" key={header.id} style={{ height: 48 }}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => {
              // @ts-ignore
              const style = cell.column.columnDef.meta?.styleCol || {};

              return (
                <TableCell className="h-12" style={{ ...style }} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
});

export default UsersTable;

const CellPassword = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  &:hover {
    background-color: ${(props) => props.theme.colors.blue200};
  }
`;

const Icon = styled.div`
  height: 17px;
  width: 17px;
  cursor: pointer;
`;
