import { makeAutoObservable } from "mobx";

import textMock from "mock/text.mock";
import { IText } from "models/Text";

class AppStore {
  text: IText;

  constructor() {
    makeAutoObservable(this);
  }

  setText() {
    this.text = textMock;
  }
}

export default new AppStore();
