import { FC, useEffect, useRef, useState } from "react";
import React from "react";

import { useClickAway } from "ahooks";
import { isEmpty, last, uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ReactComponent as OnBackIcon } from "assets/chevron-left.svg";
import ButtonLight from "components/ButtonLight";
import DropDown from "components/Dropdown/Dropdown";
import { EmptyList } from "components/Dropdown/EmptyList";
import Input from "components/Input";
import { LocalLoader } from "components/Loader/LocalLoader";
import Modal from "components/Modal/Modal";
import Search from "components/Search";
import Tree, { TOnNodeCheck } from "components/Tree/Tree";
import useCreateReferenceElements from "hooks/useReferences/useCreateReferenceElements";
import useText from "hooks/useText";
import emitter from "services/emitter";
import { Spacer } from "styles/common";

import { defaultLabel, defaultModalForm } from "./constants";
import styles from "./modalForm.module.css";

interface ITypeElement {
  mapFormCreateElement: any;
  onSaveNewElement: any;
}

interface IModalForm {
  label?: string;
  permissiveRole?: boolean;
  textButton?: string;
  icon?: string;
  mask?: string;
  onClickIcon?: () => void;
  onChangeSearch?: TOnNodeCheck;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedValue?: React.Dispatch<React.SetStateAction<string>>;
  data?: any[];
  checkedElements?: any[];
  onSave?: () => void;
  placeholder?: string;
  isLoading?: boolean;
  isError?: boolean;
  scrollToEnd?: boolean;
  isLoadingTree?: boolean;
  onClearSelectedValueCallback?: () => void;
}
const ModalForm: FC<IModalForm> = observer((props) => {
  const mapData = props.data ? props.data : defaultModalForm;
  const labelForm = props.label ? props.label : defaultLabel;
  const [isOpenInnerForm, setOpenInnerForm] = useState(false);
  const [typeElement, setTypeElement] = useState("");

  const onSaveRef = useRef(false);
  const scrollRef = useRef(null);

  const closeModal = () => {
    if (isEmpty(innerModalOpenedTypesStack.current)) {
      setOpenInnerForm(false);
      return;
    }
    innerModalOpenedTypesStack.current.pop();
    if (isEmpty(innerModalOpenedTypesStack.current)) {
      setOpenInnerForm(false);
      return;
    }

    setTypeElement(last(innerModalOpenedTypesStack.current));
  };

  const newElementForms = useCreateReferenceElements({ openInnerForm: closeModal });

  const innerModalOpenedTypesStack = React.useRef<string[]>([]);

  useEffect(() => {
    if (!props.isLoading && onSaveRef.current && !props.isError) {
      props.setIsVisible(false);
    }
  }, [props.isLoading]);

  useEffect(() => {
    if (props.scrollToEnd) {
      const observerHeight = new ResizeObserver(() => {
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      });
      observerHeight.observe(scrollRef?.current);
      return () => {
        observerHeight.disconnect();
      };
    }
  }, []);

  const onSaveHandle = () => {
    props.onSave();
    onSaveRef.current = true;
  };

  const handleOpenInnerForm = (value: string) => {
    innerModalOpenedTypesStack.current.push(value);
    setOpenInnerForm(true);
    setTypeElement(value);
  };

  const newElement = React.useMemo(() => {
    if (typeElement === "Contractor") {
      return {
        mapFormCreateElement: newElementForms?.contractors?.modalCreateContractorsForm,
        onSaveNewElement: newElementForms?.contractors?.onSaveNewContractors,
      };
    }
    if (typeElement === "Work") {
      return {
        mapFormCreateElement: newElementForms?.works?.modalCreateWorksForm,
        onSaveNewElement: newElementForms?.works?.onSaveNewWork,
      };
    }
    if (typeElement === "Measure") {
      return {
        mapFormCreateElement: newElementForms?.measures?.modalCreateMeasuresForm,
        onSaveNewElement: newElementForms?.measures?.onSaveNewMeasure,
      };
    }
  }, [newElementForms, typeElement]);

  const isManuallyOrUsersScreen = location.pathname === "/objects_edit/manually" || location.pathname === "/users";

  return (
    <>
      <Modal setIsVisible={props.setIsVisible} closeInnerModal={closeModal} isCloseInner={isOpenInnerForm}>
        <div
          className={`flex-col items-center duration-500 ${isOpenInnerForm ? "absolute scale-0 opacity-0" : "relative scale-1 opacity-1"} `}
        >
          <TextModal className="text-sm font-bold">{labelForm}</TextModal>
          <Container className={`flex flex-col py-5  ${props.permissiveRole ? "gap-0" : "gap-[30px]"}`}>
            <Container
              ref={scrollRef}
              className={`max-h-[460px]  py-0 px-2 gap-[30px] ${isManuallyOrUsersScreen ? "overflow-hidden overflow-y-auto" : ""}`}
            >
              {mapData.map((node, i) => {
                if (node.type === "custom") {
                  const Node = node.Component;
                  return <Node />;
                }
                if (node.type === "input") {
                  return (
                    <Field className="relative">
                      <Input
                        icon={node.icon}
                        value={node.value}
                        onChange={node.onChange}
                        disabled={node.disabled}
                        placeholder={node.placeholder}
                        widthInputLine="100%"
                        label={node.label}
                        mask={node.mask}
                        redStarSign={node.redStarSign}
                        type={node.typeInput}
                      />
                    </Field>
                  );
                }

                if (node.type === "select") {
                  return (
                    <Field className="relative">
                      <DropDown
                        type="simple"
                        data={node.selectData}
                        onChange={node.onSelect}
                        selectedValue={node.selectPlaceholder ?? "Единица измерения"}
                        buttonChangeClassName={styles.input_drop}
                        topPlaceholder={node.topPlaceholder}
                        placeholderInSelectedText={props.data[i]?.placeholderInSelectedText}
                        addNewElementsForm={node.addNewElement}
                        openNewElementsForm={handleOpenInnerForm}
                        isFullWidthList={node.isFullList}
                        onClearSelectedValueCallback={node.onClearSelectedValueCallback}
                      />
                    </Field>
                  );
                }
                if (node.type === "tree-select-checkbox") {
                  return (
                    <Field className="relative">
                      <DropDown
                        type="tree-select-checkbox"
                        data={node.dataTree}
                        onChange={node.onSelect}
                        selectedValue={node.selectPlaceholder ?? "Единица измерения"}
                        buttonChangeClassName={styles.input_drop}
                        topPlaceholder={node.topPlaceholder}
                        onChangeSelectCheckbox={props.onChangeSearch}
                        permissiveRole={props.permissiveRole}
                        checkedElements={props.checkedElements}
                        isFullWidthList={node.isFullList}
                        isLoadingTree={props.isLoadingTree}
                      />
                    </Field>
                  );
                }
                if (node.type === "search" || node.type === "checkboxTree") {
                  return (
                    <>
                      {node.type === "search" && <Search onChange={node.onSearch} />}
                      <ListInfo className="flex">
                        <EmptyList condition={isEmpty(node.dataTree)} />
                        <Tree
                          mode="checkbox"
                          nodes={node.dataTree as any}
                          onNodeClick={() => undefined}
                          onNodeChange={props.onChangeSearch}
                          checkedElements={props.checkedElements}
                          coloredSearchLetter={node.searchText}
                        />
                      </ListInfo>
                    </>
                  );
                }
              })}
            </Container>
            <Field className="relative flex-col">
              <ButtonLight fullWidth type="primary" title={props.textButton} onClick={onSaveHandle} />
            </Field>
          </Container>
          <LocalLoader condition={props.isLoading} />
        </div>
        <div
          className={`flex-col items-center duration-500 ${isOpenInnerForm ? "relative scale-1 opacity-1" : "absolute scale-0 opacity-0"} `}
        >
          <Container
            ref={scrollRef}
            className={`max-h-[450px]  py-0 px-2 gap-[20px] ${
              location.pathname === "/objects_edit/manually" || location.pathname === "/users" ? "overflow-hidden overflow-y-auto" : ""
            }`}
          >
            <TextModal className="text-sm font-bold">
              {newElement?.mapFormCreateElement && newElement?.mapFormCreateElement[0].textAction}
            </TextModal>
            <OnBack onClick={closeModal}>
              <OnBackIcon width={12} height={10} />
              Вернуться назад
            </OnBack>
            {newElement?.mapFormCreateElement?.map((node, i) => {
              if (node.type === "select") {
                return (
                  <>
                    <Field className="relative">
                      <DropDown
                        type="simple"
                        data={node.selectData}
                        onChange={node.onSelect}
                        selectedValue={node.selectPlaceholder ?? "Единица измерения"}
                        buttonChangeClassName={styles.input_drop}
                        topPlaceholder={node.topPlaceholder}
                        placeholderInSelectedText={props.data[i]?.placeholderInSelectedText}
                        addNewElementsForm={node.addNewElement}
                        openNewElementsForm={handleOpenInnerForm}
                        isFullWidthList={node.isFullList}
                        onClearSelectedValueCallback={node.onClearSelectedValueCallback}
                      />
                    </Field>
                  </>
                );
              }

              return (
                <Field className="relative">
                  <Input
                    key={i}
                    value={node.value}
                    mask={node.mask}
                    onChange={node.onChange}
                    placeholder={node.placeholder}
                    widthInputLine="100%"
                  />
                </Field>
              );
            })}
          </Container>
          <Spacer px={30} />
          <Field className="relative flex-col">
            <ButtonLight fullWidth type="primary" title={props.textButton} onClick={newElement?.onSaveNewElement} />
          </Field>
          <LocalLoader condition={props.isLoading} />
        </div>
      </Modal>
    </>
  );
});

export default ModalForm;

const Container = styled.div<{ ref?: any }>`
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const ListInfo = styled.div`
  height: 370px;
  width: 380px;
  padding: 20px 40px;
  max-width: 435px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.absolute300};
  overflow-y: auto;
  position: relative;
`;

const TextModal = styled.div`
  display: flex;
  min-width: 320px;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  color: ${(props) => props.theme.colors.absolute700};
  font-size: 30px;
  line-height: 38px;
`;

const Field = styled.div`
  @media (max-width: 1023px) {
    width: 48%;
  }
`;

const OnBack = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  width: 50%;
  color: ${(props) => props.theme.colors.blue400};
`;
