import React from "react";

import styled from "styled-components";

interface IDropdownOverlay {
  children: React.ReactNode;
  paddings?: "sm" | "xl";
  style: React.CSSProperties;
  isFullWidthList?: boolean;
}

const DropdownOverlay = React.forwardRef<React.LegacyRef<HTMLDivElement>, IDropdownOverlay>((props, ref) => (
  <DropDownCmp
    ref={ref as unknown as React.LegacyRef<HTMLDivElement>}
    paddings={props.paddings}
    className={`flex flex-col ${props.isFullWidthList ? "w-full" : null}`}
    style={props.style}
  >
    {props.children}
  </DropDownCmp>
));

export default DropdownOverlay;

export const XL_PADDING_HORIZONTAL_DROPDOWN_OVERLAY = "33px";
export const SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY = "12px";

const DropDownCmp = styled.div<{ paddings?: "sm" | "xl" }>`
  position: absolute;
  z-index: 3;
  background-color: ${(props) => props.theme.colors.absolute100};
  padding: 0px 12px 5px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 3px ${(props) => props.theme.colors.shadow};
  min-width: 120px;
  min-height: 73px;
`;
