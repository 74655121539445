import React, { FC } from "react";

import styled from "styled-components";

import Input from "components/Input";
import RadioGroup from "components/RadioGroup";
import useText from "hooks/useText";
import { Spacer } from "styles/common";

const OPTIONS_DAY_OF_WEEK = [
  { value: "MONDAY", label: "Пн" },
  { value: "TUESDAY", label: "Вт" },
  { value: "WEDNESDAY", label: "Ср" },
  { value: "THURSDAY", label: "Чт" },
  { value: "FRIDAY", label: "Пт" },
  { value: "SATURDAY", label: "Сб" },
  { value: "SUNDAY", label: "Вс" },
];

export interface IRadioOptions {
  value: string;
  label: string;
}

interface ISetPeriod {
  label?: string;
  defaultChecked?: string;
  options?: IRadioOptions[];
  onChange?: React.Dispatch<React.SetStateAction<string>>;
  optionPeriod?: string;
  amountMonths?: string;
  amountDays?: string;
  disabled?: boolean;
  changeMonths?: React.Dispatch<React.SetStateAction<string>>;
  changeDays?: React.Dispatch<React.SetStateAction<string>>;
}

const SetPeriod: FC<ISetPeriod> = (props) => {
  const text = useText();

  const handleChange = (value: string) => {
    props.onChange(value);
  };

  return (
    <>
      <ShadowContainer className="flex  flex-col">
        {(props.optionPeriod === "MONTH" || props.optionPeriod === "ARBITRARY") && (
          <div className="flex gap-5 items-center">
            <Text>{props.optionPeriod === "ARBITRARY" ? text.numberOfDay : text.numberOfMonth}</Text>
            {/* <NumContainer>3</NumContainer> */}
            <Input
              widthInputLine={"30px"}
              value={props.optionPeriod === "MONTH" ? props.amountMonths : props.amountDays}
              onChange={props.optionPeriod === "MONTH" ? props.changeMonths : props.changeDays}
            />
          </div>
        )}
        {(props.optionPeriod === "WEEK" || props.optionPeriod === "ARBITRARY") && (
          <>
            {props.optionPeriod === "ARBITRARY" && <Spacer px={15} />}
            <Text className="">{props.optionPeriod === "ARBITRARY" ? text.startDayOfPeriod : text.startDay}</Text>
            <div className="flex pt-9">
              <RadioGroup
                vertical={true}
                options={OPTIONS_DAY_OF_WEEK}
                defaultChecked={props.defaultChecked}
                onChange={handleChange}
                disabled={props.disabled}
              />
            </div>
          </>
        )}
      </ShadowContainer>
    </>
  );
};

export default SetPeriod;

const Text = styled.div`
  display: flex;
  padding-left: 10px;
  color: ${(props) => props.theme.colors.absolute700};
  font-size: 16px;
  font-weight: 700;
`;
const ShadowContainer = styled.div`
  max-width: 323px;
  padding: 20px 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
  background-color: ${(props) => props.theme.colors.absolute100};
`;
