import { IWork } from "models/References/IWork";
import { IReportSettings } from "models/Settings/IReportSettings";

import apiClient from "../api.client";

export type TGetReportSettingsApi = () => Promise<IReportSettings>;

export const getReportSettingsApi: TGetReportSettingsApi = async () => {
  try {
    const res = await apiClient.get("/settings/v1/reports");

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdatePeriodApi = (body: IReportSettings) => Promise<IWork>;

export const updatePeriodApi: TUpdatePeriodApi = async (body) => {
  try {
    const res = await apiClient.patch("/settings/v1/reports/", body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
