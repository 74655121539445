export const mainTable = [
  {
    control: "Еж",
    contractor: "ИП Мирошниченко Александр Александрович",
    name_of_the_work: "Каменная кладка",
  },
  {
    control: "Нд",
    contractor: "ИП Иванов Андрей Александрович",
    name_of_the_work: "Прокладка кабельной продукции",
  },
  {
    control: "Еж",
    contractor: "ИП Мирошниченко Александр Александрович",
    name_of_the_work: "Каменная кладка",
  },
  {
    control: "Нд",
    contractor: "ИП Иванов Андрей Александрович",
    name_of_the_work: "Каменная кладка",
  },
  {
    control: "Нд",
    contractor: "ИП Мирошниченко Александр Александрович",
    name_of_the_work: "Каменная кладка",
  },
  {
    control: "Нд",
    contractor: "ИП Иванов Андрей Александрович",
    name_of_the_work: "Прокладка кабельной продукции",
  },
];
export const measureTable = [
  {
    measureUnits: "Этажи",
    delete: "",
  },
  {
    measureUnits: "м²",
    delete: "",
  },
  {
    measureUnits: "м³ ",
    delete: "",
  },
];
export const referenceTable = [
  {
    control: "Еж",
    measureUnits: "этаж",
    name_of_the_work: "Каменная кладка",
  },
  {
    control: "Нд",
    measureUnits: "м³",
    name_of_the_work: "Прокладка кабельной продукции",
  },
  {
    control: "Еж",
    measureUnits: "этаж",
    name_of_the_work: "Каменная кладка",
  },
  {
    control: "Нд",
    measureUnits: "м³",
    name_of_the_work: "Каменная кладка",
  },
  {
    control: "Нд",
    measureUnits: "м³",
    name_of_the_work: "Каменная кладка",
  },
  {
    control: "Нд",
    measureUnits: "м³",
    name_of_the_work: "Прокладка кабельной продукции",
  },
];
export const contractorTable = [
  {
    contractor: "ИП Мирошниченко Александр Александрович",
    contactPerson: "Мирошниченко Александр Александрович",
    contact: "+ 7 (800) 000-00-00",
    delete: "",
  },
  {
    contractor: "ИП Иванов Андрей Александрович",
    contactPerson: "Иванов Андрей Александрович",
    contact: "+ 7 (800) 000-00-00",
    delete: "",
  },
  {
    contractor: "ИП Петров Игорь Александрович",
    contactPerson: "Петров Игорь Александрович",
    contact: "+ 7 (800) 000-00-00",
    delete: "",
  },
];
export const refSectionTable = [
  {
    contractor: { value: "ИП Иванов Иван Иванович", rowId: "" },
    name_of_the_work: { value: "Прокладка кабельной продукции ", rowId: "" },
    measureUnits: { value: "этаж", rowId: "" },
    control: { value: "Еж", rowId: "" },
    delete: "",
  },
];

export const usersTable = [
  {
    firstName: "",
    lastName: "",
    middleName: "",
    role: "",
    login: "",
    // password: "телефон подтвержден пользователем",
    // chapter: "ЖК “Парк Победы” Участок 2336, Литер 3, Секция Б",
    // delete: "",
  },
];

export const scrollableTable = [
  {
    units_of_measurement: "м³",
    "by_gpr/beginning": "01.02.23",
    "by_gpr/ending": "16.06.23",
    "by_gpr/number_of_days": "135",
    total_for_the_project: "1534,8",
    fact: "909",
    remains: "626,14",
    "%_completed": "59%",
    staff_an_average_per_week: "13",
    collapse_days: "",
  },
  {
    units_of_measurement: "этаж",
    "by_gpr/beginning": "21.03.23",
    "by_gpr/ending": "24.09.23",
    "by_gpr/number_of_days": "187",
    total_for_the_project: "16",
    fact: "6",
    remains: "10",
    "%_completed": "38%",
    staff_an_average_per_week: "4",
    collapse_days: "",
  },
  {
    units_of_measurement: "м³",
    "by_gpr/beginning": "01.02.23",
    "by_gpr/ending": "16.06.23",
    "by_gpr/number_of_days": "135",
    total_for_the_project: "1534,8",
    fact: "909",
    remains: "626,14",
    "%_completed": "59%",
    staff_an_average_per_week: "13",
    collapse_days: "",
  },
  {
    units_of_measurement: "этаж",
    "by_gpr/beginning": "21.03.23",
    "by_gpr/ending": "24.09.23",
    "by_gpr/number_of_days": "187",
    total_for_the_project: "16",
    fact: "6",
    remains: "10",
    "%_completed": "38%",
    staff_an_average_per_week: "4",
    collapse_days: "",
  },
  {
    units_of_measurement: "м³",
    "by_gpr/beginning": "01.02.23",
    "by_gpr/ending": "16.06.23",
    "by_gpr/number_of_days": "135",
    total_for_the_project: "1534,8",
    fact: "909",
    remains: "626,14",
    "%_completed": "59%",
    staff_an_average_per_week: "13",
    collapse_days: "",
  },
  {
    units_of_measurement: "этаж",
    "by_gpr/beginning": "21.03.23",
    "by_gpr/ending": "24.09.23",
    "by_gpr/number_of_days": "187",
    total_for_the_project: "16",
    fact: "6",
    remains: "10",
    "%_completed": "38%",
    staff_an_average_per_week: "4",
    collapse_days: "",
  },
];
