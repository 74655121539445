import React from "react";

import TreeStore from "components/Tree/Tree.store";

type THook = (args?: { withoutSection?: boolean }) => string[];

const useObjectNames: THook = (args) => {
  const currentArea = TreeStore.selectedObject?.meta?.area;

  const objectNames = React.useMemo(
    () =>
      [
        TreeStore.selectedComplex?.name,
        // eslint-disable-next-line no-negated-condition
        currentArea !== "complex" ? TreeStore.selectedArea?.name : undefined,
        currentArea === "section" || currentArea === "building" ? TreeStore.selectedBuilding?.name : undefined,
        currentArea === "section" && !args?.withoutSection ? TreeStore.selectedSection?.name : undefined,
      ].filter((name) => name),
    [TreeStore.selectedComplex, TreeStore.selectedArea, TreeStore.selectedBuilding, TreeStore.selectedSection]
  );

  return objectNames;
};

export default useObjectNames;
