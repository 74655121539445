/* eslint-disable arrow-body-style */
import { FC, useState } from "react";
import React from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ReactComponent as ArrowDownIcon } from "assets/arrowDown_blue.svg";
import { ReactComponent as ArrowUpIcon } from "assets/arrowUp_blue.svg";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import HomeStore from "screens/Home/store/Home.store";
import CalendarStore from "store/Calendar.Store";
import { SpanColored } from "styles/common";
import { LIGHT_THEME } from "theme/colors";

export type TDailyDataItem = {
  value: number;
  cell: string;
  total?: boolean;
  periodId?: string;
  startDate?: string;
  endDate?: string;
};

export type TDailyData = TDailyDataItem[];

type TWeeklyDataItem = {
  value: number;
  cell: string;
  total?: boolean;
  periodId?: string;
  startDate?: string;
  endDate?: string;
};

export type TWeeklyData = TWeeklyDataItem[];

export interface IDaysCellChart {
  controlType: string;
  days?: TDailyData[];
  weeklyData?: TWeeklyData;
  isAgreed: boolean;
  sectionNameExist: boolean;
  sectionItemId: string;

  periodIdx?: number;
}

const colors = LIGHT_THEME;
const getColor = (data: TWeeklyDataItem | TDailyDataItem, isAgreed: boolean, isFocused?: boolean) => {
  if (isFocused) {
    return colors.absolute100;
  }
  if (data.cell.indexOf("volume") !== -1) {
    if (data.total) {
      return colors.green200;
    }
    return colors.green100;
  }
  if (data.cell.indexOf("fact") !== -1) {
    if (data.total) {
      return colors.blue200;
    }
    return colors.absolute100;
  }
  if (data.cell.indexOf("staff") !== -1) {
    if (data.total) {
      return colors.yellow200;
    }
    return colors.yellow100;
  }

  return colors.absolute100;
};

const DaysCellChart: FC<IDaysCellChart> = observer((props) => {
  const [colorArrow, setColorArrow] = useState(colors.absolute500);
  const isTotalHeader = props.days[0][0].total;
  const isWeekly = HomeStore.typePeriodForCharts === "WEEKLY";

  return (
    <>
      {props.sectionNameExist && (
        <div className="">
          <SectionEmptyOffset />
          <ButtonCollapseTable
            className="flex absolute"
            onClick={CalendarStore.toggleIsHiddenLiters}
            onMouseOver={() => setColorArrow(colors.blue400)}
            onMouseOut={() => setColorArrow(colors.absolute500)}
          >
            <RenderWithCondition condition={!CalendarStore.isHiddenLiters}>
              <ArrowUpIcon fill={colorArrow} />
            </RenderWithCondition>
            <RenderWithCondition condition={CalendarStore.isHiddenLiters}>
              <ArrowDownIcon fill={colorArrow} />
            </RenderWithCondition>
            <SpanColored color={colors.absolute500} hoverColor={colors.blue400}>
              {CalendarStore.isHiddenLiters ? "Открыть" : "Скрыть"} таблицу литеров
            </SpanColored>
          </ButtonCollapseTable>
        </div>
      )}
      <CalendarTableCellContainer controlType={props.controlType}>
        <HeaderCell color={isTotalHeader ? colors.green200 : colors.green100} area="volume">
          План
        </HeaderCell>
        <HeaderCell area="fact" color={isTotalHeader ? colors.blue200 : null}>
          Факт
        </HeaderCell>
        <HeaderCell area="staff" color={isTotalHeader ? colors.yellow200 : colors.yellow100} total={isTotalHeader}>
          Персонал
        </HeaderCell>

        {props.days?.map((currDay, index) => {
          return currDay.map((day, i) => {
            const coloredCellGrey =
              CalendarStore.calendarStartDate.add(index, "day").format("MM") === CalendarStore.calendarCurrentMonth.format("MM")
                ? getColor(day, props.isAgreed)
                : colors.absolute150;

            if (!day.startDate) {
              day.startDate = CalendarStore.calendarStartDate.add(index, "days").format("YYYY-MM-DD");
            }

            return (
              <DataCell
                key={day.periodId + i}
                color={isWeekly ? getColor(day, props.isAgreed) : coloredCellGrey}
                area={day.cell}
                total={!!day.total}
              >
                <InputCell disabled value={day.value} />
              </DataCell>
            );
          });
        })}
      </CalendarTableCellContainer>
    </>
  );
});

export default DaysCellChart;

const CalendarTableCellContainer = styled.div<{ controlType: string }>`
  display: grid;
  grid-template-areas:
    "volume ${(props) =>
      props.controlType === "WEEKLY"
        ? "weekly_volume"
        : "day1_volume day2_volume day3_volume day4_volume day5_volume day6_volume day7_volume"}"
    "fact ${(props) =>
      props.controlType === "WEEKLY" ? "weekly_fact" : "day1_fact day2_fact day3_fact day4_fact day5_fact day6_fact day7_fact"}"
    "staff ${(props) =>
      props.controlType === "WEEKLY" ? "weekly_staff" : "day1_staff day2_staff day3_staff day4_staff day5_staff day6_staff day7_staff"}";
  grid-template-columns: 80px ${(props) => (props.controlType === "WEEKLY" ? "repeat(1, 1fr)" : "repeat(7, minmax(0, 1fr))")};
  grid-template-rows: auto;
  justify-content: space-between;
  align-items: center;
  height: 72px;

  text-align: center;
  font-size: 12px;
`;

const HeaderCell = styled.div<{ area: string; color?: string; total?: boolean }>`
  grid-area: ${(props) => props.area};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid ${(props) => props.theme.colors.absolute200};
  border-bottom: 1px solid
    ${(props) => (props.area.endsWith("staff") && !props.total ? props.theme.colors.absolute500 : props.theme.colors.absolute200)};
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
`;

const DataCell = styled.div<{ area: string; color?: string; total: boolean }>`
  grid-area: ${(props) => props.area};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  border-bottom: 1px solid
    ${(props) => (props.area.endsWith("staff") && !props.total ? props.theme.colors.absolute500 : props.theme.colors.absolute200)};
  width: 100%;
  height: 100%;
  margin: -1px;
  padding: 1px;

  ${(props) => props.color && `background-color: ${props.color}`};
`;

const InputCell = styled.input`
  background-color: transparent;
  text-align: center;
`;

const SectionEmptyOffset = styled.div<{ height?: string }>`
  height: ${(props) => (props.height ? props.height : "26px")};
  border: 1px solid ${(props) => props.theme.colors.absolute200};
`;
const ButtonCollapseTable = styled.button<{ height?: string }>`
  height: ${(props) => (props.height ? props.height : "25.2px")};
  border: 1px solid ${(props) => props.theme.colors.absolute100};
  background-color: ${(props) => props.theme.colors.absolute100};
  bottom: 73px;
  left: 0;
  width: 99.8%;
  z-index: 2;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.blue200};
  }
`;
