import React, { FC } from "react";

import dayjs from "dayjs";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import { Events } from "models/Events";
import { IAgreement } from "models/Objects/IAgreement";
import { IItemPeriod } from "models/Objects/IItemPeriod";
import PlanStatusStore from "screens/Home/store/PlanStatus.store";
import emitter from "services/emitter";

import { TCreatePeriodQuery, TUpdatePeriodQuery } from "./Calendar.Table";
import { TDailyData, TUpdatePeriodsDaysPayload } from "./Calendar.Table.Cell";

interface ICalendarTableCellValue {
  cellData: {
    value: number;
    cell: string;
    periodId?: string;
    startDate?: string;
  };
  currDay?: TDailyData;
  tabIndex?: number;
  id?: string;
  currIndex?: number;
  weekly: number | null;
  keyDown?: (e: React.KeyboardEvent<HTMLInputElement>, nextId?: string, currId?: string) => void;
  setFocusedCellId: React.Dispatch<React.SetStateAction<string>>;
  sectionItemId: string;
  updatePeriodDays?: (payload: TUpdatePeriodsDaysPayload) => void;
  createPeriod: TCreatePeriodQuery;
  updatePeriod: TUpdatePeriodQuery;
  planInputData?: {
    value: number;
    onChange: (newValue: string) => void;
  };
  currentPlan?: IAgreement;
}

const findPeriodCellValueByTypeCell = (dayCells: TDailyData, type: "fact" | "staff" | "volume") =>
  dayCells?.find((c) => c.cell.indexOf(type) !== -1).value;

const CalendarTableCellValue: FC<ICalendarTableCellValue> = observer((props) => {
  const [inputValue, setInputValue] = React.useState("");
  const [isOutlinedInput, setIsOutlinedInput] = React.useState(false);
  const initialValue = React.useRef(props.cellData.value);
  const restrictedData = useRestrictedAccessData();

  const inputRef = React.useRef<HTMLInputElement>();

  const isEditAnotherItemPeriod = computed(
    () => PlanStatusStore.currentEditableItemId && props.sectionItemId !== PlanStatusStore.currentEditableItemId
  ).get();

  const isPlanCell = props.cellData.cell.endsWith("volume");

  React.useEffect(() => {
    emitter.addListener("focused_calendar_table_cell", () => {
      setIsOutlinedInput(false);
      props.setFocusedCellId("");
    });
  }, []);

  const onClickInput = (e) => {
    if (isEditAnotherItemPeriod) {
      return;
    }

    props.keyDown(e);
    if (!isOutlinedInput) {
      emitter.emit(Events["focused_calendar_table_cell"]);
      inputRef.current.focus();
      props.setFocusedCellId(props.id);
      setIsOutlinedInput(true);
    } else if (inputRef.current) {
      inputRef.current.blur();
      setIsOutlinedInput(false);
      props.setFocusedCellId("");
    }
  };

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!isNaN(Number(value))) {
      const floatNumbers = value.split(".");

      if (floatNumbers.length === 2) {
        const [decimalNum, restNum] = floatNumbers;

        const newValue = `${decimalNum}.${restNum.slice(0, 2)}`;

        setInputValue(`${decimalNum}.${restNum.slice(0, 2)}`);

        if (isPlanCell) {
          props.planInputData.onChange(newValue);
        }

        return;
      }

      if (isPlanCell) {
        props.planInputData.onChange(value);
      }
      setInputValue(value);
    }
  };

  const updatePeriod = (args: { isFact: boolean; isStaff: boolean }) => {
    const body: Partial<IItemPeriod> = {};
    const inputValueIsUndefined = inputValue === "";

    props.updatePeriodDays?.({
      value: inputValue,
    });

    const getCellValue = findPeriodCellValueByTypeCell.bind(null, props.currDay);

    if (args.isFact) {
      body.actualVolume = inputValueIsUndefined ? null : Number(inputValue);
      body.staffVolume = getCellValue("staff");
    }

    if (args.isStaff) {
      body.staffVolume = inputValueIsUndefined ? null : Number(inputValue);
      body.actualVolume = getCellValue("fact");
    }

    props.updatePeriod.mutateAsync({
      id: props.cellData.periodId,
      body,
    });
  };

  const [needToUpdatePeriodAfterCreation, setNeedToUpdatePeriodAfterCreation] = React.useState(false);

  React.useEffect(() => {
    if (props.cellData.periodId && needToUpdatePeriodAfterCreation) {
      setNeedToUpdatePeriodAfterCreation(false);

      const isFact = props.cellData.cell.indexOf("fact") !== -1;
      const isStaff = props.cellData.cell.indexOf("staff") !== -1;

      updatePeriod({
        isFact,
        isStaff,
      });
    }
  }, [props.cellData]);

  const onBlur = async () => {
    setIsOutlinedInput(false);
    props.setFocusedCellId("");

    if (inputValue === "" && !Number.isFinite(props.cellData.value)) {
      return;
    }
    if (Number(inputValue) > 0 && Number(inputValue) === initialValue.current) {
      return;
    }

    const isFact = props.cellData.cell.indexOf("fact") !== -1;
    const isStaff = props.cellData.cell.indexOf("staff") !== -1;

    if (!isFact && !isStaff) {
      return;
    }
    // eslint-disable-next-line no-negated-condition
    if (!props.cellData.periodId) {
      if (props.createPeriod.isLoading) {
        setNeedToUpdatePeriodAfterCreation(true);
      } else {
        const newPeriod = await props.createPeriod.mutateAsync({
          id: props.sectionItemId,
          body: {
            actualVolume: isFact ? Number(inputValue) : null,
            staffVolume: isStaff ? Number(inputValue) : null,
            date: props.cellData.startDate,
          },
          additionalData: {
            controlType: "DAILY",
          },
        });
        props.updatePeriodDays?.({
          value: inputValue,
          periodId: newPeriod.id,
        });
      }
    } else {
      updatePeriod({
        isFact,
        isStaff,
      });
    }
  };

  const onFocus = () => {
    if (!isPlanCell) {
      return;
    }

    if (PlanStatusStore.currentEditableItemId && PlanStatusStore.currentEditableItemId !== props.sectionItemId) {
      inputRef.current.blur();
      emitter.emit(Events["show_modal_you_need_to_save_or_cancel_plan_changes"]);
      return;
    }
    PlanStatusStore.setCurrentEditableItemId(props.sectionItemId);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Escape") {
      setInputValue(props.cellData.value.toString());

      if (isPlanCell) {
        props.planInputData.onChange(props.cellData.value.toString());
      }

      inputRef.current.blur();
      return;
    }

    props.keyDown(
      e,
      String(props.tabIndex * 3 + props.currIndex + 2 + props.weekly ?? 0),
      String(props.tabIndex * 3 + props.currIndex + 2 - (props.weekly ? 0 : 1))
    );
  };

  React.useEffect(() => {
    if (isPlanCell) {
      setInputValue(props.planInputData?.value?.toString());
      return;
    }
    setInputValue(props.cellData.value.toString());
  }, [props.cellData.value, props.planInputData]);

  const isDisabledFactValue = () => {
    if (props.cellData.cell.indexOf("fact") !== -1) {
      const diff = dayjs().startOf("day").diff(dayjs(props.cellData.startDate).startOf("day"), "days");
      if (diff < 0) {
        return true;
      }
    }
    return false;
  };

  const canChangeCell = () => {
    if (props.cellData.cell.indexOf("volume") !== -1) {
      return restrictedData.fn.canEditAndApprovalPlan(props.currentPlan) || restrictedData.fn.canSendPlanForApproval(props.currentPlan);
    }

    if (props.cellData.cell.indexOf("fact") !== -1) {
      return restrictedData.vars.canInputFactWork;
    }

    if (props.cellData.cell.indexOf("staff") !== -1) {
      return restrictedData.vars.canInputStaffWork;
    }

    return true;
  };

  return (
    <>
      <InputCell
        disabled={isDisabledFactValue() || !canChangeCell()}
        onClick={onClickInput}
        onChange={onChangeInputValue}
        outlined={isOutlinedInput}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={inputRef}
        value={inputValue}
        tabIndex={-1}
        onKeyDown={onKeyDown}
        id={props.id}
        autoComplete="off"
        data-sectionItem-id={props.sectionItemId}
      />
    </>
  );
});

export default CalendarTableCellValue;

const InputCell = styled.input<{ outlined: boolean }>`
  background-color: transparent;
  text-align: center;
  outline: ${(props) => (props.outlined ? `1px solid ${props.theme.colors.blue400}` : "none")};

  height: 95%;
`;
