import { FC } from "react";

import InputMask from "react-input-mask";
import styled from "styled-components";

import { ReactComponent as SearchIcon } from "assets/search.svg";

interface ISearch {
  mask?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const Search: FC<ISearch> = (props) => {
  const { onChange, ...inputProps } = props;
  return (
    <Container className="flex items-center">
      <SearchIcon />
      <InputContainer className="flex flex-col">
        {inputProps.mask ? <InputMask {...inputProps} /> : <Input onChange={onChange} placeholder={props.placeholder} />}
      </InputContainer>
    </Container>
  );
};

export default Search;

const Container = styled.div`
  padding-left: 18px;
  display: flex;
  align-items: center;
  height: 43px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
  background-color: ${(props) => props.theme.colors.absolute100};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 10px 8px 15px;
  justify-content: center;
  height: 43px;
  border-radius: 10px;
`;
const Input = styled.input`
  outline: none;
  width: 150px;
  border: 0;
`;
