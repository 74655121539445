import { observer } from "mobx-react-lite";
import { BrowserRouter, Route } from "react-router-dom";

import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import Auth from "screens/Auth/Auth";
import Callback from "screens/Auth/Callback";
import Logout from "screens/Auth/Logout";
import LogoutSuccess from "screens/Auth/Logout.Success";
import Contractors from "screens/Contractors/Contractors";
import Home from "screens/Home/Home";
import MeasureUnits from "screens/MeasureUnits/MeasureUnits";
import ObjectsEdit from "screens/ObjectsEdit/ObjectsEdit";
import ObjectsEditManually from "screens/ObjectsEdit/ObjectsEdit.Manually";
import Reference from "screens/Reference/Reference";
import RefSection from "screens/RefSection/RefSection";
import Settings from "screens/RefSection/Settings";
import Users from "screens/Users/Users";

import ExtendedRoutes from "./components/ExtendedRoutes";
import WithNavbar from "./components/WithNavbar";

const routesWithSideBarOrNavbar = [
  "/",
  "/users",
  "/references",
  "/references/measures",
  "/references/contractors",
  "/settings",
  "/settings/section",
  "/objects_edit",
  "/objects_edit/manually",
];

const MainRouter = observer(() => {
  const restrictedData = useRestrictedAccessData();

  return (
    <>
      <BrowserRouter>
        <ExtendedRoutes publicPaths={["/callback", "/auth"]}>
          <Route Component={Auth} path="/auth" />
          <Route Component={Callback} path="/callback" />
          <Route Component={Logout} path="/logout-screen" />
          <Route Component={LogoutSuccess} path="/logout-success" />
        </ExtendedRoutes>
        <WithNavbar routesPath={routesWithSideBarOrNavbar} publicPaths={[]}>
          <>
            <Route Component={Home} path="/" />
            {restrictedData.vars.canVisibleUsers && <Route Component={Users} path="/users" />}
            {restrictedData.vars.canVisibleReferences && (
              <Route path="/references">
                <Route index element={<Reference />} />
                <Route path="measures" element={<MeasureUnits />} />
                <Route path="contractors" element={<Contractors />} />
              </Route>
            )}
            {restrictedData.vars.canVisibleSettings && (
              <Route path="/settings">
                <Route index element={<Settings />} />
                <Route element={<RefSection />} path="section" />
              </Route>
            )}
            {restrictedData.vars.canVisibleAddAndChangeObjects && (
              <Route path="/objects_edit">
                <Route index element={<ObjectsEdit />} />
                <Route path="manually" element={<ObjectsEditManually />} />
              </Route>
            )}
          </>
        </WithNavbar>
      </BrowserRouter>
    </>
  );
});

export default MainRouter;
