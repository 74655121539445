import dayjs from "dayjs";
import { capitalize } from "lodash";

export default (args: { startDate: string; endDate: string; allMonths: string[]; monthWidth: number }) => {
  const startedPointPosition = getPointLine({ date: args.startDate, allMonths: args.allMonths, monthWidth: args.monthWidth });
  const endedPointPosition = getPointLine({ date: args.endDate, allMonths: args.allMonths, monthWidth: args.monthWidth });

  return {
    startPositionLine: startedPointPosition,
    width: endedPointPosition - startedPointPosition,
  };
};

const getPointLine = (args: { date: string; allMonths: string[]; monthWidth: number }) => {
  const startPlanDate = dayjs(args.date);
  const startPlanMonth = capitalize(startPlanDate.format("MMMM YYYY"));
  const startPlanMonthIdxInAllMonths = args.allMonths.findIndex((month) => month === startPlanMonth);

  const dayWidth = args.monthWidth / startPlanDate.daysInMonth();

  return args.monthWidth * startPlanMonthIdxInAllMonths + dayWidth * startPlanDate.date();
};
