import React, { FC } from "react";

import { useLocation } from "react-router-dom";
import styled from "styled-components";

import Dropdown from "components/Dropdown/Dropdown";
import useText from "hooks/useText";
import { Spacer } from "styles/common";

import HomeStore from "../store/Home.store";
import { HomeCheckboxSectionsStore } from "../store/HomeCheckbox.store";

import CalendarHeader from "./Calendar/Calendar.Header";

interface IHomeHeaders1023 {}

const HomeHeaders1023: FC<IHomeHeaders1023> = () => {
  const text = useText();
  const location = useLocation();
  const lessThan1023 = window.matchMedia("(max-width: 1023px");

  const PERIOD_OF_CONTROL = React.useMemo(
    () => [
      { value: "Show all", label: text.showAll },
      { value: "Daily", label: text.daily },
      // { value: "Weekly", label: text.weekly },
    ],
    [text]
  );

  const setPeriodOfControl = (changedPeriod: { value: string }) => {
    const periodOfControl = PERIOD_OF_CONTROL.find((item) => item.value === changedPeriod.value);
    HomeStore.setPeriodOfControl(periodOfControl as any);
  };

  if (location.pathname !== "/" || !lessThan1023.matches) {
    return <></>;
  }

  return (
    <Container>
      <Group>
        <div>
          <Dropdown
            type="multi-select"
            data={HomeCheckboxSectionsStore.checkboxItems}
            onChange={HomeCheckboxSectionsStore.toggleCheckedItem}
            checkboxCheckedIds={HomeCheckboxSectionsStore.checkboxSelectedItems?.map((section) => section.id)}
            selectedValue={HomeCheckboxSectionsStore.checkboxSelectedString}
          />
        </div>

        <Spacer horizontal px={10} />
        <div>
          <Dropdown
            type="simple"
            data={PERIOD_OF_CONTROL}
            onChange={setPeriodOfControl}
            selectedValue={HomeStore.selectedPerionOfControl?.label ?? text.controlPeriod}
          />
        </div>
      </Group>
      <CalendarHeader />
    </Container>
  );
};

export default HomeHeaders1023;

const Group = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
