import { FC, useState } from "react";
import React from "react";

import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { INode } from "components/Tree/Tree.Node";
import TreeStore from "components/Tree/Tree.store";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useTreeData from "hooks/useTreeData/useTreeData";

import ObjectNames from "./components/ObjectNames";

const ObjectsMonitoring: FC = observer(() => {
  const [nameBuilding, setNameBuilding] = useState("");

  const { areas, data: treeData } = useTreeData();
  const [objectItems, setObjectItems] = React.useState<INode[]>([]);

  React.useEffect(() => {
    const currentObjId = TreeStore.selectedObject?.id;
    const currentArea = TreeStore.selectedObject?.meta?.area;

    if (currentArea === "complex") {
      const currentComplexChildren = areas.areas.filter((area) => area?.parentId === currentObjId);

      setObjectItems(currentComplexChildren);
      setNameBuilding("участка");
    } else if (currentArea === "area") {
      const currentAreasChildren = areas.buildings?.filter((area) => area?.parentId === currentObjId);
      setObjectItems(currentAreasChildren);
      setNameBuilding("литера");
    } else if (currentArea === "building") {
      const currentSectionChildren = areas.sections?.filter((area) => area?.parentId === currentObjId);
      setObjectItems(currentSectionChildren);
      setNameBuilding("секции");
    } else {
      setNameBuilding("");
      setObjectItems([]);
    }
  }, [TreeStore.selectedObject, treeData]);

  return (
    <>
      <div className="flex flex-col gap-5">
        <ObjectNames />
        <RenderWithCondition condition={!isEmpty(objectItems)}>
          <ContainerTable className="flex flex-col">
            <TableHeader className="flex ">{`Название ${nameBuilding}`}</TableHeader>
            <TransitionGroup component={null}>
              {objectItems?.map((item) => (
                <CSSTransition key={item.id} classNames="stagger" timeout={300}>
                  <TableItem className="flex">{item.name}</TableItem>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </ContainerTable>
        </RenderWithCondition>
      </div>
    </>
  );
});

export default ObjectsMonitoring;

const ContainerTable = styled.div`
  max-width: 444px;
  color: ${(props) => props.theme.colors.absolute700};
`;

const TableHeader = styled.div`
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid ${(props) => props.theme.colors.absolute300};
  background-color: ${(props) => props.theme.colors.blue200};
`;
const TableItem = styled.div`
  height: 48px;
  padding: 10px;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.absolute300};
  margin-top: -1px;

  /* Additional styling for the animation */
  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
`;
