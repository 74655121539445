import React from "react";
import { FC } from "react";

import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import Dropdown from "components/Dropdown/Dropdown";
import Search from "components/Search";
import { lessThan1023 } from "constants/mediaQuery";
import useText from "hooks/useText";
import HomeStore from "screens/Home/store/Home.store";
import { HomeCheckboxSectionsStore } from "screens/Home/store/HomeCheckbox.store";
import { Spacer } from "styles/common";

const UsersHeader1023: FC = observer(() => {
  const text = useText();
  const location = useLocation();

  const setRegion = (item: { value: string }) => HomeStore.setSelectedRegion(item.value);

  if (location.pathname === "/users" && lessThan1023.matches) {
    return (
      <Container className="flex flex-col">
        <Group>
          <div className="flex-grow w-1/2">
            <Dropdown
              type="multi-select"
              data={HomeCheckboxSectionsStore.checkboxItems}
              onChange={HomeCheckboxSectionsStore.toggleCheckedItem}
              checkboxCheckedIds={HomeCheckboxSectionsStore.checkboxSelectedItems?.map((section) => section.id)}
              selectedValue={HomeCheckboxSectionsStore.checkboxSelectedString}
            />
          </div>
          <Spacer horizontal px={20} />
          <div className="flex-grow w-1/2">
            <Dropdown
              type="multi-select"
              data={HomeCheckboxSectionsStore.checkboxItems}
              onChange={HomeCheckboxSectionsStore.toggleCheckedItem}
              checkboxCheckedIds={HomeCheckboxSectionsStore.checkboxSelectedItems?.map((section) => section.id)}
              selectedValue={HomeCheckboxSectionsStore.checkboxSelectedString}
            />
          </div>
        </Group>
        <Group>
          <div className="flex-grow w-1/2">
            <Dropdown
              type="tree-select"
              data={HomeStore.regions}
              onChange={setRegion}
              selectedValue={HomeStore.selectedRegion ?? "Участок"}
            />
          </div>
          <Spacer horizontal px={20} />
          <div className="flex-grow w-1/2">
            <Search placeholder={text.search} />
          </div>
        </Group>
      </Container>
    );
  }
  return <></>;
});

export default UsersHeader1023;

const Group = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
const Container = styled.div`
  padding: 0px 10px 20px;
  margin-top: -3px;
  gap: 20px;
`;
