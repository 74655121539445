import apiClient from "api/api.client";
import { IItemPeriodComment } from "models/Objects/IIemPeriodComment";
import { IItemPeriod } from "models/Objects/IItemPeriod";

export type TGetItemPeriodsApi = (
  id: string,
  additionalData: { controlType: string },
  params?: Pick<IItemPeriod, "startDate" | "endDate">
) => Promise<{
  items: IItemPeriod[];
  controlType: string;
}>;

export const getItemPeriodApi: TGetItemPeriodsApi = async (id, additionalData, params) => {
  try {
    const res = await apiClient.get(`objects/v1/items/${id}/periods`, { params });

    return {
      items: res.data,
      controlType: additionalData.controlType,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TCreatePeriodApi = (payload: {
  id: string;
  body: {
    actualVolume: number | null;
    staffVolume: number | null;
    date: string;
  };
  additionalData: { controlType: "DAILY" | "WEEKLY" };
}) => Promise<IItemPeriod>;

export const createPeriodApi: TCreatePeriodApi = async (payload) => {
  try {
    const res = await apiClient.post(`objects/v1/items/${payload.id}/periods`, payload.body);

    return {
      ...res.data,
      controlType: payload.additionalData.controlType,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TCreateCommentApi = (id: string, body: { message: string }) => Promise<IItemPeriod>;

export const createCommentApi: TCreateCommentApi = async (id, body) => {
  try {
    const res = await apiClient.post(`objects/v1/items/periods/${id}/comments`, body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TApprovePeriodApi = (args: {
  id: string;
  body: Pick<IItemPeriod, "volume">;
  params: Pick<IItemPeriod, "startDate" | "endDate">;
}) => Promise<IItemPeriod>;

export const approvePeriodApi: TApprovePeriodApi = async (args) => {
  try {
    const res = await apiClient.post(`objects/v1/items/${args.id}/periods/approve`, args.body, {
      params: args.params,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdatePeriodApi = (args: { id: string; body: Omit<Partial<IItemPeriod>, "volume"> }) => Promise<IItemPeriod>;

export const updatePeriodApi: TUpdatePeriodApi = async (args) => {
  try {
    const res = await apiClient.patch(`objects/v1/items/periods/${args.id}`, args.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteCommentApi = (id: string) => Promise<void>;

export const deleteCommentApi: TDeleteCommentApi = async (id) => {
  try {
    const res = await apiClient.delete(`/v1/items/periods/comments/${id}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
