import dayjs from "dayjs";
import { capitalize } from "lodash";

export default (args?: { year?: string; fromMonth?: number; to?: number }) => {
  const listMonth: string[] = [];

  const year = args?.year || dayjs().format("YYYY");
  const fromMonth = args?.fromMonth ?? 0;
  const to = args?.to ?? 11;

  for (let i = fromMonth; i <= to; i++) {
    const month = capitalize(dayjs(year).month(i).format("MMMM YYYY"));
    listMonth.push(month);
  }

  return listMonth;
};
