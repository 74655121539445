import styled from "styled-components";

import { RenderWithCondition } from "hoc/RenderWithCondition";

interface IProps {
  condition: boolean;
  posY?: string;
  size?: string;
}

export const EmptyList = ({ condition, posY }: IProps) => {
  if (condition) {
    return (
      <RenderWithCondition condition={condition}>
        <TextContainer condition={condition} posY={posY ?? "0px"}>
          <Text>Список пуст</Text>
        </TextContainer>
      </RenderWithCondition>
    );
  }
  return null;
};

const TextContainer = styled.section<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${(props) => props.posY};
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.backgroundOpacity60};
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.absolute500};
`;
