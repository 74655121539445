import React from "react";
import { FC } from "react";

import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import AuthStore from "store/Auth.Store";

const Auth: FC = observer(() => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (AuthStore.checkAuth()) {
      navigate("/");
    } else {
      AuthStore.login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Loading...</div>;
});

export default Auth;
