import axios, { AxiosResponse } from "axios";

import { ITokens } from "models/Tokens";
import { ICurrentUser } from "models/Users/IUser";

import apiClient from "./api.client";

const credentials = `web-client:${process.env.REACT_APP_CLIENT_SECRET}`;
const encodedCredentials = btoa(credentials);

const authApiClient = axios.create({
  baseURL: `https://${window.location.host}/oauth2/token`,
  headers: { Authorization: `Basic ${encodedCredentials}`, "Content-Type": "application/x-www-form-urlencoded" },
});

const revokeTokenClient = axios.create({
  baseURL: `https://${window.location.host}/oauth2/revoke`,
  headers: { Authorization: `Basic ${encodedCredentials}`, "Content-Type": "application/x-www-form-urlencoded" },
});

export type TGetTokensApi = (auth_code: string) => Promise<ITokens>;

export const getTokensApi: TGetTokensApi = async (auth_code) => {
  try {
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("code", auth_code);
    params.append("redirect_uri", `https://${window.location.host}/callback`);

    const res: AxiosResponse<ITokens> = await authApiClient.post("", params);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateTokensApi = () => Promise<ITokens>;

export const updateTokensApi: TUpdateTokensApi = async () => {
  try {
    const refresh_token = localStorage.getItem("refresh_token");

    const data = new URLSearchParams();
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", refresh_token);
    data.append("client_id", "web-client");

    const res: AxiosResponse<ITokens> = await authApiClient.post("", data);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TLogoutApi = () => Promise<any>;

export const logoutApi: TLogoutApi = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_AUTH_URL}/logout`,
      {},
      {
        headers: {
          // Authorization: `Bearer ${AuthStore.getTokens().access_token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, PATCH",
          "Access-Control-Allow-Headers": "*",
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetUserMeApi = () => Promise<ICurrentUser>;

export const getUserMeApi: TGetUserMeApi = async () => {
  try {
    const res = await apiClient.get("/users/v1/users/info");

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
