import { FC } from "react";
import React from "react";

import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import styled from "styled-components";

import { IRefSectionTableData } from "models/Objects/ITemplateSection";
import { Table, TableCell, TableHeader, TableRow } from "screens/Home/styles/common";

interface IRefSectionTableItem {
  width: number | string;
  data: IRefSectionTableData[];
  columns: any;
}

const RefSectionTableItem: FC<IRefSectionTableItem> = (props) => {
  const [columnVisibility, setColumnVisibility] = React.useState({});

  const table = useReactTable({
    data: props.data ?? [],
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  return (
    <>
      <Table width={props.width}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader className="text-sm" key={header.id} style={{ height: 48 }}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                // @ts-ignore
                const style = cell.column.columnDef.meta?.styleCol || {};
                // @ts-ignore

                return (
                  <TableCell className="h-12" style={{ ...style }} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default RefSectionTableItem;

export const LeftButtonTable = styled.button`
  height: 100%;
  width: 20px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.blue200};
`;
