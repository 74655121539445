export enum Events {
  "selected_item" = "selected_item",
  "selected_clear" = "selected_clear",
  "close_away_dropdown" = "close_away_dropdown",
  "click_dropdown" = "click_dropdown",
  "close_dropdown" = "close_dropdown",
  "close_modal" = "close_modal",
  "get_data_for_select" = "get_data_for_select",
  "close_other_history_modals" = "close_other_history_modals",
  "focused_calendar_table_cell" = "focused_calendar_table_cell",
  "show_modal_you_need_to_save_or_cancel_plan_changes" = "show_modal_you_need_to_save_or_cancel_plan_changes",
  "trigger_collapse_calendar_table" = "trigger_collapse_calendar_table",
  "trigger_collapse_main_table" = "trigger_collapse_main_table",
}
