import React, { FC, useCallback } from "react";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { createPortal } from "react-dom";
import { useQuery } from "react-query";
import styled, { css } from "styled-components";

import { getHistoryAgreementsApi } from "api/Objects/api.objects";
import { getUsersApi } from "api/Users/api.users";
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { Events } from "models/Events";
import { QueriesKeys } from "models/QueriesKeys";
import emitter from "services/emitter";
import { getRoleText } from "utils/user";

interface ICalendarHistory {
  currentPlanId: string;
}

const HISTORY_MODAL_WIDTH = 400;

const CalendarHistorl: FC<ICalendarHistory> = (props) => {
  const [isVisibleModal, setIsVisibleModal] = React.useState(false);

  const users = useQuery({
    queryFn: () => getUsersApi({}),
    queryKey: [QueriesKeys.users],
  });

  const historyData = useQuery({
    queryFn: () =>
      getHistoryAgreementsApi({
        path: {
          id: props.currentPlanId!,
        },
      }),
    queryKey: [QueriesKeys.statusPlanHistory, props.currentPlanId],
    enabled: !!props.currentPlanId,
  });

  const getHistoryDataText = useCallback(() => {
    const historyDataListString = historyData.data?.map((history) => {
      const date = dayjs(history.dateTime);
      const dateTime = `${date.format("DD.MM.YY")}г. в ${date.format("HH:mm")}`;

      let whoDidTheAction = "";

      switch (history.status) {
        case "APPROVED":
          whoDidTheAction = "Согласовал(а): ";
          break;
        case "IN_PROCESS":
          whoDidTheAction = "Отправил(а) на согласование: ";
          break;
        case "CANCELED":
          whoDidTheAction = "Отозвал(а) согласование: ";
          break;
        case "EDITED":
          whoDidTheAction = "Отредактировал(а) согласование: ";
      }

      const user = users.data?.find((currUser) => currUser.uaaId === history.userId);

      let initials = `${user?.firstName} ${user?.lastName.slice(0, 1)}.`;
      if (user?.middleName) {
        initials += ` ${user.middleName.slice(0, 1)}.`;
      }

      whoDidTheAction += `${getRoleText(user?.role).toLowerCase()} ${initials}`;

      return {
        dateTime,
        whoDidTheAction,
      };
    });

    return historyDataListString;
  }, [historyData.data, users.data]);

  const isOutOfInfoIcon = React.useRef(false);

  const closeModal = useCallback(() => setIsVisibleModal(false), []);
  const openModal = () => {
    emitter.emit(Events["close_other_history_modals"]);
    isOutOfInfoIcon.current = false;
    setIsVisibleModal(true);
  };
  const onMouseLeave = () => {
    isOutOfInfoIcon.current = true;
  };

  React.useEffect(() => {
    emitter.addListener("close_other_history_modals", closeModal);

    return () => {
      emitter.removeListener("close_other_history_modals", closeModal);
    };
  }, []);

  const [positionModal, setPositionModal] = React.useState({ top: 20, left: 123 });
  const refPositionModal = React.useRef({ top: 20, left: 123 });
  const modalRef = React.useRef<HTMLDivElement>();
  const iconContainerRef = React.useRef<HTMLDivElement>();

  const setPositionModalHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const newObject = {
      top: e.pageY,
      left: e.pageX - (HISTORY_MODAL_WIDTH + 40),
    };
    refPositionModal.current = newObject;
    setPositionModal(newObject);
  };

  const listener = React.useCallback(
    (e: MouseEvent) => {
      if (isOutOfInfoIcon.current) {
        if (e.pageY < refPositionModal.current.top - 20) {
          closeModal();
        }

        if (modalRef.current?.offsetHeight) {
          if (e.pageY > positionModal.top + modalRef.current.offsetHeight + 20) {
            closeModal();
          }
        }

        if (modalRef.current?.offsetWidth) {
          if (e.pageX < refPositionModal.current.left) {
            closeModal();
          }
        }
      }
    },
    [isVisibleModal, positionModal]
  );

  React.useEffect(() => {
    if (isVisibleModal) {
      document.addEventListener("mousemove", listener);
    } else {
      document.removeEventListener("mousemove", listener);
    }
  }, [isVisibleModal]);

  // eslint-disable-next-line arrow-body-style
  React.useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", listener);
    };
  }, []);

  return (
    <>
      {!isEmpty(historyData.data) && (
        <ContainerIcon ref={iconContainerRef} onMouseEnter={openModal} onMouseLeave={onMouseLeave} onMouseMove={setPositionModalHandler}>
          {historyData && <InfoIcon />}
        </ContainerIcon>
      )}
      {isVisibleModal &&
        createPortal(
          <Modal ref={modalRef} top={positionModal.top} left={positionModal.left}>
            <ModalContent>
              <GreenLine />
              <TextContainer>
                {getHistoryDataText()?.map((historyText, idx) => (
                  <HistoryTextContainer style={{ marginTop: idx > 0 ? "10px" : "0" }}>
                    <HistoryDateTimeText>{historyText.dateTime}</HistoryDateTimeText>
                    <HistoryWhoDidTheActionText>{historyText.whoDidTheAction}</HistoryWhoDidTheActionText>
                  </HistoryTextContainer>
                ))}
              </TextContainer>
            </ModalContent>
          </Modal>,
          document.getElementById("modal-root")
        )}
    </>
  );
};

export default CalendarHistorl;

const Modal = styled.div<{ top: number; left: number }>`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left + 20}px`};
`;

const ContainerIcon = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  width: ${HISTORY_MODAL_WIDTH}px;
  max-height: 400px;
  background-color: ${(props) => props.theme.colors.absolute100};
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px #0013551c;
`;

const GreenLine = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.green400};
  width: 1px;
  margin: 10px 10px;
  height: calc(100% - 20px); // ? 20 - margin sum of margin top/bottom
`;

const TextContainer = styled.div`
  padding: 10px 10px;
  padding-left: 20px;
`;

const HistoryTextContainer = styled.div``;

const historyTextMixin = css`
  line-height: 24px;
`;

const HistoryDateTimeText = styled.p`
  ${historyTextMixin}
  color: ${(props) => props.theme.colors.absolute500};
`;

const HistoryWhoDidTheActionText = styled.p`
  ${historyTextMixin}
  color: ${(props) => props.theme.colors.absolute700};
`;
