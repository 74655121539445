import React, { FC } from "react";

import styled from "styled-components";

import { IMeasureUnit } from "models/References/IMeasureUnit";
import { Spacer } from "styles/common";

import ReferTableItem from "./ReferTableItem";

interface IMeasureTable {
  onClickHandle?: (e: IMeasureUnit) => void;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MeasureTable: FC<IMeasureTable> = (props) => (
  <div className="flex flex-col">
    <Container className="flex">
      {/* TODO: ==== TEMPORARY w-[350px] - while the "Bin-delete" is hidden ========== */}
      <div className="flex w-[350px] ">
        <ReferTableItem handleOpenUpdate={props.onClickHandle} width="100%" />
      </div>
      <Spacer px={30} horizontal />
    </Container>
  </div>
);

export default MeasureTable;

const Container = styled.section`
  position: relative;
  // padding: 10px;
`;
