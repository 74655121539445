import React from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { useMutation, useQuery } from "react-query";

import { deleteSectionItemApi } from "api/Objects/api.objects";
import { getTemplateSectionsApi } from "api/Objects/api.sections";
import { getContractorsApi } from "api/References/api.contractor";
import { getWorksApi } from "api/References/api.works";
import { queryClient } from "App";
import { ReactComponent as Bin } from "assets/bin.svg";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import { QueriesKeys } from "models/QueriesKeys";

import useText from "../useText";

const useReferenceTableData = () => {
  const restrictedData = useRestrictedAccessData();

  const text = useText();

  const contractors = useQuery({ queryKey: QueriesKeys.contractors, queryFn: getContractorsApi });
  const works = useQuery({ queryKey: QueriesKeys.works, queryFn: getWorksApi });

  const templateSectionItems = useQuery({
    queryFn: () => getTemplateSectionsApi(),
    queryKey: [QueriesKeys.sectionItems],
  });

  const deleteTemplateSectionItem = useMutation(deleteSectionItemApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.sectionItems]);
    },
  });

  const refSectionTableData = templateSectionItems?.data?.items?.map((item) => ({
    contractor: { value: contractors.data?.find((contractor) => contractor?.id === item?.contractorId)?.name, rowId: item?.id },
    name_of_the_work: { value: works.data?.find((work) => work?.id === item?.workId)?.name, rowId: item?.id },
    measureUnits: { value: works.data?.find((work) => work?.id === item?.workId)?.measureUnit?.value, rowId: item.id },
    control: { value: item.controlType === "DAILY" ? "Дн" : "Нед", rowId: item?.id },
    delete: "",
  }));

  const columnHelper = createColumnHelper<any>();

  const getColumns = () => {
    const columns = [
      columnHelper.accessor("contractor", {
        header: text.contractor,
        cell: (info) => <div className="flex grow w-full">{info.row.original.contractor.value}</div>,
        enableResizing: true,
        meta: { styleCol: { textAlign: "left", paddingLeft: "8px", height: "48px" } },
      }),
      columnHelper.accessor("name_of_the_work", {
        header: text.jobTitle,
        cell: (info) => <div className="flex">{info.row.original.name_of_the_work.value}</div>,
        enableResizing: true,
        meta: { styleCol: { textAlign: "left", paddingLeft: "8px", height: "48px" } },
      }),
      columnHelper.accessor("measureUnits", {
        header: text.measureUnits,
        cell: (info) => (
          <div className="flex justify-center gap-7">
            <div className="w-6">{info.row.original.measureUnits.value}</div>
          </div>
        ),
        enableResizing: true,
        meta: { styleCol: { textAlign: "center", padding: "10px 20px", height: "48px" } },
      }),
      columnHelper.accessor("control", {
        header: text.control,
        cell: (info) => (
          <div className="flex justify-center gap-7">
            {info.row.original.control.value}
            {/* <Dropdown
            data={[
              { value: "Ежедневный", label: "DAILY" },
              // { value: "Еженедельный", label: "WEEKLY" },
            ]}
            onChange={function (item: { value: string; label?: string; id?: string }): void {
              updateSectionItem.mutateAsync({
                id: info.row.original.control.rowId,
                body: {
                  controlType: item.label,
                },
              });
            }}
            dropdownStyles={{ marginTop: "-10px", marginLeft: "-115px" }}
            dropdownItemStyles={{ padding: "4px 0" }}
            type="simple"
            CustomButton={(props) => (
              <div className="flex justify-center items-center">
                <button onClick={props.onClick} className="w-6 flex justify-center items-center">
                  <SetMenu />
                </button>
              </div>
            )}
          /> */}
          </div>
        ),
        enableResizing: true,
        meta: { styleCol: { paddingLeft: "8px", padding: "8px", height: "48px" } },
      }),
    ];

    if (restrictedData.vars.canCreateAndUpdateReferenceSection) {
      const deleteColumn = columnHelper.accessor("delete", {
        header: text.delete,
        cell: (info) => (
          <div className="flex justify-center">
            <button
              className="w-6 flex justify-center items-center"
              onClick={() => {
                deleteTemplateSectionItem.mutateAsync(info.row.original.control.rowId);
              }}
            >
              <Bin fill="#DB333D" />
            </button>
          </div>
        ),
        enableResizing: true,
        meta: { styleCol: { padding: "8px", height: "48px" } },
      });

      return [...columns, deleteColumn];
    }

    return columns;
  };

  return {
    templateId: templateSectionItems?.data?.id,
    columns: {
      templateColumns: getColumns(),
    },
    data: {
      templateSection: refSectionTableData,
      isLoadingTemplateSection: templateSectionItems.isFetching,
    },
  };
};

export default useReferenceTableData;
