import React from "react";
import { FC } from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";

import { getWorksApi } from "api/References/api.works";
import Dropdown from "components/Dropdown/Dropdown";
import { INode } from "components/Tree/Tree.Node";
import TreeStore from "components/Tree/Tree.store";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useTreeData from "hooks/useTreeData/useTreeData";
import { QueriesKeys } from "models/QueriesKeys";
import ButtonsTypeSwitch from "screens/Home/components/ButtonsTypeSwitch";

import HomeStore from "../store/Home.store";
import { HomeCheckboxBuildingsStore, HomeCheckboxSectionsStore, HomeCheckboxWorksStore } from "../store/HomeCheckbox.store";
import getAllBuildingsIds from "../utils/getAllBuildingsIds";
import getAllWorksIds from "../utils/getAllWorksIds";

import useAreaQuery from "./Gant/hooks/useAreaQuery";
import HomeHeaderGant from "./Home.Header.Gant";

interface IMainHomeHeaderCharts {
  treeData: any;
  selectedBuildingName: string;
  setBuilding: (item: INode, closeDropdownCallback?: () => void) => void;
  GantHeaders?: FC;
}

const MainHomeHeaderCharts: FC<IMainHomeHeaderCharts> = observer((props) => {
  const { areaStatistic } = useAreaQuery();
  const works = useQuery({ queryKey: QueriesKeys.works, queryFn: getWorksApi });
  const { areas } = useTreeData();

  React.useEffect(() => {
    const areaWorksIds = getAllWorksIds(areaStatistic.data);

    const currentWorks = works.data?.filter((work) => areaWorksIds?.includes(work.id));

    HomeCheckboxWorksStore.setCheckboxItems(currentWorks);
  }, [works.data, areaStatistic.data]);

  React.useEffect(() => {
    if (TreeStore.selectedObject.meta.area === "area") {
      const areaBuildingsIds = getAllBuildingsIds(areaStatistic.data);

      const currentBuildings = areas?.buildings?.filter((building) => areaBuildingsIds?.includes(building.id));

      HomeCheckboxBuildingsStore.setCheckboxItems(currentBuildings);
    }
  }, [TreeStore.selectedObject, areas.buildings, areaStatistic.data]);

  React.useEffect(() => {
    HomeCheckboxSectionsStore.setCheckboxItems(areas.sections.filter((section) => section.parentId === TreeStore.selectedBuilding?.id));
  }, [areas.buildings, TreeStore.selectedBuilding]);

  if (TreeStore.isEmptyTreeData) {
    return <></>;
  }

  const handleOnChangeData = (e) => {
    HomeStore.setTypeDataForCharts(e);
  };

  return (
    <>
      <Group>
        <div className="flex gap-4">
          <ButtonRow>
            <ButtonsTypeSwitch onClick={handleOnChangeData} />
          </ButtonRow>
          <div>
            <Dropdown
              type="multi-select"
              data={HomeCheckboxBuildingsStore.checkboxItems}
              onChange={HomeCheckboxBuildingsStore.toggleCheckedItem}
              checkboxCheckedIds={HomeCheckboxBuildingsStore.checkboxSelectedItems?.map((section) => section.id)}
              selectedValue={HomeCheckboxBuildingsStore.checkboxSelectedString || "Выберите литеры"}
            />
          </div>
          <div>
            <Dropdown
              type="multi-select"
              data={HomeCheckboxWorksStore.checkboxItems}
              onChange={HomeCheckboxWorksStore.toggleCheckedItem}
              checkboxCheckedIds={HomeCheckboxWorksStore.checkboxSelectedItems?.map((section) => section.id)}
              selectedValue={HomeCheckboxWorksStore.checkboxSelectedString || "Выберите работы"}
            />
          </div>
        </div>
        <RenderWithCondition condition={HomeStore.tableMode === "gant"}>
          <HomeHeaderGant />
        </RenderWithCondition>
      </Group>
    </>
  );
});

export default MainHomeHeaderCharts;

export const Group = styled.div`
  display: flex;
  /* padding-right: 30px; */
  /* width: 80%; */
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;
export const ButtonRow = styled.div`
  display: flex;
  height: 43px;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 4px 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.absolute100};
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.colors.shadow};
  border: 1px solid ${(props) => props.theme.colors.absolute300};
`;
