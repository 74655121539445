import dayjs from "dayjs";

import { ReactComponent as DeciationFrom16Legend } from "assets/deviationFrom16Legend.svg";
import { ReactComponent as DeciationUpTo0_5Legend } from "assets/deviationUpTo0_5Legend.svg";
import { ReactComponent as DeciationUpTo15Legend } from "assets/deviationUpTo15.svg";

import { TCellType } from "../models";

export const getBorderRightSize = (cellType: TCellType) => {
  if (cellType === "plan" || cellType === "endPlan" || cellType === "endFact") {
    return "2px";
  }
  return "1px";
};

export const getDateFormattedToView = (date: string) => {
  const formattedDate = dayjs(date).format("DD.MM.YYYY");

  if (formattedDate === "Invalid Date") {
    return "-";
  }

  return formattedDate;
};

export const getFactDeviationIcon = (actualInPercentage: number, planInPercentage: number) => {
  const difference = planInPercentage - actualInPercentage;
  if (difference <= 5) {
    return DeciationUpTo0_5Legend;
  }

  if (difference >= 5 && difference <= 15) {
    return DeciationUpTo15Legend;
  }

  return DeciationFrom16Legend;
};

export const isShouldWorkBegun = (planDate?: string, factDate?: string) => isDeadlineOverdueAndNoDateFact(planDate, factDate);

export const isDeadlineOverdueAndNoDateFact = (planDate?: string, factDate?: string) => {
  if (!planDate) {
    return false;
  }

  if (!factDate) {
    const currentDate = dayjs();
    const currPlanDate = dayjs(planDate);

    if (currPlanDate.isSame(currentDate) || currPlanDate.isBefore(currentDate)) {
      return true;
    }
  }

  return false;
};
