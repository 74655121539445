import React from "react";
import { FC } from "react";

import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ModelBlue } from "assets/model-blue.svg";
import { ReactComponent as Model } from "assets/model.svg";
import { ReactComponent as SettingsBlue } from "assets/settings-blue.svg";
import { ReactComponent as Settings } from "assets/settings.svg";
import { IDropdownItem } from "components/Dropdown/Dropdown";
import DropdownList from "components/Dropdown/Dropdown.List";
import useText from "hooks/useText";
import { LIGHT_THEME } from "theme/colors";

const NavBarRefSection: FC = observer(() => {
  const text = useText();
  const navigate = useNavigate();

  const onChange = (changedItem: { value: string }) => {
    const linkTo = NAV_MENU.find((item) => item.value === changedItem.value)?.linkTo;
    navigate(linkTo);
  };

  const NAV_MENU: IDropdownItem[] = React.useMemo(
    () => [
      { value: text.referenceSection, label: "section", icon: Model, activeIcon: ModelBlue, linkTo: "/settings/section" },
      { value: text.settings, label: "settings", icon: Settings, activeIcon: SettingsBlue, linkTo: "/settings" },
    ],
    [text]
  );
  return (
    <Container className="flex flex-col items-center">
      <NavList>
        <DropdownList data={NAV_MENU} onChange={onChange} color={LIGHT_THEME.blue400} />
      </NavList>
    </Container>
  );
});

export default NavBarRefSection;

const NavList = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
`;
const Container = styled.div`
  min-width: 283px;
  background-color: ${(props) => props.theme.colors.blue100};
`;
