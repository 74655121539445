import apiClient from "api/api.client";
import { IContractor } from "models/References/IContractor";

export type TCreateContractorApi = (body: { name: string; contactName: string; contactPhone: string }) => Promise<IContractor>;

export const createContractorApi: TCreateContractorApi = async (body) => {
  try {
    const res = await apiClient.post("references/v1/contractors", body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetContractorsApi = () => Promise<IContractor[]>;

export const getContractorsApi: TGetContractorsApi = async () => {
  try {
    const res = await apiClient.get("references/v1/contractors");

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteContractorApi = (id: string) => Promise<void>;

export const deleteContractorApi: TDeleteContractorApi = async (id) => {
  try {
    const res = await apiClient.delete(`references/v1/contractors/${id}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateContractorApi = (payload: { body: Partial<IContractor>; path: { id: string } }) => Promise<IContractor>;

export const updateContractorApi: TUpdateContractorApi = async (payload) => {
  try {
    const res = await apiClient.patch(`references/v1/contractors/${payload.path.id}`, payload.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
