import React from "react";

import { IText } from "models/Text";
import { TextProviderContext } from "providers/TextProvider";

type THook = () => IText;

const useText: THook = () => {
  const { text } = React.useContext(TextProviderContext);
  return text;
};

export default useText;
