import React, { FC } from "react";

import styled from "styled-components";

import { SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY } from "./Dropdown/Dropdown.Overlay";

interface ICheckbox {
  label: string;
  checked: boolean;
  disabled?: boolean;
  nowrap?: boolean;
  noHover?: boolean;
  setChecked: (checked: boolean, innerText: string) => void;
}

const Checkbox: FC<ICheckbox> = (props) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    props.setChecked(e.target.checked, e.target.offsetParent.innerText);
  };

  return (
    <Container noHover={props.noHover}>
      <LabelInput className="flex">
        <input type="checkbox" disabled={props.disabled} checked={props.checked} onChange={handleCheckboxChange} />
        <Label nowrap={props.nowrap}>{props.label}</Label>
      </LabelInput>
    </Container>
  );
};

export default Checkbox;

const Label = styled.span<{ nowrap?: boolean }>`
  text-wrap: ${(props) => (props.nowrap ? "nowrap" : "none")};
  padding-left: 10px;
  cursor: pointer;
`;

const Container = styled.div<{ noHover?: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover {
    margin-left: -${SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY};
    margin-right: -${SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY};
    padding-left: ${SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY};
    padding-right: ${SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY};
    background-color: ${(props) => (props.noHover ? "transparent" : props.theme.colors.blue200)};
  }
`;
const LabelInput = styled.label`
  cursor: pointer;
  width: calc(100% + 24px);
  margin-left: -12px;
  padding-left: 12px;
`;
