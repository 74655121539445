import { FC, useState } from "react";

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ReactComponent as ChartDownIcon } from "assets/chart_down.svg";
import { ReactComponent as ChartUpIcon } from "assets/chart_up.svg";
import DropDown from "components/Dropdown/Dropdown";
import InfoTooltip from "components/InfoTooltip";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import { CurrentUserRoles } from "models/Users/IUser";

import HomeStore, { TTableMode } from "../store/Home.store";

interface IButtonsTypeSwitch {
  title?: string;
  icon?: string;
  disabled?: boolean;
  onClick?: (item: any) => void;
  fullWidth?: boolean;
}

const ButtonsTypeSwitch: FC<IButtonsTypeSwitch> = observer((props) => {
  const isDaily = HomeStore.typeDataForCharts?.label === "Daily";

  const onClickByHistogramButton = () => {
    HomeStore.setTableMode("histogram");
  };
  const onClickByGantButton = () => {
    HomeStore.setTableMode("gant");
  };

  return (
    <div className="flex gap-[5px]">
      <ButtonContainer
        onClick={onClickByHistogramButton}
        active={HomeStore.tableMode === "histogram"}
        className={classNames("flex justify-center items-center", props.fullWidth && "w-full")}
      >
        <p className="min-w-[125px]">Гистограмма</p>
      </ButtonContainer>
      {HomeStore.tableMode === "histogram" && (
        <div className="flex items-center justify-center w-7 relative">
          <DropDown
            data={[
              { label: "Daily", value: "Ежедневные данные" },
              { label: "Cumulative", value: "Накопительные данные" },
            ]}
            onChange={function (item: { value: string; label?: string; id: CurrentUserRoles }): void {
              props.onClick(item);
            }}
            dropdownStyles={{ width: "350px", marginTop: "130px", marginRight: "0px" }}
            dropdownItemStyles={{ padding: "4px 0" }}
            type="simple"
            CustomButton={(props) => (
              <button onClick={props.onClick} className="w-6 flex justify-center items-center">
                <InfoTooltip text={"Изменить тип данных"} type={"md"} top={-10}>
                  <IconWrap className="flex">
                    <RenderWithCondition condition={isDaily}>
                      <ChartDownIcon width={18} />
                    </RenderWithCondition>
                    <RenderWithCondition condition={!isDaily}>
                      <ChartUpIcon width={18} />
                    </RenderWithCondition>
                  </IconWrap>
                </InfoTooltip>
              </button>
            )}
          />
        </div>
      )}
      <ButtonContainer
        onClick={onClickByGantButton}
        disabled={HomeStore.tableMode === "gant"}
        active={HomeStore.tableMode === "gant"}
        className={classNames("flex justify-center items-center", props.fullWidth && "w-full")}
      >
        <p className="min-w-[125px]">Диаграмма Ганта</p>
      </ButtonContainer>
    </div>
  );
});
("");
export default ButtonsTypeSwitch;

const ButtonContainer = styled.button<{ active?: boolean }>`
  padding: 4px 10px;
  border: 0;
  border-radius: 4px;
  white-space: nowrap;
  transition: all 0.3s;
  background-color: ${(props) => (props.active ? props.theme.colors.blue400 : props.theme.colors.blue200)};
  color: ${(props) => (props.active ? props.theme.colors.absolute100 : props.theme.colors.absolute700)};
  &:hover {
    background-color: ${(props) => (props.active ? null : props.theme.colors.blue300)};
    color: ${(props) => (props.active ? null : props.theme.colors.blue400)};
  }
`;
const IconWrap = styled.div`
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  transition: all 0.3s;
  background-color: ${(props) => props.theme.colors.blue400};
  &:hover {
    /* background-color: ${(props) => props.theme.colors.blue200}; */
  }
`;
