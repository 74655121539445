import React, { FC, useState } from "react";

import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import styled from "styled-components";

import ButtonLight from "components/ButtonLight";
import { LocalLoader } from "components/Loader/LocalLoader";
import ModalForm from "components/Modal/ModalForm";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import MainTable from "screens/Home/components/MainTable/MainTable";
import ScrollableTable from "screens/Home/components/ScrollableTable/Scrollable.Table";
import { Spacer } from "styles/common";
import notify from "utils/notify";

import useTableData from "../../hooks/useTableData";

import ObjectNames from "./components/ObjectNames";
import useFormAddObjectData from "./hooks/useFormAddObjectData";
import ObjectStore from "./store/Object.store";
import { Title } from "./styles";

const ObjectsEditManually: FC = observer(() => {
  const [isHiddenColumns, setHiddenColumns] = useState(false);
  const text = useText();
  const form = useFormAddObjectData();
  const navigate = useNavigate();

  const restrictedData = useRestrictedAccessData();

  const containerRef = React.useRef<HTMLDivElement>();

  const tableData = useTableData({
    sectionId: ObjectStore.selectedSection?.id,
    openModalPeriod: form.fn.setOpenModalPeriod,
    setIsOpenModalTotalForProject: form.fn.setIsOpenModalTotalForProject,
    setIsOpenModalCompletedVolume: form.fn.setIsOpenModalCompletedVolume,
  });
  // @ts-ignore
  const errorUpdate = form.vars.errorUpdateSection?.response?.data?.message ?? undefined;
  const errorCreationSection = form.vars.errorCreationSection;

  React.useEffect(() => {
    if (errorUpdate) {
      notify.error(errorUpdate);
    }
    if (!isEmpty(tableData.data.right)) {
      ObjectStore.setEmptySection(false);
    }
  }, [errorUpdate, tableData.data.right]);

  React.useEffect(() => {
    if (!ObjectStore.selectedSection && !ObjectStore.selectedSection?.children.length) {
      navigate("/objects_edit");
    }
  }, [ObjectStore.selectedObject]);

  const getIsHidden = (value: boolean) => {
    setHiddenColumns(value);
  };

  return (
    <>
      <Container ref={containerRef}>
        <div className="px-5 pt-5">
          <Title>{`${text.addOrChangeObjects}. Вручную`}</Title>
        </div>
        <div className="px-5 pt-5">
          <ObjectNames />
        </div>
        <RenderWithCondition condition={!isEmpty(tableData.data.right)}>
          <div className="flex flex-col px-4 font-normal">
            <p>
              <SpanStar>*</SpanStar>
              {text.textCreateSections}
            </p>
            <p>
              <SpanStar>*</SpanStar>
              {text.textUpdateSections}
            </p>
          </div>
        </RenderWithCondition>
        <Spacer px={20} />
        <TableContainer>
          <MainTableContainer isHidden={isHiddenColumns}>
            <MainTable
              columns={tableData.columns.left}
              // @ts-ignore
              data={tableData.data.left ?? []}
              buildingSubHeader={ObjectStore.selectedBuilding?.name}
              getIsHidden={getIsHidden}
            />
          </MainTableContainer>
          <ScrollableTableContainer>
            <ScorllableTableContainerInner>
              <ScrollableTable
                // @ts-ignore
                data={tableData.data.right ?? []}
                columns={tableData.columns.right}
                isBuildingHeaderExist
              />
            </ScorllableTableContainerInner>
          </ScrollableTableContainer>
        </TableContainer>

        <RenderWithCondition condition={restrictedData.vars.canAddAndChangeObjects}>
          <div className="p-5">
            <ButtonLight type="secondary" title={text.add} onClick={() => form.fn.setIsVisibleModal(true)} />
          </div>
        </RenderWithCondition>
        <LocalLoader condition={tableData.data.isLoadingLeft} />
        <RenderWithCondition condition={form.vars.isVisibleModal}>
          <ModalForm
            label={"Добавить в секцию"}
            textButton={text.save}
            setIsVisible={form.fn.setIsVisibleModal}
            data={form.data.addObjectForm}
            onSave={form.fn.onSave}
            isLoading={form.vars.isLoading}
            isError={!!errorCreationSection}
          />
        </RenderWithCondition>
        <RenderWithCondition condition={form.vars.openModalPeriod}>
          <ModalForm
            label={"Изменить период"}
            textButton={text.save}
            setIsVisible={form.fn.setOpenModalPeriod}
            data={form.data.updatePeriodForm}
            onSave={form.fn.onSavePeriodDate}
            isLoading={form.vars.isLoadingUpdateSection}
            isError={!!errorUpdate}
          />
        </RenderWithCondition>
        <RenderWithCondition condition={form.vars.isOpenModalTotalForProject}>
          <ModalForm
            label={"Изменить кол-во по проекту"}
            textButton={text.save}
            setIsVisible={form.fn.setIsOpenModalTotalForProject}
            data={form.data.updateTotalForProjectForm}
            onSave={form.fn.onSaveTotalForProject}
            isLoading={form.vars.isLoadingUpdateSection}
            isError={!!errorUpdate}
          />
        </RenderWithCondition>
        <RenderWithCondition condition={form.vars.isOpenModalCompletedVolume}>
          <ModalForm
            label={"Выполненный объем"}
            textButton={text.save}
            setIsVisible={form.fn.setIsOpenModalCompletedVolume}
            data={form.data.updateCompletedVolumeForm}
            onSave={form.fn.onSaveCompletedVolume}
            isLoading={form.vars.isLoadingUpdateSection}
            isError={!!errorUpdate}
          />
        </RenderWithCondition>
      </Container>
    </>
  );
});

const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
`;

export default ObjectsEditManually;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const MainTableContainer = styled.div<{ isHidden: boolean }>`
  width: ${(props) => (props.isHidden ? "25%" : "75%")};
  /* width: 75%; */
`;
const ScrollableTableContainer = styled.div`
  /* overflow: auto; */
  width: 60%;
`;

const ScorllableTableContainerInner = styled.div`
  min-widh: 600px;
  margin-top: 1px;
`;
const SpanStar = styled.span`
  color: ${(props) => props.theme.colors.red400};
`;
