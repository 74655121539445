import React, { FC } from "react";

import { Routes, useLocation, useNavigate } from "react-router-dom";

import AuthStore from "store/Auth.Store";

interface IExtendedRoutes {
  children: React.ReactNode;
  publicPaths?: string[];
}

const ExtendedRoutes: FC<IExtendedRoutes> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const isPublicLocation = (props.publicPaths ?? []).includes(location.pathname);
    if (!AuthStore.checkAuth() && !isPublicLocation) {
      navigate("/auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <Routes>{props.children}</Routes>;
};

export default ExtendedRoutes;
