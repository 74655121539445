/* eslint-disable arrow-body-style */
import { FC, useCallback } from "react";
import React from "react";

import { cloneDeep, first, includes, isEmpty, range } from "lodash";
import { last } from "lodash";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled, { css } from "styled-components";

import { getPeriodAgreementApi, revokeAgreementsApi } from "api/Objects/api.objects";
import { getUsersApi } from "api/Users/api.users";
import { queryClient } from "App";
import { ReactComponent as CloseSmallIcon } from "assets/closeSmall.svg";
import { ReactComponent as SaveIcon } from "assets/save.svg";
import { ReactComponent as SendIcon } from "assets/send.svg";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import { IAgreement } from "models/Objects/IAgreement";
import { QueriesKeys } from "models/QueriesKeys";
import PlanStatusStore from "screens/Home/store/PlanStatus.store";
import CalendarStore from "store/Calendar.Store";
import { LIGHT_THEME } from "theme/colors";

import CalendarHistory from "./Calendar.History";
import { TCreatePeriodQuery, TUpdatePeriodQuery } from "./Calendar.Table";
import CalendarTableCellValue from "./Calendar.Table.Cell.Value";

export type TDailyDataItem = {
  value: number;
  cell: string;
  periodId?: string;
  startDate?: string;
  endDate?: string;
};

export type TDailyData = TDailyDataItem[];

type TWeeklyDataItem = {
  value: number;
  cell: string;
  periodId?: string;
  startDate?: string;
  endDate?: string;
};

export type TWeeklyData = TWeeklyDataItem[];

export interface ICalendarTableCell {
  controlType: "DAILY" | "WEEKLY";
  days?: TDailyData[];
  weeklyData?: TWeeklyData;
  isAgreed: boolean;
  sectionNameExist: boolean;
  sectionItemId: string;

  periodIdx: number;
  onApprove: (periodIdx: number) => void;
  createPeriod: TCreatePeriodQuery;
  updatePeriod: TUpdatePeriodQuery;
}

const colors = LIGHT_THEME;
const getColor = (data: TWeeklyDataItem | TDailyDataItem, currentPlan: IAgreement | undefined, isFocused: boolean) => {
  if (isFocused) {
    return colors.absolute100;
  }

  if (data.cell.indexOf("volume") !== -1) {
    if (currentPlan?.status === "APPROVED") {
      return colors.green100;
    }
    if (currentPlan?.status === "IN_PROCESS") {
      return colors.yellow200;
    }
    return colors.absolute100;
  }

  return colors.absolute100;
};

const getHoverColor = (data: TWeeklyDataItem | TDailyDataItem, isAgreed: boolean, isFocused: boolean) => {
  if (isFocused) {
    return colors.absolute100;
  }

  if (data.cell.indexOf("volume") !== -1) {
    if (isAgreed) {
      if (data.value) {
        return colors.green200;
      }
      return colors.absolute100;
    }
    if (data.periodId) {
      return colors.yellow300;
    }
    return colors.absolute200;
  }

  return colors.absolute200;
};

const getColorHeaderPlanText = (isApproved?: boolean) => {
  return isApproved ? colors.green400 : colors.absolute700;
};

export type TUpdatePeriodsDaysPayload = {
  periodId?: string;
  value: string;
};

const TOTAL_CELLS_COUNT_IN_A_WEEK_WITH_DAILY_PERIOD = 21;

const CalendarTableCell: FC<ICalendarTableCell> = observer((props) => {
  const [focusedCellId, setFocusedCellId] = React.useState("");

  const restrictedData = useRestrictedAccessData();

  const statusPlan = useQuery({
    queryFn: () =>
      getPeriodAgreementApi({
        params: {
          startDate: CalendarStore.calendarStartDate.format("YYYY-MM-DD"),
          endDate: CalendarStore.calendarEndDate.format("YYYY-MM-DD"),
          itemIds: props.sectionItemId,
        },
      }),
    queryKey: [QueriesKeys.statusPlan, CalendarStore.calendarStartDate, props.sectionItemId],
    enabled: !!props.sectionItemId,
  });

  const users = useQuery({
    queryFn: () => getUsersApi({ ids: null, roles: null }),
    queryKey: [QueriesKeys.users],
  });

  const revokeAgreement = useMutation({
    mutationFn: (id: string) =>
      revokeAgreementsApi({
        path: {
          id,
        },
      }),
  });

  const currentPlan = first(statusPlan.data);

  const planColorText = getColorHeaderPlanText(first(statusPlan.data)?.status === "APPROVED");

  const handleKeyDown = async (e: any, nextId?: string) => {
    const currID = e.target.id;
    const currInput = document.getElementById(currID);
    const NumbersCellsOfRowPlan = range(4, 23, 3);
    const NumbersCellsOfRowFact = range(5, 24, 3);
    const NumbersCellsOfRowStaff = range(6, 25, 3);
    const NumbersSectionsOfNextItem = 300;
    const LengthOfRow = 18;
    const LastCellNumberOfRowFact = last(NumbersCellsOfRowFact);

    if (e.key === "Enter" || e.key === "Return") {
      const nextInput = document.getElementById(nextId) as HTMLInputElement;

      if (includes(NumbersCellsOfRowStaff, Number(currID % 100))) {
        const nextStepToInput = document.getElementById((Number(currID) + NumbersSectionsOfNextItem - 1).toString()) as HTMLInputElement;
        if (nextStepToInput?.disabled) {
          const nextDoubleStepToInput = document.getElementById(
            (Number(currID) + NumbersSectionsOfNextItem).toString()
          ) as HTMLInputElement;
          nextDoubleStepToInput.focus();
        }
        if (nextStepToInput) {
          nextStepToInput.focus();
        } else {
          currInput.blur();
          e.preventDefault();
        }
      } else {
        if (nextInput?.disabled) {
          const nextDoubleStepToInput = document.getElementById((Number(currID) + 2).toString()) as HTMLInputElement;
          nextDoubleStepToInput.focus();
        }
        if (nextInput) {
          nextInput.focus();
        } else {
          currInput.blur();
          e.preventDefault();
        }
      }
    }

    if (e.key === "Tab") {
      const nextCellID = Number(currID) + 3;

      const nextStepToInput = document.getElementById(nextCellID.toString()) as HTMLInputElement;

      if (nextCellID % TOTAL_CELLS_COUNT_IN_A_WEEK_WITH_DAILY_PERIOD === 0) {
        await onApprove();
        const sectionItemId = nextStepToInput.getAttribute("data-sectionItem-id");
        PlanStatusStore.setCurrentEditableItemId(sectionItemId);
      }

      if (nextStepToInput?.disabled) {
        const nextRowToInput = document.getElementById(
          (Number(currID) + (LastCellNumberOfRowFact - (currID % 100)) + NumbersSectionsOfNextItem - LengthOfRow).toString()
        );
        if (nextRowToInput) {
          nextRowToInput?.focus();
          e.preventDefault();
        } else {
          currInput.blur();
          e.preventDefault();
        }
      }
      if (nextStepToInput) {
        nextStepToInput.focus();
        e.preventDefault();
      } else {
        const nextRowToInput = document.getElementById((Number(currID) + NumbersSectionsOfNextItem - LengthOfRow).toString());
        if (nextRowToInput) {
          nextRowToInput.focus();
          e.preventDefault();
        } else {
          currInput.blur();
          e.preventDefault();
        }
      }
    }
    if (e.key === "ArrowRight") {
      const nextArrowInput = document.getElementById((Number(currID) + 3).toString());
      if (nextArrowInput && !e.target.value) {
        nextArrowInput.focus();
      }
    }
    if (e.key === "ArrowLeft") {
      const nextArrowInput = document.getElementById((Number(currID) - 3).toString());
      if (nextArrowInput) {
        nextArrowInput.focus();
      }
    }
    if (e.key === "ArrowDown") {
      if (includes(NumbersCellsOfRowStaff, Number(currID % 100))) {
        const nextStepToInput = document.getElementById((Number(currID) + NumbersSectionsOfNextItem - 2).toString());
        if (nextStepToInput) {
          nextStepToInput.focus();
        }
      } else {
        const nextInput = document.getElementById(nextId) as HTMLInputElement;
        if (includes(NumbersCellsOfRowPlan, Number(currID % 100))) {
          if (nextInput?.disabled) {
            const nextStepToInput = document.getElementById((Number(currID) + 2).toString());
            nextStepToInput.focus();
          }
        }
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
    if (e.key === "ArrowUp") {
      if (includes(NumbersCellsOfRowPlan, Number(currID % 100))) {
        const nextStepToInput = document.getElementById((Number(currID) - NumbersSectionsOfNextItem + 2).toString());
        if (nextStepToInput) {
          nextStepToInput.focus();
        }
      } else {
        const nextInput = document.getElementById((Number(currID) - 1).toString()) as HTMLInputElement;
        if (includes(NumbersCellsOfRowStaff, Number(currID % 100))) {
          if (nextInput?.disabled) {
            const nextStepToInput = document.getElementById((Number(currID) - 2).toString());
            nextStepToInput.focus();
          }
        }
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const calendarStartDate = CalendarStore.calendarStartDate.format("YYYY-MM-DD");
  const calendarEndDate = CalendarStore.calendarEndDate.format("YYYY-MM-DD");

  const updatePeriodsDays = (dayIndex: number, periodIndexIdDay: number, payload: TUpdatePeriodsDaysPayload) => {
    queryClient.setQueriesData([QueriesKeys.sectionItemsPeriod, calendarStartDate, calendarEndDate], (oldData) => {
      // @ts-ignore
      const data: any[] = cloneDeep(oldData);

      const sectionPeriodsIdx = data.findIndex((sectionPeriods) => sectionPeriods.sectionItemId === props.sectionItemId);

      if (payload.periodId) {
        data[sectionPeriodsIdx].days[dayIndex].forEach((periodDay, idx) => {
          data[sectionPeriodsIdx].days[dayIndex][idx].periodId = payload.periodId;
        });
      }

      data[sectionPeriodsIdx].days[dayIndex][periodIndexIdDay].value = payload.value;

      return data;
    });
  };

  const isVisibleSendToApprovalButtons = computed(() => {
    return PlanStatusStore.currentEditableItemId === props.sectionItemId && restrictedData.vars.canSendPlanForApproval(currentPlan);
  }).get();

  const isVisibleRevokeButton = computed(() => {
    return restrictedData.fn.canRevokePlan(currentPlan);
  }).get();

  const isVisibleEditApprovedPlanButtons = computed(() => {
    return PlanStatusStore.currentEditableItemId === props.sectionItemId && restrictedData.fn.canEditAndApprovalPlan(currentPlan);
  }).get();

  const isVisibleApprovalButton = computed(() => {
    return restrictedData.fn.canApprovalPlan(currentPlan) && !isVisibleEditApprovedPlanButtons;
  }).get();

  const isAtLeastOneButtonVisible = computed(() => {
    return isVisibleSendToApprovalButtons || isVisibleRevokeButton || isVisibleApprovalButton || isVisibleEditApprovedPlanButtons;
  }).get();

  const currentPeriodsOfPlanValues = React.useRef<{
    monday: null | string;
    tuesday: null | string;
    wednesday: null | string;
    thursday: null | string;
    friday: null | string;
    saturday: null | string;
    sunday: null | string;
  }>({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  });

  const getDayStringByIdx = useCallback(
    (idx: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentDay: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday" = CalendarStore.calendarStartDate
        .locale("en")
        .add(idx, "days")
        .format("dddd")
        .toLowerCase();

      return currentDay;
    },
    [CalendarStore.calendarStartDate]
  );

  const getPlanValues = useCallback(() => {
    const planValues = props.days?.map((periodValues, idx) => {
      const planObj = periodValues.find((period) => period.cell.endsWith("volume"));

      const dayString = getDayStringByIdx(idx);
      currentPeriodsOfPlanValues.current[dayString] = planObj.value?.toString();

      return {
        value: planObj.value,
        onChange: (newValue: string) => {
          currentPeriodsOfPlanValues.current[dayString] = newValue;
        },
        startDate: planObj.startDate,
        endDate: planObj.endDate,
        periodId: planObj.periodId,
      };
    });

    return planValues;
  }, [props.days, getDayStringByIdx]);

  const [planValues, setPlanValues] = React.useState<
    {
      value: number;
      onChange: (newValue: string) => void;
      startDate: string;
      endDate: string;
      periodId: string;
    }[]
  >([]);

  React.useEffect(() => {
    setPlanValues(getPlanValues());
  }, [props.days]);

  const onApprove = async () => {
    const plansData = planValues.map((plan, idx) => {
      const volume = currentPeriodsOfPlanValues.current[getDayStringByIdx(idx)];

      return {
        date: plan.startDate,
        volume: isEmpty(volume) ? null : Number(volume),
        id: plan.periodId,
      };
    });

    await restrictedData.fn.onApprovePlan(
      {
        startDate: CalendarStore.calendarStartDate.format("YYYY-MM-DD"),
        endDate: CalendarStore.calendarEndDate.format("YYYY-MM-DD"),
        itemId: props.sectionItemId,
        periods: plansData,
      },
      currentPlan
    );
    PlanStatusStore.setCurrentEditableItemId(undefined);
  };

  const onRevokeAgreement = async () => {
    await revokeAgreement.mutateAsync(currentPlan?.id ?? "");
    PlanStatusStore.setCurrentEditableItemId(undefined);
    queryClient.invalidateQueries([QueriesKeys.statusPlan, CalendarStore.calendarStartDate, props.sectionItemId]);
  };

  const onCancelChanges = () => {
    setPlanValues(getPlanValues());
    PlanStatusStore.setCurrentEditableItemId(undefined);
  };

  React.useEffect(() => {
    const isISentPlanForAgreement = currentPlan?.userId === users.data?.find((user) => user.id === restrictedData.userMe?.id)?.uaaId;

    PlanStatusStore.changeIsISentPlanForAgreement(isISentPlanForAgreement);
    PlanStatusStore.changeStatusPlan(currentPlan?.status);
  }, [statusPlan.data, restrictedData.userMe, users.data]);

  return (
    <>
      {props.sectionNameExist && <SectionHeaderGreyOffset isCollapsed={CalendarStore.isHiddenColumns} />}
      <CalendarTableCellContainer controlType={props.controlType}>
        {currentPlan?.status === "APPROVED" && !isVisibleEditApprovedPlanButtons && (
          <HeaderCell area="volume">
            <PlanText color={planColorText}>План</PlanText>
          </HeaderCell>
        )}
        {(currentPlan?.status !== "APPROVED" || isVisibleEditApprovedPlanButtons) && (
          <HeaderCell area="volume">
            {!isAtLeastOneButtonVisible && <PlanText color={planColorText}>План</PlanText>}

            {isVisibleSendToApprovalButtons && (
              <ButtonsContainer>
                <SendIcon onClick={onApprove} />
                <CloseSmallIcon onClick={onCancelChanges} />
              </ButtonsContainer>
            )}

            {isVisibleRevokeButton && (
              <ButtonContainer onClick={onRevokeAgreement}>
                <ButtonText>Отозвать</ButtonText>
              </ButtonContainer>
            )}

            {isVisibleApprovalButton && (
              <ButtonContainer onClick={onApprove}>
                <ButtonText>Согласовать</ButtonText>
              </ButtonContainer>
            )}

            {isVisibleEditApprovedPlanButtons && (
              <ButtonsContainer>
                <SaveIcon onClick={onApprove} />
                <CloseSmallIcon onClick={onCancelChanges} />
              </ButtonsContainer>
            )}
          </HeaderCell>
        )}
        <HeaderCell area="fact">Факт</HeaderCell>
        <HeaderCell area="staff">Персонал</HeaderCell>

        <RenderWithCondition condition={!CalendarStore.isHiddenColumns}>
          <>
            {props.weeklyData &&
              props.weeklyData.map((weekly, index) => {
                const idWeek = String((props.periodIdx * 100 + index + 1) * 3 + index + 2);

                return (
                  <DataCell
                    color={getColor(weekly, currentPlan, focusedCellId === idWeek)}
                    hoverColor={getHoverColor(weekly, props.isAgreed, focusedCellId === idWeek)}
                    area={`weekly_${weekly.cell}`}
                  >
                    <CalendarTableCellValue
                      setFocusedCellId={setFocusedCellId}
                      keyDown={handleKeyDown}
                      cellData={weekly}
                      tabIndex={props.periodIdx * 100 + index + 1}
                      currIndex={index}
                      id={idWeek}
                      weekly={4}
                      sectionItemId={props.sectionItemId}
                      createPeriod={props.createPeriod}
                      updatePeriod={props.updatePeriod}
                    />
                  </DataCell>
                );
              })}

            {props.days?.map((currDay, index) => {
              return currDay.map((day, i) => {
                const columnsInDay = 3;
                const indexOfDay = index * columnsInDay;
                const indexOfPeriodDay = i;
                const periodIdx = props.periodIdx * TOTAL_CELLS_COUNT_IN_A_WEEK_WITH_DAILY_PERIOD;

                const idDay = (indexOfPeriodDay + indexOfDay + periodIdx).toString();
                const updatePeriodFn = updatePeriodsDays.bind(null, index, i);

                if (!day.startDate) {
                  day.startDate = CalendarStore.calendarStartDate.add(index, "days").format("YYYY-MM-DD");
                }

                return (
                  <DataCell
                    color={getColor(day, currentPlan, focusedCellId === idDay)}
                    hoverColor={getHoverColor(day, props.isAgreed, focusedCellId === idDay)}
                    area={day.cell}
                  >
                    <CalendarTableCellValue
                      setFocusedCellId={setFocusedCellId}
                      keyDown={handleKeyDown}
                      cellData={day}
                      tabIndex={props.periodIdx * 100 + index + 1}
                      currIndex={i}
                      id={idDay}
                      weekly={null}
                      currDay={currDay}
                      sectionItemId={props.sectionItemId}
                      updatePeriodDays={updatePeriodFn}
                      createPeriod={props.createPeriod}
                      updatePeriod={props.updatePeriod}
                      planInputData={planValues[index]}
                      currentPlan={currentPlan}
                    />
                  </DataCell>
                );
              });
            })}
          </>
        </RenderWithCondition>
        <HistoryContainer>
          <CalendarHistory currentPlanId={currentPlan?.id} />
        </HistoryContainer>
      </CalendarTableCellContainer>
    </>
  );
});

export default CalendarTableCell;

const CalendarTableCellContainer = styled.div<{ controlType: "DAILY" | "WEEKLY" }>`
  display: grid;
  grid-template-areas:
    "volume ${(props) =>
      props.controlType === "WEEKLY"
        ? "weekly_volume"
        : "day1_volume day2_volume day3_volume day4_volume day5_volume day6_volume day7_volume history"}"
    "fact ${(props) =>
      props.controlType === "WEEKLY" ? "weekly_fact" : "day1_fact day2_fact day3_fact day4_fact day5_fact day6_fact day7_fact history"}"
    "staff ${(props) =>
      props.controlType === "WEEKLY"
        ? "weekly_staff"
        : "day1_staff day2_staff day3_staff day4_staff day5_staff day6_staff day7_staff history"}";
  grid-template-columns: 80px ${(props) => (props.controlType === "WEEKLY" ? "repeat(1, 1fr)" : "repeat(7, minmax(0, 1fr)) 20px")};
  grid-template-rows: auto;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-bottom-width: 2px;
  border-top-width: 2px;

  text-align: center;
  font-size: 12px;
`;

const HeaderCell = styled.div<{ area: string }>`
  grid-area: ${(props) => props.area};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  width: 100%;
  height: 100%;
`;

const DataCell = styled.div<{ area: string; color?: string; hoverColor: string }>`
  grid-area: ${(props) => props.area};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  width: 100%;
  height: 100%;

  ${(props) => props.color && `background-color: ${props.color}`};

  &:hover {
    ${(props) => props.color && `background-color: ${props.hoverColor}`};
  }
`;

const SectionHeaderGreyOffset = styled.div<{ isCollapsed: boolean }>`
  background-color: ${(props) => props.theme.colors.absolute200};
  height: 18px;
  width: ${(props) => (props.isCollapsed ? "80px" : "100%")};
`;

const PlanText = styled.div<{ color: string }>`
  color: ${(props) => props.color};
`;

const ApproveButton = styled.button`
  color: ${(props) => props.theme.colors.absolute200};
`;

const buttonsContainerMixin = css`
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: ${(props) => props.theme.colors.blue400};

  height: 100%;
  cursor: pointer;
`;

const ButtonsContainer = styled.div`
  ${buttonsContainerMixin}
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  ${buttonsContainerMixin}
  justify-content: center;
  font-size: 12px;
`;

const ButtonText = styled.p`
  color: ${(props) => props.theme.colors.absolute100};
  text-align: center;
`;

const HistoryContainer = styled.div`
  grid-area: history;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  height: 100%;
`;
