import apiClient from "api/api.client";
import { IComplex } from "models/Objects/IComplex";
import { ITree } from "models/Objects/ITree";

export type TGetComplexApi = () => Promise<IComplex[]>;

export const getComplexApi: TGetComplexApi = async () => {
  try {
    const res = await apiClient.get("objects/v1/complexes");

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TCreateComplexApi = (body: { name: string }) => Promise<IComplex>;

export const createComplexApi: TCreateComplexApi = async (body) => {
  try {
    const res = await apiClient.post("objects/v1/complexes", body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateComplexApi = (payload: { body: { name: string }; path: { id: string } }) => Promise<Omit<IComplex, "parentId">>;

export const updateComplexApi: TUpdateComplexApi = async (payload) => {
  try {
    const res = await apiClient.patch(`objects/v1/complexes/${payload.path.id}`, payload.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteComplexApi = (payload: { path: { id: string } }) => Promise<void>;

export const deleteComplexApi: TDeleteComplexApi = async (payload) => {
  try {
    const res = await apiClient.delete(`objects/v1/complexes/${payload.path.id}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetTreeDataApi = () => Promise<ITree[]>;

export const getTreeDataApi: TGetTreeDataApi = async () => {
  try {
    const res = await apiClient.get("objects/v1/complexes/tree");

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
