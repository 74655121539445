import { makeAutoObservable } from "mobx";

import { ICheckboxValue } from "components/Dropdown/Dropdown";

const DEFAULT_SELECTED_SECTIONS = [{ label: "Показать все", value: "all", id: "all" }];
const DEFAULT_SELECTED_SECTIONS_STRING = "Показать все";

class HomeCheckboxStore {
  checkboxItems = DEFAULT_SELECTED_SECTIONS;
  checkboxSelectedItems: ICheckboxValue[] = [];
  checkboxSelectedString = DEFAULT_SELECTED_SECTIONS_STRING;

  private emptyItemsString = "";

  constructor(emptyItemsString: string) {
    this.emptyItemsString = emptyItemsString;
    makeAutoObservable(this);
  }

  private setSelectedItemsString() {
    const isAllExist = this.checkboxSelectedItems.findIndex((section) => section.id === "all");

    // eslint-disable-next-line no-negated-condition
    if (isAllExist !== -1) {
      this.checkboxSelectedString = DEFAULT_SELECTED_SECTIONS[0].label;
    } else {
      this.checkboxSelectedString = this.checkboxSelectedItems.map((section) => section.value).join(", ");
    }
  }

  setCheckboxItems = (sections: any[] | undefined) => {
    if (!sections) {
      return;
    }
    if (sections.length <= 1) {
      this.checkboxSelectedItems =
        sections.length === 0
          ? []
          : [
              {
                label: sections[0].name,
                value: sections[0].name,
                id: sections[0].id,
              },
            ];
      this.checkboxSelectedString = sections.length === 0 ? this.emptyItemsString : sections[0].name;
      this.checkboxItems = [
        ...sections.map((section) => ({
          id: section.id,
          value: section.name,
          label: section.name,
        })),
      ];
    } else {
      this.checkboxItems = [
        ...DEFAULT_SELECTED_SECTIONS,
        ...sections.map((section) => ({
          id: section.id,
          value: section.name,
          label: section.name,
        })),
      ];
      this.checkboxSelectedItems = this.checkboxItems;
      this.setSelectedItemsString();
    }
  };

  toggleCheckedItem = (section: ICheckboxValue) => {
    const isSelectedSectionIndexExist = this.checkboxSelectedItems.findIndex((currSection) => currSection.id === section.id);

    if (section.id === "all") {
      if (isSelectedSectionIndexExist > -1) {
        this.checkboxSelectedItems = [];
      } else {
        this.checkboxSelectedItems = this.checkboxItems;
      }

      this.setSelectedItemsString();
      return;
    }

    if (isSelectedSectionIndexExist === -1) {
      const newSections = this.checkboxSelectedItems.filter((currSection) => currSection.id !== "all");
      newSections.push(section as any);

      this.checkboxSelectedItems = newSections;
    } else {
      const newSections = this.checkboxSelectedItems.filter((currSection) => currSection.id !== "all" && currSection.id !== section.id);

      this.checkboxSelectedItems = newSections;
    }
    this.setSelectedItemsString();
  };
}

export const HomeCheckboxSectionsStore = new HomeCheckboxStore("Нет секций");
export const HomeCheckboxBuildingsStore = new HomeCheckboxStore("Нет литеров");
export const HomeCheckboxWorksStore = new HomeCheckboxStore("Нет работ");
