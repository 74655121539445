import React, { FC, useState } from "react";

import styled, { css } from "styled-components";

import emitter from "services/emitter";

const BurgerMenu: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    emitter.addListener("click_dropdown", (data) => {
      if (data.meta?.element === "burger-menu") {
        setIsOpen(data.isOpenDropDown);
      }
    });
  }, []);

  return (
    <Button>
      <BurgerIcon isOpen={isOpen}>
        <BurgerLine top isOpen={isOpen} />
        <BurgerLine middle isOpen={isOpen} />
        <BurgerLine bottom isOpen={isOpen} />
      </BurgerIcon>
    </Button>
  );
};

export default BurgerMenu;

const Button = styled.button`
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: 0px;
  overflow: hidden;

  @media (max-width: 1279px) {
    left: -1px;
  }
`;

const BurgerIcon = styled.div<{ isOpen: boolean }>`
  width: 20px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease;
  ${(props) => props.isOpen && "transform: translateX(-10%)"};
`;

const BurgerLine = styled.span<{ top?: boolean; middle?: boolean; bottom?: boolean; isOpen: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.blue400};
  transition: transform 0.3s ease;

  ${({ top, isOpen }) =>
    top &&
    css`
      transform-origin: right; /* Modified: Changed from right to center */
      transform: rotate(${isOpen ? "-45deg" : "0"});
    `}

  ${({ middle, isOpen }) =>
    middle &&
    css`
      opacity: ${isOpen ? 0 : 1};
    `}

  ${({ bottom, isOpen }) =>
    bottom &&
    css`
      transform-origin: right; /* Modified: Changed from right to center */
      transform: rotate(${isOpen ? "45deg" : "0"});
    `}
`;
