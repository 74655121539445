import { CurrentUserRoles } from "models/Users/IUser";

export const getRoleText = (userRole?: CurrentUserRoles) => {
  switch (userRole) {
    case CurrentUserRoles.CHIEF_ADMINISTRATOR:
      return "Главный администратор";
    case CurrentUserRoles.ENGINEER_CNP:
      return "Инженер КСП";
    case CurrentUserRoles.ADMINISTRATOR:
      return "Администратор";
    case CurrentUserRoles.AREA_HEAD:
      return "Начальник участка";
    case CurrentUserRoles.DATA_OPERATOR:
      return "Оператор данных";
    case CurrentUserRoles.CHIEF_ENGINEER:
      return "Главный инженер";
    case CurrentUserRoles.HEAD:
      return "Руководитель";
    default:
      return "";
  }
};
