import { FC, MouseEvent, ReactNode, useRef } from "react";
import React from "react";

import styled from "styled-components";

import { ReactComponent as CloseGrey } from "assets/close-grey.svg";
import { ReactComponent as Close } from "assets/close.svg";
import { lessThan1023 } from "constants/mediaQuery";
import { Events } from "models/Events";
import emitter from "services/emitter";

const emitCloseModalEvent = () => {
  emitter.emit(Events["close_modal"]);
};

interface IModal {
  children: ReactNode;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  closeInnerModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isCloseInner?: boolean;
}

const Modal: FC<IModal> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  const onCloseHandler = React.useCallback(() => {
    if (props.isCloseInner) {
      props.closeInnerModal(false);
    } else {
      props.setIsVisible(false);
      emitCloseModalEvent();
    }
  }, [props.isCloseInner]);

  const onClickHandler = (event: MouseEvent<HTMLDivElement>) => {
    if (!ref?.current?.contains(event.target as any)) {
      onCloseHandler();
    }
  };

  return (
    <Container onClick={onClickHandler} className="flex justify-center items-center">
      <CloseContainer className="flex flex-col" ref={ref}>
        <IconContainer className="flex" onClick={onCloseHandler}>
          {lessThan1023.matches ? (
            <IconInSide>
              <CloseGrey />
            </IconInSide>
          ) : (
            <Icon>
              <Close />
            </Icon>
          )}
        </IconContainer>
        <ContainerContent className="flex flex-col">{props.children}</ContainerContent>
      </CloseContainer>
    </Container>
  );
};

export default Modal;

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.absolute1000blure50};
  /* backdrop-filter: blur(5.5px); */
  overflow-y: scroll;
  z-index: 10;
  @media (max-width: 1023px) {
    align-items: flex-end;
  }
`;
const IconContainer = styled.div`
  align-self: end;
  cursor: pointer;
`;
const CloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
const ContainerContent = styled.div`
  background-color: ${(props) => props.theme.colors.absolute100};
  border-radius: 20px;
  padding: 30px;
  @media (max-width: 1023px) {
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: -16px;
  right: -16px;
`;
const IconInSide = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24px;
  right: 24px;
`;
