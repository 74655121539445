import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ReactComponent as DeciationFrom16Legend } from "assets/deviationFrom16Legend.svg";
import { ReactComponent as DeciationUpTo0_5Legend } from "assets/deviationUpTo0_5Legend.svg";
import { ReactComponent as DeciationUpTo15Legend } from "assets/deviationUpTo15.svg";
import { ReactComponent as EndPlanLegend } from "assets/endPlanLegend.svg";
import { ReactComponent as FactLegend } from "assets/factLegend.svg";
import { ReactComponent as ForecastLegend } from "assets/forecastLegend.svg";
import { ReactComponent as JobStartLegend } from "assets/jobStartLegend.svg";
import { ReactComponent as JobSuccessLegend } from "assets/jobSuccessLegend.svg";
import { ReactComponent as PlanLegend } from "assets/planLegend.svg";
import { ReactComponent as TodayLegend } from "assets/todayLegend.svg";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import DropDown from "components/Dropdown/Dropdown";

import CalendarTableStore from "./Gant/CalendarTable/store/CalendarTable.Store";

interface IHomeHeaderGant {}

const HomeHeaderGant: FC<IHomeHeaderGant> = observer((props) => {
  const dropdownList = [
    getDropdownItem(PlanLegend, "План"),
    getDropdownItem(JobStartLegend, "Начало плана"),
    getDropdownItem(EndPlanLegend, "Окончание плана"),
    getDropdownItem(FactLegend, "Факт"),
    getDropdownItem(TodayLegend, "Сегодня"),
    getDropdownItem(JobSuccessLegend, "Работа выполнена"),
    getDropdownItem(ForecastLegend, "Прогноз на основе средней скорости предыдущих работ"),
    getDropdownItem(DeciationUpTo0_5Legend, "Отклонение факт/план до 0,5%"),
    getDropdownItem(DeciationUpTo15Legend, "Отклонение факт/план 6%-15%"),
    getDropdownItem(DeciationFrom16Legend, "Отклонение факт/план от 16%"),
  ];

  const onCheckboxClicked = () => {
    CalendarTableStore.toggleIsHidden100PercentsWorks();
  };

  return (
    <div className="flex gap-4">
      <Button type="usual" onClick={onCheckboxClicked}>
        <ButtonContentContainer>
          <Checkbox
            label={"Скрыть 100%"}
            checked={CalendarTableStore.isHidden100PercentJobs}
            setChecked={onCheckboxClicked}
            nowrap
            noHover
          />
        </ButtonContentContainer>
      </Button>
      <div>
        <DropDown
          dropdownStyles={{ maxWidth: "300px", right: "1px" }}
          selectedValue="Легенда"
          data={dropdownList}
          type="simple"
          onChange={(item) => console.warn(item)}
        />
      </div>
    </div>
  );
});

export default HomeHeaderGant;

const getDropdownItem = (icon: FC, value: string) => {
  const Icon = icon;

  return {
    value: (
      <DropdownItemContainer>
        <IconContainer>
          <Icon />
        </IconContainer>
        <p>{value}</p>
      </DropdownItemContainer>
    ),
    label: "",
  };
};

const ButtonContentContainer = styled.div`
  display: flex;
`;

const DropdownItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled.div`
  width: 26px;
  display: flex;
  justify-content: center;
`;
