import { FC } from "react";

import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { RenderWithCondition } from "hoc/RenderWithCondition";
import {
  HeaderTitle,
  SectionHeader,
  Table,
  TableCell,
  TableCellText,
  TableHeaderHalf,
  TableHeaderHalfText,
  TableRow,
} from "screens/Home/styles/common";
import getOffsetFromHeaderToTable from "screens/Home/utils/getOffsetFromHeaderToTable";
import { Spacer } from "styles/common";

interface IScrollableTable {
  columns: any[];
  data: {
    units_of_measurement: string;
    "by_gpr/beginning": string;
    "by_gpr/ending": string;
    "by_gpr/number_of_days": number;
    total_for_the_project: number;
    fact: string;
    remains: string;
    "%_completed": string;
    staff_an_average_per_week: string;
    collapse_days: string;
    sectionNameExist: boolean;
  }[];
  isBuildingHeaderExist?: boolean;
  EmptyPlaceholderComponent?: React.FC;
}

const calculateAnimationDelay = (index) => index * 300; // Изменяйте это значение по своему
const ScrollableTable: FC<IScrollableTable> = (props) => {
  const table = useReactTable({
    data: props.data,
    // @ts-ignore
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true,
  });

  return (
    <>
      <Table width={"100%"}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow half key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                // @ts-ignore
                const hasParent = !!header.column.parent;
                // @ts-ignore
                const hasColumns = !!header.column.columnDef?.columns;

                return (
                  <TableHeaderHalf
                    isEmptyCell={header.isPlaceholder}
                    key={header.id}
                    colSpan={header.colSpan}
                    hasParent={hasParent}
                    hasColumns={hasColumns}
                  >
                    <TableHeaderHalfText hasParent={hasParent} hasColumns={hasColumns}>
                      <HeaderTitle>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </HeaderTitle>
                    </TableHeaderHalfText>
                  </TableHeaderHalf>
                );
              })}
            </TableRow>
          ))}
        </thead>
        <TransitionGroup component="tbody">
          {table.getRowModel().rows.map((row, rowIdx) => (
            <CSSTransition
              key={rowIdx}
              classNames="stagger"
              timeout={300}
              unmountOnExit
              mountOnEnter
              style={{ transitionDelay: `${calculateAnimationDelay(rowIdx)}ms` }}
            >
              <TableRow half key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  // @ts-ignore
                  const style = cell.column.columnDef.meta?.styleCol || {};

                  const offset = getOffsetFromHeaderToTable(props.isBuildingHeaderExist, !!row.original.sectionNameExist, rowIdx);

                  return (
                    <TableCell additionalHeight={offset} style={{ ...style }} key={cell.id} borderBottom="3px">
                      <RenderWithCondition condition={row.original.sectionNameExist}>
                        <>
                          {props.isBuildingHeaderExist && <SectionHeader color="blue"></SectionHeader>}
                          <SectionHeader offsetTop={props.isBuildingHeaderExist && rowIdx === 0 ? 18 : 0}></SectionHeader>
                          <Spacer px={offset} />
                        </>
                      </RenderWithCondition>
                      <TableCellText>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCellText>
                    </TableCell>
                  );
                })}
              </TableRow>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Table>
    </>
  );
};

export default ScrollableTable;
