import React from "react";
import { FC } from "react";

import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as MetroBlue } from "assets/metro-blue.svg";
import { ReactComponent as Metro } from "assets/metro.svg";
import { ReactComponent as PeopleBlue } from "assets/people-blue.svg";
import { ReactComponent as People } from "assets/people.svg";
import { ReactComponent as TreeBlue } from "assets/tree-blue.svg";
import { ReactComponent as Tree } from "assets/tree.svg";
import { IDropdownItem } from "components/Dropdown/Dropdown";
import DropdownList from "components/Dropdown/Dropdown.List";
import useText from "hooks/useText";

const NavBarReference: FC = observer(() => {
  const text = useText();
  const navigate = useNavigate();

  const onChange = (changedItem: { value: string }) => {
    const linkTo = NAV_MENU.find((item) => item.value === changedItem.value)?.linkTo;
    navigate(linkTo);
  };

  const NAV_MENU: IDropdownItem[] = React.useMemo(
    () => [
      { value: text.referenceWorks, label: "references", icon: Tree, activeIcon: TreeBlue, linkTo: "/references" },
      { value: text.referenceMeasureUnits, label: "measures", icon: Metro, activeIcon: MetroBlue, linkTo: "/references/measures" },
      { value: text.referenceContractor, label: "contractors", icon: People, activeIcon: PeopleBlue, linkTo: "/references/contractors" },
    ],
    [text]
  );
  return (
    <Container className="flex flex-col items-center">
      <NavList>
        <DropdownList data={NAV_MENU} onChange={onChange} />
      </NavList>
    </Container>
  );
});

export default NavBarReference;

const NavList = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
`;
const Container = styled.div`
  /* min-width: 283px; */
  background-color: ${(props) => props.theme.colors.blue100};
`;
