import React, { useState } from "react";

import dayjs from "dayjs";
import { capitalize, isEmpty } from "lodash";
import { useMutation, useQuery } from "react-query";

import { createTemplateApi } from "api/Objects/api.sections";
import { getContractorsApi } from "api/References/api.contractor";
import { getMeasureUnitsApi } from "api/References/api.measureUnits";
import { getWorksApi } from "api/References/api.works";
import { queryClient } from "App";
import { PERIOD_OF_CONTROL } from "constants/conrolPeriodForm";
import { QueriesKeys } from "models/QueriesKeys";
import { IContractor } from "models/References/IContractor";
import { IMeasureUnit } from "models/References/IMeasureUnit";

import useText from "../useText";

const useReferenceFormsData = () => {
  const [selectedWork, setSelectedWork] = useState<IMeasureUnit>();

  const [selectedControl, setSelectedControl] = React.useState<IMeasureUnit>();

  const [searchContractor, setSearchContractor] = React.useState("");
  const [selectedContractorId, setSelectedContractorId] = React.useState<string | undefined>();
  const [filteredContractorsBySearch, setFilteredContractorsBySearch] = React.useState<IContractor[] | []>();

  const getContractors = () => contractors.data?.map((contractor) => ({ ...contractor, value: contractor.name }));

  const text = useText();

  const contractors = useQuery({ queryKey: QueriesKeys.contractors, queryFn: getContractorsApi });
  const works = useQuery({ queryKey: QueriesKeys.works, queryFn: getWorksApi });
  const measures = useQuery({ queryKey: QueriesKeys.measures, queryFn: getMeasureUnitsApi });

  const createTemplate = useMutation(createTemplateApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.sectionItems);
    },
  });

  React.useEffect(() => {
    if (isEmpty(searchContractor)) {
      setFilteredContractorsBySearch(getContractors());
      return;
    }

    setFilteredContractorsBySearch(
      getContractors().filter((contractor) => contractor.name.toLowerCase().indexOf(searchContractor.toLowerCase()) !== -1)
    );
  }, [searchContractor, contractors.data]);

  const mapFormCreateTemplate = React.useMemo(
    () => [
      {
        type: "select",
        selectData: filteredContractorsBySearch,
        selectPlaceholder: text.contractor,
        topPlaceholder: text.contractor,
        isFullList: true,
        addNewElement: {
          text: text.addContractor,
          type: "Contractor",
          onChangeInputSearch: (e) => setSearchContractor(e.target.value),
          valueInputSearch: searchContractor,
        },
        onSelect: (item) => {
          setSelectedContractorId(item.id);
        },
      },
      {
        type: "select",
        selectPlaceholder: text.jobTitle,
        selectData: works?.data?.map((work) => ({ value: work.name, id: work.id })) ?? [],
        onSelect: setSelectedWork,
        isFullList: true,
      },
      {
        type: "input",
        placeholder: capitalize(text.measureUnits),
        value: works?.data?.find((work) => work?.id === selectedWork?.id)?.measureUnit?.value,
        isFullList: true,
        disabled: true,
      },
      {
        type: "select",
        selectPlaceholder: text.control,
        selectData: PERIOD_OF_CONTROL,
        onSelect: setSelectedControl,
        isFullList: true,
      },
    ],
    [text, measures, works, contractors]
  );

  const onSaveTemplateSection = () => {
    const params = {
      controlType: selectedControl?.id,
      workId: selectedWork?.id,
      startDate: dayjs()
        .subtract(dayjs().day() - 1, "day")
        .format("YYYY-MM-DD"),
      endDate: dayjs()
        .add(7 - dayjs().day(), "day")
        .format("YYYY-MM-DD"),
      volume: 1,
      contractorId: selectedContractorId,
    };

    const isEmptyValue = Object.values(params).every((value) => value);
    if (isEmptyValue) {
      createTemplate.mutateAsync(params);
    }
  };

  return {
    formCreateTemplate: {
      data: mapFormCreateTemplate,
      isLoading: createTemplate.isLoading,
      onSave: onSaveTemplateSection,
    },
  };
};

export default useReferenceFormsData;
