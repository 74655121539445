import { FC } from "react";
import React from "react";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { CELL_HEIGHT } from "../constants/common";

import CalendarTableStore from "./store/CalendarTable.Store";
import getOffsetFromStartTable from "./utils/getOffsetFromStartTable";

interface ICalendarTableDayLine {}

const CalendarTableDayLine: FC<ICalendarTableDayLine> = observer((props) => {
  const [offsetLine, setOffsetLine] = React.useState("0px");

  React.useEffect(() => {
    const offset = getOffsetFromStartTable(CalendarTableStore.calendarMonths, CalendarTableStore.monthWidth);

    setOffsetLine(offset);
  }, [CalendarTableStore.calendarMonths, CalendarTableStore.monthWidth]);

  React.useEffect(() => {
    CalendarTableStore.setCurrentOffsetCurrentDayLine(offsetLine);
  }, [offsetLine]);

  return (
    <>
      <CustomTooltip id="calendar-table-day-line" positionStrategy="absolute" style={{ zIndex: 3 }} />
      <Line
        data-tooltip-id="calendar-table-day-line"
        data-tooltip-content={dayjs().format("DD.MM.YY")}
        data-tooltip-place="right-start"
        data-tooltip-variant="light"
        data-tooltip-float={true}
        offsetLeft={offsetLine}
      />
    </>
  );
});

export default CalendarTableDayLine;

const Line = styled.div<{ offsetLeft: string }>`
  height: 100%;
  width: 2px;
  background-color: ${(props) => props.theme.colors.yellow400};
  position: absolute;
  top: ${CELL_HEIGHT};
  left: ${(props) => props.offsetLeft};
  z-index: 2;
  cursor: help;
`;

const CustomTooltip = styled(Tooltip)`
  box-shadow: 0px 4px 20px 0px #0013551c;
`;
