/* eslint-disable max-nested-callbacks */
import React, { useEffect } from "react";

import { isEmpty } from "lodash";
import { useQuery } from "react-query";

import { getTreeDataApi } from "api/Objects/api.complexes";
import { INode } from "components/Tree/Tree.Node";
import TreeStore from "components/Tree/Tree.store";
import { IArea } from "models/Objects/IArea";
import { IBuilding } from "models/Objects/IBuilding";
import { ISection } from "models/Objects/ISection";
import { QueriesKeys } from "models/QueriesKeys";
import ObjectStore from "screens/ObjectsEdit/store/Object.store";
import UsersStore from "screens/Users/store/Users.store";

import useRestrictedAccessData from "../useRestrictedAccessData";

import { convertFromMapToArray, getRestrictedTreeData } from "./utils";

type TArea = "complex" | "area" | "building" | "section";
type THook = (
  disabledSelectAreas?: TArea[],
  disabledCollapseAreas?: TArea[]
) => {
  data: INode[] | undefined;
  areas: {
    areas: IArea[] | undefined;
    buildings: IBuilding[] | undefined;
    sections: ISection[] | undefined;
  };
  isLoading: boolean;
};

interface IState {
  areas: IArea[];
  buildings: IBuilding[];
  sections: ISection[];
}
export const initialState: IState = {
  areas: [],
  buildings: [],
  sections: [],
};

export enum actionTypes {
  SET_FILTERED_AREAS = "SET_FILTERED_AREAS",
  SET_FILTERED_BUILDINGS = "SET_FILTERED_BUILDINGS",
  SET_FILTERED_SECTIONS = "SET_FILTERED_SECTIONS",
}

interface IAction {
  type: string;
  payload: IArea[] | IBuilding[] | ISection[];
}

export function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case actionTypes.SET_FILTERED_AREAS:
      return { ...state, areas: action.payload };
    case actionTypes.SET_FILTERED_BUILDINGS:
      return { ...state, buildings: action.payload };
    case actionTypes.SET_FILTERED_SECTIONS:
      return { ...state, sections: action.payload };

    default:
      return initialState;
  }
}

const useTreeData: THook = (disabledSelectAreas = [], disabledCollapseAreas = []) => {
  const [data, setData] = React.useState<INode[] | undefined>();
  const restrictedData = useRestrictedAccessData();

  const [filteredObjects, dispatchFilteredObjects] = React.useReducer(reducer, initialState);

  const treeData = useQuery({
    queryKey: [QueriesKeys.tree],
    queryFn: getTreeDataApi,
    staleTime: Infinity,
  });

  const getDisabledProps = (area: TArea) => ({
    disabledCollapse: disabledCollapseAreas.includes(area),
    disabledSelect: disabledSelectAreas.includes(area),
  });

  React.useEffect(() => {
    if (TreeStore.selectedComplex?.id !== TreeStore.selectedArea?.meta?.parentId) {
      const newComplex = treeData.data?.find((complex) => complex.id === TreeStore.selectedArea?.meta?.parentId);

      if (newComplex) {
        TreeStore.setComplex({
          ...getDisabledProps("complex"),
          meta: { area: "complex", addedNewObjectType: "input" },
          readOnlyAddNewObjectInputPart: "Участок",
          canBeDeleted: newComplex.canBeDeleted,
          maxLengthChildObject: 10,
          id: newComplex.id,
          name: newComplex.name,
        });
      }
    }
  }, [TreeStore.selectedArea]);

  React.useEffect(() => {
    if (TreeStore.selectedArea?.id !== TreeStore.selectedBuilding?.meta?.parentId) {
      const areasData = filteredObjects.areas;

      const newArea = areasData?.find((area) => area.id === TreeStore.selectedBuilding?.meta?.parentId);

      if (newArea) {
        TreeStore.setArea({
          ...getDisabledProps("area"),
          id: newArea.id,
          name: newArea.name,
          meta: { area: "area", parentId: newArea.parentId, addedNewObjectType: "modal" },
          readOnlyAddNewObjectInputPart: "Литер",
          canBeDeleted: newArea.canBeDeleted,
          maxLengthChildObject: 10,
        });
      }
    }
  }, [TreeStore.selectedBuilding]);

  React.useEffect(() => {
    if (TreeStore.selectedBuilding?.id !== TreeStore.selectedSection?.meta?.parentId) {
      const buildingsData = filteredObjects.buildings;

      const newBuilding = buildingsData?.find((building) => building.id === TreeStore.selectedSection?.meta?.parentId);

      if (newBuilding) {
        TreeStore.setBuilding({
          ...getDisabledProps("building"),
          id: newBuilding.id,
          name: newBuilding.name,
          meta: { area: "building", parentId: newBuilding.parentId, addedNewObjectType: "modal" },
          readOnlyAddNewObjectInputPart: "Секция",
          canBeDeleted: newBuilding.canBeDeleted,
        });
      }
    }
  }, [TreeStore.selectedSection]);

  const isLoading = treeData.isFetching;

  useEffect(() => {
    TreeStore.setIsLoading(isLoading);
  }, [isLoading]);

  React.useEffect(() => {
    if (!isLoading) {
      TreeStore.setIsEmptyTreeData(isEmpty(treeData.data));

      const complexes =
        treeData.data?.map((obj) => ({
          id: obj.id,
          name: obj.name,
          canBeDeleted: obj.canBeDeleted,
          parentId: obj.parentId,
          children: obj.childs,
        })) ?? [];
      const areas =
        complexes
          ?.map((data) => data.children)
          .flat()
          .map((obj) => ({
            id: obj.id,
            name: obj.name,
            canBeDeleted: obj.canBeDeleted,
            parentId: obj.parentId,
            children: obj.childs,
          })) ?? [];
      const buildings =
        areas
          ?.map((data) => data.children)
          .flat()
          .map((obj) => ({
            id: obj.id,
            name: obj.name,
            canBeDeleted: obj.canBeDeleted,
            parentId: obj.parentId,
            children: obj.childs,
          })) ?? [];
      const sections =
        buildings
          ?.map((data) => data.children)
          .flat()
          .map((obj) => ({
            id: obj.id,
            name: obj.name,
            canBeDeleted: obj.canBeDeleted,
            parentId: obj.parentId,
            children: obj.childs,
          })) ?? [];

      const restrictedStructures = getRestrictedTreeData(
        {
          complexes: complexes,
          areasData: areas,
          buildingsData: buildings,
          sectionsData: sections,
        },
        restrictedData.fn.canVisibleSection,
        restrictedData.vars.canVisibleAllSections
      );

      dispatchFilteredObjects({ type: actionTypes.SET_FILTERED_AREAS, payload: restrictedStructures.restrictedAreas });
      dispatchFilteredObjects({ type: actionTypes.SET_FILTERED_BUILDINGS, payload: restrictedStructures.restrictedBuildings });
      dispatchFilteredObjects({ type: actionTypes.SET_FILTERED_SECTIONS, payload: restrictedStructures.restrictedSections });

      const res: INode[] = restrictedStructures.restrictedComplexes.map((complex) => ({
        ...complex,
        ...getDisabledProps("complex"),
        meta: { area: "complex", addedNewObjectType: "input" },
        readOnlyAddNewObjectInputPart: "Участок",
        canBeDeleted: complex.canBeDeleted,
        maxLengthChildObject: 10 + "Участок ".length,
        children: restrictedStructures.restrictedAreas
          .filter((area) => area?.parentId === complex.id)
          .map((area) => ({
            ...getDisabledProps("area"),
            id: area.id,
            name: area.name,
            meta: { area: "area", parentId: complex.id, addedNewObjectType: "modal" },
            readOnlyAddNewObjectInputPart: "Литер",
            canBeDeleted: area.canBeDeleted,
            maxLengthChildObject: 10 + "Литер ".length,
            children: restrictedStructures.restrictedBuildings
              .filter((building) => building?.parentId === area.id)
              .map((building) => ({
                ...getDisabledProps("building"),
                id: building.id,
                name: building.name,
                meta: { area: "building", parentId: area.id, addedNewObjectType: "modal" },
                readOnlyAddNewObjectInputPart: "Секция",
                canBeDeleted: building.canBeDeleted,
                children: restrictedStructures.restrictedSections
                  .filter((section) => section?.parentId === building.id)
                  .map((section) => ({
                    ...getDisabledProps("section"),
                    id: section.id,
                    name: section.name,
                    canBeDeleted: section.canBeDeleted,
                    meta: { area: "section", parentId: building.id },
                  })),
              })),
          })),
      }));
      setData(res);
    }
  }, [isLoading, restrictedData.isLoading]);

  UsersStore.setDataTree(data);

  return {
    data,
    areas: {
      areas: filteredObjects.areas,
      buildings: filteredObjects.buildings,
      sections: filteredObjects.sections,
    },
    isLoading: isLoading,
  };
};

export default useTreeData;
