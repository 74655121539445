export enum QueriesKeys {
  "authorization" = "authorization",

  "measures" = "measures",

  "works" = "works",

  "contractors" = "contractors",

  "sectionItems" = "sectionItems",
  "sectionItemsPeriod" = "sectionItemsPeriod",

  "settings" = "settings",

  "statistic" = "statistic",

  "users" = "users",
  "userMe" = "userMe",

  "roles" = "roles",

  "logout" = "logout",

  "areaGantStatistic" = "areaGantStatistic",
  "areaHistogram" = "areaHistogram",

  "tree" = "tree",

  "statusPlan" = "statusPlan",
  "statusPlanHistory" = "statusPlanHistory",
}
