import React, { FC, useEffect, useState } from "react";

import dayjs from "dayjs";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";

import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";
import { ReactComponent as CalendarIcon } from "assets/calendar.svg";
import Button from "components/Button";
import TreeStore from "components/Tree/Tree.store";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import HomeStore from "screens/Home/store/Home.store";
import CalendarStore from "store/Calendar.Store";
import { Spacer } from "styles/common";

interface ICalendarHeader {
  title?: string;
  icon?: FC;
  formateDate?: string;
}

const CalendarHeader: FC<ICalendarHeader> = observer((props) => {
  const [periodFromEmitter, setPeriodFromEmitter] = useState<"left" | "right" | "">();
  const isWeekly = HomeStore.typePeriodForCharts === "WEEKLY";

  let textButtonStartDate = (isWeekly ? CalendarStore.calendarStartDate : CalendarStore.calendarCurrentMonth)
    .locale("ru")
    .format(props.formateDate ?? "DD MMMM");
  let textButtonEndDate = CalendarStore.calendarEndDate.locale("ru").format(props.formateDate ?? "DD MMMM");

  if (props.formateDate === "MMMM") {
    textButtonStartDate = capitalize(textButtonStartDate);
    textButtonEndDate = capitalize(textButtonEndDate);
  }

  const onClickLeftArrow = (icon: "left" | "right" | "") => {
    if (icon === "left") {
      if (isWeekly) {
        CalendarStore.setCalendarStartDate(dayjs(CalendarStore.calendarStartDate).subtract(7, "days"));
        CalendarStore.setCalendarEndDate(dayjs(CalendarStore.calendarEndDate).subtract(7, "days"));
        CalendarStore.setCalendarCurrentMonth(CalendarStore.calendarEndDate);
      } else {
        CalendarStore.setCalendarCurrentMonth(dayjs(CalendarStore.calendarCurrentMonth).subtract(1, "month"));
        CalendarStore.setCalendarStartDate(dayjs(CalendarStore.calendarCurrentMonth).startOf("week").add(1, "day"));
        CalendarStore.setCalendarEndDate(dayjs(CalendarStore.calendarCurrentMonth).startOf("week").add(7, "day"));
      }
    } else if (icon === "right") {
      if (isWeekly) {
        CalendarStore.setCalendarStartDate(dayjs(CalendarStore.calendarStartDate).add(7, "days"));
        CalendarStore.setCalendarEndDate(dayjs(CalendarStore.calendarEndDate).add(7, "days"));
        CalendarStore.setCalendarCurrentMonth(CalendarStore.calendarStartDate);
      } else {
        CalendarStore.setCalendarCurrentMonth(dayjs(CalendarStore.calendarCurrentMonth).add(1, "month"));
        CalendarStore.setCalendarStartDate(dayjs(CalendarStore.calendarCurrentMonth).startOf("week").add(1, "day"));
        CalendarStore.setCalendarEndDate(dayjs(CalendarStore.calendarCurrentMonth).startOf("week").add(7, "day"));
      }
    }
  };

  useEffect(() => {
    if (periodFromEmitter) {
      onClickLeftArrow(periodFromEmitter);
      setPeriodFromEmitter("");
    }
  }, [periodFromEmitter]);

  if (TreeStore.isEmptyTreeData) {
    return <></>;
  }

  return (
    <div className="flex justify-between items-center">
      <Button type="usual" icon={ArrowLeft} onClick={() => onClickLeftArrow("left")} />
      <Spacer px={10} horizontal />
      <Button type="usual" icon={props.icon ?? CalendarIcon} title={textButtonStartDate} />
      <RenderWithCondition condition={props.formateDate !== "MMMM"}>
        <>
          <Spacer px={8} horizontal />
          <p>-</p>
          <Spacer px={8} horizontal />
          <Button type="usual" icon={props.icon ?? CalendarIcon} title={textButtonEndDate} />
        </>
      </RenderWithCondition>
      <Spacer px={10} horizontal />
      <Button type="usual" icon={ArrowRight} onClick={() => onClickLeftArrow("right")} />
    </div>
  );
});

export default CalendarHeader;
