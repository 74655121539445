import { FC } from "react";

import cn from "classnames";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { getMeasureUnitsApi } from "api/References/api.measureUnits";
import { getWorksApi } from "api/References/api.works";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import { IGantWork } from "models/Objects/IGantChart";

import CalendarTableForecastLine from "./CalendarTable.ForecastLine";
import CalendarTableRow from "./CalendarTable.Row";
import CalendarTableStore from "./store/CalendarTable.Store";
import getLineSizesByDates from "./utils/getLineSizesByDates";

interface ICalendarTableRows {
  work?: IGantWork;
  emptyRow?: boolean;
}

import "./styles/index.css";
import { QueriesKeys } from "models/QueriesKeys";

const CalendarTableRows: FC<ICalendarTableRows> = observer((props) => {
  const months = new Array(CalendarTableStore.calendarMonths.length).fill(undefined);
  const isWorkDone = props.work?.actualInPercentage === 100;

  const planLineSizes = getLineSizesByDates({
    allMonths: CalendarTableStore.calendarMonths,
    startDate: props.work?.startDateByPlan,
    endDate: props.work?.endDateByPlan,
    monthWidth: CalendarTableStore.monthWidth,
  });

  const factLineSizes = getLineSizesByDates({
    allMonths: CalendarTableStore.calendarMonths,
    startDate: props.work?.startDateActual,
    endDate: props.work?.endDateActual ?? dayjs().format("YYYY-MM-DD"),
    monthWidth: CalendarTableStore.monthWidth,
  });

  const forecastLineSizes = getLineSizesByDates({
    allMonths: CalendarTableStore.calendarMonths,
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: props.work?.forecastEndDate,
    monthWidth: CalendarTableStore.monthWidth,
  });

  const planLineOverLegendSizes = getLineSizesByDates({
    allMonths: CalendarTableStore.calendarMonths,
    startDate: props.work?.endDateByPlan,
    endDate: props.work?.endDateByPlan,
    monthWidth: CalendarTableStore.monthWidth,
  });

  const forecastForDaySizes = getLineSizesByDates({
    allMonths: CalendarTableStore.calendarMonths,
    startDate: props.work?.forecastEndDate,
    endDate: props.work?.forecastEndDate,
    monthWidth: CalendarTableStore.monthWidth,
  });

  const isGreaterForecastThanPlan =
    forecastForDaySizes.startPositionLine + forecastLineSizes.width > planLineSizes.startPositionLine + planLineSizes.width;

  const measures = useQuery({ queryKey: QueriesKeys.measures, queryFn: getMeasureUnitsApi });

  const measureName = measures.data.find((unit) => unit.id === props.work?.measureUnitId)?.value;
  return (
    <RowContainer>
      <Tooltip id="calendar-table-tooltip" positionStrategy="fixed" style={{ zIndex: 3 }} />

      <RowInnerContainer>
        {months.map((month) => (
          <CalendarTableRow key={month} isBlueCell={props.emptyRow} />
        ))}
      </RowInnerContainer>

      <RenderWithCondition condition={!!props.work}>
        <>
          <PlanLine isGreenCell={isWorkDone} offset={`${planLineSizes.startPositionLine}px`} width={`${planLineSizes.width}px`} />

          {props.work?.forecastEndDate && isGreaterForecastThanPlan && (
            <PlanLineOverLegend offset={`${planLineOverLegendSizes.startPositionLine - 2}px`} />
          )}

          {props.work?.startDateActual && (
            <FactLine
              isGreenCell={isWorkDone}
              className={cn("factLine-border", { "factLine-border_ended": !props.work?.endDateActual })}
              offset={`${factLineSizes.startPositionLine}px`}
              width={`${factLineSizes.width}px`}
            >
              <RenderWithCondition condition={factLineSizes.width > 40}>
                <FactPercentage>{props.work?.actualInPercentage ?? 0} %</FactPercentage>
              </RenderWithCondition>
            </FactLine>
          )}

          {props.work?.forecastEndDate && (
            <>
              <CalendarTableForecastLine offset={forecastLineSizes.startPositionLine} width={forecastLineSizes.width} />

              {props.work?.recommendedSpeed && isGreaterForecastThanPlan && (
                <ForecastForDayText
                  data-tooltip-id="calendar-table-tooltip"
                  data-tooltip-content={"Рекомендованная скорость для выполнения сроков"}
                  data-tooltip-place="top-start"
                  data-tooltip-variant="light"
                  offset={`${forecastForDaySizes.startPositionLine + 5}px`}
                >
                  {props.work?.recommendedSpeed} {measureName} в день
                </ForecastForDayText>
              )}
            </>
          )}
        </>
      </RenderWithCondition>
    </RowContainer>
  );
});

export default CalendarTableRows;

const RowInnerContainer = styled.div`
  display: flex;
`;
const RowContainer = styled.div`
  position: relative;
`;

const PlanLine = styled.div<{ offset: string; width: string; isGreenCell }>`
  background-color: ${(props) => (props.isGreenCell ? props.theme.colors.green200 : props.theme.colors.red200)};
  height: 20px;
  width: ${(props) => props.width};
  position: absolute;
  bottom: 5px;
  left: ${(props) => props.offset};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.red300};
`;

const FactLine = styled.div<{ offset: string; width: string; isGreenCell: boolean }>`
  background-color: ${(props) => (props.isGreenCell ? props.theme.colors.green300 : "#86c0f5")};
  height: 20px;
  width: ${(props) => props.width};
  position: absolute;
  top: 6px;
  left: ${(props) => props.offset};
  border-radius: 5px;
  display: flex;
  justify-content: center;
`;

const FactPercentage = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.colors.absolute100};
`;

const PlanLineOverLegend = styled.div<{ offset: string }>`
  background-color: ${(props) => props.theme.colors.red400};
  height: 20px;
  width: 2px;
  position: absolute;
  top: 6px;
  left: ${(props) => props.offset};
  border-radius: 5px;
  z-index: 1;
`;

const ForecastForDayText = styled.div<{ offset: string }>`
  min-width: 150px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: ${(props) => props.offset};
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: ${(props) => props.theme.colors.absolute500};
  cursor: help;
  display: flex;
  justify-content: flex-start;
`;
