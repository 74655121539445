import { FC, useState } from "react";
import React from "react";

import styled from "styled-components";

import { ReactComponent as CalendarIcon } from "assets/calendar-blue.svg";
import RadioGroup from "components/RadioGroup";
import { OPTIONS_CHART_PERIOD } from "screens/Home/constants";
import HomeStore, { TTypeDataForCharts } from "screens/Home/store/Home.store";

import CalendarHeader from "../Calendar/Calendar.Header";
import { ButtonRow, Group } from "../MainHome.Header.Charts";

import { Container, TitleLine } from "./LineChart.Cumulative";

interface IHeaderChartTable {
  onClickCheck: React.Dispatch<React.SetStateAction<string>>;
  dataTable: any;
}

const HeaderChartTable: FC<IHeaderChartTable> = (props) => {
  const nameOfWork = props.dataTable && props.dataTable[0].workId?.name;
  const onChangePeriod = (value: TTypeDataForCharts) => {
    HomeStore.setTypePeriodForCharts(value);
    props.onClickCheck(value);
  };
  const formatDate = HomeStore.typePeriodForCharts === "MONTHLY" ? "MMMM" : "DD.MM.YY";

  React.useEffect(
    () => () => {
      HomeStore.setTypePeriodForCharts("WEEKLY");
    },
    []
  );

  return (
    <Container>
      <Group>
        <TitleLine fontSize={22} weight={700}>
          {nameOfWork}
        </TitleLine>
        <TitleLine>{HomeStore.typeDataForCharts.value}</TitleLine>
        <ButtonRow>
          <RadioGroup options={OPTIONS_CHART_PERIOD} defaultChecked={HomeStore.typePeriodForCharts} onChange={onChangePeriod} />
        </ButtonRow>
        <CalendarHeader icon={CalendarIcon} formateDate={formatDate} />
      </Group>
    </Container>
  );
};

export default HeaderChartTable;
