import apiClient from "api/api.client";
import { IGantChartArea } from "models/Objects/IGantChart";

export type TGetGantChartApi = (params: { areaId: string }) => Promise<IGantChartArea>;

export const getGantChartApi: TGetGantChartApi = async (params) => {
  try {
    const res = await apiClient.get("objects/v1/statistic/area/gantt-chart", {
      params: params,
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
