import React from "react";
import { FC } from "react";

import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";

import { getRolesApi, getUsersApi } from "api/Users/api.users";
import Dropdown from "components/Dropdown/Dropdown";
import Search from "components/Search";
import { getFullPathById } from "components/Tree/Tree";
import { INode } from "components/Tree/Tree.Node";
import { lessThan1023 } from "constants/mediaQuery";
import useText from "hooks/useText";
import useTreeData from "hooks/useTreeData/useTreeData";
import { QueriesKeys } from "models/QueriesKeys";
import { Spacer } from "styles/common";

import UsersStore from "../store/Users.store";

const UsersHeader: FC = observer(() => {
  const treeData = useTreeData();
  const [selectedSectionName, setSelectedSectionName] = React.useState<string | undefined>();
  const [searchValue, setSearchValue] = React.useState<string | undefined>();
  const setBuilding = (item: INode, closeDropdownCallback) => {
    if (item.children) {
      UsersStore.selectBuilding(undefined);
    } else {
      UsersStore.selectBuilding(item);
      closeDropdownCallback();
    }
  };

  const text = useText();

  const roles = useQuery({ queryKey: QueriesKeys.roles, queryFn: getRolesApi });
  //TODO: временно скрыть CHIEF_ENGINEER
  const dataRoles = roles?.data ? roles?.data.filter((role) => !(role.name === "CHIEF_ENGINEER")) : [];

  const users = useQuery({
    queryFn: () => getUsersApi({ ids: null, roles: null }),
    queryKey: [QueriesKeys.users],
  });

  React.useEffect(() => {
    if (UsersStore.selectedBuilding) {
      setSelectedSectionName(getFullPathById(treeData.data, UsersStore.selectedBuilding.id));
    } else {
      setSelectedSectionName(undefined);
    }
  }, [UsersStore.selectedBuilding, treeData.data]);

  React.useEffect(() => {
    UsersStore.setCheckboxRoles(dataRoles);
    UsersStore.setCheckboxUsers(users.data ?? []);
    UsersStore.setSearchValue(searchValue);
  }, [roles.data, users.data, searchValue]);

  if (lessThan1023.matches) {
    return <Text className="text-xl font-bold">{text.users}</Text>;
  }
  const debounceValue = debounce((value) => {
    setSearchValue(value);
  }, 2000);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceValue(event.target.value);
  };

  return (
    <>
      <Text className="text-xl font-bold">{text.users}</Text>
      <Group>
        <div className="flex-grow">
          <Dropdown
            type="multi-select"
            data={UsersStore.checkboxUsers}
            onChange={UsersStore.toggleCheckedUser}
            checkboxCheckedIds={UsersStore.checkboxSelectedUsers?.map((role) => role.id)}
            selectedValue={UsersStore.checkboxSelectedUsersString || "Ничего не выбрано"}
          />
        </div>
        <Spacer horizontal px={20} />
        <div className="flex-grow">
          <Dropdown
            type="multi-select"
            data={UsersStore.checkboxRoles}
            onChange={UsersStore.toggleCheckedRole}
            checkboxCheckedIds={UsersStore.checkboxSelectedRoles?.map((role) => role.id)}
            selectedValue={UsersStore.checkboxSelectedRolesString || "Ничего не выбрано"}
          />
        </div>
        <Spacer horizontal px={20} />
        <div className="flex-grow">
          <Dropdown
            type="tree-select"
            data={treeData.data as any}
            onChange={setBuilding as any}
            selectedValue={selectedSectionName ?? "Выберите участок"}
            highlightSelectedValue={!!selectedSectionName}
            isLoadingTree={treeData.isLoading}
          />
        </div>
        <Spacer horizontal px={20} />
        <div className="flex-grow">
          <Search placeholder={text.search} onChange={handleSearch} />
        </div>
      </Group>
    </>
  );
});

export default UsersHeader;

const Group = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
const Text = styled.div`
  color: ${(props) => props.theme.colors.absolute700};
  padding: 20px;
  border-left: 1px solid ${(props) => props.theme.colors.absolute200};
`;
