import ReactLoading from "react-loading";
import styled from "styled-components";

import { RenderWithCondition } from "hoc/RenderWithCondition";
import { LIGHT_THEME } from "theme/colors";

interface IProps {
  condition: boolean;
  withoutBlur?: boolean | undefined;
  posY?: string;
  size?: string;
}

export const LocalLoader = ({ condition, posY, withoutBlur }: IProps) => (
  <RenderWithCondition condition={condition} animated>
    <LoaderContainer condition={condition} posY={posY ?? "0px"} withoutBlur={withoutBlur}>
      <ReactLoading type="spokes" color={LIGHT_THEME.blue400} height={25} width={25} />
    </LoaderContainer>
  </RenderWithCondition>
);

const LoaderContainer = styled.section<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${(props) => props.posY};
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.withoutBlur ? "transparent" : props.theme.colors.backgroundOpacity60)};
`;
