import { FC } from "react";
import React from "react";

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";

import { deleteContractorApi, getContractorsApi, updateContractorApi } from "api/References/api.contractor";
import { queryClient } from "App";
import { ReactComponent as Bin } from "assets/bin.svg";
import { ReactComponent as SetMenu } from "assets/set-menu.svg";
import { LocalLoader } from "components/Loader/LocalLoader";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import { QueriesKeys } from "models/QueriesKeys";
import { IContractor } from "models/References/IContractor";
import { Table, TableCell, TableHeader, TableRow } from "screens/Home/styles/common";

interface IContrTableItem {
  width: number | string;
  handleOpenUpdate: (e: IContractor) => void;
}

const columnHelper = createColumnHelper<any>();

const ContrTableItem: FC<IContrTableItem> = (props) => {
  const text = useText();
  const contractors = useQuery({ queryKey: QueriesKeys.contractors, queryFn: getContractorsApi });
  const deleteContractor = useMutation(deleteContractorApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueriesKeys.contractors);
    },
  });

  const restrictedData = useRestrictedAccessData();

  const data = contractors.data ?? [];

  const getColumns = () => {
    const contentColumns = [
      columnHelper.accessor("contractor", {
        header: text.contractor,
        cell: (info) => <div className="flex">{info.row.original.name}</div>,
        enableResizing: true,
        meta: { styleCol: { textAlign: "left", paddingLeft: "8px", height: "48px" } },
      }),
      columnHelper.accessor("contactPerson", {
        header: text.contactPerson,
        cell: (info) => <div className="flex grow w-full">{info.row.original.contactName}</div>,
        enableResizing: true,
        meta: { styleCol: { textAlign: "left", paddingLeft: "8px", height: "48px" } },
      }),
      columnHelper.accessor("contact", {
        header: text.contactData,
        cell: (info) => <div className="flex justify-center">{info.row.original.contactPhone}</div>,
        enableResizing: true,
        meta: { styleCol: { width: "250px", padding: "10px 20px", height: "48px" } },
      }),
    ];
    const updateColumn = columnHelper.accessor("update", {
      header: "",
      cell: (info) => (
        <div className="flex justify-center cursor-pointer h-full items-center">
          <button className="flex justify-center w-full h-full items-center" onClick={() => props.handleOpenUpdate(info.cell.row.original)}>
            <SetMenu />
          </button>
        </div>
      ),
      enableResizing: true,
      meta: { styleCol: { padding: "4px", height: "48px", width: "38px" } },
    });

    if (!restrictedData.vars.canChangeDataOfReference) {
      return [...contentColumns];
    }

    if (restrictedData.vars.canChangeDataOfReference) {
      return [
        ...contentColumns,
        updateColumn,
        //TODO: ==== TEMPORARY HIDING ==========
        // columnHelper.accessor("delete", {
        //   header: text.delete,
        //   cell: (info) => (
        //     <div className="flex justify-center">
        //       <button className="flex justify-center" onClick={() => deleteContractor.mutateAsync(info.row.original.id)}>
        //         <Bin />
        //       </button>
        //     </div>
        //   ),
        //   enableResizing: true,
        //   meta: { styleCol: { padding: "10px", height: "48px", width: "148px" } },
        // }),
      ];
    }
    return contentColumns;
  };

  const [columnVisibility, setColumnVisibility] = React.useState({});

  const table = useReactTable({
    data,
    columns: getColumns(),
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  return (
    <>
      <Table width={props.width}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader className="text-sm" key={header.id} style={{ height: 48, paddingLeft: 4 }}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                // @ts-ignore
                const style = cell.column.columnDef.meta?.styleCol || {};

                return (
                  <TableCell className="h-12" style={{ ...style }} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </tbody>
      </Table>
      <LocalLoader condition={contractors.isFetching} posY="60px" />
    </>
  );
};

export default ContrTableItem;

export const LeftButtonTable = styled.button`
  height: 100%;
  width: 20px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.blue200};
`;
