import React, { FC, useState } from "react";

import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";

import { RenderWithCondition } from "hoc/RenderWithCondition";

import { IMaxScales } from "./BarChart.Total";

export interface IRenderLine {
  fill: string;
  dataKey: string;
}

interface IBarChartItem {
  data: any;
  externalTooltip: any;
  interval?: number;
  fill?: string;
  pointXaxis?: string;
  placeLabel?: "center" | "top";
  maxScale?: IMaxScales;
  factorScale: number;
  isValuesOfData?: boolean;
}

const getTopLeftScale = (maxPers: number, maxPlan: number, basePers: number) => (maxPers * maxPlan) / basePers;

const BarChartItem: FC<IBarChartItem> = (props) => {
  const size = props.placeLabel === "center" ? 16 : 9;
  const angleTick = props.placeLabel === "center" ? 0 : -90;
  const tickMargin = props.placeLabel === "center" ? 5 : 25;
  const bottomMargin = props.placeLabel === "center" ? 0 : 30;
  const tickXInterval = !(props.placeLabel === "center") && props.isValuesOfData ? 3 : 0;

  const topScales = React.useMemo(() => {
    const { maxPlan, maxFact, maxPersonal } = props.maxScale;
    const maxFromPlanFact = Math.max(maxPlan, maxFact);
    const staffScale = props.factorScale ? Math.round(maxFromPlanFact / props.factorScale) : maxFromPlanFact;
    const topLeftScale =
      props.factorScale && maxPersonal > staffScale ? getTopLeftScale(maxPersonal, maxFromPlanFact, staffScale) : maxFromPlanFact;
    const topRightScale = maxPersonal > staffScale ? maxPersonal : staffScale;

    if (topLeftScale < 11) {
      return { topLeftScale: topLeftScale + 1, topRightScale };
    }
    if (topLeftScale > 10 && topLeftScale < 31) {
      return { topLeftScale: Math.ceil(topLeftScale / 5) * 5, topRightScale };
    }
    if (topLeftScale > 30) {
      return { topLeftScale: Math.ceil(topLeftScale / 10) * 10, topRightScale };
    }
  }, [props.maxScale]);

  const CustomLabel = (Props) => (
    <text x={Props.x - 120} y={Props.y - 145} dx={-1} dy={-10} fill={Props.stroke} fontSize={7} textAnchor="middle" transform="rotate(90)">
      {Props.value}
    </text>
  );

  const sizeOfChart = props.isValuesOfData ? 19.4 : 4.4;

  const scalePitchLeft = React.useMemo(() => {
    if (topScales?.topLeftScale < 11) {
      return topScales?.topLeftScale + 1;
    }
    if (topScales?.topLeftScale > 10 && topScales?.topLeftScale < 31) {
      return topScales?.topLeftScale / 5 + 1;
    }
    if (topScales?.topLeftScale > 30) {
      return topScales?.topLeftScale / 10 + 1;
    }
  }, [topScales?.topLeftScale]);

  const scalePitchRight = React.useMemo(() => {
    if (topScales?.topRightScale < 9) {
      return topScales?.topRightScale + 1;
    }
    if (topScales?.topRightScale > 8) {
      return 10;
    }
  }, [topScales?.topRightScale]);

  return (
    <div className="flex relative overflow-hidden">
      <RenderWithCondition condition={props.isValuesOfData}>
        <TextInfo className="flex">Нет данных для графика</TextInfo>
      </RenderWithCondition>
      <ResponsiveContainer width="100%" aspect={sizeOfChart}>
        <BarChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: props.isValuesOfData ? 22 : 10,
            right: -30,
            left: -30,
            bottom: props.isValuesOfData ? 0 : bottomMargin,
          }}
        >
          <CartesianGrid vertical={false} height={350} />
          <XAxis
            textAnchor="middle"
            angle={props.isValuesOfData ? 0 : angleTick}
            tickMargin={props.isValuesOfData ? 5 : tickMargin}
            orientation={props.isValuesOfData ? "top" : "bottom"}
            fontSize={12}
            textLength={51}
            tickLine={false}
            axisLine={false}
            interval={tickXInterval}
            fontFamily={"Inter"}
            dataKey="date"
          />
          <YAxis
            fontFamily={"Inter"}
            fontSize={12}
            fontWeight={400}
            tick={!props.isValuesOfData}
            tickCount={scalePitchLeft}
            tickFormatter={(value) => (Number.isInteger(value) ? value : "")}
            tickLine={false}
            yAxisId="left"
            orientation="left"
            axisLine={false}
            domain={[0, topScales?.topLeftScale]}
          />
          {props.factorScale && (
            <YAxis
              tick={{ fill: "#F3BD00" }}
              fontFamily={"Inter"}
              fontSize={12}
              fontWeight={400}
              // tickSize={15}
              tickCount={scalePitchRight}
              tickFormatter={(value) => (Number.isInteger(value) ? value : "")}
              tickLine={false}
              yAxisId="right"
              orientation="right"
              axisLine={false}
              domain={[0, topScales.topRightScale]}
              scale={"auto"}
            />
          )}
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar
            dataKey="plan"
            fill="#82C09E"
            radius={2}
            maxBarSize={40}
            width={40}
            yAxisId="left"
            label={{
              position: props.placeLabel,
              fontSize: size,
              angle: props.placeLabel === "top" ? 90 : 0,
              textAnchor: "middle",
              baselineShift: -3,
            }}
          />
          <Bar
            dataKey="fact"
            fill="#BFE0FE"
            radius={2}
            maxBarSize={40}
            width={40}
            yAxisId="left"
            label={{
              position: props.placeLabel,
              fontSize: size,
              angle: props.placeLabel === "top" ? 90 : 0,
              textAnchor: "middle",
              baselineShift: -3,
            }}
          />
          {props.factorScale && (
            <Bar
              dataKey="personal"
              fill="#FFF6D7"
              radius={2}
              maxBarSize={40}
              width={40}
              yAxisId="right"
              label={{
                position: props.placeLabel,
                fontSize: size,
                angle: props.placeLabel === "top" ? 90 : 0,
                textAnchor: "middle",
                baselineShift: -3,
              }}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartItem;

export const TextInfo = styled.div`
  justify-content: center;
  align-items: center;
  width: 98%;
  position: absolute;
  color: ${(props) => props.theme.colors.absolute500};
  background-color: ${(props) => props.theme.colors.absolute100};
  margin-top: 3px;
  z-index: 2;
`;
