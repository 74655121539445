import styled from "styled-components";

import { ROW_HEIGHT } from "../constants";

export const TableHeader = styled.th<{ isRightColumnsCollapse?: boolean }>`
  height: 61px;
  border-right: 1px solid ${(props) => props.theme.colors.absolute200};
  border-bottom: 1px solid ${(props) => props.theme.colors.absolute200};
  position: relative;
  background-color: ${(props) => (props.isRightColumnsCollapse ? props.theme.colors.blue200 : props.theme.colors.blue100)};
  padding-left: 12px;
  padding-right: 4px;
`;

export const TableHeaderHalf = styled.th<{
  hasParent: boolean;
  hasColumns: boolean;
  isEmptyCell: boolean;
  isRightColumnsCollapse?: boolean;
  highlight?: boolean;
}>`
  border-right: 1px solid ${(props) => props.theme.colors.absolute200};
  border-bottom: ${(props) => (props.isEmptyCell ? undefined : `1px solid ${props.theme.colors.absolute200}`)};
  height: ${ROW_HEIGHT / 2}px;
  white-space: break-spaces;
  position: relative;
  text-align: ${(props) => (props.isRightColumnsCollapse ? "left" : "center")};
  padding-left: ${(props) => (props.isRightColumnsCollapse ? "4px" : 0)};
  background-color: ${(props) =>
    props.isRightColumnsCollapse || props.highlight ? props.theme.colors.blue200 : props.theme.colors.blue100};
  width: ${(props) => (props.isRightColumnsCollapse ? "90px" : "auto")};
`;

export const TableHeaderHalfText = styled.p<{ hasParent: boolean; hasColumns: boolean }>`
  transform: ${(props) => (!props.hasColumns && !props.hasParent ? "translateY(-35%)" : undefined)};
`;

export const TableCell = styled.td<{ additionalHeight?: number; background?: string; forCharts?: boolean; borderBottom?: string }>`
  border-right: 1px solid ${(props) => props.theme.colors.absolute200};
  border-bottom: ${(props) => props.borderBottom ?? "1px"} solid
    ${(props) => (props.forCharts ? props.theme.colors.absolute500 : props.theme.colors.absolute200)};
  height: ${(props) => (props.additionalHeight ? "90px" : "72px")};
  background-color: ${(props) => (props.background ? props.theme.colors.blue100 : "none")};
  text-align: center;
  position: relative;
  color: ${(props) => props.theme.colors.absolute700};

  @media (max-width: 1279px) {
    text-overflow: ellipsis;
  }
`;
export const EmptyRow = styled.td<{ emptyRow?: number }>`
  /* border-right: 1px solid ${(props) => props.theme.colors.absolute200}; */
  border-bottom: 1px solid ${(props) => props.theme.colors.absolute200};
  height: ${(props) => (props.emptyRow ? props.emptyRow : "27px")};
  text-align: center;
  position: relative;
`;

export const Table = styled.table`
  width: 100%;
  overflow: scroll;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
`;

export const TableRow = styled.tr<{ half?: boolean }>`
  height: 22px;
  position: relative;
`;

export const HeaderTitle = styled.p`
  padding: 0 4px;
  @media (max-width: 1279px) {
    padding: 0;
    text-overflow: ellipsis;
    font-size: 12px;
  }
`;

export const TableCellText = styled.p`
  @media (max-width: 1279px) {
    padding: 0;
    text-overflow: ellipsis;
    font-size: 12px;
  }
`;

export const SectionHeader = styled.div<{ offsetTop?: number; color?: string }>`
  position: absolute;
  top: ${(props) => (props.offsetTop ? `${props.offsetTop}px` : "0px")};
  left: 0;
  width: 100%;
  background-color: ${(props) => (props.color === "blue" ? props.theme.colors.blue300 : props.theme.colors.absolute200)};
  height: 18px;
`;
