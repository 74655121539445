/* eslint-disable max-nested-callbacks */
import { FC } from "react";

import { uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useAreaQuery from "../hooks/useAreaQuery";

import CalendarTableDayLine from "./CalendarTable.DayLine";
import CalendarTableHeader from "./CalendarTable.Header";
import CalendarTableRows from "./CalendarTable.Rows";

interface ICalendarTable {}

const CalendarTable: FC<ICalendarTable> = observer((props) => {
  const {
    fn: { getFilteredJobsData },
  } = useAreaQuery();

  return (
    <Container>
      <Container>
        <CalendarTableHeader />
        {getFilteredJobsData().map((work) => {
          if ("emptyRow" in work) {
            return <CalendarTableRows key={uniqueId()} emptyRow />;
          }

          return <CalendarTableRows key={uniqueId(work.id)} work={work} />;
        })}
      </Container>
      <CalendarTableDayLine />
    </Container>
  );
});

export default CalendarTable;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;
