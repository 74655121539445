import React, { FC } from "react";

import GantEmptyTableDataCell from "../components/Gant.EmptyTableDataCell";

interface ICalendarTableRow {
  isBlueCell?: boolean;
}

const CalendarTableRow: FC<ICalendarTableRow> = (props) => <GantEmptyTableDataCell isBlueCell={props.isBlueCell} />;

export default CalendarTableRow;
