import dayjs from "dayjs";
import { capitalize } from "lodash";

import { monthWidth } from "../constants/calendarTable";

export default (allMonths: string[]) => {
  const currentMonth = capitalize(dayjs().format("MMMM YYYY"));
  const indexCurrentMonthInAllMonths = allMonths.findIndex((month) => month === currentMonth);

  if (indexCurrentMonthInAllMonths === -1 || indexCurrentMonthInAllMonths === 0) {
    return 0;
  }

  return monthWidth * (indexCurrentMonthInAllMonths - 1);
};
