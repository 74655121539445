import React, { useEffect } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { capitalize, isEmpty, max } from "lodash";
import { useMutation, useQuery } from "react-query";
import { styled } from "styled-components";

import { getItemPeriodApi } from "api/Objects/api.itemPeriod";
import { deleteSectionItemApi, getObjectsBySectionIdApi, updateObjectApi } from "api/Objects/api.objects";
import { getContractorsApi } from "api/References/api.contractor";
import { getMeasureUnitsApi } from "api/References/api.measureUnits";
import { getWorksApi } from "api/References/api.works";
import { queryClient } from "App";
import { ReactComponent as Bin } from "assets/bin.svg";
import { ReactComponent as CalendarIcon } from "assets/calendar-blue.svg";
import { ReactComponent as EditPencilBlue } from "assets/pencil-blue.svg";
import { ReactComponent as EditPencil } from "assets/pencil-grey.svg";
import { ReactComponent as SetMenu } from "assets/set-menu.svg";
import Dropdown from "components/Dropdown/Dropdown";
import useText from "hooks/useText";
import { IItemPeriod } from "models/Objects/IItemPeriod";
import { QueriesKeys } from "models/QueriesKeys";
import ObjectStore from "screens/ObjectsEdit/store/Object.store";
import CalendarStore from "store/Calendar.Store";
import { getISODate } from "utils/getISODate";
import notify from "utils/notify";

import useRestrictedAccessData from "./useRestrictedAccessData";

type THookArgs = {
  controlType?: string;
  sectionId: string;
  disabledEditMode?: boolean;
  sectionItemsStatisticEnabled?: boolean;
  openModalPeriod?: (boo: boolean) => void;
  setIsOpenModalTotalForProject?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenModalCompletedVolume?: React.Dispatch<React.SetStateAction<boolean>>;
};

// TODO: temporary solution, problen: with a large value, namely more than 920, the application is freezes and the layout goes
const MAX_VALUE = 920;

export const serializePeriodDataItemForDay = (periods: IItemPeriod[], dayIdx?: number, total?: boolean) => {
  const currentDayDate = CalendarStore.calendarStartDate.add(dayIdx, "days").format("YYYY-MM-DD").toString();

  const currPeriod = periods.find((period) => period.startDate === currentDayDate);

  return [
    {
      value: currPeriod?.volume ?? "",
      cell: `day${dayIdx + 1}_volume`,
      periodId: currPeriod?.id,
      startDate: currPeriod?.startDate,
      endDate: currPeriod?.endDate,
      total: total,
    },
    {
      value: currPeriod?.actualVolume ?? "",
      cell: `day${dayIdx + 1}_fact`,
      periodId: currPeriod?.id,
      startDate: currPeriod?.startDate,
      endDate: currPeriod?.endDate,
      total: total,
    },
    {
      value: currPeriod?.staffVolume ?? "",
      cell: `day${dayIdx + 1}_staff`,
      periodId: currPeriod?.id,
      startDate: currPeriod?.startDate,
      endDate: currPeriod?.endDate,
      total: total,
    },
  ];
};

export const getPeriodDataForChart = (periods: IItemPeriod[], dayIdx?: number, mode?: "MONTH") => {
  const currentDayDate = CalendarStore.calendarStartDate.add(dayIdx, "days").format("YYYY-MM-DD").toString();
  const currentDayDateOfWeek = CalendarStore.calendarStartDate;
  const currentDayDateOfMonth = CalendarStore.calendarCurrentMonth;
  const getDayOfWeek = (dayNum: number, format: "dd" | "dddd") =>
    capitalize((mode ? currentDayDateOfMonth : currentDayDateOfWeek).add(dayNum, "day").locale("ru").format(format)).toString();
  const formattedCurrentDayDate = `${(mode ? currentDayDateOfMonth : currentDayDateOfWeek)
    .add(dayIdx, "days")
    .format("DD.MM")
    .toString()} ${getDayOfWeek(dayIdx, "dd")}`;

  const currPeriodOfWeek = periods.find((period) => period.startDate === currentDayDate);
  const currPeriodOfMonth = periods.find(
    (period) => period.startDate === currentDayDateOfMonth.add(dayIdx, "days").format("YYYY-MM-DD").toString()
  );
  const currPeriod = mode ? currPeriodOfMonth : currPeriodOfWeek;

  return [
    {
      date: formattedCurrentDayDate,
      planCumulative: Math.min(currPeriod?.volume, MAX_VALUE),
      factCumulative: Math.min(currPeriod?.actualVolume, MAX_VALUE),
      tooltipLabelCumulative: getDayOfWeek(dayIdx, "dddd"),
      labelCumulativePlan: "План",
      labelCumulativeFact: "Факт",
      plan: currPeriod?.volume === 0 ? null : Math.min(currPeriod?.volume, MAX_VALUE),
      fact: currPeriod?.actualVolume === 0 ? null : Math.min(currPeriod?.actualVolume, MAX_VALUE),

      personal: currPeriod?.staffVolume === 0 ? null : Math.min(currPeriod?.staffVolume, MAX_VALUE),
      startDate: currPeriod?.startDate,
      endDate: currPeriod?.endDate,
      production: currPeriod?.endDate,
    },
  ];
};

const columnHelperLeftTable = createColumnHelper<{
  control: { rowId: string; value: string };
  contractor: { rowId: string; value: string };
  name_of_the_work: { rowId: string; value: string };
}>();

export type TColumnHelperRightTable = {
  units_of_measurement: { rowId: string; value: string };
  by_gpr: { rowId: string; value: string };
  "by_gpr/beginning": { rowId: string; value: string };
  "by_gpr/ending": { rowId: string; value: string };
  "by_gpr/number_of_days": { rowId: string; value: string };
  total_for_the_project: { rowId: string; value: string };
  completed_volume: { rowId: string; value: string };
  delete: { rowId: string; value: string };
};

const columnHelperRightTable = createColumnHelper<TColumnHelperRightTable>();

const useTableData = (args: THookArgs) => {
  const text = useText();

  const restrictedData = useRestrictedAccessData();
  const contractors = useQuery({ queryKey: QueriesKeys.contractors, queryFn: getContractorsApi });
  const works = useQuery({ queryKey: QueriesKeys.works, queryFn: getWorksApi });
  const measures = useQuery({ queryKey: QueriesKeys.measures, queryFn: getMeasureUnitsApi });

  const updateSectionItem = useMutation(updateObjectApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.sectionItems]);
    },
  });

  const deleteSectionItem = useMutation(deleteSectionItemApi, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueriesKeys.sectionItems]);
    },
  });
  useEffect(() => {
    if (deleteSectionItem.isError) {
      // @ts-ignore
      const textError = deleteSectionItem.error?.response?.data?.message;
      notify.error(textError.slice(0, 23));
    }
  }, [deleteSectionItem.isError]);

  const sectionItems = useQuery({
    queryFn: () =>
      getObjectsBySectionIdApi(
        {
          controlType: args.controlType ?? "DAILY,WEEKLY",
          sectionId: args.sectionId,
        },
        {
          enabledStatistic: args.sectionItemsStatisticEnabled,
        }
      ),
    queryKey: [QueriesKeys.sectionItems, args.sectionId, args.controlType],
  });

  const calendarStartDate = CalendarStore.calendarStartDate.format("YYYY-MM-DD");
  const calendarEndDate = CalendarStore.calendarEndDate.format("YYYY-MM-DD");

  const handleOpenPeriod = (e: TColumnHelperRightTable) => {
    args.openModalPeriod(true);
    ObjectStore.setUpdateStartPeriod(getISODate(e && e["by_gpr/beginning"].value));
    ObjectStore.setUpdateEndPeriod(getISODate(e && e["by_gpr/ending"].value));
    ObjectStore.setUpdatePeriodRowId(e && e["by_gpr/beginning"].rowId);
    ObjectStore.setUpdatePeriodDays(e && e["by_gpr/number_of_days"].value);
  };

  const handleOpenModalUpdateTotalForProject = (e: TColumnHelperRightTable) => {
    args.setIsOpenModalTotalForProject(true);
    ObjectStore.setUpdateTotalForProject(e && e["total_for_the_project"].value);
    ObjectStore.setUpdateTotalForProjectId(e && e["total_for_the_project"].rowId);
  };

  const handleOpenModalCompletedVolume = (e: TColumnHelperRightTable) => {
    args.setIsOpenModalCompletedVolume(true);
    ObjectStore.setUpdateCompletedVolume(e && e["completed_volume"].value);
    ObjectStore.setUpdateTotalForProjectId(e && e["completed_volume"].rowId);
  };

  const sectionItemsPeriods = useQuery({
    queryFn: async () => {
      if (isEmpty(sectionItems.data)) {
        return [];
      }

      let allPeriods: { items: IItemPeriod[]; controlType: string; sectionItemId: string }[][] = [];

      for await (const sectionItem of sectionItems.data) {
        const itemsPeriod = await Promise.all(
          sectionItem.items.map(async (item) => {
            const periodResult = await getItemPeriodApi(
              item.id,
              {
                controlType: item.controlType,
              },
              {
                startDate: calendarStartDate,
                endDate: calendarEndDate,
              }
            );

            const res = {
              sectionItemId: item.id,
              ...periodResult,
            };

            return res;
          })
        );

        allPeriods = [...allPeriods, itemsPeriod];
      }

      return allPeriods
        .map((section) =>
          section.map((period, periodsIdx) => {
            const isAgreed = period.items.some((p) => p?.isApproved);
            const sortedPeriodByDate = period.items.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)));

            if (period.controlType === "WEEKLY") {
              const periodForCurrentWeekly = sortedPeriodByDate.find((period) => period.startDate === calendarStartDate);

              return {
                weeklyData: [
                  {
                    value: periodForCurrentWeekly?.volume ?? "",
                    cell: "volume",
                    periodId: periodForCurrentWeekly?.id,
                    startDate: periodForCurrentWeekly?.startDate,
                    endDate: periodForCurrentWeekly?.endDate,
                  },
                  {
                    value: periodForCurrentWeekly?.actualVolume ?? "",
                    cell: "fact",
                    periodId: periodForCurrentWeekly?.id,
                    startDate: periodForCurrentWeekly?.startDate,
                    endDate: periodForCurrentWeekly?.endDate,
                  },
                  {
                    value: periodForCurrentWeekly?.staffVolume ?? "",
                    cell: "staff",
                    periodId: periodForCurrentWeekly?.id,
                    startDate: periodForCurrentWeekly?.startDate,
                    endDate: periodForCurrentWeekly?.endDate,
                  },
                ],
                sectionNameExist: periodsIdx === 0,
                controlType: "WEEKLY",
                isAgreed: periodForCurrentWeekly?.isApproved,
                sectionItemId: period.sectionItemId,
              };
            }

            return {
              days: [
                serializePeriodDataItemForDay(sortedPeriodByDate, 0),
                serializePeriodDataItemForDay(sortedPeriodByDate, 1),
                serializePeriodDataItemForDay(sortedPeriodByDate, 2),
                serializePeriodDataItemForDay(sortedPeriodByDate, 3),
                serializePeriodDataItemForDay(sortedPeriodByDate, 4),
                serializePeriodDataItemForDay(sortedPeriodByDate, 5),
                serializePeriodDataItemForDay(sortedPeriodByDate, 6),
              ],
              sectionNameExist: periodsIdx === 0,
              controlType: "DAILY",
              isAgreed,
              sectionItemId: period.sectionItemId,
            };
          })
        )
        .flat();
    },
    queryKey: [QueriesKeys.sectionItemsPeriod, calendarStartDate, calendarEndDate],
    enabled: location.pathname === "/",
  });

  React.useEffect(() => {
    if (location.pathname === "/") {
      sectionItemsPeriods.refetch();
    }
  }, [sectionItems.data]);

  const leftTableData = sectionItems?.data
    ?.map((sectionItem) =>
      sectionItem.items.map((item, idx) => ({
        control: { value: item.controlType === "DAILY" ? "Дн" : "Нед", rowId: item.id },
        contractor: { value: contractors.data?.find((contractor) => contractor.id === item.contractorId)?.name, rowId: item.id },
        name_of_the_work: { value: works.data?.find((work) => work.id === item.workId)?.name, rowId: item.id },
        sectionName: idx === 0 ? sectionItem.section.name : undefined,
      }))
    )
    .flat();

  const rightTableData = sectionItems?.data
    ?.map((sectionItem) =>
      sectionItem.items.map((item, idx) => ({
        units_of_measurement: { value: works.data.find((work) => work.id === item.workId)?.measureUnit?.value, rowId: item.id },
        "by_gpr/beginning": { value: dayjs(item.startDate).format("DD.MM.YY"), rowId: item.id },
        "by_gpr/ending": { value: dayjs(item.endDate).format("DD.MM.YY"), rowId: item.id },
        "by_gpr/number_of_days": { value: item.days, rowId: item.id },
        total_for_the_project: { value: item.volume, rowId: item.id },
        completed_volume: { value: item.completedVolume, rowId: item.id },
        fact: { value: item.statistic?.actualVolume ?? "", rowId: item.id },
        remains: { value: item.statistic?.leftVolume ?? "", rowId: item.id },
        "%_completed": { value: item.statistic?.completedInPercentage ?? "", rowId: item.id },
        staff_an_average_per_week: {
          value: item.statistic?.staffAverage === null ? "" : item.statistic?.staffAverage.toFixed(2),
          rowId: item.id,
        },
        collapse_days: { value: item.statistic?.actualVolume ?? "", rowId: item.id },
        actual_volime: { value: item.statistic?.actualVolumeOnDate ?? "", rowId: item.id },
        planneed_volue: { value: item.statistic?.plannedVolumeOnDate ?? "", rowId: item.id },
        sectionNameExist: idx === 0,
      }))
    )
    .flat();

  const leftColumns = [
    // columnHelperLeftTable.accessor("control", {
    //   header: text.control,
    //   cell: (info) => (
    //     <div className="flex justify-center gap-4">
    //       {info.row.original.control.value}
    //       {/*
    //       <Dropdown
    //         data={[
    //           { value: "Ежедневный", label: "DAILY" },
    //           // { value: "Еженедельный", label: "WEEKLY" },
    //         ]}
    //         onChange={function (item: { value: string; label?: string; id?: string }): void {
    //           updateSectionItem.mutateAsync({
    //             id: info.row.original.control.rowId,
    //             body: {
    //               controlType: item.label,
    //             },
    //           });
    //         }}
    //         dropdownStyles={{ marginTop: "-20px", marginLeft: "180px" }}
    //         dropdownItemStyles={{ padding: "4px 0" }}
    //         type="simple"
    //         CustomButton={(props) => (
    //           <button onClick={props.onClick} className="w-6 flex justify-center items-center">
    //             <SetMenu />
    //           </button>
    //         )}
    //       /> */}
    //     </div>
    //   ),
    // }),
    columnHelperLeftTable.accessor("contractor", {
      header: text.contractor,
      cell: (info) => {
        const textContractors = info.cell.row.original.contractor.value
          ? info.cell.row.original.contractor.value
          : text.contractorNotDetermined;
        return (
          <>
            {restrictedData.vars.canAddAndChangeObjects ? (
              <Dropdown
                data={
                  contractors?.data?.map((contractor) => ({
                    value: contractor.name,
                    id: contractor.id,
                    label: "",
                  })) ?? []
                }
                onChange={function (item: { value: string; label?: string; id?: string }): void {
                  updateSectionItem.mutateAsync({
                    id: info.cell.row.original.contractor.rowId,
                    body: {
                      contractorId: contractors.data.find((contractor) => contractor.id === item.id).id,
                    },
                  });
                }}
                dropdownStyles={{ marginTop: "-40px", marginLeft: "-15px" }}
                dropdownItemStyles={{ padding: "4px 0" }}
                type="simple"
                CustomButton={(props) => (
                  <div className="flex justify-between gap-4 w-full">
                    <p>{textContractors}</p>
                    <button onClick={props.onClick} className="w-6 flex justify-center items-center">
                      <EditPencil />
                    </button>
                  </div>
                )}
              />
            ) : (
              <p>{textContractors}</p>
            )}
          </>
        );
      },
      enableResizing: true,
      meta: { styleCol: { textAlign: "left", paddingLeft: "8px" } },
    }),
    columnHelperLeftTable.accessor("name_of_the_work", {
      header: text.jobTitle,
      cell: (info) => (
        <>
          {restrictedData.vars.canAddAndChangeObjects ? (
            <Dropdown
              data={
                works?.data?.map((work) => ({
                  value: work.name,
                  id: work.id,
                  label: "",
                })) ?? []
              }
              onChange={function (item: { value: string; label?: string; id?: string }): void {
                updateSectionItem.mutateAsync({
                  id: info.cell.row.original.contractor.rowId,
                  body: {
                    workId: item.id,
                  },
                });
              }}
              dropdownStyles={{ marginTop: "-40px", marginLeft: "-15px" }}
              dropdownItemStyles={{ padding: "4px 0" }}
              type="simple"
              CustomButton={(props) => (
                <div className="flex justify-between gap-4 w-full">
                  <p>{info.renderValue().value}</p>
                  <button onClick={props.onClick} className="w-6 flex justify-center items-center">
                    <EditPencil />
                  </button>
                </div>
              )}
            />
          ) : (
            <p>{info.renderValue().value}</p>
          )}
        </>
      ),
      meta: { styleCol: { textAlign: "left", paddingLeft: "8px" } },
    }),
  ];
  const rightColumnsMain = [
    columnHelperRightTable.accessor("units_of_measurement", {
      header: text.unit,
      cell: (info) => <p>{info.cell.row.original.units_of_measurement.value}</p>,
      meta: { styleCol: { width: "50px" } },
    }),
    columnHelperRightTable.group({
      header: text.byGPR,
      columns: [
        columnHelperRightTable.accessor("by_gpr/beginning", {
          header: text.begin,
          cell: (info) => (
            <>
              {restrictedData.vars.canAddAndChangeObjects ? (
                <DataCell
                  className="flex justify-around items-center gap-3 px-2 cursor-pointer"
                  onClick={() => handleOpenPeriod(info.cell.row.original)}
                >
                  <p>{info.getValue().value}</p>
                  <Icon className="w-6 flex justify-center items-center">
                    <CalendarIcon />
                  </Icon>
                </DataCell>
              ) : (
                <p>{info.getValue().value}</p>
              )}
            </>
          ),
          meta: { styleCol: { width: "70px" } },
        }),
        columnHelperRightTable.accessor("by_gpr/ending", {
          header: text.ending,
          cell: (info) => (
            <>
              {restrictedData.vars.canAddAndChangeObjects ? (
                <DataCell
                  className="flex justify-around items-center gap-3 px-2 cursor-pointer"
                  onClick={() => handleOpenPeriod(info.cell.row.original)}
                >
                  <p>{info.getValue().value}</p>
                  <Icon className="w-6 flex justify-center items-center">
                    <CalendarIcon />
                  </Icon>
                </DataCell>
              ) : (
                <p>{info.getValue().value}</p>
              )}
            </>
          ),
          meta: { styleCol: { width: "70px" } },
        }),
        columnHelperRightTable.accessor("by_gpr/number_of_days", {
          header: text.amountOfDays,
          cell: (info) => (
            <>
              {restrictedData.vars.canAddAndChangeObjects ? (
                <DataCell
                  className="flex justify-around items-center gap-3 px-2 cursor-pointer"
                  onClick={() => handleOpenPeriod(info.cell.row.original)}
                >
                  <p>{info.getValue().value}</p>
                  <Icon className="w-6 flex justify-center items-center">
                    <CalendarIcon />
                  </Icon>
                </DataCell>
              ) : (
                <p>{info.getValue().value}</p>
              )}
            </>
          ),
          meta: { styleCol: { width: "50px" } },
        }),
      ],
    }),
    columnHelperRightTable.accessor("total_for_the_project", {
      header: text.totalForProject,
      cell: (info) => (
        <>
          {restrictedData.vars.canAddAndChangeObjects ? (
            <DataCell
              className="flex justify-center gap-5 items-center cursor-pointer"
              onClick={() => handleOpenModalUpdateTotalForProject(info.cell.row.original)}
            >
              <p>{info.getValue().value}</p>
              <Icon className="w-6 flex justify-center items-center">
                <EditPencilBlue />
              </Icon>
            </DataCell>
          ) : (
            <p>{info.getValue().value}</p>
          )}
        </>
      ),
      meta: { styleCol: { width: "100px", minWidth: "67px" } },
    }),
    columnHelperRightTable.accessor("completed_volume", {
      header: "Выполненный объем",
      cell: (info) => (
        <>
          {restrictedData.vars.canAddAndChangeObjects ? (
            <DataCell
              className="flex justify-center gap-5 items-center cursor-pointer"
              onClick={() => handleOpenModalCompletedVolume(info.cell.row.original)}
            >
              <p>{info.getValue().value ?? 0}</p>
              <Icon className="w-6 flex justify-center items-center">
                <EditPencilBlue />
              </Icon>
            </DataCell>
          ) : (
            <p>{info.getValue().value ?? 0}</p>
          )}
        </>
      ),
      meta: { styleCol: { width: "100px", minWidth: "60px" } },
    }),
  ];

  const deleteColumn = columnHelperRightTable.accessor("delete", {
    header: "",

    cell: (info) => (
      <div className="flex justify-center">
        <button
          className="flex justify-center"
          onClick={() => deleteSectionItem.mutateAsync(info.cell.row.original.units_of_measurement.rowId)}
        >
          <Bin fill="#DB333D" />
        </button>
      </div>
    ),
    enableResizing: true,
    meta: { styleCol: { width: "50px" } },
  });

  const rightColumns = restrictedData.vars.canAddAndChangeObjects ? [...rightColumnsMain, deleteColumn] : rightColumnsMain;

  return {
    columns: {
      left: leftColumns,
      right: rightColumns,
    },
    data: {
      left: leftTableData,
      isLoadingLeft: sectionItems.isFetching,
      right: rightTableData,
      calendarData: sectionItemsPeriods.data ?? [],
    },
  };
};

export default useTableData;

const Icon = styled.div`
  height: 12.7px;
  width: 12.7px;
  cursor: pointer;
`;
export const DataCell = styled.div`
  height: 35px;
  cursor: pointer;
  &:hover {
    /* background-color: ${(props) => props.theme.colors.blue200}; */
    color: ${(props) => props.theme.colors.blue400};
  }
`;
