import React, { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";

import { RenderWithCondition } from "hoc/RenderWithCondition";
import HomeStore from "screens/Home/store/Home.store";
import { getMaxValueDivisionScale } from "screens/Home/utils/getMaxValueDivisionScale";
import { Spacer } from "styles/common";

import BarChartTotal from "../../Charts/BarChart.Total";
import HeaderChartTable from "../../Charts/HeaderChart.Table";
import LineChartCumulative from "../../Charts/LineChart.Cumulative";
import MainTableCharts from "../../TablesCharts/MainTable.Charts";

export interface IHistogramsData {
  date: string;
  planCumulative: number;
  factCumulative: number;
  tooltipLabelCumulative: string;
  labelCumulativePlan: string;
  labelCumulativeFact: string;
  plan: number;
  fact: number;
  personal: number;
  startDate: string;
  endDate: string;
}
interface IWorkItem {
  data?: any;
  dataLineChart?: IHistogramsData[];
  dataBarChart?: IHistogramsData[];
  dataCells?: any[];
  maxScale?: number;
  factorScale: number;
  isDailyPeriods?: boolean;
  isCumulativePeriods?: boolean;
}

const WorkItem: FC<IWorkItem> = observer((props) => {
  const [isWeekly, setWeekly] = useState(HomeStore.typePeriodForCharts);
  const isDaily = HomeStore.typeDataForCharts.label === "Daily";
  const chartLabel = HomeStore.typePeriodForCharts === "WEEKLY" ? "center" : "top";
  const chartInterval = isWeekly === "WEEKLY" ? 0 : 6;
  const maxValuesForScale = getMaxValueDivisionScale(props.dataBarChart);

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1400px)");
    const handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
      setIsLargeScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, [location.pathname]);

  return (
    <>
      <HeaderChartTable dataTable={props.data} onClickCheck={setWeekly} />
      <MainTableCharts dataTable={props.data} dataCells={props.dataCells} />
      <Spacer px={15} />
      <RenderWithCondition condition={isDaily}>
        <BarChartTotal
          data={props.dataBarChart}
          placeLabel={chartLabel}
          interval={chartInterval}
          maxScale={maxValuesForScale}
          factorScale={props.factorScale}
          isValuesOfData={props.isDailyPeriods}
          isLargeScreen={isLargeScreen}
        />
      </RenderWithCondition>
      <RenderWithCondition condition={!isDaily}>
        <LineChartCumulative
          data={props.dataLineChart}
          isValuesOfData={props.isCumulativePeriods}
          isWeekly={HomeStore.typePeriodForCharts === "WEEKLY"}
          isLargeScreen={isLargeScreen}
        />
      </RenderWithCondition>
      <Spacer px={45} />
    </>
  );
});

export default WorkItem;
