import { FC, useEffect } from "react";
import React from "react";

import classNames from "classnames";
import { uniqueId } from "lodash";
import styled from "styled-components";

import emitter from "services/emitter";

import { IDropdownItem } from "./Dropdown";
import { SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY } from "./Dropdown.Overlay";

interface IDropdownList {
  data: IDropdownItem[];
  onChange: (item: { value: string | React.ReactElement; label?: string; id?: string; typeList?: string }) => void;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  color?: string;
  typeOfList?: string;
  style?: React.CSSProperties;
}

const DropdownList: FC<IDropdownList> = (props) => {
  useEffect(() => {
    emitter.addListener("get_data_for_select", (newResponse) => {
      if (newResponse.typeList === props.typeOfList) {
        props.onChange(newResponse);
      }
    });
  }, []);

  return (
    <div className="flex flex-col w-full">
      {props.data?.map((item) => {
        const Icon =
          (location.pathname === item.linkTo || location.pathname.replace(/_.+/, "") === item.parentPath) && item.activeIcon
            ? item.activeIcon
            : item.icon;
        return (
          <React.Fragment key={item.id + uniqueId("list_item_dropdown")}>
            <Container
              className={classNames(
                "flex",
                (location.pathname === item.linkTo || location.pathname.replace(/_.+/, "") === item.parentPath) && "text-blue-800"
              )}
              onClick={() =>
                props.onChange({
                  value: item.value,
                  label: item.label,
                  id: item.id,
                  typeList: props.typeOfList,
                })
              }
            >
              {item.icon && (
                <IconContainer>
                  <Icon />
                </IconContainer>
              )}
              <DropDownButton className="flex">
                <p>{item.value}</p>
                <RightDropDownText>{item.rightText}</RightDropDownText>
              </DropDownButton>
            </Container>
            {item.bottomDivider && <Line />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DropdownList;

const Container = styled.button`
  padding: 8px 0;
  gap: 7px;
  align-items: center;
  &:hover {
    margin-left: -${SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY};
    margin-right: -${SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY};
    padding-left: ${SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY};
    padding-right: ${SM_PADDING_HORIZONTAL_DROPDOWN_OVERLAY};
    background-color: ${(props) => props.theme.colors.blue200};
  }
`;

const DropDownButton = styled.button`
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: baseline;
  width: 100%;
  overflow: hidden;
`;

const Line = styled.span`
  width: calc(100% + 20px);
  transform: translateX(-10px);
  height: 1px;
  background-color: ${(props) => props.theme.colors.absolute300};
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  padding-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightDropDownText = styled.p`
  color: ${(props) => props.theme.colors.absolute500};
  font-size: 12px;
`;
