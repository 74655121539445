import { IWork } from "models/References/IWork";

import apiClient from "../api.client";

export type TGetWorksApi = () => Promise<IWork[]>;

export const getWorksApi: TGetWorksApi = async () => {
  try {
    const res = await apiClient.get("/references/v1/works");

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TCreateWorkApi = (body: { name: string; measureUnitId: string }) => Promise<IWork>;

export const createWorkApi: TCreateWorkApi = async (body) => {
  try {
    const res = await apiClient.post("/references/v2/works", body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TGetWorkByIdApi = (workId: string) => Promise<IWork>;

export const getWorkByIdApi: TGetWorkByIdApi = async (workId: string) => {
  try {
    const res = await apiClient.get(`/references/v1/works/${workId}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TDeleteWorkApi = (workId: string) => Promise<void>;

export const deleteWorkApi: TDeleteWorkApi = async (workId: string) => {
  try {
    const res = await apiClient.delete(`/references/v1/works/${workId}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type TUpdateWorkApi = (data: { body: { name: string; measureUnitId: string }; workId: string }) => Promise<IWork>;

export const updateWorkApi: TUpdateWorkApi = async (data) => {
  try {
    const res = await apiClient.patch(`/references/v1/works/${data.workId}`, data.body);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
