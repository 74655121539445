import { Dayjs } from "dayjs";

import getYearMonths from "./getYearMonths";

export default (dates: { minDate: Dayjs; maxDate: Dayjs }) => {
  const minYear = dates.minDate.year();
  const maxYear = dates.maxDate.year();
  const yearsDiff = maxYear - minYear;

  const allMonths = [];

  for (let i = 0; i <= yearsDiff; i++) {
    if (yearsDiff > 0 && i === 0) {
      allMonths.push(
        getYearMonths({
          year: (minYear + i).toString(),
          fromMonth: dates.minDate.month() - 1,
        })
      );
      continue;
    }

    if (i === yearsDiff) {
      if (i === 0) {
        allMonths.push(
          getYearMonths({
            year: minYear.toString(),
            fromMonth: dates.minDate.month() - 1,
            to: dates.maxDate.month() + 1,
          })
        );
        continue;
      } else {
        allMonths.push(
          getYearMonths({
            year: maxYear.toString(),
            to: dates.maxDate.month() + 1,
          })
        );
        continue;
      }
    }

    allMonths.push(
      getYearMonths({
        year: (minYear + i).toString(),
      })
    );
  }

  return allMonths.flat();
};
