import React, { FC } from "react";

import AuthStore from "store/Auth.Store";

const LogoutSuccess: FC = () => {
  React.useEffect(() => {
    AuthStore.login();
  }, []);

  return null;
};

export default LogoutSuccess;
