import { makeAutoObservable } from "mobx";

import { INode } from "components/Tree/Tree.Node";

class ObjectStore {
  selectedBuilding: INode | undefined = undefined;
  selectedSection: INode | undefined = undefined;
  selectedObject: INode | undefined = undefined;
  selectedUpdateStartPeriod: string | undefined = undefined;
  selectedUpdateEndPeriod: string | undefined = undefined;
  selectedUpdatePeriodRowId: string | undefined = undefined;
  selectedUpdateTotalForProjectRowId: string | undefined = undefined;
  selectedUpdatePeriodDays: string | undefined = undefined;
  selectedObjectParentNames: string[] | undefined = undefined;
  selectedTotalForProject: string | undefined = undefined;
  selectedCompletedVolume: string | undefined = undefined;
  isEmptySection = false;

  sideBarWidth: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setSideBarWidth = (width: string) => {
    this.sideBarWidth = width;
  };

  setSelectedObjectParentNames = (parentNames: string[]) => {
    this.selectedObjectParentNames = parentNames;
  };

  setSelectedObject = (node: INode | undefined) => {
    this.selectedObject = node;
  };

  toggleSelectedBuilding = (node: INode, mode?: "force-set") => {
    if (mode === "force-set") {
      this.selectedBuilding = node;
    } else {
      if (this.selectedBuilding && this.selectedBuilding.id === node.id) {
        this.selectedBuilding = undefined;
        this.selectedSection = undefined;
      } else {
        this.selectedBuilding = node;
      }
    }
  };

  toggleSelectedSection = (node: INode) => {
    if (this.selectedSection && this.selectedSection.id === node.id) {
      this.selectedSection = undefined;
    } else {
      this.selectedSection = node;
    }
  };

  clearSelectedSection = () => {
    this.selectedSection = undefined;
  };

  clearSelectedBuilding = () => {
    this.selectedBuilding = undefined;
  };

  setUpdateStartPeriod = (startPeriod: string) => {
    this.selectedUpdateStartPeriod = startPeriod;
  };
  setUpdateEndPeriod = (startPeriod: string) => {
    this.selectedUpdateEndPeriod = startPeriod;
  };
  setUpdatePeriodRowId = (rowId: string | undefined) => {
    this.selectedUpdatePeriodRowId = rowId;
  };
  setUpdateTotalForProjectId = (rowId: string | undefined) => {
    this.selectedUpdateTotalForProjectRowId = rowId;
  };
  setUpdatePeriodDays = (days: string | undefined) => {
    this.selectedUpdatePeriodDays = days;
  };
  setUpdateTotalForProject = (total?: string) => {
    this.selectedTotalForProject = total;
  };
  setUpdateCompletedVolume = (volume?: string) => {
    this.selectedCompletedVolume = volume;
  };
  setEmptySection = (value: boolean) => {
    this.isEmptySection = value;
  };
}

export default new ObjectStore();
