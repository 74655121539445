import React, { FC, useRef } from "react";

import dayjs from "dayjs";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ReactComponent as ArrowLeftIcon } from "assets/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "assets/arrowRight.svg";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import HomeStore from "screens/Home/store/Home.store";
import CalendarStore from "store/Calendar.Store";
import { LIGHT_THEME } from "theme/colors";

interface ICalendarHeaderChart {
  withOutCollapse?: boolean;
}

const CalendarHeaderChart: FC<ICalendarHeaderChart> = observer((props) => {
  const getDay = (dayNum: number) => capitalize(CalendarStore.calendarStartDate.add(dayNum, "day").locale("ru").format("dd"));
  const getDate = (dayNum: number) => CalendarStore.calendarStartDate.add(dayNum, "day").locale("ru").format("DD");

  const isWeekly = HomeStore.typePeriodForCharts === "WEEKLY";

  const isDiffLeft = CalendarStore.calendarStartDate.month() < CalendarStore.calendarCurrentMonth.month();
  const isDiffRight = CalendarStore.calendarEndDate.month() > CalendarStore.calendarCurrentMonth.month();
  const isSameStartLeft = CalendarStore.calendarStartDate.format("DD") === CalendarStore.calendarCurrentMonth.startOf("month").format("DD");
  const isSameEndRight = CalendarStore.calendarEndDate.format("DD") === CalendarStore.calendarCurrentMonth.endOf("month").format("DD");
  const refAllowChangeCurrMonth = useRef(false);

  const isColoredColumn = (args: { day?: string; date?: string }) => {
    const startDate = CalendarStore.calendarStartDate;
    const endDate = CalendarStore.calendarEndDate;
    const currentDate = dayjs();

    const isGreaterThanStartDate = startDate.diff(currentDate, "days") <= 0;
    const isLessThanEndDate = endDate.diff(currentDate, "days") >= 0;

    if (!isGreaterThanStartDate || !isLessThanEndDate) {
      return false;
    }

    const currentDateFormatted = currentDate.locale("ru").format("DD");
    const currentDayFormatted = capitalize(currentDate.locale("ru").format("dd"));

    if (args.day && currentDayFormatted === args.day) {
      return true;
    }

    if (args.date && currentDateFormatted === args.date) {
      return true;
    }

    return false;
  };
  const colors = LIGHT_THEME;

  const dayColumns = new Array(7).fill(<></>).map((_, idx) => {
    const day = getDay(idx);
    const areaKey = `day${idx + 1}`;
    const coloredCellBlue = isColoredColumn({ day }) ? colors.blue200 : undefined;
    const coloredCellGrey =
      CalendarStore.calendarStartDate.add(idx, "day").month() === CalendarStore.calendarCurrentMonth.month()
        ? coloredCellBlue
        : colors.absolute150;

    return () => (
      <HeaderCell key={areaKey} colored={isWeekly ? coloredCellBlue : coloredCellGrey} area={areaKey}>
        {day}
      </HeaderCell>
    );
  });

  const dateColumns = new Array(7).fill(<></>).map((_, idx) => {
    const date = getDate(idx);
    const areaKey = `date${idx + 1}`;
    const coloredCellBlue = isColoredColumn({ date }) ? colors.blue200 : undefined;
    const coloredCellGrey =
      CalendarStore.calendarStartDate.add(idx, "day").month() === CalendarStore.calendarCurrentMonth.month()
        ? coloredCellBlue
        : colors.absolute150;

    return () => (
      <HeaderCell key={areaKey} colored={isWeekly ? coloredCellBlue : coloredCellGrey} area={areaKey}>
        {date}
      </HeaderCell>
    );
  });

  const onChangeWeeks = (icon: "left" | "right") => {
    if (icon === "left") {
      if (!isWeekly && isDiffLeft) {
        refAllowChangeCurrMonth.current = true;
      } else {
        if (isSameStartLeft) {
          CalendarStore.setCalendarCurrentMonth(dayjs(CalendarStore.calendarCurrentMonth).subtract(1, "month"));
        }
        CalendarStore.setCalendarStartDate(dayjs(CalendarStore.calendarStartDate).subtract(7, "days"));
        CalendarStore.setCalendarEndDate(dayjs(CalendarStore.calendarEndDate).subtract(7, "days"));
        refAllowChangeCurrMonth.current = false;
      }
      if (refAllowChangeCurrMonth.current) {
        CalendarStore.setCalendarCurrentMonth(dayjs(CalendarStore.calendarCurrentMonth).subtract(1, "month"));
        refAllowChangeCurrMonth.current = false;
      }
    } else if (icon === "right") {
      if (!isWeekly && isDiffRight) {
        refAllowChangeCurrMonth.current = true;
      } else {
        if (isSameEndRight) {
          CalendarStore.setCalendarCurrentMonth(dayjs(CalendarStore.calendarCurrentMonth).add(1, "month"));
        }
        CalendarStore.setCalendarStartDate(dayjs(CalendarStore.calendarStartDate).add(7, "days"));
        CalendarStore.setCalendarEndDate(dayjs(CalendarStore.calendarEndDate).add(7, "days"));
        refAllowChangeCurrMonth.current = false;
      }
      if (refAllowChangeCurrMonth.current) {
        CalendarStore.setCalendarCurrentMonth(dayjs(CalendarStore.calendarCurrentMonth).add(1, "month"));
        refAllowChangeCurrMonth.current = false;
      }
    }
  };

  return (
    <ContainerCalendar>
      <HeaderCell colored={colors.blue100} area="collapse_days">
        <PlanText>План / Факт</PlanText>
      </HeaderCell>
      <>
        <RenderWithCondition condition={!isWeekly}>
          <CellArrow left="80px" onClick={() => onChangeWeeks("left")}>
            <ArrowLeftIcon height={10} fill={"#0B4795"} />
          </CellArrow>
        </RenderWithCondition>
        {dayColumns.map((DayColumn) => (
          <>
            <DayColumn />
          </>
        ))}
        {dateColumns.map((DateColumn) => (
          <>
            <DateColumn />
          </>
        ))}
        <RenderWithCondition condition={!isWeekly}>
          <CellArrow onClick={() => onChangeWeeks("right")}>
            <ArrowRightIcon height={10} fill={"#0B4795"} />
          </CellArrow>
        </RenderWithCondition>
      </>
    </ContainerCalendar>
  );
});

export default CalendarHeaderChart;

const HeaderCell = styled.div<{ area: string; colored?: string }>`
  grid-area: ${(props) => props.area};
  background-color: ${(props) => (props.colored ? props.colored : undefined)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  width: 100%;
`;
const CellArrow = styled.button<{ left?: string }>`
  display: flex;
  position: absolute;
  left: ${(props) => (props.left ? props.left : "calc(100% - 17px)")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue200};
  height: 100%;
  border: 1px solid ${(props) => props.theme.colors.absolute200};
  width: 17px;
  transition: 0.4s all;
  &:hover {
    background-color: ${(props) => props.theme.colors.blue300};
    border: 1px solid ${(props) => props.theme.colors.blue300};
  }
`;

const PlanText = styled.p`
  padding: 8px;
  text-align: center;
`;
export const ContainerCalendar = styled.div`
  display: grid;
  grid-template-areas:
    "collapse_days day1 day2 day3 day4 day5 day6 day7"
    "collapse_days date1 date2 date3 date4 date5 date6 date7";
  grid-template-columns: 80px repeat(7, 1fr);
  grid-template-rows: auto;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 12px;
`;
