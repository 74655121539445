import React, { ChangeEvent, FC, useEffect, useState } from "react";

import styled from "styled-components";

export interface IRadioOptions {
  value: string;
  label: string;
}

interface IRadioGroup {
  defaultChecked?: string;
  options?: IRadioOptions[];
  onChange?: React.Dispatch<React.SetStateAction<string>>;
  vertical?: boolean;
  disabled?: boolean;
  gap?: number;
}

const OPTIONS_DEFAULT = [
  { value: "MONDAY", label: "Пн" },
  { value: "Вт", label: "Вт" },
  { value: "Ср", label: "Ср" },
];

const RadioGroup: FC<IRadioGroup> = (props) => {
  const [selectedOption, setSelectedOption] = useState(props.defaultChecked ? props.defaultChecked : "MONDAY");
  const mapValue = props.options ? props.options : OPTIONS_DEFAULT;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    //TODO:  временно скрыть
    setSelectedOption(e.target.value);
    props.onChange(e.target.value);
  };

  useEffect(() => {
    setSelectedOption(props.defaultChecked);
  }, [props.defaultChecked]);

  return (
    <Container>
      {mapValue.map((option, i) => (
        <div key={option.label + i} className="flex flex-col">
          <Input
            type="radio"
            id={option.value}
            value={option.value}
            onChange={handleChange}
            className="radio-input"
            checked={selectedOption === option.value}
            disabled={props.disabled}
          />
          {props.vertical ? (
            <LabelVertical className="" htmlFor={option.value}>
              {option.label}
            </LabelVertical>
          ) : (
            <Label className="" htmlFor={option.value}>
              {option.label}
            </Label>
          )}
        </div>
      ))}
    </Container>
  );
};

export default RadioGroup;

const Label = styled.label`
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 1279px) {
    font-size: 14px;
  }
  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    background-color: ${(props) => props.theme.colors.absolute100};
    border-radius: 50%;
    transition: all 0.3s;
  }
  &::before {
    width: 10.8px;
    height: 10.8px;
    z-index: 2;
    left: 2.75px;
    bottom: 3.6px;
  }
  &::after {
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.theme.colors.blue400};
    left: 0;
    bottom: 1px;
  }
`;
const LabelVertical = styled.label`
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding: 0 10px;
  font-size: 18px;
  line-height: 20px;
  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    background-color: ${(props) => props.theme.colors.absolute100};
    border-radius: 50%;
    transition: all 0.3s;
  }
  &::before {
    width: 10.8px;
    height: 10.8px;
    left: 16.85px;
    bottom: 27.8px;
    z-index: 2;
  }
  &::after {
    width: 16px;
    height: 16px;
    left: 14px;
    bottom: 25px;
    border: 1px solid ${(props) => props.theme.colors.blue400};
  }
`;
const Input = styled.input`
  /* position: absolute; */
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + Label:before {
    content: "";
    background-color: ${(props) => props.theme.colors.blue400};
    transition: all 0.3s;
  }
`;
const Container = styled.div`
  display: flex;
  margin-right: -20px;
  width: 100%;
  justify-content: space-between;
`;
