import { makeAutoObservable } from "mobx";

// mocks
import regions from "mock/tree-select";

type TPeriodOfControl = {
  value: "Показать все" | "Еженедельный" | "Ежедневный" | "Накопительные данные" | "Ежедневные данные";
  label: string;
};

export type TTableMode = "gant" | "histogram";

export type TTypeDataForCharts = "MONTHLY" | "WEEKLY";

class HomeStore {
  regions = regions;
  selectedRegion: string | undefined;
  selectedPerionOfControl: TPeriodOfControl | undefined;

  firstOpenWork: string[] | [];

  tableMode: TTableMode = "histogram";

  isLoadedTree = false;

  typeDataForCharts = { value: "Ежедневные данные", label: "Daily" };
  typePeriodForCharts: TTypeDataForCharts = "WEEKLY";

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoadedTree = (status: boolean) => {
    this.isLoadedTree = status;
  };

  setTableMode = (mode: TTableMode) => {
    this.tableMode = mode;
  };

  setSelectedRegion(region: string) {
    this.selectedRegion = region;
  }

  setPeriodOfControl(periodOfControl: TPeriodOfControl) {
    this.selectedPerionOfControl = periodOfControl;
  }

  setTypeDataForCharts(type: TPeriodOfControl) {
    this.typeDataForCharts = type;
  }
  setTypePeriodForCharts(period: TTypeDataForCharts) {
    this.typePeriodForCharts = period;
  }

  setFirstOpenWork(value: string[]) {
    this.firstOpenWork = value;
  }
}

export default new HomeStore();
