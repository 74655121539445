import { FC } from "react";

import { isEmpty, uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { IGantWork } from "models/Objects/IGantChart";
import HomeChartsStore from "screens/Home/store/Home.Charts.store";

import useAreaQuery from "../hooks/useAreaQuery";

import DataTableHeader from "./DataTable.Header";
import DataTableRow from "./DataTable.Row";

interface IDataTable {}

const DataTable: FC<IDataTable> = observer((props) => {
  const {
    fn: { getFilteredBuildings, getFilteredJobsData },
  } = useAreaQuery();

  const mapBuildingsData = getFilteredBuildings();

  return (
    <GridContainer>
      {mapBuildingsData?.map((building, idx) => {
        const works = getFilteredJobsData(building.works) as IGantWork[];

        return (
          <>
            <DataTableHeader key={building.id} buildingName={building.name} buildingIndex={idx} />
            {works.map((row) => (
              <DataTableRow key={uniqueId(row.id)} row={row} />
            ))}
          </>
        );
      })}
    </GridContainer>
  );
});

export default DataTable;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
`;
