import React, { useState } from "react";

import styled from "styled-components";

import ButtonLight from "components/ButtonLight";
import ModalForm from "components/Modal/ModalForm";
import { RenderWithCondition } from "hoc/RenderWithCondition";
import useReferenceFormsData from "hooks/useReferences/useReferenceFormsData";
import useReferenceTableData from "hooks/useReferences/useReferenceTableData";
import useRestrictedAccessData from "hooks/useRestrictedAccessData";
import useText from "hooks/useText";
import { Screen } from "styles/common";

import RefSectionTable from "./components/RefSectionTable";

const RefSection = () => {
  const restrictedData = useRestrictedAccessData();

  const [isVisible, setIsVisible] = useState(false);

  const text = useText();
  const tableData = useReferenceTableData();
  const formsData = useReferenceFormsData();

  const mapFormCreateTemplate = formsData.formCreateTemplate.data;
  const onSaveCreateTemplate = formsData.formCreateTemplate.onSave;
  const isLoadingCreateTemplate = formsData.formCreateTemplate.isLoading;

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  return (
    <Screen className="w-full">
      <Container className="md:container  flex">
        <TableContainer className="flex flex-col">
          <Text className="text-xl font-bold">{text.referenceSection}</Text>
          <RefSectionTable data={tableData.data.templateSection} columns={tableData.columns.templateColumns} />
          {restrictedData.vars.canCreateAndUpdateReferenceSection && (
            <ButtonRow className="flex">
              <ButtonLight type="secondary" title={text.add} onClick={handleOpenModal} />
            </ButtonRow>
          )}
        </TableContainer>
        <RenderWithCondition condition={isVisible}>
          <ModalForm
            onSave={onSaveCreateTemplate}
            textButton={text.save}
            data={mapFormCreateTemplate}
            label={text.addValue}
            setIsVisible={setIsVisible}
            isLoading={isLoadingCreateTemplate}
          />
        </RenderWithCondition>
      </Container>
    </Screen>
  );
};

export default RefSection;

const TableContainer = styled.div`
  flex: 1;
`;
const Container = styled.section`
  padding-top: 20px;
`;
const Text = styled.p`
  color: ${(props) => props.theme.colors.absolute700};
  padding-left: 20px;
  padding-bottom: 20px;
`;
const ButtonRow = styled.p`
  padding: 20px;
`;
